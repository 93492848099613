import React from "react";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { ReactComponent as ExportIcon } from "../../assets/ExportIcon.svg"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { REACT_URL_GENERATED_REPORTS } from "../../constants/ReactEndPoints";
import { getOperationLogReport } from "../../services/OperationService";
import CommonUtil from "../../util/CommonUtils";
import { useSnackbar } from "notistack";

export default function OperationLogExport(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onSubmitExportAll = () => {
        enqueueSnackbar(t('COMMON047'), {
            variant: 'success'
        })

        try {
            let executingState = props.executingState
            let operatorName = props.operator === "" ? "" : "operatorName " + props.operator;
            const defaultColumns = ['operator', 'operatorTime', 'deviceIpAddress', 'operateFunction', 'executingState', 'description', executingState];
            const payload = ['operator' + "," + props.selectedColumn, executingState, operatorName]
            const payloadArray = payload.join(',').split(",")
            handleClose()
            getOperationLogReport(payloadArray, (res) => {
                if (res.data.code === 'CASI301') {
                    enqueueSnackbar(t('ACCEVENT015'), {
                        variant: "success"
                    })
                }
            })
        }
        catch (err) {
            enqueueSnackbar(t('COMMON034'), {
                variant: 'error'
            })
        }
    }

    const onSubmitSelected = () => {
        if (!CommonUtil.isEmpty(props.ids)) {
            try {
                enqueueSnackbar(t('COMMON047'), {
                    variant: 'success'
                })

                let operatorName = props.operator === "" ? "" : "operatorName " + props.operator;
                const payload = props.ids + "," + ["operator" + "," + props.selectedColumn, props.executingState, operatorName]
                const payloadArray = payload.split(",");

                const payloadWithIds = payloadArray.map(item => {
                    if (props.ids.includes(item)) {
                        return `id=${item}`
                    } else {
                        return item
                    }
                })
                handleClose()
                getOperationLogReport(payloadWithIds, (res) => {
                    if (res.data.code === 'CASI301') {
                        enqueueSnackbar(t('ACCEVENT015'), {
                            variant: 'success'
                        })
                    }
                })
            } catch (error) {
                enqueueSnackbar(t('COMMON034'), {
                    variant: 'error'
                })
            }
        }
    }

    const onSubmitGenrated = () => {
        navigate(REACT_URL_GENERATED_REPORTS)
    }

    const operationsLogsIds = props.ids.length < 1 === true
    return (
        <>
            <Tooltip title={t('ACCEVENT004')}>
                <Box
                    aria-label="export"
                    id="icon-operationlog-export"
                    sx={{ display: { xl: 'block', xs: 'none' } }}
                    onClick={handleClick}
                >
                    <ExportIcon
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    />
                </Box>
                <Box
                    aria-label="export"
                    id="icon-operationlog-export"
                    sx={{ display: { xl: 'none', xs: 'block' } }}
                    onClick={handleClick}
                >
                    <ExportIcon
                        width={40}
                        height={40}
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        style={{ cursor: 'pointer' }}
                    />

                </Box>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                id="basic-menu"
                MenuListProps={{
                    "aria-labelledby": "basic-button",

                }}
            >
                <MenuItem disabled={props.totalRecords === 0 || props.totalRecords === undefined} className="menu-item-popup"
                    sx={{
                        lineHeight: '21px!important', cursor: "pointer", "&:hover": {
                            backgroundColor: "#F4FCF7 !important",
                            color: ' #76B745 !important'
                        }
                    }}
                    onClick={onSubmitExportAll}
                >
                    {t('ACCEVENT037')}
                </MenuItem>

                <MenuItem className="menu-item-popup"
                    sx={{
                        lineHeight: '21px!important', cursor: "pointer", "&:hover": {
                            backgroundColor: "#F4FCF7 !important",
                            color: ' #76B745 !important'
                        }
                    }}
                    onClick={onSubmitSelected}
                    disabled={operationsLogsIds}

                >
                    {t('ACCEVENT039')}
                </MenuItem>
                <MenuItem className="menu-item-popup"
                    sx={{
                        lineHeight: '21px!important', cursor: "pointer", "&:hover": {
                            backgroundColor: "#F4FCF7 !important",
                            color: ' #76B745 !important'
                        }
                    }}
                    onClick={onSubmitGenrated}
                >
                    {t('ACCEVENT038')}
                </MenuItem>
            </Menu>

        </>
    )

}