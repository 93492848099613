import api from "../configurations/http-common";

export const loadDeviceReader = async (page, size, filter) => {
    let query = 'pageNumber=' + page + '&pageSize=' + size + buildQuery(filter);
   return  api.securedAxios().get('/web/devices/readers?' + query);

   function buildQuery(filter) {
      let query = '';

    if(filter!=undefined){
        if (filter.doorName != '' && filter.doorName == undefined) {
            query = query + `&doorName= ${filter.doorName}`.replace(" ", "");
  
        }
        if (filter.deviceAlias != '' && filter.deviceAlias!=undefined) {
            query = query + `&deviceAlias= ${encodeURIComponent(filter.deviceAlias)}`.replace(" ", "");
        }
  
        if (filter.name != ''  && filter.name!=undefined) {
            query = query + `&name= ${filter.name}`.replace(" ", "");
        }
    }
      return query

  }
}

export const loadDeviceReaderById = async (id) => {
   return  api.securedAxios().get('/web/readers/' + id);
}

export const updateReader = async (id, payload) => {
   return api
   .securedAxios()
   .put('/web/readers/' + id, payload);
 };