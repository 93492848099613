import React, { useEffect, useState } from 'react';
import {  Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import CommonUtil from '../../util/CommonUtils';
import 'antd/dist/antd.css';


import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import ViewPage from '../../components/ViewPage';
import CreateNewTimeSlot from './CreateNewTimeSlot';

export default function AddTimeSlot() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const [perAddTimeslot, setAddTimeslot] = useState(false);
  const [permissions, setPermissions] = useState([]);
  
    useEffect(() => {
      getPermissionsByRoleId(api.decoded().roleId).then((res) => {
        if (res.data.code === "CACCZE001") {
          res.data.data.rolePermissions.map((item, index) => {
            setPermissions(prev => [...prev, item.code])
          });
        }
        setAddTimeslot(true);
      });
    }, []);

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  return (
    <>
      {perAddTimeslot ? checkPermission("ADD_TIMESLOT")?(<ViewPage
        title={t("AccTime014")}
        onClick={() => navigate(
          '/time-slots',
          { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
      >
        <CreateNewTimeSlot buttonReq={true}/>
        </ViewPage>)
        : (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t('AccTime021')} </Typography>
        </Box>):<></>}

    </>
  );
}
