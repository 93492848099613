import { Box, Typography } from '@mui/material'
import React from 'react';
import { ReactComponent as DeleteOne } from "../../../assets/new-images/Delete Icon.svg";
import { ReactComponent as FingerPrint } from './Image/fingerprint.svg';

const CredentialCountBox = (props) => {
   
    return (
        <>
          <Box sx={{ 
            border: '1px solid #76B745', 
            borderRadius: '5px', 
            width: '100%', 
            height:{xl:"40px",xs:"30px"},
            margin: '2% 2%',
            display: 'flex',
            justifyContent: 'space-around', 
            alignItems: 'center'}}>
              {props.icon ? props.icon : <FingerPrint />}
              <Typography style={{ fontSize: '90%' }}>{props.value}</Typography>
              {/* <DeleteOne opacity={'0.7'} height={'75%'} style={{cursor:'pointer'}}
              onClick={()=>props.deleteIndividualCredential({
                data:props.data,
                credentialType:props.credentialType,
                value:props.value
              })}
              /> */}
          </Box>
        </>
      )
}

export default CredentialCountBox
