import { Box, Typography, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import OverFlowText from '../../components/OverFlowText';
import TitleBar from '../../components/TitleBar';
import { useNavigate } from 'react-router-dom';
import CustomizedAccordions from './CustomizedAccordion';
import { getPermissionsByRoleId, roleList } from '../../services/UserRolesService';
import { defaultPermissionCode } from './defaultPermissionCode';

import api from '../../configurations/http-common';
import { REACT_URL_PAGE_NOT_FOUND, REACT_URL_USERROLES } from '../../constants/ReactEndPoints';
import CommonUtil from '../../util/CommonUtils';
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';
import OwnerViewAccordions from './OwnerViewAccordions';

export default function ViewUserRole() {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const location = useLocation();
  let navigate = useNavigate();
  const [permissions, setPermissions] = useState(defaultPermissionCode);
  const defaultPermissions = useState(defaultPermissionCode);
  const [viewPermission, setViewPermission] = useState(true);
  const [roles, setRoles] = React.useState(['']);
  const { roleCode } = useParams();
  const [assignPermission, setAssignPermission] = useState([])
  const [viewErrorPage, setViewErrorPage] = useState(false);
  const [loader, setLoader] = React.useState(true);

  const [state, setState] = useState({
    name: "",
    code: "",
    id: ""
  })

  useEffect(() => {
    if (state.id !== "")
      getPermissionsByRoleId(state.id).then((res) => {
        if (res?.data?.code === "CACCZE001") {
          setData(res.data.data.rolePermissions);
        }
      });
  }, [state])

  useEffect(() => {
    if (CommonUtil.isEmpty(roleCode)) {
      navigate({
        pathname: REACT_URL_PAGE_NOT_FOUND,
      });
    }
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setAssignPermission(prev => [...prev, item.code])
        });
      }
      setViewPermission(true);
    });
  }, []);

  useEffect(() => {
    if (checkPermission("ACC_VIEW_PERMISSIONS")) {
      loadUserDetails();
    }
  }, [assignPermission])

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = assignPermission.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  const loadUserDetails = () => {
    const payload1 = {
      name: "",
      code: (roleCode).toLowerCase() === 'super admin' ? 'SUPERADMIN' : roleCode,
    }
    roleList(payload1).then((res) => {
      setLoader(true);
      if (res?.data?.code === 'UASI0000') {
        if (res.data.data.totalCount > 0){
          const roles = res.data.data.roles;
          const matchingRole = roles.find(role => role.code === payload1.code);
          if (matchingRole) {
            setState(matchingRole);
          }
         } else {
          setViewErrorPage(true);
          setState({
            name: "",
            code: ""
          });
        }
      } else {
        setState({
          name: "",
          code: ""
        })
        setViewErrorPage(true);
      }
      setLoader(false);
    });
  }

  useEffect(() => {
    if (data instanceof Array && data.length > 0) {
      var newPermissions = { ...permissions };
      data.map((data) => {
        newPermissions[data.code] = true;
      });
      setPermissions({
        ...permissions,
        ...newPermissions,
      });
    } else {
      setPermissions({
        ...defaultPermissions,
      });
    }
  }, [data]);

  const headerStyle = {
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'rgba(71, 75, 79, 0.8)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Roboto",
  }

  return (
    <>
      {viewErrorPage ? <PageNotFound /> : viewPermission ? checkPermission("ACC_VIEW_PERMISSIONS") ?
        <>
          <TitleBar title={t('ACCRolPerm054')} path={true}
            onClick={() => navigate('/roles')} />
          <Box
            sx={{
              width: "100%",
              marginTop: { xl: "30px", xs: "15px" }
            }}>
            <Box item flexGrow={1} paddingBottom={1}>
              <Typography sx={headerStyle} >
                {t('ACCRolPerm055')}
              </Typography>
            </Box>
            <Paper elevation={0}
              sx={{
                backgroundColor: '#ffffff',
                border: "1px solid #DDDDDD",
                borderRadius: '8px',
                padding: "20px",
                overflow: 'hidden',
                '&:hover': {
                  boxShadow: "2px 0px 4px #0000001A, -2px 0px 4px #0000001A, 0px 2px 4px #0000001A, 0px -2px 4px #0000001A",
                  cursor: 'pointer',
                  borderRadius: "8px",
                },

              }}>
              <Box display='flex' padding={1}>
                <Box item paddingRight={1} sx={{cursor:'auto'}} >
                  <OverFlowText variant='fieldLabel' >User Role Name :</OverFlowText>
                </Box>
                <Box item>
                  <Typography variant='fieldValue'sx={{cursor:'auto'}}>
                    {(state.code) === "SUPERADMIN" ? "Super Admin" : state.name}</Typography>
                </Box>
              </Box>
              <Box display='flex' padding={1} paddingBottom={2}>
                <Box item paddingRight={1} sx={{cursor:'auto'}} >
                  <OverFlowText variant='fieldLabel'>User Role Code :</OverFlowText>
                </Box>
                <Box item>
                  <Typography sx={{cursor:'auto'}} variant='fieldValue'>{(state.code) === "SUPERADMIN" ? "SUPER ADMIN" : state.code}</Typography>
                </Box>
              </Box>
            </Paper>
            {loader ? <Loader open={loader} /> : <></>}
            <Box item flexGrow={1} paddingBottom={1} paddingTop={3}>
              <Typography sx={headerStyle}>
                {t('ACCRolPerm011')}
              </Typography>
            </Box>
            {
              state.code === "ORGANIZATION OWNER" || state.code === "SUPERADMIN" ? <OwnerViewAccordions permissions={permissions} role={state.code} setPermissions={(v) => setPermissions(v)} disabled={true} /> : <CustomizedAccordions permissions={permissions} role={state.code} setPermissions={(v) => setPermissions(v)} disabled={true} />
            }
          </Box>
        </>
        : (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t("ACCRolPerm057")}</Typography></Box>) : <></>}
    </>
  );
}
