import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RightViewLayout from '../../components/RighViewLayout'
// import { ReactComponent as QuickSetup } from '../../assets/QuickSetupImage.svg'
import infoPage from "../../assets/InfoPage.png"
import { useTranslation } from 'react-i18next'
import { GetContext } from '../../AppStore';
import { useNavigate } from 'react-router-dom';
import { REACT_URL_QUICK_SETUP_PAGE } from '../../constants/ReactEndPoints'
import { getPermissionsByRoleId } from '../../services/RoleService'
import api from '../../configurations/http-common'

const InfoPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const value = GetContext();
  const resetContext = () => {
    value.setQuickSetupPayload({
      accessGroup: {
        name: "",
        schedulerId: "",
        startTime: "",
        endTime: ""
      },
      personIds: [],
      doorIds: []
    });
    value.setErrorQuickSetupPayload({
      accessGroup: {
        name: "",
        schedulerId: "",
        startTime: "",
        endTime: ""
      },
      personIds: '',
      doorIds: ''
    });
  }

  const [roles, setRoles] = React.useState([]);
  const [viewpage, setViewPage] = React.useState(false)

  useEffect(() => {
    getPermissionsByRoleId(api.decoded()?.roleId).then((res) => {
      var l = [];
      res.data?.data?.rolePermissions.map((item, index) => {
        //setRoles(...roles,item.permissionCode);
        l.push(item.code);
      });
      setRoles(l);
      setViewPage(true)
    });
  }, []);

  const checkPermission = (code) => {
    if (api.decoded() != undefined &&
      (api.decoded()?.roleCode === 'OWNER' || api.decoded()?.roleCode === 'SUPERADMIN'))
      return true;
    return roles.includes(code);
  }

  const headingStyle = {
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 500,
    color: "#474B4F",
    textAlign: "center"
  }
  return (
    <>
      {viewpage ? checkPermission("ADD_EDIT_ACCESS_GROUP") ? <> <RightViewLayout
        navigateBack={true}
        title={t('SIDEBAR022')}
      />
        <Box
          sx={{
            marginTop: "15px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
          }}>
          <Box>
            <Typography sx={headingStyle}>Access Control Workflow</Typography>
            <Box mt={2} sx={{ display: { xl: "block", lg: "none", md: "none", xs: "none" } }} >
              <img src={infoPage} alt="" width={"90%"} />
            </Box>
            <Box sx={{ display: { xl: "none", lg: "flex", md: "flex", xs: "flex" }, alignItems: "center", justifyContent: "center" }} >
              <img src={infoPage} alt="" width="80%" />
            </Box>
          </Box>
          <Box mt={3}>
            <Button
              variant='contained'
              onClick={() => { resetContext(); navigate(REACT_URL_QUICK_SETUP_PAGE); }}
              sx={{
                width: { xl: "233px", xs: "180px" },
                height: { xl: "64px", xs: "50px" }
              }}
            >
              {t("SIDEBAR022")}
            </Button>
          </Box>
        </Box></> : <Box marginTop={'150px'} width={'100%'}>
        <Typography textAlign={'center'}>
          {t("AccGroupAccessTime050")}
        </Typography></Box> : <></>
      }    </>
  )
}

export default InfoPage
