import { ReactComponent as Delete } from "../../assets/new-images/Delete Icon.svg";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Filter } from "../../assets/new-images/Filter icon (1).svg";
import { ReactComponent as Add } from "../../assets/new-images/Plus icon.svg";
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import DataTable from '../../components/layout-components/DataTable';
import RightViewLayout from "../../components/RighViewLayout";
import CommonUtil from '../../util/CommonUtils';
import { REACT_URL_ACCESS_GROUP_ADD_PERSON, REACT_URL_PAGE_NOT_FOUND } from "../../constants/ReactEndPoints";
import { deleteAcccessGroupPersonById, getFilterAccessGroup, loadAccessGroupPersons } from "../../services/AccessGroupService";
import { useSnackbar } from "notistack";
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from "react-i18next";
import PageNotFound from "../../components/PageNotFound";
import CustomDialogBox from "../../components/CustomDialogBox";
import PaginationContext from "../../components/layout-components/PaginationContext";

function AccessGroupPerson(props) {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    const { manageAGPersonPageNumber, setManageAGPersonPageNumber, manageAGPersonPageSize, setManageAGPersonPageSize } = useContext(PaginationContext)
    const [page, setPage] = React.useState(manageAGPersonPageNumber ? manageAGPersonPageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(manageAGPersonPageSize ? manageAGPersonPageSize : 10);
    const [pageSize, setPageSize] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [Filtered, setFiltered] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [roles, setRoles] = React.useState(['']);
    const [ids, setIds] = React.useState([]);
    const { id } = useParams();
    const [viewErrorPage, setViewErrorPage] = React.useState(false)
    const [accessId, setAccessId] = React.useState("");
    const [permissions, setPermissions] = React.useState([]);
    const [delId, setDelId] = React.useState('');
    const [del, SetDel] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);

    const [addPersonAccessGroupList, setAddPersonAccessGroupList] = React.useState(false);
    const [managePersonAccessGroupList, setManagePersonAccessGroupList] = React.useState(false);

    const [defaultFilter, setDefaultFilter] = React.useState({
        firstName: '',
        lastName: '',
        code: ''
    });

    const [filterData, setFilterData] = React.useState({
        ...defaultFilter
    });
    const DEFAULT_PAGE_SIZE = 10;

    const resetFilter = () => {
        if (page === 1) {
            loadData(1, manageAGPersonPageSize, filterData);
            setRowsPerPage(manageAGPersonPageSize);
        } else {
            setPage(manageAGPersonPageNumber);
            loadData(manageAGPersonPageNumber, manageAGPersonPageSize, filterData);
            setRowsPerPage(manageAGPersonPageSize);
        }
    };

    const loadAccessGroup = (page, size) => {
        const filter = {
            id: id,
        }
        getFilterAccessGroup(1, 1, filter).then((res) => {
            if (
                res?.status === 200 &
                res != null &&
                !CommonUtil.isEmpty(res) &&
                res?.data?.code === 'DMSI0000' &&
                !CommonUtil.isEmpty(res?.data?.data) &&
                res.data.data.accessGroups instanceof Array
            ) {
                setAccessId(res.data.data.accessGroups[0].id);
            } else {
                setViewErrorPage(true)
            }
        });
    };

    const loadData = (pageNumber, pageSize, filter) => {
        setIds([])
        if (id) {
            loadAccessGroupPersons(accessId, pageNumber, pageSize, filter).then((res) => {
                if (res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data)) {
                    setTotalRecords(res.data.data.totalCount);
                    setData(res.data.data.levelPersons);
                    setPageSize((res.data.data?.levelPersons) instanceof Array ? (res.data.data?.levelPersons).length : 0);
                } else {
                    setData([]);
                    setTotalRecords(0);
                    setPageSize(0);
                }
            });
        }
    }

    const checkPermission = (permissionCode) => {
        if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
            return true;
        else {
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
    }

    useEffect(() => {
        if (accessId !== "")
            loadData(page, rowsPerPage, filterData);
    }, [accessId]);

    useEffect(() => {
        if (checkPermission("ADD_PERSONS_ACCESS_GROUP")
            || checkPermission("MANAGE_PERSONS_ACCESS_GROUP"))
            loadAccessGroup();
    }, [permissions]);

    useEffect(() => {
        if (CommonUtil.isEmpty(id)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if (res.data.code === "CACCZE001") {
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev => [...prev, item.code])
                });
            }
            setAddPersonAccessGroupList(true);
            setManagePersonAccessGroupList(true)
        });
    }, []);

    const columns = [
        {
            field: "firstName",
            minWidth: 190,
            headerName: t('COMMON030'),
            flex: 1,
        },
        {
            field: "lastName",
            minWidth: 190,
            headerName: t('COMMON031'),
            flex: 1,
        },
        {
            field: "code",
            minWidth: 190,
            headerName: t('COMMON032'),
            flex: 1,
        },
        {
            field: "Actions",
            headerName: t('COMMON019'),
            type: "actions",
            flex: 1,
            minWidth: 190,
            headerAlign: "center",
            getActions: (params) => [
                <>
                    {checkPermission("ADD_PERSONS_ACCESS_GROUP") &&
                        <GridActionsCellItem
                            icon={<Tooltip title={t('COMMON008')}>
                                <Delete id="icon-accessgroup-person-delete" /></Tooltip>}
                            onClick={() => deleteRecord([params.row])}
                        />}
                </>
            ],
        },

    ]
    const deleteRecord = (data) => {
        setOpenDelete(true);
        setDelId(data);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleSubmitDelete = () => {
        if (delId.length < 1) {
            enqueueSnackbar(t('AccGroupAccessTime014'), {
                variant: 'warning',
            });
        }
        let name = delId[0].firstName + " " + delId[0].lastName

        deleteAcccessGroupPersonById(accessId, delId[0].id, name).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                enqueueSnackbar(t('AccGroupAccessTime034'), {
                    variant: 'success',
                });
                SetDel(true);
            } else {
                enqueueSnackbar(res.data.msg, {
                    variant: 'error',
                });
            }
            setOpenDelete(false);
        });
    };

    useEffect(() => {
        if (del === true && parseInt(pageSize) !== 1) {
            loadData(page, rowsPerPage, defaultFilter);
            SetDel(false);
        } else {
            if (del === true) {
                if (page !== 1) {
                    setPage(page - 1);
                    loadData(page - 1, rowsPerPage, defaultFilter);
                } else {
                    setPage(1);
                    loadData(1, rowsPerPage, defaultFilter);
                }

                SetDel(false);
            }
        }
    }, [del]);

    const gotoAddAccessGroupPerson = () => {
        navigate(REACT_URL_ACCESS_GROUP_ADD_PERSON + `/${id}`,
            { state: { pageNumber: page, pageSize: rowsPerPage } }
        );
    };

    const actions = () => (
        <Box display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }}>
            <Tooltip title={t('COMMON002')}>
                <Box
                    aria-label="refresh"
                    onClick={() => resetFilter()}
                    mr={2}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"refresh-Icon"}
                >
                    <Refresh id="icon-accessgroup-person-refresh" style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => resetFilter()}
                    mr={2}
                    sx={{ display: { xl: "none", xs: "block" } }}
                >
                    <Refresh id="icon-accessgroup-person-refresh" width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
            {addPersonAccessGroupList ? checkPermission("ADD_PERSONS_ACCESS_GROUP") ?
                (<>
                    <Box
                        aria-label="add-device"
                        sx={{ display: { xl: "block", xs: "none" } }}
                        id={"add-accessgroup-Icon"}
                    >
                        <Tooltip title={t('COMMON033')}>
                            <Add id="icon-accessgroup-person-add" style={{ cursor: 'pointer' }}
                                onClick={() => gotoAddAccessGroupPerson()}
                            />
                        </Tooltip>
                    </Box>
                    <Box
                        aria-label="add-device"
                        sx={{ display: { xl: "none", xs: "block" } }}
                        id={"add-accessgroup-Icon"}
                    >
                        <Tooltip title={t('COMMON033')}>
                            <Add id="icon-accessgroup-person-add" height={"40px"} style={{ cursor: 'pointer' }}
                                onClick={() => gotoAddAccessGroupPerson()}
                            />
                        </Tooltip>
                    </Box>
                </>) : <></> : <></>}
        </Box>
    );


    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    }

    return (
        <>
            {viewErrorPage ? <PageNotFound /> : managePersonAccessGroupList ? checkPermission("MANAGE_PERSONS_ACCESS_GROUP") ? <><RightViewLayout
                title={t('AccGroupAccessTime007')}
                path={true}
                onClick={() => navigate('/group-access-time',
                    { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
            >
                {actions()}
            </RightViewLayout>
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        getRowId={(row) => row.id}
                        columns={columns}
                        rows={data}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        count={totalRecords}
                        onPageChange={(e) => {
                            const CurrentPage = CommonUtil.getPageNumber(e);
                            setPage(CurrentPage);
                            setManageAGPersonPageNumber(CurrentPage);
                            loadData(CurrentPage, rowsPerPage, filterData);
                        }}
                        onPageSizeChange={(e) => {
                            setRowsPerPage(e);
                            setManageAGPersonPageSize(e);
                            loadData(page, e, filterData)
                        }}
                        noRecordText={t("AccGroupAccessTime035")} />
                </Box>
            </> : (<Box marginTop={'150px'} width={'100%'} justifyContent={"center"} display={"flex"}>
                <Typography textAlign={'center'}>{t("AccGroupAccessTime042")}</Typography>
            </Box>) : <></>}
            <CustomDialogBox
                id="delete-person-group-access"
                open={openDelete}
                customWidth={'420px'}
                title={t("COMMON013")}
                message={t("AccGroupAccessTime033")}
                saveButtonName={t("COMMON008")}
                cancelButtonName={t("COMMON009")}
                onClose={handleCloseDelete}
                handleSubmit={handleSubmitDelete}
            />

        </>);
}

export default AccessGroupPerson;