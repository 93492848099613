import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localeID from 'antd/es/date-picker/locale/id_ID';
import localeJA from 'antd/es/date-picker/locale/ja_JP';
import localeRU from 'antd/es/date-picker/locale/ru_RU';
import localeTH from 'antd/es/date-picker/locale/th_TH';
import localeZH from 'antd/es/date-picker/locale/zh_CN';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from '../../components/CustomInput';
import { getTimeZoneIntervalList, saveScheduleTimeZoneInterval } from '../../services/SchedulesService';
import BrowserLanguage from '../../util/BrowserLanguage';
import CommonUtil from '../../util/CommonUtils';
import { TimePicker } from 'antd';
import moment from 'moment-timezone';
import 'antd/dist/antd.css';
import { useSnackbar } from 'notistack';

import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import ViewPage from '../../components/ViewPage';
import { GetContext } from '../../AppStore';
import Loader from '../../components/Loader';
import CustomButton from '../../components/CustomButton';

const CreateNewTimeSlot = (props) => {
    const { t } = useTranslation();
    const language = BrowserLanguage.getDefaultLanguage();
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();
    const location = useLocation();
    const value=GetContext();
    const[loader,setLoader]=React.useState(false);
    const [isLoading, setIsLoading] = useState(false);

  
    const [copyMonday, setCopyMonday] = useState(false);
    const [copyWeekend, setCopyWeekend] = useState(false);
    const [monday1, setMonday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [monday2, setMonday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [monday3, setMonday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [tuesday1, setTuesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [tuesday2, setTuesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [tuesday3, setTuesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [wednesday1, setWednesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [wednesday2, setWednesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [wednesday3, setWednesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [thursday1, setThursday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [thursday2, setThursday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [thursday3, setThursday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [friday1, setFriday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [friday2, setFriday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [friday3, setFriday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [saturday1, setSaturday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [saturday2, setSaturday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [saturday3, setSaturday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [sunday1, setSunday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [sunday2, setSunday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [sunday3, setSunday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  
    const [error, setError] = useState({
      name: '',
      remark: 'false',
    });
   
    const localeMap = {
      en: localeEN,
      es: localeES,
      zh: localeZH,
      ru: localeRU,
      ja: localeJA,
      th: localeTH,
      idn: localeID,
    };
  
    const defaultPayload = {
      id: '',
      companyId: '',
      name: '',
      remark: '',
      sundayStart1: '',
      sundayEnd1: '',
      sundayStart2: '',
      sundayEnd2: '',
      sundayStart3: '',
      sundayEnd3: '',
      mondayStart1: '',
      mondayEnd1: '',
      mondayStart2: '',
      mondayEnd2: '',
      mondayStart3: '',
      mondayEnd3: '',
      tuesdayStart1: '',
      tuesdayEnd1: '',
      tuesdayStart2: '',
      tuesdayEnd2: '',
      tuesdayStart3: '',
      tuesdayEnd3: '',
      wednesdayStart1: '',
      wednesdayEnd1: '',
      wednesdayStart2: '',
      wednesdayEnd2: '',
      wednesdayStart3: '',
      wednesdayEnd3: '',
      thursdayStart1: '',
      thursdayEnd1: '',
      thursdayStart2: '',
      thursdayEnd2: '',
      thursdayStart3: '',
      thursdayEnd3: '',
      fridayStart1: '',
      fridayEnd1: '',
      fridayStart2: '',
      fridayEnd2: '',
      fridayStart3: '',
      fridayEnd3: '',
      saturdayStart1: '',
      saturdayEnd1: '',
      saturdayStart2: '',
      saturdayEnd2: '',
      saturdayStart3: '',
      saturdayEnd3: '',
    }
    const [payload, setPayload] = React.useState(defaultPayload);
  
    useEffect(() => {
      if (copyMonday) handleCopyMonday(monday1, monday2, monday3);
      if (copyWeekend) handleCopyWeekend(monday1, monday2, monday3);
    }, [monday1, monday2, monday3]);
  
    const timeFormat = 'HH:mm';
  
    const handleChange = (event) => {
      var val = event.target.value;
      if (val.trim() === '') {
        val = val.trim();
      }
      if (event.target.value.length > 0 && val.length == 0) {
        return;
      }
      if (val.length > 2) {
        if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
          return;
        }
        if (val[0] === ' ') {
          return;
        }
      }
      if (event.target.name === "copyMonday") {
        setCopyMonday(event.target.checked);
        var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
        if (event.target.checked) handleCopyMonday(monday1, monday2, monday3);
        else handleCopyMonday(defaultTime, defaultTime, defaultTime);
      }
      if (event.target.name === "copyWeekend") {
        setCopyWeekend(event.target.checked);
        var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
        if (event.target.checked) handleCopyWeekend(monday1, monday2, monday3);
        else handleCopyWeekend(defaultTime, defaultTime, defaultTime);
      }
  
    };
  
    const findByName = async (name) => {
      var res = false;
  
      await getTimeZoneIntervalList(1, 1, name, (res) => {
        if (res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data) && res?.data?.data?.totalCount > 0) {
          res = true;
        } else {
          res = false;
        }
      });
      return res;
    };

    useEffect(()=>{
      if(props.handleDailogSubmit)  {
        handleSubmit()
      }

    },[props.handleDailogSubmit])

    useEffect(()=>{
        if(props.handleCloseDialog)  {
            handleClose();
            props.handleAfterClear(); 
        }
    },[props.handleCloseDialog])
  
    const handleSubmit = () => {

        if(props.handleDailogSubmit)  {
            props.handleAfterSubmit();
        }
      
      if (CommonUtil.isEmptyString(payload.name)) {
        setError({
          ...error,
          name: t('COMMON027'),
        });
        return;
      }
  
      if (monday1[1].isAfter(monday2[0]) && monday2[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (monday2[1].isAfter(monday3[0]) && monday3[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (tuesday1[1].isAfter(tuesday2[0]) && tuesday2[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (tuesday2[1].isAfter(tuesday3[0]) && tuesday3[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (wednesday1[1].isAfter(wednesday2[0]) && wednesday2[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (wednesday2[1].isAfter(wednesday3[0]) && wednesday3[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (thursday1[1].isAfter(thursday2[0]) && thursday2[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (thursday2[1].isAfter(thursday3[0]) && thursday3[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (friday1[1].isAfter(friday2[0]) && friday2[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (friday2[1].isAfter(friday3[0]) && friday3[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (saturday1[1].isAfter(saturday2[0]) && saturday2[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (saturday2[1].isAfter(saturday3[0]) && saturday3[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (sunday1[1].isAfter(sunday2[0]) && sunday2[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
  
      if (sunday2[1].isAfter(sunday3[0]) && sunday3[0].format('HH:mm') != '00:00') {
        enqueueSnackbar(t('AccTime024'), { variant: 'error' });
        return;
      }
    
      const state = {
        id: payload.id,
        name: payload.name,
        remark: payload.remark,
        companyId: payload.companyId,
        mondayStart1: monday1[0].format('HH:mm'),
        mondayStart2: monday2[0].format('HH:mm'),
        mondayStart3: monday3[0].format('HH:mm'),
        mondayEnd1: monday1[1].format('HH:mm'),
        mondayEnd2: monday2[1].format('HH:mm'),
        mondayEnd3: monday3[1].format('HH:mm'),
  
        tuesdayStart1: tuesday1[0].format('HH:mm'),
        tuesdayStart2: tuesday2[0].format('HH:mm'),
        tuesdayStart3: tuesday3[0].format('HH:mm'),
        tuesdayEnd1: tuesday1[1].format('HH:mm'),
        tuesdayEnd2: tuesday2[1].format('HH:mm'),
        tuesdayEnd3: tuesday3[1].format('HH:mm'),
  
        wednesdayStart1: wednesday1[0].format('HH:mm'),
        wednesdayStart2: wednesday2[0].format('HH:mm'),
        wednesdayStart3: wednesday3[0].format('HH:mm'),
        wednesdayEnd1: wednesday1[1].format('HH:mm'),
        wednesdayEnd2: wednesday2[1].format('HH:mm'),
        wednesdayEnd3: wednesday3[1].format('HH:mm'),
  
        thursdayStart1: thursday1[0].format('HH:mm'),
        thursdayStart2: thursday2[0].format('HH:mm'),
        thursdayStart3: thursday3[0].format('HH:mm'),
        thursdayEnd1: thursday1[1].format('HH:mm'),
        thursdayEnd2: thursday2[1].format('HH:mm'),
        thursdayEnd3: thursday3[1].format('HH:mm'),
  
        fridayStart1: friday1[0].format('HH:mm'),
        fridayStart2: friday2[0].format('HH:mm'),
        fridayStart3: friday3[0].format('HH:mm'),
        fridayEnd1: friday1[1].format('HH:mm'),
        fridayEnd2: friday2[1].format('HH:mm'),
        fridayEnd3: friday3[1].format('HH:mm'),
  
        saturdayStart1: saturday1[0].format('HH:mm'),
        saturdayStart2: saturday2[0].format('HH:mm'),
        saturdayStart3: saturday3[0].format('HH:mm'),
        saturdayEnd1: saturday1[1].format('HH:mm'),
        saturdayEnd2: saturday2[1].format('HH:mm'),
        saturdayEnd3: saturday3[1].format('HH:mm'),
  
        sundayStart1: sunday1[0].format('HH:mm'),
        sundayStart2: sunday2[0].format('HH:mm'),
        sundayStart3: sunday3[0].format('HH:mm'),
        sundayEnd1: sunday1[1].format('HH:mm'),
        sundayEnd2: sunday2[1].format('HH:mm'),
        sundayEnd3: sunday3[1].format('HH:mm'),
      };
  
      // if (payload.name.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)) {
      //   setError({ ...error, name: true });
      //   toast.error(t('SCHEDULES002'));
      //   return;
      // }
      setLoader(true);
      setIsLoading(true);
      saveScheduleTimeZoneInterval(state).then((res) => {
        if (res?.status === 200 && res?.data?.code === 'DMSI0007') {
          setIsLoading(false);
          enqueueSnackbar((res.data.msg).replace("Schedules", t("AccTime013")), { variant: 'success' });
          if(props.handleDailogSubmit)  {
            value.setQuickSetupPayload({
              ...value.quickSetupPayload,
              accessGroup:{
                ...value.quickSetupPayload.accessGroup,
                schedulerId:res.data.data.id
              }
             
            })
            props.handleClose()
          }else{
            navigate('/time-slots',
            { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })
          }
          
        } else if (res?.data?.code === 'DMSE00065') {
          setIsLoading(false);
          enqueueSnackbar(t('AccTime007'), { variant: 'error' })
  
        } else if (res?.data?.code === 'CASE0101') {
          setIsLoading(false);
          setError({
            ...error,
            name: true,
          });
          enqueueSnackbar((res.data.msg).replace("Schedules", t("AccTime013")), { variant: 'error' });
        } else {
          setIsLoading(false);
          enqueueSnackbar((res.data.msg).replace("Schedules", t("AccTime013")), { variant: 'error' });
        }
        setLoader(false);
      });
    };
  
    var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
    const handleClose = () => {
      setMonday1(defaultTime);
      setMonday2(defaultTime);
      setMonday3(defaultTime);
      setPayload(defaultPayload);
      setThursday1(defaultTime);
      setWednesday1(defaultTime);
      setTuesday1(defaultTime);
      setFriday1(defaultTime);
      setSunday1(defaultTime);
      setSaturday1(defaultTime);
  
      setSunday2(defaultTime);
      setTuesday2(defaultTime);
      setWednesday2(defaultTime);
      setThursday2(defaultTime);
      setFriday2(defaultTime);
      setSaturday2(defaultTime);
  
      setTuesday3(defaultTime);
      setWednesday3(defaultTime);
      setThursday3(defaultTime);
      setFriday3(defaultTime);
      setSaturday3(defaultTime);
      setSunday3(defaultTime);
  
      setCopyMonday(false);
      setCopyWeekend(false);
      setError({
        ...error,
        name: ''
      });
    };
  
    const handleCopyMonday = (monday1, monday2, monday3) => {
      setThursday1(monday1);
      setWednesday1(monday1);
      setTuesday1(monday1);
      setFriday1(monday1);
  
      setTuesday2(monday2);
      setWednesday2(monday2);
      setThursday2(monday2);
      setFriday2(monday2);
  
      setTuesday3(monday3);
      setWednesday3(monday3);
      setThursday3(monday3);
      setFriday3(monday3);
  
    };
  
    const handleCopyWeekend = (monday1, monday2, monday3) => {
  
      setSunday1(monday1);
      setSaturday1(monday1);
  
      setSunday2(monday2);
      setSaturday2(monday2);
  
      setSaturday3(monday3);
      setSunday3(monday3);
    }
  
    const formatTime = (time) => {
      if (CommonUtil.isEmptyString(time)) return '00:00';
      else return time;
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && !isLoading) {
        handleSubmit();
      }
    };
  
    const labelStyle = {
      textAlign: 'left',
      color: 'rgba(71, 75, 79, 0.8)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "21px",
      fontFamily: "Roboto",
    }
  
    const checkStyle = {
      "& .MuiTypography-root": {
        textAlign: 'left',
        color: 'rgba(71, 75, 79, 0.8)',
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "20px",
        fontFamily: "Roboto",
      }
  
    }
    
  return (
    <>
    <Grid container mb={3}>
      <Grid item md={4} xs={12} >
        <CustomInput
          id="textfield-add-time-slot-name"
          label='Name'
          required
          size='small'
          name='name'
          placeholder={t('AccTime004')}
          height={{ xl: "64px", xs: "50px" }}
          padding={{ xl: "0px 20px", xs: "0px 15px" }}
          value={payload.name}
          handleChange={(event) => {
            setPayload({ ...payload, name: event.target.value });
            setError({ ...error, name: false });
          }}
          onKeyDown={handleKeyPress}
          validation="name"
          error={error.name}
          helperText={error.name}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      {loader?<Loader open={loader}/>:<></>}
    </Grid>
    <Grid container mb={3}>
      <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
         <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop:{xl:"0px",xs:"10px"} }}>
          <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime005')}</Typography>
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={monday1}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setMonday1(time);
            }}
            order={false}
            placeholder={[('00:00'), ('00:00')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field2" sx={{cursor:'pointer'}} >
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            getPopupContainer={(trigger) => trigger.parentNode}
            value={monday2}
            onChange={(time, timeString) => {
              setMonday2(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field3" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            getPopupContainer={(trigger) => trigger.parentNode}
            value={monday3}
            onChange={(time, timeString) => {
              setMonday3(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
    </Grid>

    <Grid container mb={3}>
      <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
         <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop:{xl:"0px",xs:"10px"} }}>
          <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime015')}</Typography>
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field4" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={tuesday1}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setTuesday1(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field5" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            getPopupContainer={(trigger) => trigger.parentNode}
            value={tuesday2}
            onChange={(time, timeString) => {
              setTuesday2(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field6" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={tuesday3}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setTuesday3(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
    </Grid>

    <Grid container mb={3}>
      <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
         <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop:{xl:"0px",xs:"10px"} }}>
          <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime016')}</Typography>
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field7" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            getPopupContainer={(trigger) => trigger.parentNode}
            value={wednesday1}
            onChange={(time, timeString) => {
              setWednesday1(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field8" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            getPopupContainer={(trigger) => trigger.parentNode} 
            value={wednesday2}
            onChange={(time, timeString) => {
              setWednesday2(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field9" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
             getPopupContainer={(trigger) => trigger.parentNode}
            value={wednesday3}
            onChange={(time, timeString) => {
              setWednesday3(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
    </Grid>

    <Grid container mb={3}>
      <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
         <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop:{xl:"0px",xs:"10px"} }}>
          <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime017')}</Typography>
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field10" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
             getPopupContainer={(trigger) => trigger.parentNode}
            value={thursday1}
            onChange={(time, timeString) => {
              setThursday1(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field11" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={thursday2}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setThursday2(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field12" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={thursday3}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setThursday3(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
    </Grid>

    <Grid container mb={3}>
      <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
         <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop:{xl:"0px",xs:"10px"} }}>
          <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime018')}</Typography>
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field13" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={friday1}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setFriday1(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field14" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={friday2}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setFriday2(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field15" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={friday3}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setFriday3(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
    </Grid>

    <Grid container mb={3}>
      <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
         <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop:{xl:"0px",xs:"10px"} }}>
          <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime019')}</Typography>
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field16" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
              getPopupContainer={(trigger) => trigger.parentNode}
            value={saturday1}
            onChange={(time, timeString) => {
              setSaturday1(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field17" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={saturday2}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setSaturday2(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field18" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={saturday3}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setSaturday3(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
    </Grid>

    <Grid container mb={3}>
      <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop:{xl:"0px",xs:"10px"} }}>
          <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime020')}</Typography>
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field19" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={sunday1}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setSunday1(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
        <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field20" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={sunday2}
              getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setSunday2(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" },marginTop:{lg:"0px",xs:"10px"}, position:"relative" }}>
        <Box display='flex' justifyContent='flex-end' id="time-picker-field21" sx={{cursor:'pointer'}}>
          <TimePicker.RangePicker
            size="large"
            allowClear={false}
            locale={localeMap[language]}
            format={timeFormat}
            value={sunday3}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(time, timeString) => {
              setSunday3(time);
            }}
            order={false}
            placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
          />
        </Box>
      </Grid>
    </Grid>

    <Grid container
      sx={{
        display: "flex",
        justifyContent: { lg: "space-between", xs: "flex-start" },
        alignItems: "center"
      }}
      direction="row" >
        <Box
          sx={{
            width: { lg: "auto", xs: "100%" }
          }}
        >
          <Box flexGrow={1}>
            <FormControlLabel control={<Checkbox id="check-box-updatetimeslot-copy-monday" checked={copyMonday} onChange={handleChange} name='copyMonday' />}
              label={t('AccTime008')}
              sx={checkStyle}
            />
          </Box>
          <Box flexGrow={1}>
            <FormControlLabel 
            control={<Checkbox id="check-box-updatetimeslot-copy-weekend" 
            checked={copyWeekend} onChange={handleChange} name='copyWeekend' />}
            label={t('AccTime009')}
            sx={checkStyle}
            />
          </Box>
        </Box>
       {props.buttonReq? <Box
          sx={{
            width: { lg: "auto", xs: "100%" },
            display: "flex",
            justifyContent:"flex-end"
          }}
        >
          <Box>
              <CustomButton
                label= {t('COMMON018')}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
              />
            {/* <Button id="button-time-slot-save" 
            onClick={handleSubmit} 
            variant='contained'
              sx={{
                width: { xl: "233px", xs: "180px" },
                height: { xl: "64px", xs: "50px" },
                marginRight: "30px"
              }}
            >
              {t('COMMON018')}
            </Button> */}
          </Box>
          <Box>
            <Button id="button-time-slot-clear" 
            onClick={handleClose} 
            variant='outlined'
              sx={{
                width: { xl: "233px", xs: "180px" },
                height: { xl: "64px", xs: "50px" },
              }}>
              {t('COMMON015')}
            </Button>
          </Box>
        </Box>:<></>}
    </Grid>
  </>
  )
}

export default CreateNewTimeSlot
