import { Box, Button, Grid, Typography } from '@mui/material'
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import CustomInput from '../../../components/CustomInput';
import { getCredentialByEmpCodeAndCredTypeId, updateDevicePasscode } from '../../../services/DeviceService';
import CommonUtil from '../../../util/CommonUtils';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/Loader';

const BioSpecForPin = (props) => {

  const [loading, isLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [visibility, setVisibility] = useState(true);
  const { t } = useTranslation();
  const [state, setState] = useState({
    devId: props.devId,
    personId: props.credential.employeeId,
    passcode: '',
    empCode: props.credential.employeeCode
  });
  const [error, setError] = useState({
    devId: false,
    personId: false,
    passcode: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.passcode)) {
      setError({
        ...error,
        passcode: true,
      });
      enqueueSnackbar('Passcode is required', {
        variant: 'error',
      });
      return;
    }
    let device = [props.pin.deviceDetails.ip, props.credential.firstName, props.credential.lastName]
    isLoading(true)
    updateDevicePasscode(state.devId, state.personId, state.passcode, device, (data) => {
      isLoading(false)
      props.onClose();
      if (data?.data?.code === 'CMSI0005') {
        enqueueSnackbar(data.data.message, {
          variant: 'success',
        });
      }
      else if (data?.data?.code === 'CMSI0004') {
        enqueueSnackbar(data.data.message, {
          variant: 'success',
        });
      }
      else {
        enqueueSnackbar(data.data.message, {
          variant: 'error',
        });
      }
      props.reload();
    });
  };

  useEffect(() => {
    getCredentialByEmpCodeAndCredTypeId(state.empCode, 102, (data) => {
      if (data?.data?.code === 'CMSI0000') {
        setState({
          ...state,
          passcode: data.data.data.credential[0].passcode
        })

      }
    })
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <Grid container alignItems='center'>
        <Grid item xs={12} sm={12}>
          <Box display='flex' justifyContent='center' fullWidth>
            <CustomInput
              id="textfiled-biospecforpin-passcode"
              required
              fullWidth
              name='passcode'
              error={error.passcode}
              label={'Passcode'}
              helperText=''
              variant='outlined'
              type={visibility ? 'password' : 'text'}
              size='small'
              handleChange={handleChange}
              value={state.passcode}
              onKeyDown={handleKeyPress}
              height={{ xl: "64px", xs: "50px" }}
              padding={{ xl: "0px 20px", xs: "0px 10px" }}
              inputProps={{
                maxLength: 10,
              }}
              validation="numericWithoutDot"
              InputProps={{
                endAdornment: visibility ? (
                  <VisibilityOff
                    style={{ cursor: 'pointer', opacity: 0.6 }}
                    onClick={() => {
                      setVisibility(!visibility);
                    }}
                  />
                ) : (
                  <Visibility
                    style={{ cursor: 'pointer', opacity: 0.6 }}
                    onClick={() => {
                      setVisibility(!visibility);
                    }}
                  />
                ),
              }}
            />
          </Box>
          <Typography><span style={{ fontWeight: 600 }}>{"Tips: "}</span> If device is non-touch screen, it can not use Password verify function</Typography>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} display={'flex'} sx={{ mt: { xl: "30px", xs: "20px" } }}>
          <Button id="button-biospecforpin-submit" size='large' fullWidth variant='contained'
            style={{ marginRight: '15px', textTransform: 'none' }}
            onClick={() => handleSubmit()}
          >{t("COMMON029")}</Button>
          <Button id="button-biospecforpin-cancel" size='large' fullWidth variant='outlined' style={{ textTransform: 'none' }}
            onClick={() => props.onClose()}
          >{t("COMMON009")}</Button>
        </Grid>
        {loading ? <Loader top={"48%"} left={"48%"} open={loading} /> : <></>}
      </Grid>
     
    </>
  )
}

export default BioSpecForPin
