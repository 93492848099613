import api from "../configurations/http-common";

export const getAddressByPersonId = async (id) => {
    return api.securedAxios().get('/api/v2.0/address/' + id);
}

export const loadLocSite = async (page, size, filter) => {
    let query = 'page=' + page + '&size=' + size + buildQuery(filter);
    return api.securedAxios().post('/web/location/site/filter?' + query);

    function buildQuery(filter) {

        let query = ''

        if (filter.name != '') {
            query = query + `&name=${encodeURIComponent(filter.name)}`;
            return query.replace(" ", "")
        }
        return query
    }
}

export const filterSitelist = async (page, size, filter) => {
    const requestBody = {
        ...filter,
        pageNumber: page,
        pageSize: size
    };
    return api.securedAxios().post('/web/location/site/list', requestBody);
}

export const loadLocSiteByFilter = async (page, size, filter) => {
    let query = 'page=' + page + '&size=' + size + buildQuery(filter);
    return api.securedAxios().post('/api/v2.0/site/addres?' + query);

    function buildQuery(filter) {
        let query = ''
        if (filter.ids != '' && filter.ids != undefined) {
            query = query + `&ids= ${filter.ids}`
            return query.replace(" ", "")
        }
        if (filter.name != '') {
            let encoded = encodeURIComponent(filter.name);
            query = query + `&name= ${encoded}`
            return query.replace(" ", "")
        }
        return query
    }
}

export const filterSite = async (payload, callBack) => {
    try {
        const res = await api.securedAxios().get(`/api/v2.0/site?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`);
        callBack(res);
    } catch (error) { }
}

export const listAllSites = async () => {
    return api.securedAxios().get('/api/v2.0/site' + '?isPageable=false');
}


export const siteListWithAddressV2 = async (page, size) => {
    return api.securedAxios().get('/api/v2.0/site/addres?page=' + page + '&size=' + size);
}

export const getSiteById = async (id, callBack) => {
    try {
        const res = await api.securedAxiosWithoutLoader().get('/web/location/site/' + id);
        callBack(res)
    }
    catch (error) { }
}

export const getSiteByIdV2 = async (id, callBack) => {
    try {
        const res = await api.securedAxiosWithoutLoader().get('/api/v2.0/site/' + id);
        callBack(res)
    }
    catch (error) { }
}
