import React, { useRef, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { Grid } from '@mui/material';
import { withStyles } from "@mui/material";
const OverflowTip = props => {
  // Create Ref
  const textElementRef = useRef();

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(() => () => {
    window.removeEventListener('resize', compareSize);
  }, []);

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(true);
  const CustomTooltip = (Tooltip);

  const toolTipStyle={
    overflow: "hidden",
    textOverflow: "ellipsis",
    color:"rgba(71, 75, 79, 0.8) !important",
    letterSpacing: "0.4px",
    whiteSpace: "nowrap",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize:{ xl: '16px', lg: "14px", md: "14px", sm: "14px", xs: "14px" },
    lineHeight: { xl: '19px', lg: "17px", md: "17px", sm: "17px", xs: "17px" },
    fontFamily: "Roboto",
  }
  return (
    <>
      {props.children}
      <Tooltip
        sx={{
          ...toolTipStyle,
           width: props.width ? props.width : 'auto',
           fontWeight:props.fontWeight?props.fontWeight:'unset' }}
        title={props.value}
        interactive={"true"}
        disableHoverListener={!hoverStatus}
      >
        <Grid ref={textElementRef}>
          {props.value}
        </Grid>
      </Tooltip>
    </>
  );
};

export default OverflowTip;