import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { ReactComponent as Arrow } from '../../../assets/DeviceIcon/down-arrow.svg';
import { filterDevices, findAuxInDetails, findAuxOutDetails, findReader, getDoor } from '../../../services/DeviceService';
import { filterSite } from '../../../services/SiteService';
import { filterZone } from '../../../services/ZoneService';
import CommonUtil from '../../../util/CommonUtils';
import AuxInInfoPage from './AuxInInfoPage';
import AuxOutInfoPage from './AuxOutInfoPage';
import DoorInfoPage from './DoorInfoPage';
import ReaderInfoPage from './ReaderInfoPage';
import { useTranslation } from 'react-i18next';



const TablePage = () => {
  const {t} = useTranslation()
  const [site,setSite]=useState([]);
  const [zone,setZone]=useState([]);
  const [device,setDevice]=useState([])
  const [door,setDoor]=useState([]);
  const [auxIn,setAuxIn]=useState([]);
  const [auxOut,setAuxOut]=useState([]);
  const [reader,setReader]=useState([]);

  const [selectSite,setSelectSite]=useState('');
  const [selectZone,setSelectZone]=useState('')
  const [selectDevice,setSelectDevice]=useState('');
  const [port,setPort]=useState('');
  const [auxInInfo,setAuxInInfo]=useState('')
 
  const[totalSite,setTotalSite]=useState(0);
  const[totalzone,setTotalZone]=useState(0);
  const[totalDevice,setTotalDevice]=useState(0);
  const[totalDoor,setTotalDoor]=useState(0);

  const headerStyle={
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'rgba(71, 75, 79, 1)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: 500,
    fontSize: {xl:"18px",xs:"16px"},
    lineHeight:{xl:"22px",xs:"18px"},
    fontFamily: "Roboto",
    overflow: 'hidden',
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap'
  }

  const countStyle={
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    font: 'normal normal normal 14px/17px Roboto',
    color:'#76B745 !important',
    letterSpacing: '0px',
    whiteSpace: 'nowrap',
  
  }

  const valueStyle={
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'rgba(71, 75, 79, 1)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight:  "normal",
    fontSize: {xl:"16px",xs:"14px"},
    lineHeight:{xl:"20px",xs:"18px"},
    fontFamily: "Roboto",
    overflow: 'hidden',
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
    cursor:'pointer'
  }

    useEffect(() => {
          const payload = {
            pageNumber: 1,
            pageSize: 0
          }
          filterSite(payload, (response) => {
            if (response?.data?.code === 'LMSI0000'){setTotalSite(response.data.data.totalCount); setSite(response.data.data.site);}
          })
      }, []);
    
      useEffect(() => {
        if (!CommonUtil.isEmpty(selectSite.id)) {
          const payload = {
            pageNumber: 1,
            pageSize: 0,
            filter: { siteId: selectSite.id }
          }
          filterZone(payload, (response) => {
            if (response?.data?.code === 'LMSI6000') {
              setZone(response.data.data.zones);
              setTotalZone(response.data.data.totalCount);
            }
          })
        }
      }, [selectSite]);

      useEffect(() => {
        if (!CommonUtil.isEmpty(selectZone.id)) {
          const payload = {
            pageNumber: 1,
            pageSize: 1000,
            filter: { zoneId: selectZone.id }
          }
          filterDevices(payload, (res) => {
            if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data)) {
              setTotalDevice(res.data.data.totalCount);
              setDevice(res.data.data.devices);
            } else {
              setTotalDevice(0);
                setDevice([]);
            }
        });
        }
      }, [selectZone]);

      useEffect(() => {
        if (!CommonUtil.isEmpty(selectDevice.id)) {
          loadDoor(selectDevice.id);
          loadAuxIn(selectDevice.id);
          loadAuxOut(selectDevice.id);
          loadReader(selectDevice.id);
        }
      }, [selectDevice]);

   const loadDoor=(deviceId)=>{
    getDoor(deviceId, (res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data)) {
        setTotalDoor(res.data.data.length);
        setDoor(res.data.data);
      } else {
        setTotalDoor(0);
        setDoor([]);
      }
  });
  }   

      
  const loadAuxIn = (deviceId) => {
    findAuxInDetails(deviceId, (res) => {
      if (res?.data?.code === 'DMSI0000') {
        setAuxIn(res.data.data);
      } else {
        setAuxIn([]);
      }
    });
  };

  const loadAuxOut = (deviceId) => {
    findAuxOutDetails(deviceId, (res) => {
      if (res?.data?.code === 'DMSI0000') {
        setAuxOut(res.data.data);
      } else {
        setAuxOut([]);
      }
    });
  };

  const loadReader = (deviceId) => {
    findReader(deviceId, (res) => {
      if (res?.data?.code === 'DMSI0000') {
        setReader(res.data.data);
      } else {
        setReader([]);
      }
    });
  };
      
  return (
    <>
     <Grid container>
      {/* This Grid is showing site list */}
      <Grid item lg={2} md={2} sm={12}xs={12} borderRight={"1px solid rgba(245, 246, 246, 1)"}>
      <Box 
      sx={{
        display:'flex',
        justifyContent:'space-between',
        // borderBottom:"1px solid rgba(245, 246, 246, 1)",
        paddingRight:{xl:"20px",xs:"10px"},
        height:{xl:"67px",xs:"45px"}
      }}
      >
          <Typography sx={headerStyle}> {t('AccDMTopology002')}</Typography>
          <Typography alignSelf={"flex-start"} sx={countStyle}> {totalSite>1?`${totalSite} Sites`:`${totalSite} Site`}</Typography>
      </Box>
      <Box
      sx={{height:{xl:'68vh',lg:'62vh',md:'58vh'}}}
      className='scroll-container'
      >
        { !CommonUtil.isEmpty(site)?
        (site.map((val,index)=>
        <Box 
        key={index}
        sx={{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:"1px solid rgba(245, 246, 246, 1)",
           padding:{xl:"0px 20px",xs:"0px 10px"},
          height:{xl:"70px",xs:"50px"},
          alignItems:"center",
          backgroundColor:val.id === selectSite.id ? '#F5F6F6' : '#FFFF',
          cursor:'pointer'
        }}

        onClick={()=>{               // setting state values as  default after selecting another site
          setSelectSite({id:val.id});
          setDevice([]);
          setDoor([]);
          setAuxIn([]);
          setAuxOut([]);
          setReader([]);
          setTotalDevice(0)
          setTotalDoor(0)
          setTotalZone(0)
          setPort('');
          setSelectZone('');
          setSelectDevice('')
          setAuxInInfo('')
        }}
        >
          <Typography sx={valueStyle} style={{cursor:'pointer',alignSelf:'center'}}>{val.name}</Typography>
            <Arrow id="icon-table-page-arrow-one" style={{alignSelf:'center', cursor:'pointer'}}/>
          </Box>)) 
        :<Box display={'flex'} justifyContent={'center'} marginTop='20px'><CircularProgress style={{width:'18%',height:'10%'}}/></Box>}
      </Box>
        
      </Grid>
  {/* This Grid is showing zone list after selecting one site */}
      <Grid item lg={2} md={2} sm={12}xs={12}  borderRight={"1px solid rgba(245, 246, 246, 1)"}>
      <Box 
       sx={{
        display:'flex',
        justifyContent:'space-between',
        // borderBottom:"1px solid rgba(245, 246, 246, 1)",
        padding:{xl:"0px 20px",xs:"0px 10px"},
        height:{xl:"67px",xs:"45px"},
      }}
       >
          <Typography sx={headerStyle}> {t('AccDMTopology003')}</Typography>
          <Typography sx={countStyle} alignSelf={"flex-start"}> {totalzone>1?`${totalzone} Zones`:`${totalzone} Zone`}</Typography>
      </Box>
      <Box
       sx={{height:{xl:'68vh',lg:'62vh',md:'58vh'}}}
       className='scroll-container'
      >
      { !CommonUtil.isEmpty(zone)?
        (zone.map((val,index)=>
        <Box 
        key={index}
        sx={{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:"1px solid rgba(245, 246, 246, 1)",
          padding:{xl:"0px 20px",xs:"0px 10px"},
          height:{xl:"70px",xs:"50px"},
          alignItems:"center",
          backgroundColor:val.id === selectZone.id ? '#F5F6F6' : '#FFFF',
          cursor:'pointer'
        }}
        onClick={()=>{
          setSelectZone({id:val.id});
          setDoor([]);
          setTotalDevice(0)
          setTotalDoor(0)
          setAuxIn([]);
          setAuxOut([]);
          setReader([]);
          setPort('');
          setSelectDevice('')
          setAuxInInfo('')
        }}
        >
          <Typography  sx={valueStyle}>{val.name}</Typography>
            <Arrow id="icon-table-page-arrow-two" style={{alignSelf:'center', cursor:'pointer'}}/>
          </Box>)) 
        :<></>
        }
      </Box>
      </Grid>
        {/* This Grid is showing Device list after clicking zone */}
      <Grid item lg={2} md={2} sm={12}xs={12}  borderRight={"1px solid rgba(245, 246, 246, 1)"}>
      <Box 
       sx={{
        display:'flex',
        justifyContent:'space-between',
        // borderBottom:"1px solid rgba(245, 246, 246, 1)",
        padding:{xl:"0px 20px",xs:"0px 10px"},
        height:{xl:"67px",xs:"45px"},
      }}
      >
          <Typography sx={headerStyle}> {t('AccDMTopology004')}</Typography>
          <Typography  sx={countStyle} alignSelf={"flex-start"}> {totalDevice>1?`${totalDevice} Devices`:`${totalDevice} Device`}</Typography>
      </Box>
      <Box
      sx={{height:{xl:'68vh',lg:'62vh',md:'58vh'}}}
      className='scroll-container'
      >
      { !CommonUtil.isEmpty(device)?
        (device.map((val,index)=>
        <Box 
        key={index}
        sx={{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:"1px solid rgba(245, 246, 246, 1)",
          padding:{xl:"0px 20px",xs:"0px 10px"},
          height:{xl:"70px",xs:"50px"},
          alignItems:"center",
          backgroundColor:val.id === selectDevice.id ? '#F5F6F6' : '#FFFF',
          cursor:'pointer'
        }}
        onClick={()=>{
          setSelectDevice({id:val.id}); 
          setPort('');
          setTotalDoor(0)
          setAuxIn([]);
          setAuxOut([]);
          setReader([]);
        }}
        >
          <Typography sx={valueStyle}>{val.deviceAlias}</Typography>
            <Arrow id="icon-table-page-arrow-three" style={{alignSelf:'center', cursor:'pointer'}} />
          </Box>)) 
        :<></>
        }
        
      </Box>
      </Grid>
        {/* This Grid is showing door, Aux-In, Aux-out and Reader list after clicking device*/}
      <Grid item lg={2} md={2} sm={12}xs={12}  borderRight={"1px solid rgba(245, 246, 246, 1)"}> 
      <Box 
       sx={{
        display:'flex',
        justifyContent:'space-between',
        // borderBottom:"1px solid rgba(245, 246, 246, 1)",
        padding:{xl:"0px 20px",xs:"0px 10px"},
        height:{xl:"67px",xs:"45px"}
      }}
      >
          <Typography sx={headerStyle}> {t('AccDMTopology005')}</Typography>
          <Typography  sx={countStyle} alignSelf={"flex-start"}> {totalDoor>1?`${totalDoor} Doors`:`${totalDoor} Door`}</Typography>
      </Box>
      <Box
      sx={{height:{xl:'68vh',lg:'62vh',md:'58vh'}}}
      className='scroll-container'
      >
      { !CommonUtil.isEmpty(door)?
        (door.map((val,index)=>
        <Box 
         key={index}
        sx={{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:"1px solid rgba(245, 246, 246, 1)",
          padding:{xl:"0px 20px",xs:"0px 10px"},
          height:{xl:"70px",xs:"50px"},
          alignItems:"center",
          backgroundColor:val.deviceId === port.deviceId && val.doorId === port.doorId  ? '#F5F6F6' : '#FFFF',
          cursor:'pointer'
        }}
        onClick={()=>setPort(val)}
        >
          <Typography sx={valueStyle}>{val.doorParameters.name}</Typography>
            <Arrow id="icon-table-page-arrow-four" style={{alignSelf:'center', cursor:'pointer'}}/>
          </Box>)
          )
        :<></>
        }
        { !CommonUtil.isEmpty(auxIn)?
        (auxIn.map((val,index)=>
        <Box 
        key={index}
        sx={{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:"1px solid rgba(245, 246, 246, 1)",
          padding:{xl:"0px 20px",xs:"0px 10px"},
          height:{xl:"70px",xs:"50px"},
          alignItems:"center",
          backgroundColor:val.aux_id === port.aux_id ? '#F5F6F6' : '#FFFF',
          cursor:'pointer'
        }}
        onClick={()=>{setPort(val);setAuxInInfo("Aux-In")}}
        >
          <Typography sx={valueStyle}>{val.name}</Typography>
            <Arrow style={{alignSelf:'center', cursor:'pointer'}}/>
          </Box>)
          )
        :<></>
        }
        { !CommonUtil.isEmpty(auxOut)?
        (auxOut.map((val,index)=>
        <Box 
        key={index}
        sx={{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:"1px solid rgba(245, 246, 246, 1)",
          padding:{xl:"0px 20px",xs:"0px 10px"},
          height:{xl:"70px",xs:"50px"},
          alignItems:"center",
          backgroundColor:val.aux_id === port.aux_id ? '#F5F6F6' : '#FFFF',
          cursor:'pointer'
        }}
        onClick={()=>{setPort(val);setAuxInInfo("Aux-Out")}}
        >
          <Typography sx={valueStyle}>{val.name}</Typography>
            <Arrow style={{alignSelf:'center', cursor:'pointer'}}/>
          </Box>)
          )
        :<></>
        }
        { !CommonUtil.isEmpty(reader)?
        (reader.map((val,index)=>
        <Box 
        key={index}
        sx={{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:"1px solid rgba(245, 246, 246, 1)",
          padding:{xl:"0px 20px",xs:"0px 10px"},
          height:{xl:"70px",xs:"50px"},
          alignItems:"center",
          backgroundColor:val.readerId === port.readerId ? '#F5F6F6' : '#FFFF',
          cursor:'pointer'
        }}
        onClick={()=>setPort(val)}
        >
          <Typography sx={valueStyle}>{val.name}</Typography>
            <Arrow id="icon-table-page-arrow" style={{alignSelf:'center', cursor:'pointer'}}/>
          </Box>)
          )
        :<></>
        }
      </Box>
      </Grid>
        {/* This Grid is showing Information about door,Aux-In,Aux-Out,Reader after clicking list. */}
      <Grid item lg={4} md={4} sm={12}xs={12}> 
      <Box 
       sx={{
        display:'flex',
        justifyContent:'space-between',
        // borderBottom:"1px solid rgba(245, 246, 246, 1)",
        padding:{xl:"0px 20px",xs:"0px 10px"},
        height:{xl:"67px",xs:"45px"}
      }}
      >
          <Typography sx={headerStyle}> {t('AccDMTopology006')}</Typography>
      </Box>
      <Box
      sx={{height:{xl:'68vh',lg:'62vh',md:'58vh'}}}
      className='scroll-container'
      >
        <Box py={2} px={1} display='flex'>
           {port.readerId!==undefined&&<ReaderInfoPage state={port}/>}
           {(port.deviceId!==undefined && port.doorId!==undefined)&&<DoorInfoPage state={port}/>}
           {(port.aux_id!==undefined && auxInInfo==="Aux-In")&&<AuxInInfoPage state={port}/>}
           {(port.aux_id!==undefined && auxInInfo==="Aux-Out")&&<AuxOutInfoPage state={port}/>}
        </Box>
      </Box></Grid>
     </Grid>
    </>
  )
}

export default TablePage
