import api from "../configurations/http-common";

export const createNewTicket =async (payload, callBack)=>{
  try {
    const res= await api.securedAxios().post('/web/help-desk/create/new/ticket',payload);
    callBack(res);
  }catch(error){
  }
}

export const addAttachmentToTicket=async(payload,callBack)=>{
    try{
        const res=await api.securedAxios().post(`/web/help-desk/add-attachment?ticketId=${payload.ticketId}`,payload.id);
        callBack(res);
    }catch(error){

    }
}

export const uploadAttachment=async(payload ,callBack)=>{
    try{
     const res=await api.securedAxios().post(`/web/help-desk/attachment`,payload)
    }catch(error){
        
    }
}