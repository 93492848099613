import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import CommonUtil from './util/CommonUtils';

export default function SecuredRoute() {

  // useEffect(() => {

  //   const jwtToken = CommonUtil.getCookie('zlink_token');

  //   if (jwtToken) {
  //     const decoded_jwtToken = jwt_decode(jwtToken);
  //     const currentTime = Date.now() / 1000;
  //     if (decoded_jwtToken.exp < currentTime) {
  //       window.location.href = '/error';
  //     }
  //     localStorage.removeItem('USER');
  //     localStorage.setItem('USER' , JSON.stringify(decoded_jwtToken))
  //   } else {
  //       window.location.href = '/error';
  //   }
  // }, []);

  return <></>;
}