import { Autocomplete, Avatar, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import ProfileUplod from '../../components/layout-components/ProfileUplod';
import RightViewLayout from '../../components/RighViewLayout';
import { filterDevices, getDeviceDetails, updateDevice } from '../../services/DeviceService';
import { listAllSites } from '../../services/SiteService';
import { filterZoneV3 } from '../../services/ZoneService';
import CommonUtil from '../../util/CommonUtils';
import DevicePic from './../../assets/new-images/DevicePic.svg';
import { getPermissionsByRoleId } from '../../services/RoleService';
import { REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import api from '../../configurations/http-common';
import ViewPage from '../../components/ViewPage';
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';
import { DeviceImage } from './DeviceImage';
import newDevieImage from '../../assets/ViewDevice.png';


const EditDevice = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sites, setSites] = React.useState([]);
  const [zoneList, setZoneList] = React.useState([]);
  const [zones, setZones] = React.useState([]);
  const [profile, setProfile] = React.useState(DevicePic);
  const [profileImage, setProfileImage] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const { sn } = useParams();
  const [viewErrorPage, setViewErrorPage] = useState(false)
  const [personInDevice, setPersonInDevice] = React.useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loader, setLoader] = React.useState(false);
  const [state, setState] = React.useState({
    id: "",
    sn: "",
    //This will come in future not delete these lines.
    // registrationDevice: location.state.registrationDevice,
    deviceAlias: "",
    ip: "",
    siteId: "",
    zoneId: "",
  });

  const [error, setError] = React.useState({
    deviceAlias: false,
    siteId: false,
    zoneId: false,
    //This will come in future not delete these lines.
    // registrationDevice: false,
  });

  const loadDeviceBySN = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 10,
      filter: {
        deviceSn: sn
      }
    }
    filterDevices(payload, (res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data?.devices)) {
        setState(res.data.data.devices[0]);
      } else {
        setViewErrorPage(true);
      }
    });
  };

  useEffect(() => {
    if (CommonUtil.isEmpty(sn)) {
      navigate({
        pathname: REACT_URL_PAGE_NOT_FOUND,
      });
    }
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setPermissions(prev => [...prev, item.code])
        });
      }
      setPersonInDevice(true);
    });
    if (location?.state?.sn) {
      setState(location.state);
    }
  }, []);

  useEffect(() => {
    if (checkPermission("EDIT_DEVICE_LIST_DETAILS")) {
      if (location?.state?.sn === undefined)
        loadDeviceBySN();
      loadSites();
      loadZones();
    }
  }, [permissions])

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  const loadSites = () => {
    setLoader(true);
    listAllSites().then((res) => {
      if (res?.status === 200 && res != null && !CommonUtil.isEmpty(res) && res?.data?.code === 'LMSI0000' && !CommonUtil.isEmpty(res?.data) && res?.data?.data?.site instanceof Array) {
        setSites(res.data.data.site);
      }
      setLoader(false);
    });
  };

  const loadZones = () => {
    let payload = {
      name: '',
      parentId: '',
      zoneId: '',
    };
    filterZoneV3(1, 1000, payload).then((res) => {
      if (res?.data?.code === 'LMSI6000' && !CommonUtil.isEmpty(res?.data) && res?.data?.data?.zones instanceof Array) {
        setZones(res.data.data.zones);
      }
    });
  };

  useEffect(() => {
    const zoneFilter = zones.filter((zone) => { return zone.siteId === state.siteId });
    setZoneList(zoneFilter);
  }, [zones, state.siteId]);

  const handleProfile = (file) => {
    setState({
      ...state,
      profilePhoto: state.id,
    });
    setProfile(file.base64);
    setProfileImage(file.file);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === 'siteId') {
      setState({ ...state, zoneId: '', siteId: event.target.value })
    } else {
      setState({
        ...state,
        [name]: event.target.value,
      });
      setError({
        [name]: '',
      });
    }
  };

  const handleClear = () => {
    setState({
      ...state,
      id: location.state.id,
      sn: location.state.sn,
      //This will come in future not delete these lines.
      // registrationDevice: location.state.registrationDevice,
      deviceAlias: !CommonUtil.isEmptyString(location.state.deviceAlias) ? location.state.deviceAlias : '',
      ip: location.state.ip,
      siteId: location.state.siteId,
      zoneId: location.state.zoneId,
    })
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.deviceAlias)) {
      setError({
        ...error,
        deviceAlias: true,
      });
      enqueueSnackbar('Please Enter the Device Name', {
        variant: 'error',
      });
      return;
    }

    if (CommonUtil.isEmptyString(state.siteId)) {
      setError({
        ...error,
        siteId: true,
      });
      enqueueSnackbar('Please select a site', {
        variant: 'error',
      });
      return;
    }

    if (CommonUtil.isEmptyString(state.zoneId)) {
      setError({
        ...error,
        zoneId: true,
      });
      enqueueSnackbar('Please select a Zone', {
        variant: 'error',
      });
      return;
    }

    const payload = {
      id: location.state.id,
      //This will come in future not delete these lines.
      // registrationDevice: state.registrationDevice == true ? '1' : '0',
      deviceAlias: state.deviceAlias,
      siteId: state.siteId,
      zoneId: state.zoneId,
      ip: state.ip
    };

    setLoader(true);
    updateDevice(payload).then((res) => {
      if (res?.status === 200 && res != null && !CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000' && CommonUtil.isEmpty(res?.data?.data?.devices instanceof Array)) {
        enqueueSnackbar(t('ACCRolPerm064'), {
          variant: 'success',
        });
        navigate(-1);
      } else if (res?.data?.code === 'DMSE0004') {
        enqueueSnackbar(t('ACCRolPerm065'), {
          variant: 'error',
        })
        navigate(
          '/device-list',
          { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } });
      } else if (res?.data?.code === 'DMSE9999') {
        enqueueSnackbar(t('ACCRolPerm068'), {
          variant: 'error',
        })

      }
      else {
        enqueueSnackbar(res.data.message, {
          variant: 'error',
        });
      }
      setLoader(false);
    });
  };


  const fieldDeviceName = () => (
    <CustomInput
      id="textfield-updatedevice-name"
      fullWidth
      required
      placeholder={'Please Enter the Device Name'}
      error={error.deviceAlias}
      name='deviceAlias'
      onKeyDown={handleKeyPress}
      label={t('Device Name')}
      helperText=''
      variant='outlined'
      size='small'
      height={{ xl: "64px", xs: "50px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      value={state.deviceAlias}
      defaultValue={state.deviceAlias}
      handleChange={handleChange}
      inputProps={{
        maxLength: 30,
      }}
    />
  )

  const fieldSerialNumber = () => (
    <CustomInput
      id="textfield-updatedevice-sn"
      fullWidth
      disabled
      label={t('Serial Number')}
      helperText=''
      variant='outlined'
      size='small'
      height={{ xl: "64px", xs: "50px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      onKeyDown={handleKeyPress}
      value={state.sn}
      defaultValue={state.sn}
      inputProps={{
        maxLength: 30,
      }}
    />
  )

  const fieldIpAddress = () => (
    <CustomInput
      id="textfield-updatedevice-ipaddress"
      fullWidth
      disabled
      label={t('IP Address')}
      helperText=''
      height={{ xl: "64px", xs: "50px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      variant='outlined'
      size='small'
      onKeyDown={handleKeyPress}
      value={state.ip}
      defaultValue={state.ip}
    />
  );

  const findZone = (id) => {
    let zoneObj = { id: '', name: '' };
    zones.map((data) => {
      if (data.id === id)
        zoneObj = data;
    });
    return zoneObj;
  }

  const findSite = (id) => {
    let siteObj = { id: '', name: '' };
    sites.map((data) => {
      if (data.id === id)
        siteObj = data;
    });
    return siteObj;
  }


  const fieldZone = () => (
    <Autocomplete
      required
      error={error.zoneId}
      fullWidth
      disablePortal
      name="zoneId"
      // onKeyDown={handleKeyPress}
      value={state.zoneId ? findZone(state.zoneId) : { id: '', name: '' }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="dropdown-updatedevice-zone-bind"
      getOptionLabel={(option) => option.name}
      options={zoneList}
      renderInput={(params) => <CustomInput handleChange={() => console.log()}
        cursor='pointer'
        placeholder={'Please select your Zone'}
        {...params} helperText={error.zoneId} error={error.zoneId} required label="Zone" size="small"
        height={{ xl: "47px", xs: "33.1px" }}
        padding={{ xl: "0px 20px", xs: "0px 10px" }}
      />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            zoneId: newValue.id
          });
          setError({
            ...error,
            zoneId: false,
          })
        } else {
          setState({
            ...state,
            zoneId: ''
          });
        }
      }}
      ListboxProps={{ style: { maxHeight: '90px' } }}
    />
  )

  const fieldSite = (key) => (
    <Autocomplete
      fullWidth
      error={error.siteId}
      name={"siteId"}
      disablePortal
      size="large"
      // onKeyDown={handleKeyPress}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={state.siteId ? findSite(state.siteId) : { id: '', name: '' }}
      id="dropdown-updatedevice-site"
      getOptionLabel={(option) => option.name}
      options={sites}
      renderInput={(params) => <CustomInput cursor='pointer' handleChange={() => console.log()}
        placeholder={'Please select your Site'}
        {...params} required helperText={error.siteId} error={error.siteId} label="Site" size="small"
        height={{ xl: "47px", xs: "33.1px" }}
        padding={{ xl: "0px 20px", xs: "0px 10px" }}
      />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            siteId: newValue.id,
            zoneId: ''
          });

        } else {
          setState({
            ...state,
            siteId: '',
            zoneId: ''
          });
        }
      }}
      ListboxProps={{ style: { maxHeight: '150px' } }}
    />
  )
  // This will come in future do not delete function. 

  // const fieldRegistrationDevice = () => (
  //   <>
  //     <InputLabel style={{
  //       paddingTop: '2px',
  //       paddingBottom: '6px', color: '#30466', fontFamily: 'Roboto', fontSize: '12px'
  //     }}>{t('Automatic Backup Device')}</InputLabel>
  //     <FormControl variant='outlined' fullWidth size='small'>
  //       <Select
  //         style={{ width: '100%', height: '38%', fontSize: '12px' }}
  //         MenuProps={MenuProps}
  //         name='registrationDevice'
  //         value={state.registrationDevice}
  //         defaultValue={state.registrationDevice}
  //         onChange={handleChange}>
  //         <MenuItem value={true} style={{ fontSize: '12px', }}>{t("Yes")}</MenuItem>
  //         <MenuItem value={false} style={{ fontSize: '12px', }}>{t("No")}</MenuItem>
  //       </Select>
  //     </FormControl>

  //   </>
  // )


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      {viewErrorPage ? <PageNotFound></PageNotFound> : personInDevice ? checkPermission("EDIT_DEVICE_LIST_DETAILS") ?
        <ViewPage title={t('AccDevice057')} path={true}
          onClick={() => navigate(
            '/device-list',
            { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}>
          {loader ? <Loader open={loader} /> : <></>}
          <Grid container direction='row' justifyContent='space-evenly' >
            <Grid item md={4} xs={12} display={"flex"} justifyContent='center' alignItems={"center"}>
              {DeviceImage[state?.deviceModel] ? DeviceImage[state?.deviceModel] : <ProfileUplod
                disabled={true}
                onsetProfile={(profile) => console.log("")}
                profilePhoto={profile}
              />}
            </Grid>
            <Grid item md={8} xs={12} >
              <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ pl: { xl: "30px", md: "20px" } }} >
                  {fieldDeviceName()}
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ pl: { xl: "30px", md: "20px" } }}>
                  {fieldSerialNumber()}
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ pl: { xl: "30px", md: "20px" } }} >
                  {fieldZone()}
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ pl: { xl: "30px", md: "20px" } }}>
                  {fieldSite()}
                </Grid>
                {/* <Grid item xl={6} lg={6} md={6} sm={12}> */}
                {/* {fieldSite()} */}
                {/* </Grid> */}
                <Grid item
                  // xl={6} lg={6} md={6} sm={12} 
                  xs={12}
                  sx={{ pl: { xl: "30px", md: "20px" } }}
                >
                  {fieldIpAddress()}
                </Grid>
                {/* This will come in future not delete these lines. */}
                {/* <Grid item xl={6} lg={6} md={6} sm={12}>
              {fieldRegistrationDevice()}
            </Grid> */}
                <Grid item md={6} xs={0} sx={{ pl: { xl: "30px", md: "20px" } }}></Grid>
                <Grid item md={6} xs={12} sx={{ pl: { xl: "30px", md: "20px" } }}>
                  <Box sx={{ display: "flex", width: "100%", justifyContent: { md: "flex-end", xs: "center" } }}>
                    <Button
                      id="button-updatedevice-save"
                      variant='contained'
                      onClick={handleSubmit}
                      // sx={{ marginRight: '10px', textTransform:'none' }}
                      sx={{ width: { xs: '50%', md: '50%' }, height: { xl: "64px", xs: "50px" }, mr: "20px" }}
                    >
                      {t('COMMON023')}
                    </Button>
                    <Button
                      id="button-updatedevice-clear"
                      onClick={handleClear}
                      variant='outlined'
                      // sx={{textTransform:'none' }}
                      sx={{ width: { xs: '50%', md: '50%' }, height: { xl: "64px", xs: "50px" } }}
                    >
                      {t('COMMON015')}
                    </Button>

                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ViewPage> : (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t("AccDevice063")}</Typography>
        </Box>) : <></>}
    </>
  )
}

export default EditDevice
