import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { Clear, DescriptionOutlined } from '@mui/icons-material';

const WordFilecard = ({ src, fileName, loading, attachmentId, index, cancel, ...props }) => {

    useEffect(() => {
    }, [loading]);

    const handleDelete = () => {
        console.log("Deleting attachmentId:", attachmentId, index);
        cancel(attachmentId, index);
    };


    return (
        <>
            <Box sx={{
                position: "relative",
                cursor: 'default',
                borderRadius: '5px',
                width: '150px',
                height: '150px',
                padding: ' 5px 5px 40px 5px',
                border: ' 1px solid #BFBFBF',
                backgroundColor: ' white',
                boxShadow: '5px 5px 5px #aaaaaa',
            }}
            >
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 999,
                        }}
                    >
                        <CircularProgress size={24} />
                    </div>
                )}
                <div>
                    <DescriptionOutlined
                        sx={{
                            position: 'absolute',
                            top: '30%',
                            left: '35%',
                            fontSize: 48,
                            color: 'blue',
                            cursor: 'pointer',
                        }}
                    />
                    <IconButton onClick={() => handleDelete()} color="red" size="small" sx={{
                        position: 'absolute',
                        left: '80%',

                    }}>
                        <Clear />
                    </IconButton>
                </div>

                <div style={{ position: 'absolute', width: "100%", height: "100%", top: '80%', }}>
                    <Typography
                        sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'nowrap', top: '30%', }}>{fileName}</Typography>
                </div>
            </Box>
        </>
    )
}
export default WordFilecard;
