import { ContactSupportOutlined } from '@mui/icons-material';
import api from '../configurations/http-common';

export const create = (data) => {
    return api.securedAxios().post('/web/role', data);
};

export const getAll = (payload) => {
    let url = '/web/role';
    if (payload.name !== '') {
        let name = encodeURIComponent(payload.name);
        url += '?name=' + name;
    }
    if ((payload.code !== '') & (payload.name !== '')) {
        let code = encodeURIComponent(payload.code);
        url += '&code=' + code;
    }
    if ((payload.code !== '') & (payload.name === '')) {
        let code = encodeURIComponent(payload.code);
        url += '?code=' + code;
    }
    return api.securedAxios().get(url);
};

export const getPermissionsByRoleId = (roleId) => {
  return api.securedAxios().get(`/web/role/${roleId}/permissions`);

};

export const update = (payload) => {
    return api.securedAxios().put(`/web/role/${payload.id}`, payload);
};

export const deleteRole = (roleId) => {
    return api.securedAxios().delete(`/web/role/${roleId}`);
};

export const getRoleByUsers = (roleId) => {
    return api.securedAxios().get(`/web/role/${roleId}/users`);
};