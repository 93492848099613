import api from "../configurations/http-common";

export const loadLocZone = async (page, size, filter) => {
    let query = 'page=' + page + '&size=' + size + buildQuery(filter);
    return api.securedAxios().post('/web/location/zone/filter?' + query);

    function buildQuery(filter) {
        let query = ''
        if (filter.name && filter.name.trim() !== '') {
            query += `&name=${encodeURIComponent(filter.name.trim())}`;
        }

        if (filter.ids && filter.ids.trim() !== '') {
            query += `&ids=${encodeURIComponent(filter.ids.trim())}`;
        }

        return query

    }
}

export const getZoneLists = (page, size, filter) => {
    const requestBody = {
        ...filter,
        pageNumber: page,
        pageSize: size
    };
    return api.securedAxios().post('/web/location/zone/list', requestBody);
};


export const loadLocSiteByIds = async (page, size, filter) => {
    let query = 'page=' + page + '&size=' + size + buildQuery(filter);
    return api.securedAxios().post('/api/v2.0/site/addres?' + query);

    function buildQuery(filter) {

        let query = ''

        if (filter.ids != '') {
            query = query + `&ids= ${filter.ids}`
            return query.replace(" ", "")
        }

        return query

    }
}

export const filterZone = async (payload, callBack) => {
    try {
        const res = await api.securedAxios().post(`/web/location/zone/filter?page=${payload.pageNumber}&size=${payload.pageSize}` + buildQueryFilter(payload.filter));
        callBack(res);
    } catch (error) { }
}

export const filterZoneWithoutLoader = async (payload, callBack) => {
    try {
        const res = await api.securedAxiosWithoutLoader().post(`/web/location/zone/filter?page=${payload.pageNumber}&size=${payload.pageSize}` + buildQueryFilter(payload.filter));
        callBack(res);
    } catch (error) { }
}
const buildQueryFilter = (filter) => {

    let query = "";

    if (filter.name) {
        query = query + "&name=" + filter.name
    }
    if (filter.siteId) {
        query = query + "&siteId=" + filter.siteId
    }
    return query;
}

export const filterZoneV3 = async (page, size, filter) => {
    return api.securedAxios().post('/web/location/zone/filter?' + "page=" + page + "&size=" + size + buildQueryZone(filter))
}


function buildQueryZone(filter) {
    let query = ""
    if (filter.name) {
        query = query + "&name=" + filter.name
    }
    if (filter.parentId) {
        query = query + "&parentId=" + filter.parentId
    }
    if (filter.siteId) {
        query = query + "&siteId=" + filter.siteId
    }
    return query
}

export const getZoneById = async (id, callBack) => {
    const res = await api.securedAxiosWithoutLoader().get('/web/location/zone/'+ id)
    callBack(res)
}