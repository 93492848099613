import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout-components/MainLayout";
import { REACT_ADD_GROUP_ACCESS_TIME, 
  REACT_CREATE_TIMESLOT, REACT_DASHBORD, 
  REACT_GROUP_ACCESS_TIME, REACT_TIME_SLOT, 
  REACT_UPDATE_TIMESLOT, 
  REACT_URL_ACCESS_GROUP_ADD_DOOR, 
  REACT_URL_ACCESS_GROUP_ADD_PERSON, 
  REACT_URL_ACCESS_GROUP_DOOR, 
  REACT_URL_ACCESS_GROUP_PERSON, 
  REACT_URL_ADD_DEVICE_INSTRUCTION, 
  REACT_URL_AUXILIARY_INPUT, 
  REACT_URL_AUXILIARY_OUTPUT, 
  REACT_URL_BIND_DEVICE, 
  REACT_URL_DEVICE_LIST_V4, 
  REACT_URL_DEVICE_TOPOLOGY,
   REACT_URL_DOOR_ACCESS_TIME, 
   REACT_URL_DOOR_LIST, 
   REACT_URL_EVENT, 
   REACT_URL_PERSON, 
   REACT_URL_PERSON_VIEW, 
   REACT_URL_READER_VIEW,
   REACT_URL_AUXILIARY_INPUT_VIEW,
   REACT_URL_AUXILIARY_OUTPUT_VIEW,
   REACT_URL_READER_EDIT,
   REACT_URL_AUXILIARY_OUTPUT_EDIT,
   REACT_URL_AUXILIARY_INPUT_EDIT,
   REACT_URL_READER, 
   REACT_URL_SITE, 
   REACT_URL_SITE_VIEW, 
   REACT_URL_UPDATE_DEVICE, 
   REACT_URL_ZONE, 
   REACT_URL_ZONE_VIEW, 
   REACT_USERS,
   REACT_URL_EDIT_DOOR, 
   REACT_EDIT_GROUP_ACCESS_TIME,
   REACT_URL_PERSON_IN_DEVICE,
   REACT_URL_USERROLES, 
   REACT_URL_UPDATEROLES,
   REACT_URL_VIEWEROLES,
   REACT_URL_OPERATION_LOG_LIST,
   REACT_URL_GENERATED_REPORTS,
   REACT_URL_SESSION_EXPIRED,
   REACT_URL_PAGE_NOT_FOUND,
   REACT_URL_QUICK_SETUP,
   REACT_URL_QUICK_SETUP_PAGE
  } from "./constants/ReactEndPoints";
import AccessGroupDoor from "./pages/AccessControl/AccessGroupDoor";
import AccessGroupPerson from "./pages/AccessControl/AccessGroupPerson";
import AddAccessGroupDoor from "./pages/AccessControl/AddAccessGroupDoor";
import AddAccessGroupPerson from "./pages/AccessControl/AddAccessGroupPerson";
import AddTimeSlot from "./pages/AccessControl/AddTimeSlot";
import EditTimeSlot from "./pages/AccessControl/EditTimeSlot";
import ZlinkRedirection from "./configurations/ZlinkRedirection";
import TimeSlot from "./pages/AccessControl/TimeSlot";
import AuxiliaryInput from "./pages/AuxiliaryInput/AuxiliaryInput";
import AuxiliaryOutput from "./pages/AuxiliaryOutput/AuxiliaryOutput";
import DashBoard from './pages/Dashboard/DashBoard';
import BindDevice from "./pages/Device/BindDevice";
import DeviceInstructionPage from "./pages/Device/DeviceInstructionPage";
import DeviceList from "./pages/Device/DeviceList";
import DeviceTopology from "./pages/Device/DeviceTopology/DeviceTopology";
import EditDevice from "./pages/Device/EditDevice";
import DoorList from "./pages/Door/DoorList";
import DoorAccessTime from "./pages/DoorAccessTime/DoorAccessTime";
import Events from "./pages/Events/Events";
import AddGroupAccessTime from "./pages/GroupAccessTime/AddGroupAccessTime";
import GroupAccessTime from "./pages/GroupAccessTime/GroupAccessTime";
import Person from "./pages/Person/Person";

import ViewPerson from "./pages/Person/ViewPerson";
import Reader from "./pages/Reader/Reader";
import Site from "./pages/Site/Site";
import ViewSite from "./pages/Site/ViewSite";
import Users from "./pages/Users/Users";
import ViewZone from "./pages/Zone/ViewZone";
import Zone from "./pages/Zone/Zone";
import EditDoorPage from "./pages/Door/EditDoorPage";
import EditGroupAccessTime from "./pages/GroupAccessTime/EditGroupAccessTime";
import PersonInDevice from "./pages/Device/PersonInDevice";
import ViewReader from "./pages/Reader/ViewReader"
import ViewAuxiliaryInput from "./pages/AuxiliaryInput/ViewAuxiliaryInput";
import ViewAuxiliaryOutput from "./pages/AuxiliaryOutput/ViewAuxiliaryOutput";
import EditReader from "./pages/Reader/EditReader";
import EditAuxiliaryInput from "./pages/AuxiliaryInput/EditAuxiliaryInput";
import EditAuxiliaryOutput from "./pages/AuxiliaryOutput/EditAuxiliaryOutput";
import  Roles from "./pages/UserRoles/UserRoles";
import  EditRoles from "./pages/UserRoles/EditRolePermission";
import  ViewPage from "./pages/UserRoles/ViewRolePermission";
import OperationLog from "./pages/OperationLog/OperationLog";
import GeneratedReports from "./pages/Events/GeneratedReports";
import SessionOut from "./configurations/SessionOut";
import Page404 from "./components/Page404";
import InfoPage from "./pages/QuickSetup/InfoPage";
import CommonPage from "./pages/QuickSetup/CommonPage";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={REACT_DASHBORD} replace={true} />} />
      <Route path={REACT_URL_PAGE_NOT_FOUND} element={  <Page404/>} />
      <Route path={REACT_URL_SESSION_EXPIRED} element={<SessionOut/>}  />
      <Route
        path={REACT_DASHBORD}
        element={
          <MainLayout >
            <DashBoard/>
          </MainLayout>} />
      <Route
        path={REACT_USERS}
        element={
          <MainLayout>
            <Users />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_PERSON}
        element={
          <MainLayout>
            <Person />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_USERROLES}
        element={
          <MainLayout>
            <Roles/>
          </MainLayout>
        }
      />

      <Route
        path={REACT_URL_UPDATEROLES+"/:roleCode"}
        element={
          <MainLayout>
            <EditRoles/>
          </MainLayout>
        }
      />

      <Route
        path={REACT_URL_VIEWEROLES+"/:roleCode"}
        element={
          <MainLayout>
           <ViewPage/>
          </MainLayout>
        }
      /> 
      <Route
        path={REACT_TIME_SLOT}
        element={
          <MainLayout>
            <TimeSlot />
          </MainLayout>
        }
      />
      <Route
        path={REACT_GROUP_ACCESS_TIME}
        element={
          <MainLayout>
            <GroupAccessTime />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_PERSON_VIEW+"/:personId"}
        element={
          <MainLayout>
            <ViewPerson />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_READER_VIEW+"/:readerId"}
        element={
          <MainLayout>
            <ViewReader />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_READER_EDIT+"/:readerId"}
        element={
          <MainLayout>
            <EditReader />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_AUXILIARY_INPUT_EDIT+"/:deviceId"}
        element={
          <MainLayout>
            <EditAuxiliaryInput />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_AUXILIARY_OUTPUT_EDIT+"/:deviceId"}
        element={
          <MainLayout>
            <EditAuxiliaryOutput />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_AUXILIARY_INPUT_VIEW+"/:deviceId"}
        element={
          <MainLayout>
            <ViewAuxiliaryInput />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_AUXILIARY_OUTPUT_VIEW+"/:deviceId"}
        element={
          <MainLayout>
            <ViewAuxiliaryOutput />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_SITE}
        element={
          <MainLayout>
            <Site />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_SITE_VIEW+"/:siteId"}
        element={
          <MainLayout>
            <ViewSite />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_ZONE}
        element={
          <MainLayout>
            <Zone />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_ZONE_VIEW+"/:zoneName"}
        element={
          <MainLayout>
            <ViewZone />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_READER}
        element={
          <MainLayout>
            <Reader />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_AUXILIARY_INPUT}
        element={
          <MainLayout>
            <AuxiliaryInput />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_AUXILIARY_OUTPUT}
        element={
          <MainLayout>
            <AuxiliaryOutput />
          </MainLayout>
        }
      />

      <Route
        path={REACT_URL_EVENT}
        element={
          <MainLayout>
            <Events />
          </MainLayout>
        }
      />
      <Route
        path={REACT_CREATE_TIMESLOT}
        element={
          <MainLayout>
            <AddTimeSlot />
          </MainLayout>
        }
      />
      <Route
        path={REACT_ADD_GROUP_ACCESS_TIME}
        element={
          <MainLayout>
            <AddGroupAccessTime />
          </MainLayout>
        }
      />

      <Route
        path={REACT_URL_DEVICE_LIST_V4}
        element={
          <MainLayout>
            <DeviceList />
          </MainLayout>
        }
      />

      <Route
        path={REACT_URL_ADD_DEVICE_INSTRUCTION}
        element={
          <MainLayout>
            <DeviceInstructionPage />
          </MainLayout>
        }
      />
      <Route
        path={REACT_UPDATE_TIMESLOT+"/:timeslotId"}
        element={
          <MainLayout>
            <EditTimeSlot />
          </MainLayout>
        }
      />

      <Route
        path={REACT_URL_BIND_DEVICE}
        element={
          <MainLayout>
            <BindDevice />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_ACCESS_GROUP_PERSON+"/:id"}
        element={
          <MainLayout>
            <AccessGroupPerson />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_ACCESS_GROUP_ADD_PERSON+"/:id"}
        element={
          <MainLayout>
            <AddAccessGroupPerson />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_DEVICE_TOPOLOGY}
        element={
          <MainLayout>
            <DeviceTopology />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_ACCESS_GROUP_DOOR+"/:id"}
        element={
          <MainLayout>
            <AccessGroupDoor />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_ACCESS_GROUP_ADD_DOOR+"/:id"}
        element={
          <MainLayout>
            <AddAccessGroupDoor />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_DOOR_LIST}
        element={
          <MainLayout>
            <DoorList />
          </MainLayout>
        }
      />
       <Route
        path={REACT_URL_UPDATE_DEVICE+"/:sn"}
        element={
          <MainLayout>
            <EditDevice />
          </MainLayout>
        }
      />
       <Route
        path={REACT_EDIT_GROUP_ACCESS_TIME+"/:acecssName"}
        element={
          <MainLayout>
            <EditGroupAccessTime />
            </MainLayout>
          }
      />
       <Route
        path={REACT_URL_DOOR_ACCESS_TIME}
        element={
          <MainLayout>
            <DoorAccessTime />
            </MainLayout>
          }
      />

      <Route
        path={REACT_URL_EDIT_DOOR+"/:doorId"}
        element={
          <MainLayout>
            <EditDoorPage />
          </MainLayout>
        }
      />
       <Route
        path={REACT_URL_PERSON_IN_DEVICE+"/:sn"}
        element={
          <MainLayout>
            <PersonInDevice />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_GENERATED_REPORTS}
        element={
          <MainLayout>
            <GeneratedReports />
          </MainLayout>
        }
      />

      <Route
        path={REACT_URL_OPERATION_LOG_LIST}
        element={
          <MainLayout>
            <OperationLog />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_QUICK_SETUP}
        element={
          <MainLayout>
            <InfoPage />
          </MainLayout>
        }
      />
      <Route
        path={REACT_URL_QUICK_SETUP_PAGE}
        element={
          <MainLayout>
            <CommonPage/>
          </MainLayout>
        }
      />
      <Route path="*" element={<Page404/>} />
    </Routes>
  );
}
