import { Box, Grid, ToggleButton, ToggleButtonGroup, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import React, { useState } from 'react'
import { ReactComponent as Table } from '../../../assets/DeviceIcon/Table icon.svg';
import { ReactComponent as Topology } from '../../../assets/DeviceIcon/DeviceTopology.svg';
import { ReactComponent as TopologyDetails } from '../../../assets/DeviceIcon/DeviceTopologyDetailPage.svg';
import TablePage from './TablePage';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getPermissionsByRoleId } from '../../../services/RoleService';
import api from '../../../configurations/http-common';
import { useEffect } from 'react';
import RightViewLayout from '../../../components/RighViewLayout';


const DeviceTopology = () => {
    const [index, setIndex] = useState(1);
    const { t } = useTranslation()

    const [perDeviceTopology, setDeviceTopology] = useState(true);

    useEffect(() => {
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            var l = [];
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });
            setDeviceTopology(l.includes("ACC_VIEW_DEVICE_TOPOLOGY") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        });
    }, []);

    const handleChange = (event, nextView) => {
        setIndex(nextView);
    };

    const actions = () => (
        <>
            <ToggleButtonGroup
                value={index}
                exclusive
                onChange={handleChange}
            >
                <ToggleButton value={1} aria-label="topology-details-page">
                    <Topology />
                </ToggleButton>
                <ToggleButton value={2} aria-label="table-topology">
                    <Table />
                </ToggleButton>

            </ToggleButtonGroup>
        </>

    );

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'white !important',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 12,
            border: '1px solid #4CAF50',
            borderRadius: '4px',
            top: '2px',
        },
        '& .MuiArrow-root': {
            color: '#4CAF50',
        },
    }));

    const extraTilte = () => (
        <LightTooltip title={t("AccDMTopology026")}
            placement="right"
            arrow
            PopperProps={{
                style: { backgroundColor: 'white !important', color: 'white' },
            }}
        >
            <HelpOutlineIcon style={{ color: '#76B745', position: 'relative', marginLeft: '15px', top: '5px', cursor: "pointer" }} />
        </LightTooltip>
    )



    return (
        <>
            {
                perDeviceTopology ? (<TitleBar
                    title={t('AccDMTopology001')}
                    actions={actions}
                    extraTilte={extraTilte()}
                    navigateBack={true} path={false} >

                    <Box
                        sx={{
                            border: "1px solid #DDDDDD",
                            width: "100%",
                            borderRadius: '8px',
                            padding: index === 1 ? "19px 0px 19px 50px" : "15px",
                            marginTop: { xl: "39px", xs: "20px" }
                        }}
                        id={"view-page" + index}
                    >
                        {index === 1 &&
                            <Box
                                sx={{ height: { xl: '74vh', lg: '69vh', md: '61vh' } }}
                                className='scroll-container'
                            >
                                <TopologyDetails /></Box>}
                        {index === 2 && <TablePage />}
                    </Box>

                </TitleBar >) : (<Box marginTop={'150px'} width={'100%'}>
                    <Typography textAlign={'center'}>{t('AccDMTopology025')} </Typography></Box>)}

        </>
    )
}

const TitleBar = (props) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                }}
            >
                <Box item flexGrow={1} sx={{ display: { xl: "block", xs: "none" } }} id={"module-title"}>
                    <Typography variant={"moduleHeaderDesktop"}>{props.title}
                        <span style={{ padding: "10px 0px 0px 5px" }}>{props.extraTilte}</span>
                    </Typography>
                </Box>
                <Box item flexGrow={1} sx={{ display: { xl: "none", xs: "block" } }} id={"module-title"}>
                    <Typography variant={"moduleHeaderLaptop"}>{props.title} </Typography>
                    <span style={{ padding: "10px 0px 0px 5px" }}>{props.extraTilte}</span>
                </Box>
                {props.actions ? <props.actions /> : <Box sx={{ height: { xl: "56px", xs: "46px" } }}></Box>}
            </Box>
            <Grid
                container
                style={{
                    width: "100%",
                    backgroundColor: "#FFFFFF",
                    overflowY: "auto",
                }}
            >
                {props.children}
            </Grid>
        </>
    )
}

export default DeviceTopology
