import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './DashBoard.css';
import SiteDashboardCards from './SiteDashboardCards';
import EventGraph from "./EventGraph";
import GlobalMapContainer from './GlobalMapContainer';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import TitleBar from '../../components/TitleBar';
import Loader from '../../components/Loader';

export default function DashBoard() {
  const { t } = useTranslation(0);
  const [roles, setRoles] = React.useState([]);
  const [eventOverviewGraph, setEventOverviewGraph] = React.useState(true);
  const [siteOverviewMap, setSiteOverviewMap] = React.useState(true);
  const [dashBoardCard, setDashboardCard] = React.useState(true);
  const [dashBoardView, setDahsBoardView] = React.useState(null)
  const [loader, setLoader] = React.useState(false);


  React.useEffect(() => {
    var l = [];
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      res.data.data.rolePermissions.map((item, index) => {
        l.push(item.code);
      });
      setRoles(l);
      setEventOverviewGraph(l.includes("VIEW_EVENT_OVERVIEW_GRAPH") || api.decoded().roleCode === 'OWNER' || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
      setSiteOverviewMap(l.includes("VIEW_SITE_OVERVIEW_MAP") || api.decoded().roleCode === 'OWNER' || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))
      setDashboardCard(l.includes("VIEW_ACC_DASHBOARD") || api.decoded().roleCode === 'OWNER' || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))

      if (l.includes("VIEW_ACC_DASHBOARD") === false && api.decoded().roleCode === 'OWNER' || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
        setDahsBoardView(true)
      } else {
        if (l.includes("VIEW_ACC_DASHBOARD") === true) {
          setDahsBoardView(true)
        }
        else {
          setDahsBoardView(false)
        }
      }

    });
    setRoles(l);
  }, []);


  const checkPermission = (code) => {
    if ((api.decoded() != undefined && api.decoded().roleCode === 'OWNER') ||
      (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))
      return true;

    return roles.includes(code);
  }


  const setLoaderFunction = () => {
    let loader = localStorage.getItem('isLoading')
    setLoader(loader);
  }
  React.useEffect(() => {
    window.addEventListener('storage', setLoaderFunction);
    return () => {
      window.removeEventListener('storage', setLoaderFunction);
    };
  }, []);
  return (
    <Grid container spacing={0}>
      {/* <Typography variant='h6' component='h6' style={{  lineHeight:'29px', color: '#474B4F', marginTop: '5px', marginBottom: '20px' }}>
        {t('ACCDA0001')}
      </Typography> */}

      {(dashBoardView) ?
        (<>
          <TitleBar
            title={t('ACCDA0001')}
            navigateBack={true}
            path={false}
          />
          <Grid item xs={12} sm={12}>
            <Box width={'100%'}>
              {dashBoardCard && (<SiteDashboardCards roles={roles}
                sitePerm={checkPermission('ACC_VIEW_SITE_LISTS')}
                devicePerm={checkPermission('VIEW_DEVICE_LIST_DETAILS')}
                personPerm={checkPermission('ACC_VIEW_PERSONLIST')}
                doorPerm={checkPermission('ACC_DOOR_LIST')}
                eventPerm={checkPermission('VIEW_ACC_REPORT')}
              />)}
            </Box>
          </Grid>
          {loader ? <Loader open={loader} /> : <></>}
          <Grid item xs={12}>
            <Box width={'100%'}>
              {eventOverviewGraph && (
                <EventGraph roles={roles} />
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box width={'100%'}>
              {siteOverviewMap && (
                <GlobalMapContainer roles={roles} />)
              }
            </Box>
          </Grid></>) : (

          dashBoardView == false && <Box marginTop={'200px'} width={'100%'}>
            <Typography textAlign={'center'}>
              {t("AccDashBoard000")}
            </Typography>
          </Box>
        )}
    </Grid>
  );
}
