import { Avatar, Box, Grid, Typography } from '@mui/material';
import React from "react";
import CustomInput from '../../components/ViewText';
import { useEffect, useState } from "react";
import { loadDeviceAuxiliaryOutputById } from "../../services/AuxiliaryOutputService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import CommonUtil from '../../util/CommonUtils';
import ViewPage from '../../components/ViewPage';
import { REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import Loader from '../../components/Loader';

const ViewAuxiliaryOutput = (props) => {

    const { t } = useTranslation()
    const location = useLocation();
    const [aux, setAux] = useState([]);
    const [perViewAuxOut, setViewAuxOut] = useState(false);
    const [permissions,setPermissions]= useState([]);
    const[loader,setLoader]=React.useState(true);
    const params = useParams();
    const deviceId = params.deviceId;
    const navigate = useNavigate();
  
    React.useEffect(() => {

        if (CommonUtil.isEmpty(deviceId)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }
      
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if(res.data.code==="CACCZE001"){
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev=>[...prev,item.code])
                });
            }
            setViewAuxOut(true);
        });
    }, []);

    useEffect(()=>{
     if(checkPermission("ACC_AUXILIARY__OUTPUT_VIEW")){
      setLoader(true);
        loadDeviceAuxiliaryOutputById(deviceId).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setAux(res.data.data);

            } 
            setLoader(false);
        });
     }
    },[permissions])

    const checkPermission = (permissionCode) => {  
        if(api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
          return true;
        else{
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
      }


    return (
        <>
           {perViewAuxOut ?checkPermission("ACC_AUXILIARY__OUTPUT_VIEW") ? <ViewPage title={t('AccAuxOut003')} 
            path={true}
            onClick={()=>navigate(
               '/auxiliaryoutput',
               { state: {pageNumber:location?.state?.pageNumber,pageSize:location?.state?.pageSize}})}>
        
                <Grid direction='row' alignItems={'flex-start'} id={"auxout-view-grid"}  >
                {CustomInput({ label: t('AccAuxOut008'), value: aux.name,index:1 })}
                {CustomInput({ label: t("AccReader012"), value: aux.deviceName,index:2 })}
                {CustomInput({ label: t("AccAuxOut009"), value: aux.deviceSn,index:3 })}
                {CustomInput({ label: t("AccAuxOut010"), value: aux.aux_no,index:4 })}
                {CustomInput({ label: t("AccAuxIn009"), value: aux.printer_number,index:5 })}
                {CustomInput({ label: t("COMMON024"), value: aux.remark,index:6 })}
            </Grid>
            {loader?<Loader open={loader}/>:<></>}
            </ViewPage>          
             : (<Box marginTop={'150px'} width={'100%'} id={"permission-box-aux-out"}>
                <Typography textAlign={'center'} id={"permission-text-aux-out"}>{t("AccAuxOut014")}</Typography>
                </Box>):<></>}

        </>

    )
};

export default ViewAuxiliaryOutput;