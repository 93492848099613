import axios from "axios";
import jwt_decode from "jwt-decode";
import CommonUtil from "../util/CommonUtils";
import { BasicConfigurationApiForZlinkRedirect } from "../services/OnboardService";

const axiosHelper = (headers) => {
  const redirectKey = CommonUtil.getCookie("zlink_redirect_url");

  let req = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: headers,
  });

  req.interceptors.request.use(function (request) {
    localStorage.setItem("isLoading", true);
    window.dispatchEvent(new Event("storage"));
    return request;
  });

  req.interceptors.response.use(
    function (response) {
      localStorage.setItem("isLoading", false);
      window.dispatchEvent(new Event("storage"));
      return response;
    },
    function (error) {
      if (401 === error.response.status && window.location.pathname !== "/access/error") {
        BasicConfigurationApiForZlinkRedirect().then((responseData) => {
          if (responseData?.data?.code === 'CAAI0001') {
            window.location.href = responseData?.data?.data?.zlinkRedirectUrl;
          }
        });
        if (CommonUtil.getCookie("ZLinkCloudAccess") === "true") {
          window.location = redirectKey;
        } else {
          window.location = "/access/error";
        }
      }
      return Promise.reject(error);
    }
  );

  return req;
};

const axiosHelperWithoutLoader = (headers) => {
  const redirectKey = CommonUtil.getCookie("zlink_redirect_url");

  let req = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: headers,
  });

  req.interceptors.request.use(function (request) {
    window.dispatchEvent(new Event("storage"));
    return request;
  });

  req.interceptors.response.use(
    function (response) {
      window.dispatchEvent(new Event("storage"));
      return response;
    },
    function (error) {
      if (401 === error.response.status && window.location.pathname !== "/access/error") {
        BasicConfigurationApiForZlinkRedirect().then((responseData) => {
          if (responseData?.data?.code === 'CAAI0001') {
            window.location.href = responseData?.data?.data?.zlinkRedirectUrl;
          }
        });
        if (CommonUtil.getCookie("ZLinkCloudAccess") === "true") {
          window.location = redirectKey;
        } else {
          window.location = "/access/error";
        }
      }
      return Promise.reject(error);
    }
  );
  return req;
}

const securedAxios = () => {
  return axiosHelper({
    "Content-type": "application/json",
    "time-zone": CommonUtil.getTimeZone(),
    Authorization: CommonUtil.getCookie('zlink_token')
  });
};

const securedAxiosWithoutLoader = () => {
  return axiosHelperWithoutLoader({
    "Content-type": "application/json",
    "time-zone": CommonUtil.getTimeZone(),
    Authorization: CommonUtil.getCookie('zlink_token')
  });
}

const decoded = () => {
  try {
    var decode = jwt_decode(CommonUtil.getCookie('zlink_token'));
    return decode;
  } catch {
    const redirectKey = CommonUtil.getCookie("zlink_redirect_url");
    window.location = redirectKey;
  }
};

const unsecuredAxios = () => {
  return axiosHelper({
    "Content-type": "application/json",
    "time-zone": CommonUtil.getTimeZone(),
  });
};

const api = {
  axiosHelper,
  securedAxios,
  unsecuredAxios,
  decoded, axiosHelperWithoutLoader, securedAxiosWithoutLoader
};

export default api;
