import Button from "@mui/material/Button";
import * as React from "react";
import LoadingSpinner from "./loadingSpinner";

export default function CustomButton({ label, isLoading, handleSubmit }) {
  return (
    <>
      <Button
        size="large"
        variant="contained"
        onClick={() => handleSubmit()}
        disabled={isLoading ? true : false}
        sx={{
          borderRadius:"6px",
          textTransform: "capitalize",
            width: { xl: "233px", xs: "180px" },
            height: { xl: "64px", xs: "50px" },
            marginRight: "30px"
          }}
      >
        {isLoading ? <LoadingSpinner /> : label}
      </Button>
    </>
  );
}
