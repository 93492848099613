import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApartmentIcon from '@mui/icons-material/Apartment';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { Box, Button, Typography } from "@mui/material";
import { filterDevicesFetch } from "../../services/DeviceService";
import { filterDoorsFetch } from "../../services/DoorService";
import CommonUtil from "../../util/CommonUtils";
import { siteListWithAddressV2 } from "../../services/SiteService";

function GoogleMapDialog(props) {
    const { t } = useTranslation()
    const [map, setMap] = useState(null)
    const [zoom, setZoom] = useState(3)
    const [finallocation, Setfinallocation] = useState([]);
    const [selected, setSelected] = useState('');
    const [searchBox, setSearchBox] = useState(null);
    const [center, setCenter] = useState({ lat: 36.32852408225397, lng: -38.72770055138199 });
    const [selectedSite, setSelectedSite] = useState('');
    const [tempSelected, setTempSelected] = useState('');
    const [sitesData, setSitesData] = React.useState(new Map());
    const [filter, setFilter] = React.useState({
        siteId: '',
    });

    useEffect(() => {
        siteListWithAddressV2(1, 0).then((res) => {
            if (res?.data?.code === 'LMSI0000' && !CommonUtil.isEmpty(res?.data?.data?.site) && res?.data?.data?.site instanceof Array) {
                filterlocation(res.data.data.site);
            }
        });
    }, []);

    const filterlocation = (storedata) => {
        let requiredData = [];
        storedata.map((item) => requiredData.push({ address: item.address, id: item.id, site: item }));
        let result = [];
        requiredData.map((item) =>
            item.address.latitude
                ? result.push({
                    location: { lat: parseFloat(item.address.latitude), lng: parseFloat(item.address.longitude) },
                    id: item.id,
                    country: item.address.country,
                    site: item.site,
                })
                : ''
        );
        if (result.length > 0) {
            setCenter(result[result.length - 1].location);
            Setfinallocation(result);
        }
    };

    const loadAllCountBySiteId = (item) => {
        let collectData = {};
        collectData.siteCountry = item.country;
        collectData.location = item.location;
        collectData.siteName = item.site.name;

        filterDevicesFetch(1, 1000, { ...filter, siteId: item.id }).then((res) => {
            if (CommonUtil.isEmptyString(res?.data?.data?.totalCount)) {
                collectData.deviceCount = 0;
            } else {
                collectData.deviceCount = res.data.data.totalCount;
            }

            filterDoorsFetch(1, 1000, { ...filter, siteId: item.id }).then((res) => {
                if (CommonUtil.isEmptyString(res?.data?.data?.totalCount)) {
                    collectData.doorCount = 0;
                } else {
                    collectData.doorCount = res.data.data.totalCount;
                }

                setSelectedSite(item.id);
                setSelected({
                    ...collectData,
                });
                setTempSelected({
                    ...collectData,
                });
                var sites = sitesData;
                sites.set(item.id, collectData);
                setSitesData(sites);
            });
        });
    };

    const onSelectHover = async (item) => {
        if (!sitesData.has(item.id)) {
            setSelected({});
            loadAllCountBySiteId(item);
        } else {
            setSelected(sitesData.get(item.id));
        }
    };


    const onSelectMarker = (item) => {

    }

    const onMarkerDragEnd = (e) => {

    }



    const exampleMapStyles = [
        {
            elementType: 'geometry',
            stylers: [
                {
                    color: '#E2E4EB',
                },
            ],
        },
        {
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#eeeeee',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e5e5e5',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#dadada',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#616161',
                },
            ],
        },
        {
            featureType: 'road.local',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9e9e9e',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e5e5e5',
                },
            ],
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#eeeeee',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#FFFFFF',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9e9e9e',
                },
            ],
        },
    ];

    const image = {
        url: 'https://img.icons8.com/color/50/000000/place-marker--v2.png'
    }




    return (
        <>
            <GoogleMap
                onLoad={(map) => {
                    setMap(map);
                }}
                options={{
                    styles: exampleMapStyles,
                    fullscreenControl: false,
                }}
                mapContainerStyle={{
                    height: '80vh',
                    width: '100%',
                }}
                zoom={zoom}
                center={center}
            >
                <Box display='flex' flexDirection='row-reverse' p={1} m={1} bgcolor="red">
                    <Box p={1} bgcolor='grey.300'>
                        <Button variant="contained"   size="small" startIcon={<FullscreenExitIcon />} onClick={() => props.toggleFullScreen(false)}>

                        </Button>
                    </Box>
                </Box>

                {finallocation && (
                    <MarkerClusterer>
                        {(clusterer) =>
                            finallocation.map((item) => (
                                <Marker
                                    key={item.id}
                                    position={item.location}
                                    onMouseOver={() => onSelectHover(item)}
                                    onMouseOut={() => setSelected({})}
                                    onClick={() => onSelectMarker(item)}
                                    icon={image}
                                    onDragEnd={(e) => onMarkerDragEnd(e)}
                                    draggable={false}
                                    clusterer={clusterer}
                                ></Marker>
                            ))
                        }
                    </MarkerClusterer>
                )}

                {selected.location && (
                    <InfoWindow
                        options={{ maxWidth: 250, padding: 50, pixelOffset: { width: 0, height: -50 } }}
                        position={selected.location}
                        clickable={true}
                        visible={true}
                        onCloseClick={() => setSelected({})}

                    >
                        <div>
                            <Typography gutterBottom>
                                {' '}
                                <ApartmentIcon color='disabled' />
                                &nbsp; {t('ACCDA0021')} : <span style={{ color: 'green' }}>{selected.siteName} </span>
                            </Typography>
                            <Typography gutterBottom>
                                {' '}
                                <PhonelinkSetupIcon color='disabled' />
                                &nbsp; {t('ACCDA0022')} <span style={{ color: 'green' }}>({selected.deviceCount}) </span>
                            </Typography>
                            <Typography gutterBottom>
                                {' '}
                                <MeetingRoomIcon color='disabled' />
                                &nbsp; {t('ACCDA0023')} <span style={{ color: 'green' }}>({selected.doorCount}) </span>
                            </Typography>
                            <Typography gutterBottom>
                                {' '}
                                <LocationOnIcon color='disabled' />
                                &nbsp; {t('ACCDA0024')} : <span style={{ color: 'green' }}>{selected.siteCountry}</span>
                            </Typography>
                        </div>
                    </InfoWindow>
                )}

            </GoogleMap>

        </>
    )
}
export default GoogleMapDialog