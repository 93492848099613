import { Avatar, Grid, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import FileBase64 from 'react-file-base64';
import { useTranslation } from 'react-i18next';



export default function ProfileUplod(props) {
  const { t } = useTranslation();
  const [profile, setProfile] = React.useState('');
  const [file, setFile] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState({
    profilePhoto: '',
  });


  const handleProfile = (file) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/bmp') {
      let fileSize = file.size.split(' ')[0];
      let size = parseInt(fileSize);
      if (size <= 3072) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(',')[1],
        });
        props.onsetProfile(file);
        setProfile(file.base64);
      } else {
        setFile('');
        enqueueSnackbar('File size can not exceed 3MB', {
          variant: 'error',
        });
      }
    } else {
      setFile('');
      //setProfile(state.profilePhoto);
      enqueueSnackbar('Invalid file format', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Grid conatiner display={'flex'} direction='column' justifyContent='center' alignItems='center'>
        <Tooltip title={props.profilePhoto ? 'Click here to upload picture' : ''}>
          <IconButton
            variant='contained'
            component='label'
            style={{
              position: 'relative',
              width: '210px',
              height: '210px',
              font: 'normal normal normal 18px/24px Roboto',
              letterSpacing: '0px',
            }}
            disabled={props.disabled}
          >
            {props.profilePhoto ? (
              <Avatar className='ImageContent' imgProps={{ draggable: "false" }} alt='' src={props.profilePhoto} style={{ height: '200px', width: '200px', zIndex: '99', borderRadius: '50%' }} />
            ) : (
              <>
                <Avatar
                  className='ImageContent'
                  alt=''
                  imgProps={{ draggable: "false" }}
                  src={''}
                  // style={{ height: '100%', width: '100%', borderRadius: '50%', backgroundColor: '#F4F6F8' }}
                  sx={[
                    () => ({
                      '& .MuiAvatar-img': {
                        width: '49px',
                        height: '42px',
                        textAlign: 'center',
                        objectFit: 'cover',
                        color: 'transparent',
                        textIndent: '10000px',
                        borderStyle: 'none !important',
                      },
                    }),
                  ]}
                />
                <div style={{ position: 'absolute', top: '68%', left: '30%', zIndex: '1', color: '#C3C5C7', justifyContent: 'center' }} />
                <Grid style={{ display: 'none' }}>
                  <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                </Grid>
              </>
            )}
            <Grid style={{ display: 'none' }}>
              <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
            </Grid>
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  );
}

ProfileUplod.defaultProps = {
  disabled: false,
};
