import { Avatar, Box, Grid, Paper, Typography } from '@mui/material';
import React from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import src from '../../assets/images/locationIcon.svg';
import CustomInput from '../../components/ViewText';
import { timeZoneList } from '../../services/TimeZone';
import { useTranslation } from "react-i18next";
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useState } from 'react';
import CommonUtil from '../../util/CommonUtils';
import { REACT_URL_SITE, REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import { getSiteByIdV2, loadLocSite, loadLocSiteByFilter } from '../../services/SiteService';
import ViewPage from '../../components/ViewPage';
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';
const ViewSite = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [viewSite, setViewSite] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [site, setSite] = useState([]);
    const params = useParams();
    const siteName = params.siteId;
    let navigate = useNavigate();
    const [viewErrorPage, setViewErrorPage] = useState(false)
    const [loader, setLoader] = React.useState(true);

    const getTimeZoneName = (value) => {
        var name = '';
        timeZoneList.map((t) => {
            if (value == t.value) {
                name = t.name;
            }
        });
        return name;
    };

    React.useEffect(() => {

        if (CommonUtil.isEmpty(siteName)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }

        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if (res.data.code === "CACCZE001") {
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev => [...prev, item.code])
                });
            }
            setViewSite(true);
        });
    }, []);

    React.useEffect(() => {
        if (checkPermission("ACC_VIEW_SITE_DETAILS")) {

            setLoader(true);
            getSiteByIdV2(siteName, (res) => {

                if (res?.data?.code === 'LMSI0000') {
                    if (!CommonUtil.isEmpty(res.data.data)) {
                        setSite(res.data.data);
                    } else {
                        setViewErrorPage(true)
                    }


                } else {
                    setViewErrorPage(true)
                }
                setLoader(false);
            });
        }
    }, [permissions])

    const checkPermission = (permissionCode) => {
        if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
            return true;
        else {
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
    }
    return (
        <>
            {viewErrorPage ? <PageNotFound></PageNotFound> : viewSite ? checkPermission("ACC_VIEW_SITE_DETAILS") ? <ViewPage title={t('ACCSITE004')} path={true}
                onClick={() => navigate(
                    REACT_URL_SITE,
                    { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}>
                <Box marginBottom={'30px'} id={"site-avatar"}>
                    <Avatar alt={t('ACCSITE009')} src={src}
                        sx={{
                            "&.MuiAvatar-root": {
                                width: "178px",
                                height: "178px"
                            }
                        }} />
                </Box>
                {loader ? <Loader open={loader} /> : <></>}
                <Grid direction='row' alignItems={'flex-start'} id={"site-view-grid"}  >
                    {CustomInput({ "label": t('ACCSITE001'), 'value': site?.name, index: 1 })}
                    {CustomInput({ "label": t('ACCSITE010'), "value": site?.code, index: 2 })}
                    {CustomInput({ "label": t('ACCSITE011'), "value": getTimeZoneName(site?.address?.timeZone), index: 2 })}
                    {CustomInput({ "label": t('ACCDA0024'), "value": site?.address?.country, index: 3 })}
                    {CustomInput({ "label": t('ACCORG019'), "value": site?.address?.state, index: 4 })}
                    {CustomInput({ "label": t('ACCORG020'), "value": site?.address?.city, index: 5 })}
                    {CustomInput({ "label": t('ACCORG021'), "value": site?.address?.addressLine1, index: 6 })}
                    {CustomInput({ "label": t('ACCORG022'), "value": site?.address?.addressLine2, index: 7 })}
                    {CustomInput({ "label": t('ACCSITE012'), "value": site?.address?.latitude, index: 1 })}
                    {CustomInput({ "label": t('ACCSITE013'), "value": site?.address?.longitude, index: 8 })}
                    {CustomInput({ "label": t('ACCSITE003'), "value": site?.description, index: 9 })}
                </Grid>
            </ViewPage> : (<Box marginTop={'150px'} width={'100%'} id={"permission-box-site"}>
                <Typography textAlign={'center'}>
                    {t("ACCSITE014")}
                </Typography>
            </Box>) : <></>}
        </>

    )
};

export default ViewSite;