import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { loadDashboardCounts } from '../../services/DashboardService';
import SiteDashboardTiles from './SiteDashboardTiles';
import CommonUtil from '../../util/CommonUtils';
import { ReactComponent as SitesCountIcon } from '../../assets/new-images/SitesCount.svg';
import { ReactComponent as DevicesCountIcon } from '../../assets/new-images/DevicesCount.svg'
import { ReactComponent as DoorsCountIcon } from '../../assets/new-images/DoorsCount.svg'
import { ReactComponent as PersonsCountIcon } from '../../assets/new-images/PersonsCount.svg'
import { ReactComponent as EventsCountIcon } from '../../assets/new-images/EventsCount.svg'
import { REACT_URL_DEVICE_LIST_V4, REACT_URL_DOOR_LIST, REACT_URL_EVENT, REACT_URL_PERSON, REACT_URL_SITE } from '../../constants/ReactEndPoints';
import api from '../../configurations/http-common';

export default function SiteDashboardCards(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [roles, setRoles] = React.useState([props.roles.roles]);

  const [sitePerm, setSitePerm] = React.useState(props.sitePerm);
  const [devicePerm, setDevicePerm] = React.useState(props.devicePerm);
  const [personPerm, setPersonPerm] = React.useState(props.personPerm);
  const [doorPerm, setDoorPerm] = React.useState(props.doorPerm);
  const [eventPerm, setEventPerm] = React.useState(props.eventPerm);

  useEffect(() => {
    loadDashboardCounts().then((res) => {
      if(res!=null)
      {
      setData(res.data.data);
      }
    })
  }, []);

  const checkPermission = (code) => {
    if (api.decoded() != undefined && ((api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')))
      return true;
    return roles.includes(code);
  }


  const onSelectSite = () => {
    if (sitePerm) { navigate(REACT_URL_SITE); }
  };

  const onSelectDevice = () => {
    if (devicePerm) { navigate(REACT_URL_DEVICE_LIST_V4); }
  };

  const onSelectDoor = () => {
    if (doorPerm) { navigate(REACT_URL_DOOR_LIST); }
  };

  const onSelectEvent = () => {
    if (eventPerm) { navigate(REACT_URL_EVENT); }
  };

  const onSelectPerson = () => {
    if (personPerm) { navigate(REACT_URL_PERSON); }
  };

  return (
    <>
      <Grid container paddingBottom={'20px'} paddingTop={'20px'} >
        <Grid item lg={2.2}

          sx={{
            display: 'flex', 
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: '#fff',
            borderBlockEnd: '4px solid #FF7801',
            boxShadow: '0px 0px 1px #01c59a',
            padding: '1rem',
            // margin: '0rem 1rem',
            borderRadius: '8px',
            cursor: 'pointer',
            height:{xl:"120px",xs:"100px"},
            marginRight: '2.1%',
            marginBottom: '20px'
          }}
          onClick={() => onSelectSite()}
        > 
        
          <Box marginTop={'5px'} >
          <SitesCountIcon />
          </Box>
          <Grid style={{ marginLeft: '1rem' }}>
            <Typography color='rgba(0,0,0,.85)' style={{ fontSize: '2.2em' }} display={'contents'} m={1}>
              {' '}
              {data?.sitesCount ? data.sitesCount : 0}{' '}
            </Typography>
            <Typography color='rgba(0,0,0,.85)'>{t('ACCDA0014')}</Typography>
          </Grid>
        </Grid>
        <Grid item lg={2.2}
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: '#fff',
            borderBlockEnd: '4px solid #0193F3',
            boxShadow: '0px 0px 1px #01c59a',
            padding: '1rem',
            // margin: '0rem 2rem',
            borderRadius: '7px',
            cursor: 'pointer',
            height:{xl:"120px",xs:"100px"},
            marginRight: '2.1%',
            marginLeft:'0px',
            marginBottom: '20px'
          }}
         
          onClick={() => onSelectDevice()}
        >
          <Box marginTop={'5px'} >
          <DevicesCountIcon />
          </Box>
          <Grid style={{ marginLeft: '1rem' }}>
            <Typography color='rgba(0,0,0,.85)' style={{ fontSize: '2.2em' }} display={'contents'} m={1}>
              {' '}
              {data?.devicesCount ? data.devicesCount : 0}{' '}
            </Typography>
            <Typography color='rgba(0,0,0,.85)'>{t('ACCDA0015')}</Typography>
          </Grid>
        </Grid>

        <Grid item lg={2.2}
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: '#fff',
            borderBlockEnd: '4px solid #30B863',
            boxShadow: '0px 0px 1px #01c59a',
            padding: '1rem',
            borderRadius: '7px',
            cursor: 'pointer',
            height:{xl:"120px",xs:"100px"},
            marginRight: '2.1%',
            marginBottom: '20px'
          }}
          onClick={() => onSelectDoor()}
        >
          <Box marginTop={'5px'} >
          <DoorsCountIcon />
          </Box>
          <Grid sx={{ marginLeft: '1rem' }}>
            <Typography color='rgba(0,0,0,.85)' sx={{ fontSize: '2.2em' }} display={'contents'} m={1}>
              {' '}
              {data?.doorsCount ? data.doorsCount : 0}{' '}
            </Typography>
            <Typography color='rgba(0,0,0,.85)'>{t('ACCDA0016')}</Typography>
          </Grid>
        </Grid>

        <Grid item lg={2.2}
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: '#fff',
            borderBlockEnd: '4px solid #01C59A',
            boxShadow: '0px 0px 1px #01c59a',
            padding: '1rem',
            borderRadius: '7px',
            cursor: 'pointer',
            height:{xl:"120px",xs:"100px"},
            marginRight: '2%',
            marginBottom: '20px'
          }}
          onClick={() => onSelectPerson()}
        >
          <Box marginTop={'5px'} >
          <PersonsCountIcon />
          </Box>
          <Grid style={{ marginLeft: '1rem' }}>
            <Typography color='rgba(0,0,0,.85)' style={{ fontSize: '2.2em' }} display={'contents'} m={1}>
              {' '}
              {data?.personsCount ? data.personsCount : 0}{' '}
            </Typography>
            <Typography color='rgba(0,0,0,.85)'>{t('ACCDA0017')}</Typography>
          </Grid>
        </Grid>

        <Grid item lg={2.2}
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: '#fff',
            borderBlockEnd: '4px solid #FF5B63',
            boxShadow: '0px 0px 1px #01c59a',
            padding: '1rem',
            borderRadius: '7px',
            cursor: 'pointer',
            height:{xl:"120px",xs:"100px"},
            // marginRight: '2%',
          }}
          onClick={() => onSelectEvent()}
        >
          <Box marginTop={'5px'} >
          <EventsCountIcon />
          </Box>
          <Grid style={{ marginLeft: '1rem' }}>
            <Typography color='rgba(0,0,0,.85)' style={{ fontSize: '2.2em' }} display={'contents'} m={1}>
              {' '}
              {data?.eventCount ? data.eventCount : 0}{' '}
            </Typography>
            <Typography color='rgba(0,0,0,.85)'>{t('ACCDA0018')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
