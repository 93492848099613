import { Button, Grid, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInput from '../../components/CustomInput';
import api from '../../configurations/http-common';
import { loadOrgPersons } from '../../services/PersonService';
import CommonUtil from '../../util/CommonUtils';
import { doorOperation } from './../../services/DoorService';


export default function RemoteOpenDoor(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = React.useState({
    openInterval:0,
  });
  const [error, setError] = React.useState({
    openInterval: false,
  });

  const handleChange = (event) => {
    if (event.target.value.length > 3 || event.target.value < 0) {
      return;
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [event.target.name]: false,
    });
  };

  const [personcode, setPersonCode] = useState('0');
  useEffect(() => {
    let userdetail = api.decoded();
    const filterData = { firstName: '', lastName: '', code: '', userId: userdetail.id };

    loadOrgPersons(1, 5, filterData).then((res) => {
      if (res?.data?.code === 'OMSI0000') {
        res.data.data.employees.map((item, index) => {
          setPersonCode(item.code);
        });
      }

    });

  }, []);

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state?.openInterval)) {
      setError({
        openInterval: true,
      });
      enqueueSnackbar(t('AccDoorAccTime018'), {
        variant: 'error',
      });
      return;
    } else if (state?.openInterval.toString().includes(".")) {
      setError({
        openInterval: false,
      });
      enqueueSnackbar(t('AccDoorAccTime032'), {
        variant: 'error',
      });
      return;
    } else if (state?.openInterval > 254 || state?.openInterval == 0) {
      setError({
        openInterval: true,
      });
      enqueueSnackbar(t('AccDoorAccTime019'), {
        variant: 'error',
      });
      return;
    }
    let payload = {
      operator: personcode,
      type: 0,
      triggerNum: props.doorNo,
      delayTime: state.openInterval,
    };

    doorOperation(props.deviceId, props.doorName, payload).then((res) => {
      props.onClose(false);
      if (res?.data?.code === 'DMSI0000') {
        enqueueSnackbar(t("AccDoorAccTime020") + state.openInterval + " seconds", {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(res.data.msg, {
          variant: 'error',
        });
      }
    });
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }

  const timeField = () => (
    <CustomInput
      id="textfield-remoteopendoor-open-interval"
      fullWidth
      error={error.openInterval}
      name='openInterval'
      label={t('AccDoorAccTime021')}
      onKeyDown={(e) => handleKeyDown(e)}
      required
      variant='outlined'
      size='small'
      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      helperText={error.openInterval}
      handleChange={handleChange}
      type={'number'}
      value={state.openInterval}
      defaultValue={state.openInterval}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: < InputAdornment position="end" >In seconds  </InputAdornment>,
        maxLength: 3,

      }}
    />
  );

  return (
    <>
      <Grid>
        {timeField()}
        <Grid
          sx={{
            display: 'flex',
            width: "100%",
            marginTop: { xl: "30px", xs: "20px" }
          }}>
          <Button
            id="button-remoteopendoor-open-interval"
            variant='contained'
            onClick={() => handleSubmit()}
            sx={{
              width: "50%",
              height: { xl: '64px', xs: "50px" },
              marginRight: { xl: "25px", xs: "10px" }
            }}
          >
            {t("COMMON029")}
          </Button>
          <Button
            id="button-remoteopendoor-open-clear"
            onClick={() => setState({ ...state, openInterval: 0 })}
            variant='outlined'
            sx={{
              width: "50%",
              height: { xl: '64px', xs: "50px" },
              marginLeft: { xl: "15px", xs: "10px" }
            }}
          >
            {t("COMMON015")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
