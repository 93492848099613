import { makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ListItemIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ContactUs } from "../../assets/ContactUs.svg";
import defaultPersonProfile from '../../assets/defaultPersonProfile.svg';
import { ReactComponent as DownArrow } from "../../assets/down-arrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrow.svg";
import { ReactComponent as SwitchLanguage } from "../../assets/SwitchLanguage.svg";
import { ReactComponent as TickIcon } from "../../assets/Tick.svg";
import api from "../../configurations/http-common";
import HelpDesk from "../../pages/HelpDesk/HelpDesk";
import { getUserDetails } from "../../services/UserService";
import BrowserLanguage from "../../util/BrowserLanguage";
import CommonUtil from "../../util/CommonUtils";
import OverFlowTypography from "../OverFlowTypography";

const SwitchLanguageMenu = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [anchorEl2, setanchorEl2] = React.useState(null);
  const [leftArrow, setLeftArrow] = React.useState(false);

  const switchLangPopupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });
  const [selectedLang, setSelectedLang] = useState('en');
  const [openMenu, setOpenMenu] = React.useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    switchLangPopupState.close();
    props.setAnchorEl(false);
  };
  const handleCloseLangMenu = (event) => {
    setanchorEl2(event.currentTarget);
    if (event[0] === undefined) {
      BrowserLanguage.getDefaultLanguage();
      setSearch(null);
    } else {
      CommonUtil.setCookie("i18nextLng", event);
      BrowserLanguage.setLanguage(event);
      i18n.changeLanguage(event);
      setSearch(event);
      setSelectedLang(event);
      let successMessage = '';
      switch (localStorage.getItem("i18nextLng")) {
        case 'en':
          successMessage = t("successMessage");
          break;
        case 'vt':
          successMessage = t("successMessage5");
          break;
        case 'th':
          successMessage = t("successMessage4");
          break;
        case 'idn':
          successMessage = t("successMessage3");
          break;
        case 'bur':
          successMessage = t("successMessage1");
          break;
        default:
          successMessage = '';
      }
      if (successMessage) {
        const snackbar = enqueueSnackbar(successMessage, {
          variant: 'success',
        });
        handleClose();
      }
    }
  };

  useEffect(() => {
    const currentLanguage = CommonUtil.getCookie('i18nextLng')
    setSelectedLang(currentLanguage);
  }, []);

  // const handleClose = () => {
  //   // setAnchorEl(null);
  //   setOpenMenu(false);
  // };

  const listTextStyle = {
    font: "normal normal normal 16px/19px Roboto",
    letterSpacing: "0.4px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    "&:hover": {
      color: " #76B745",
      backgroundColor: "#F4FCF7"
    },
    padding: "8px 16px"
  }

  return (
    <div>
      <MenuItem
        id="menu-userprofile-switchlangauge"
        {...bindTrigger(switchLangPopupState)}
      >
        <Box display="flex" width={"200px"}>
          <Box item>
            <ListItemIcon>
              <SwitchLanguage
                fill={
                  props.selected === "SwitchLanguageMenu"
                    ? " #76B745"
                    : "#474B4F"
                }
                fontSize="small"
              />
            </ListItemIcon>
          </Box>
          <Box item flexGrow={1}>
            <Typography
              style={{
                color: props.selected === "SwitchLanguageMenu" ? " #76B745" : "",
              }}
            >
              {t("ACCLANG001")}
            </Typography>
          </Box>

          <Box item sx={{ marginTop: '3px' }}>
            {leftArrow ? (
              <LeftArrow fontSize="small" fill="#76B745" />
            ) : (
              <DownArrow
                fontSize="small"
                fill={props.selected === "SwitchLanguageMenu" ? ' #76B745' : "inherit"}
              />
            )}
          </Box>
        </Box>
      </MenuItem>
      <Popover
        onFocus={() => setLeftArrow(true)}
        onBlur={() => setLeftArrow(false)}
        {...bindPopover(switchLangPopupState)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          value={"en"}
          key={"en"}
          onClick={() => {
            handleCloseLangMenu("en");
          }}
          sx={{
            padding: '0px',
            backgroundColor: selectedLang === "en" ? "#F4FCF7" : "inherit",
            "&:hover": {
              backgroundColor: "#F4FCF7",
            },
          }}
        >
          <Typography sx={{ ...listTextStyle, color: selectedLang === "en" ? "#76B745 !important" : "#474B4F" }}>{t("ACCLANG002")}</Typography>
          {selectedLang === "en" && (
            <TickIcon style={{ marginRight: '10px' }} />
          )}
        </MenuItem>
        <MenuItem
          value={"bur"}
          key={"bur"}
          onClick={() => {
            handleCloseLangMenu("bur");
          }}
          sx={{
            padding: '0px',
            backgroundColor: selectedLang === "bur" ? "#F4FCF7" : "inherit",
            "&:hover": {
              backgroundColor: "#F4FCF7",
            },
          }}
        >
          <Typography sx={{ ...listTextStyle, color: selectedLang === "bur" ? "#76B745 !important" : "#474B4F" }}>{t("ACCLANG005")}</Typography>
          {selectedLang === "bur" && (
            <TickIcon style={{ marginRight: '10px' }} />
          )}
        </MenuItem>
        <MenuItem
          value={"vt"}
          key={"vt"}
          onClick={() => {
            handleCloseLangMenu("vt");
          }}
          sx={{
            padding: '0px',
            backgroundColor: selectedLang === "vt" ? "#F4FCF7" : "inherit",
            "&:hover": {
              backgroundColor: "#F4FCF7",
            },
          }}
        >
          <Typography sx={{ ...listTextStyle, color: selectedLang === "vt" ? "#76B745 !important" : "#474B4F" }}>{t("ACCLANG003")}</Typography>
          {selectedLang === "vt" && (
            <TickIcon style={{ marginRight: '10px' }} />
          )}
        </MenuItem>
        <MenuItem
          value={"th"}
          key={"th"}
          onClick={() => {
            handleCloseLangMenu("th");
          }}
          sx={{
            padding: '0px',
            backgroundColor: selectedLang === "th" ? "#F4FCF7" : "inherit",
            "&:hover": {
              backgroundColor: "#F4FCF7",
            },
          }}
        >
          <Typography sx={{ ...listTextStyle, color: selectedLang === "th" ? "#76B745 !important" : "#474B4F" }}>{t("ACCLANG004")}</Typography>
          {selectedLang === "th" && (
            <TickIcon style={{ marginRight: '10px' }} />
          )}
        </MenuItem>
        <MenuItem
          value={"idn"}
          key={"idn"}
          onClick={() => {
            handleCloseLangMenu("idn");
          }}
          sx={{
            padding: '0px',
            backgroundColor: selectedLang === "idn" ? "#F4FCF7" : "inherit",
            "&:hover": {
              backgroundColor: "#F4FCF7",
            },
          }}
        >
          <Typography sx={{ ...listTextStyle, color: selectedLang === "idn" ? "#76B745 !important" : "#474B4F" }}>{t("ACCLANG006")}</Typography>
          {selectedLang === "idn" && (
            <TickIcon style={{ marginRight: '10px' }} />
          )}
        </MenuItem>

      </Popover>
    </div>
  );
};

export default function UserProfileMenu() {
  const { t } = useTranslation();
  const [open2, setOpen2] = React.useState(false);
  const [LogOutDialog, setLogOutDialog] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeMenu, setActiveMenu] = React.useState("");
  const [user, setUser] = useState(null);

  const [openHelpDesk, setOpenHelpDesk] = useState(false);
  const handleCloseHelpDesk = () => {
    setOpenHelpDesk(false)
  }

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const useStyles = makeStyles({
    onHoverMenu: {
      "&:hover": {
        color: " #76B745",
      },
    },
  });
  const classes = useStyles();

  const handleSetAnchorE1 = () => {
    setAnchorEl(null);
  };
  const handleSelectedMenu = (menu) => {
    setActiveMenu(menu);
  };
  const [data, setData] = useState();
  useEffect(() => {
    let userdetail = api.decoded();
    if (userdetail) {
      getUserDetails(userdetail.id).then((res) => {
        if (res.data.code === "UASI0033") {
          setUser(res.data.data);
        }
      });
    }
  }, []);

  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: "center",
          maxWidth: { lg: "220px", xs: "200px" },
          margin: "0 10px 0 0"
        }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <Avatar
          sx={{
            width: "40px",
            height: "40px",
            margin: "0px 10px",
          }}
          src={user?.imagePreSignedURL ? user?.imagePreSignedURL : defaultPersonProfile}
        ></Avatar>

        <OverFlowTypography
          value={user?.firstName && `${user?.firstName.charAt(0).toUpperCase()}${user?.firstName.slice(1)} ${user?.lastName.charAt(0).toUpperCase()}${user?.lastName.slice(1)}`}
          font={"normal normal normal 16px/19px Roboto"}
          sx={{ cursor: "pointer" }}
        />

        <KeyboardArrowDownIcon
          style={{ cursor: "pointer" }}
          id="icon-userprofile-menu-arrowdown"
          {...bindTrigger(popupState)}
          sx={{
            display: "flex",
            fontSize: { xs: "24px", lg: "28px" },
            marginRight: '5px',
            color: '#474B4F'
          }}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ mt: "45px" }}
        id="basic-menu"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
        >
          <div
            style={{
              backgroundColor:
                activeMenu === "SwitchLanguageMenu" ? '#76B745!important' : "",
            }}
            onClick={() => handleSelectedMenu("SwitchLanguageMenu")}
            onMouseOver={() => handleSelectedMenu("SwitchLanguageMenu")}
            onMouseOut={() => handleSelectedMenu("")}
          >
            <SwitchLanguageMenu selected={activeMenu} setAnchorEl={handleSetAnchorE1} />
          </div>

          <MenuItem
            id='menu-userprofile-contactus'
            onClick={() => {
              setOpenHelpDesk(true)
            }}
            className={classes.onHoverMenu}
          >
            <Box display='flex' width={'200px'}>
              <Box item>
                <ListItemIcon>
                  <ContactUs fill={activeMenu === 'Contactus' ? '#76B745' : "#474B4F"}/>
                </ListItemIcon>
              </Box>
              <Box item flexGrow={1}>
                <Typography className={classes.onHoverMenu}
                  onMouseOver={() => handleSelectedMenu('Contactus')}
                  onMouseOut={() => handleSelectedMenu('')}
                >{t('CONTACT002')}</Typography>
              </Box>
            </Box>
          </MenuItem>
        </div>
      </Menu>
      {openHelpDesk && <HelpDesk open={openHelpDesk} close={handleCloseHelpDesk} version={""} />}
    </>
  );
}