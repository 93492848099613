import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Checkbox, Tooltip } from '@mui/material';
import CommonUtil from '../../../util/CommonUtils';
import NoRecordFound from '../../../components/NoRecordFound';
import Loader from './Loader';
import { GetContext } from '../../../AppStore';
import { ReactComponent as CheckBoxIcon } from "../../../assets/CheckBoxIcon.svg"

export default function QuickSetupTable(props) {

  const value = GetContext();
  const [selectHeader, setselectHeader] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(value?.quickSetupPayload?.personIds ? value?.quickSetupPayload?.personIds : []);
  // const [selectedRowName, setSelectedRowName] = React.useState(value?.quickSetupPayload?.personNames?value?.quickSetupPayload?.personNames:[]);

  React.useEffect(() => {
    if (!CommonUtil.isEmpty(selectedRow) || selectedRow)
      value.setQuickSetupPayload({
        ...value.quickSetupPayload,
        personIds: selectedRow,
        // personNames:selectedRowName
      });
    value.setErrorQuickSetupPayload({
      ...value.errorQuickSetupPayload,
      personIds: ''
    });
  }, [selectedRow])

  React.useEffect(() => {
    setSelectedRow(value?.quickSetupPayload?.personIds)
  }, [value?.quickSetupPayload?.personIds]);

  const handleSelectAllClick = (event) => {
    let newSelected = [];
    if (props.rows instanceof Array && !CommonUtil.isEmpty(props.rows)) {
      newSelected = props.rows.map((n) => n.id);
    }
    if (event.target.checked && !CommonUtil.isEmpty(newSelected)) {
      setselectHeader(newSelected);
      newSelected = Array.from(new Set(newSelected.concat(selectedRow)))
      setSelectedRow(newSelected);
      return;
    } else {
      if (!CommonUtil.isEmpty(newSelected)) {
        newSelected.map((row) => {
          setSelectedRow(prev => prev.filter(id => id !== row));
        });
        setselectHeader([]);
      }
    }
  };

  React.useEffect(() => {
    let newSelected = [];
    if (props.rows instanceof Array && !CommonUtil.isEmpty(props.rows)) {
      newSelected = props.rows.map((n) => n.id);
    } else {
      setselectHeader([])
    }
    if (props.loader === "fetched") {
      if (!CommonUtil.isEmpty(selectedRow)) {
        let val = newSelected.filter(id => selectedRow instanceof Array && selectedRow.includes(id))
        setselectHeader(val);
      } else {
        setselectHeader([])
      }
    }
  }, [props.loader, props.rows, selectedRow])

  const customCellStyle = {
    "&.MuiTableCell-root": {
      color: 'rgba(71, 75, 79, 0.8) !important',
      letterSpacing: '0.4px',
      fontSize: { xl: '16px', lg: "14px", md: "14px", sm: "14px", xs: "14px" },
      border: "none",
      lineHeight: "19px",
      padding: "0px",
      height: { xl: "60px", xs: "50px" },
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }
  }

  return (
    <Box width={"100%"} >
      <TableContainer sx={{ height: "480px", border: '1px solid rgba(245, 246, 246, 1)', borderRadius: "8px" }}>
        <Table stickyHeader aria-label="sticky table" >
          <TableHead >

            <TableRow sx={{ backgroundColor: '#F5F6F6 !important' }}>

              <TableCell sx={{ ...customCellStyle, backgroundColor: '#F5F6F6 !important' }} width={"8%"}>
                <Checkbox
                  icon={<CheckBoxIcon />}
                  indeterminate={selectHeader.length > 0 && selectHeader.length < props.rows?.length}
                  checked={selectHeader.length > 0 && selectHeader.length === props.rows?.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>

              <TableCell sx={{ ...customCellStyle, backgroundColor: '#F5F6F6 !important' }} width={"65%"} align="left">Person Name</TableCell>
              <TableCell sx={{ ...customCellStyle, backgroundColor: '#F5F6F6 !important' }} width={"40%"} align="left">Person ID</TableCell>

            </TableRow>
          </TableHead>
          {props.loader === "fetched" && (props.isDepartmentSelected !== "" || props.totalCount !== -1) && <TableBody>
            {(props.rows) instanceof Array && props.rows.map((row) => {
              return (
                <>
                  <TableRow
                    hover
                    key={row.id}
                  >
                    <TableCell sx={customCellStyle}>
                      <Checkbox
                        checked={selectedRow instanceof Array && selectedRow.includes(row.id)}
                        icon={<CheckBoxIcon />}
                        onChange={(e) => {
                          if (e.target.checked === false) {
                            setSelectedRow(prev => prev.filter(id => id !== row.id));
                            setselectHeader(prev => prev.filter(id => id !== row.id))
                          } else {
                            if (selectedRow instanceof Array) {
                              setSelectedRow(prev => [...prev, row.id]);
                              setselectHeader(prev => [...prev, row.id]);
                            } else {
                              setSelectedRow([row.id])
                              setselectHeader([row.id])
                            }
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell sx={customCellStyle} width={"65%"} align="left">
                      <Tooltip title={(row.firstName + " " + row.lastName).length > 30 ? (row.firstName + " " + row.lastName) : ""}>
                        {CommonUtil.handleTextOverflow((row.firstName + " " + row.lastName), 30)}
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={customCellStyle} width={"40%"} align="left">
                      <Tooltip title={(row.code + "").length > 30 ? (row.code) : ""}>
                        {CommonUtil.handleTextOverflow((row.code + " "), 30)}
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </>
              )
            })}
          </TableBody>}
        </Table>
        {props.loader === "fetched" || props.loader === false ? ((props.isDepartmentSelected === "" || props.totalCount == -1) && <Table>
          <Box sx={{
            width: "100%",
            mt: { xl: "20%", xs: "23%" }
          }}>
            <NoRecordFound text={props.totalCount == -1 ? "No Person found" : "Select Department to view Persons"} />
          </Box>
        </Table>) : <Table>
          <Box sx={{
          width: "100%",
          mt: { xl: "20%", xs: "23%" }
        }}><Loader /></Box></Table>}
      </TableContainer>
    </Box>
  );
}

QuickSetupTable.defaultProps = {
  rows: [],
  columns: [],
};
