import { Box } from '@mui/material'
import React from 'react'
import RightViewLayout from './RighViewLayout'

const ViewPage = (props) => {
  
  return (
    <>
     <RightViewLayout title={props.title} path={true} onClick={props.onClick}/>
    
      <Box 
        sx={{
            border:"1px solid #DDDDDD",
            width:"100%",
            borderRadius: '8px',
            padding:"30px",
            marginTop:{xl:"30px",xs:"15px"}
        }}
        id={"view-page"}
      > 
           {props.children}
      </Box>   
    </>
  )
}

export default ViewPage
