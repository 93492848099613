import axios from "axios"
import api from "../configurations/http-common";

export const loadEvents = async (page, size, filter) => {
    let query = 'page=' + page + '&size=' + size + buildQuery(filter);
    return api.securedAxios().get('/web/device/events?' + query);

    function buildQuery(filter) {
        let query = ''

        if (filter.startDateTime != '' && !filter.startDateTime.includes('NaN')) {
            query = query + `&startDateTime= ${filter.startDateTime}`.replace(" ", "");

        }
        if (filter.endDateTime != '' && !filter.endDateTime.includes('NaN')) {
            query = query + `&endDateTime= ${filter.endDateTime}`.replace(" ", "");
        }

        if (filter.sn != '' && !filter.sn!= undefined) {
            query = query + `&sn= ${filter.sn}`.replace(" ", "");
        }

        if (filter.deviceName != '' && !filter.deviceName!= undefined) {
            query = query + `&deviceName= ${filter.deviceName}`.replace(" ", "");
        }

        if (filter.eventType != '' && !filter.eventType!=undefined) {
            query = query + `&eventType= ${filter.eventType}`.replace(" ", "");
        }


        return query

    }
}

export const loadEventsByHours = async (timeRange) => {
    return api.securedAxios().get('/web/device/events/graph?hours=' + timeRange);
}

