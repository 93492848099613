import axios from "axios"
import api from "../configurations/http-common";

export const getDepartmentById = async (id) => {
    return api.securedAxios().get('/web/organization/department/' + id);
}

export const getDepartmentList = async (payload) => {
    return api.securedAxiosWithoutLoader()
        .post(`/web/organization/departments`);
}

export const getJobtitleList = async (payload) => {
    return api.securedAxiosWithoutLoader()
        .post(`/web/organization/jobtitles`);
}

export const getAllDepartmentList = async (payload) => {
    return api.securedAxiosWithoutLoader()
        .post(`/web/organization/department/filter?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`);
}

export const getDesignationById = async (id) => {
    return api.securedAxios().get('/web/organization/designation/' + id);
}

export const loadOrgPersonsById = async (id) => {
    return api.securedAxios().get('/web/organization/person/' + id);
}

export const loadOrgPersonsWithoutLoader = async (page, size, filter) => {
    let query = 'page=' + page + '&size=' + size + buildQuery(filter);

    return api.securedAxiosWithoutLoader().post('/web/organization/person/list?' + query);

}

export const loadOrgPersons = async (page, size, filter) => {
    let query = 'page=' + page + '&size=' + size + buildQuery(filter);

    return api.securedAxios().post('/web/organization/person/list?' + query);

}

function buildQuery(filter) {
    let query = ''

    if (filter?.firstName !== undefined && filter?.firstName !== '') {
        query = query + `&firstName=${filter.firstName}`.trimRight();

    }
    if (filter?.lastName !== undefined && filter?.lastName !== '') {
        query = query + `&lastName=${filter.lastName}`.trimRight();
    }

    if (filter?.code !== undefined && filter?.code !== '') {
        query = query + `&code=${filter.code}`.trimRight();
    }

    if (filter?.userId !== undefined && filter.userId !== '') {
        query = query + `&userId=${filter.userId}`.trimRight();
    }
    if (filter?.userId !== undefined && filter.userId !== '') {
        query = query + `&userId=${filter.userId}`.trimRight();
    }
    if (filter?.departmentId !== undefined && filter?.departmentId !== '') {
        query = query + `&departmentId=${filter.departmentId}`.trimRight();
    }
    if (filter?.designationId !== undefined && filter?.designationId !== '') {
        query = query + `&designationId=${filter.designationId}`.trimRight();
    }
    if (filter?.search !== undefined && filter?.search !== '') {
        query = query + `&search=${encodeURIComponent(filter?.search)}`;
    }
    return query

}


export const getPersonListByDepartmentId = async (payload) => {
    let query = 'pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize + "&departmentId=" + payload.departmentId;
    return api.securedAxios()
        .post('/web/organization/person/search?' + query);
}


export const getEmployeeCountBySiteId = (siteId) => async () => {
    return api.securedAxios().get('/api/v2.0/employee' + '/' + 'search' + '?page=' + 1 + '&size=' + 1000 + '&siteId=' + siteId);
    //   if (response.status === 200 && response.data.code === 'OMSI0000') {
};
