import { GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import BrowserLanguage from '../../util/BrowserLanguage';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { filterDevicesFetch } from '../../services/DeviceService';
import { filterDoorsFetch } from '../../services/DoorService';
import { siteListWithAddressV2 } from '../../services/SiteService';
import CommonUtil from '../../util/CommonUtils';
import ResponsiveDialog from './ResponsiveDialog';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOn';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoom';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { applicationConfigsApi } from '../../services/OnboardService';

const lib = ['places'];
// let language = BrowserLanguage.getDefaultLanguage();

export default function GlobalMapContainer() {
  const { t } = useTranslation();
  const [center, setCenter] = useState({ lat: 36.32852408225397, lng: -38.72770055138199 });
  const [zoom, setZoom] = useState(3);
  const [selected, setSelected] = useState({});
  const [tempSelected, setTempSelected] = useState('');
  const [finallocation, Setfinallocation] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [map, setMap] = useState(null);
  const [sites, setSites] = useState([]);
  const [fullScreen, toggleFullScreen] = useState(false);
  const [googleMapKey, setGoogleMapKey] = useState('');
  const [filter, setFilter] = React.useState({
    siteId: '',
  });
  const [sitesData, setSitesData] = React.useState(new Map());
  const supportedLanguages = ['en', 'vt', 'th', 'idn', 'bur'];

  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(BrowserLanguage.getDefaultLanguage());
  const browserLanguage = BrowserLanguage.getDefaultLanguage();

  useEffect(() => {
    if (supportedLanguages.includes(browserLanguage)) {
      const storedLanguage = localStorage.getItem('i18nextLng');
      const languageToSet = supportedLanguages.includes(storedLanguage) ? storedLanguage : browserLanguage;

      localStorage.setItem('i18nextLng', languageToSet);
      setLanguage(languageToSet);
      i18n.changeLanguage(languageToSet);
    } else {
      setLanguage('en');
      i18n.changeLanguage('en');
      localStorage.setItem('i18nextLng', 'en');
    }
  }, [i18n, browserLanguage]);



  useEffect(() => {
    loadData();
  }, []);

  // const loadData = () => {
  //   siteListWithAddressV2(1, 0).then((res) => {
  //     if (res?.data?.code === 'LMSI0000' && !CommonUtil.isEmpty(res?.data?.data?.site) && res?.data?.data?.site instanceof Array) {
  //       filterlocation(res.data.data.site);

  //       var siteList = [];
  //       res.data.data.site.map((site) => {
  //         if (!CommonUtil.isEmpty(site?.address) && (site?.address?.latitude !== '0' || site?.address?.longitude !== '0')) {
  //           siteList.push(site);
  //         }
  //       });
  //       setSites(siteList);
  //     }
  //   })
  // }
  const hasTwoDecimalPlaces = (num) => /^-?\d+\.\d{2}$/.test(num);

  const isValidLatLng = (lat, lng) => {
    return (
      typeof lat === 'number' &&
      typeof lng === 'number' &&
      lat >= -90 && lat <= 90 &&
      lng >= -180 && lng <= 180 &&
      hasTwoDecimalPlaces(lat.toFixed(2)) &&
      hasTwoDecimalPlaces(lng.toFixed(2))
    );
  };
  const loadData = () => {
    siteListWithAddressV2(1, 0).then((res) => {
      if (
        res?.data?.code === 'LMSI0000' &&
        !CommonUtil.isEmpty(res?.data?.data?.site) &&
        res?.data?.data?.site instanceof Array
      ) {
        filterlocation(res.data.data.site);

        var siteList = [];

        res.data.data.site.map((site) => {
          const latitude = parseFloat(site?.address?.latitude);
          const longitude = parseFloat(site?.address?.longitude);

          // Push to siteList only if the lat and lng are valid and non-zero
          if (
            !CommonUtil.isEmpty(site?.address) &&
            isValidLatLng(latitude, longitude) &&
            (latitude !== 0 || longitude !== 0)
          ) {
            siteList.push(site);
          }
        });

        setSites(siteList);
      }
    });
  };


  const filterlocation = (storedata) => {
    let requiredData = [];
    storedata.map((item) => requiredData.push({ address: item.address, id: item.id, site: item }));
    let result = [];
    requiredData.map((item) =>
      item.address.latitude
        ? result.push({
          location: { lat: parseFloat(item.address.latitude), lng: parseFloat(item.address.longitude) },
          id: item.id,
          country: item.address.country,
          site: item.site,
        })
        : ''
    );
    if (result.length > 0) {
      const lastLocation = result[result.length - 1].location;

      if (
        lastLocation &&
        isValidLatLng(lastLocation.lat, lastLocation.lng)
      ) {
        setCenter(lastLocation);
      } else {
        setCenter({ lat: 36.32852408225397, lng: -38.72770055138199 });
      }
      Setfinallocation(result);

      if (sitesData.size < 1) loadAllSitesData(result);
    }
  };

  const loadAllSitesData = (data) => {
    var sites = new Map();
    const s = data?.map((item, index) => {
      let collectData = {};
      collectData.siteCountry = item.country;
      collectData.location = item.location;
      collectData.siteName = item.site.name;

      filterDevicesFetch(1, 1000, { ...filter, siteId: item.id }).then((res) => {
        if (CommonUtil.isEmptyString(res?.data?.totalCount) || res?.data?.totalCount == 0) {
          collectData.deviceCount = 0;
          collectData.doorCount = 0;
          sites.set(item.id, collectData);
          return;
        } else {
          collectData.deviceCount = res.data.totalCount;
        }

        filterDoorsFetch(1, 1000, { ...filter, siteId: item.id }).then((res) => {
          if (CommonUtil.isEmptyString(res?.data?.totalCount)) {
            collectData.doorCount = 0;
          } else {
            collectData.doorCount = res.data.totalCount;
          }

          sites.set(item.id, collectData);
          console.error(sites);
          setSitesData(sites);

        });
      });
    });
  };

  const loadAllCountBySiteId = (item) => {
    let collectData = {};
    collectData.siteCountry = item.country;
    collectData.location = item.location;
    collectData.siteName = item.site.name;

    filterDevicesFetch(1, 1000, { ...filter, siteId: item.id }).then((res) => {
      if (CommonUtil.isEmptyString(res?.data?.data?.totalCount)) {
        collectData.deviceCount = 0;
      } else {
        collectData.deviceCount = res.data.data.totalCount;
      }

      filterDoorsFetch(1, 1000, { ...filter, siteId: item.id }).then((res) => {
        if (CommonUtil.isEmptyString(res?.data?.data?.totalCount)) {
          collectData.doorCount = 0;
        } else {
          collectData.doorCount = res.data.data.totalCount;
        }
        setSelectedSite(item.id);
        setSelected({
          ...collectData,
        });
        setTempSelected({
          ...collectData,
        });
        var sites = sitesData;
        sites.set(item.id, collectData);
        setSitesData(sites);
      });
    });
  };

  const onSelectHover = async (item) => {
    if (!sitesData.has(item.id)) {
      setSelected({});
      loadAllCountBySiteId(item);
    } else {
      setSelected(sitesData.get(item.id));
    }
  };

  const onSelectMarker = (item) => {
    if (!sitesData.has(item.id)) {
      setSelected({});
      loadAllCountBySiteId(item);
    } else {
      setSelected(sitesData.get(item.id));
    }
  };
  const [searchBox, setSearchBox] = useState(null);

  const onPlacesChanged = () => console.log(searchBox.getPlaces());

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  const centerSite = (site) => {
    if (!CommonUtil.isEmpty(site?.address?.latitude)) {
      setCenter({ lat: parseFloat(site.address.latitude), lng: parseFloat(site.address.longitude) });
      setZoom(16);
    } else {
      setZoom(3);
    }
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    // setCurrentPosition({ lat, lng })
  };

  const exampleMapStyles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#E2E4EB',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#FFFFFF',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ];

  const image = {
    url: 'https://img.icons8.com/color/50/000000/place-marker--v2.png',
    // size: google.maps.Size(20, 32),
    // origin: google.maps.Point(0, 0),
    // anchor: google.maps.Point(0, 32),
  };

  const handleZoomChange = () => {
    if (map) {
      if (map.getZoom() < 2) {
        map.setZoom(2);
      }
    }
  };

  useEffect(() => {
    applicationConfigsApi().then((res) => {
      if (res?.data?.code === 'CASI0001') {
        setGoogleMapKey(res.data.data.googleMapKey);
      }
    })
  }, []);

  return (
    <>
      <ResponsiveDialog open={fullScreen} setOpen={(value) => toggleFullScreen(value)} />
      <Box display='flex' flexDirection='row' paddingBottom={'30px'}>
        <Box flexGrow={1} paddingTop={'4px'}>
          <Typography variant='h6' component='h6' style={{ lineHeight: '24px', color: '#474B4F', marginLeft: '2px', marginTop: '6px' }}>
            {t('ACCDA0019')}
          </Typography>
        </Box>

        <Box sx={{
          width: { xl: "350px", xs: "250px" }
        }}>
          <Autocomplete
            sx={{ '& .MuiAutocomplete-input': { cursor: 'pointer' } }}
            fullWidth
            size='small'
            id="drop-down-dashboard-site-searchsite"
            openText={t('ACCDA0020')}
            closeText={t('ACCDA0020')}
            noOptionsText={t('No Options')}
            options={sites}
            clearText={t('Clear')}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box component="li" sx={{ wordBreak: 'break-all' }} {...props}>
                {option.name}
              </Box>
            )}
            onChange={(event, newValue) => {
              if (!CommonUtil.isEmpty(newValue)) {
                centerSite(newValue);
              } else {
                setZoom(3);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className='login_input'
                variant='outlined'
                fullWidth
                placeholder={t('ACCDA0020')}
                // label={t('ACCDA0020')}
                InputLabelProps={{ shrink: false }}
              />
            )}
          />
        </Box>
      </Box>
      {googleMapKey && (
        // <LoadScript id='script-loader' googleMapsApiKey={googleMapKey} language={language == 'idn' ? 'id' : language} libraries={lib}>
        <LoadScript
          id='script-loader'
          googleMapsApiKey={googleMapKey}
          language={
            language === 'idn' ? 'id' :
              language === 'th' ? 'th' :
                language === 'bur' ? 'my' :
                  language === 'vt' ? 'vi' :
                    language
          }
          libraries={lib}
        >
          <GoogleMap
            onLoad={(map) => {
              setMap(map);
            }}
            options={{
              styles: exampleMapStyles,
              fullscreenControl: false,
            }}
            mapContainerStyle={{
              height: '400px',
              width: '100%',
              marginBottom: '70px'
            }}
            zoom={zoom}
            center={center}
            onZoomChanged={() => handleZoomChange()}
            onResize={() => alert('size')}
          >
            {finallocation && (
              <>
                <MarkerClusterer>

                  {(clusterer) =>
                    finallocation.map((item) => (
                      <Marker
                        key={item.id}
                        position={item.location}
                        onMouseOver={() => onSelectHover(item)}
                        onMouseOut={() => setSelected({})}
                        onClick={() => onSelectMarker(item)}
                        // icon={{ url: logo }}
                        icon={image}
                        onDragEnd={(e) => onMarkerDragEnd(e)}
                        draggable={false}
                        clusterer={clusterer}
                      ></Marker>
                    ))
                  }
                </MarkerClusterer>
              </>
            )}

            <Box display='flex' flexDirection='row-reverse' p={1} m={1}>
              <Box p={1} bgcolor='grey.300'>
                <Button variant='outlined' backGroundColor={'default'} size='medium' startIcon={<FullscreenIcon />} onClick={() => toggleFullScreen(!fullScreen)}></Button>
              </Box>
            </Box>


            {selected.location && (
              <InfoWindow
                options={{ maxWidth: 240, padding: 50, pixelOffset: { width: 0, height: -50 } }}
                position={selected.location}
                clickable={true}
                visible={true}
                onCloseClick={() => setSelected({})}
              >
                <div style={{ marginTop: "15px" }}>
                  <Box display={'flex'} flexDirection={'row'}>
                    <ApartmentIcon color='disabled' />
                    <Typography gutterBottom style={{ fontSize: '14px' }}> {t('ACCDA0021')} : <span style={{ color: 'green', wordBreak: 'break-all' }}>{selected.siteName} </span>
                    </Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'}>
                    <PhonelinkSetupIcon color='disabled' />
                    <Typography gutterBottom style={{ fontSize: '14px' }} > {t('ACCDA0022')} : <span style={{ color: 'green' }}>{selected.deviceCount} </span>
                    </Typography>
                  </Box>

                  <Box display={'flex'} flexDirection={'row'}>
                    <MeetingRoomOutlinedIcon color='disabled' />
                    <Typography gutterBottom style={{ fontSize: '14px' }} > {t('ACCDA0023')} : <span style={{ color: 'green' }}>{selected.doorCount} </span>
                    </Typography>
                  </Box>

                  <Box display={'flex'} flexDirection={'row'}>
                    <LocationOnOutlinedIcon color='disabled' />
                    <Typography gutterBottom style={{ fontSize: '14px' }} > {t('ACCDA0024')} : <span style={{ color: 'green', wordBreak: 'break-all' }}>{selected.siteCountry} </span>
                    </Typography>
                  </Box>

                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      )}
    </>
  );
}
