import { InputAdornment, Tooltip, Typography, Zoom, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { ReactComponent as Delete } from "../../assets/new-images/Delete Icon.svg";
import { ReactComponent as Edit } from "../../assets/new-images/Edit Icon.svg";
import { ReactComponent as Filter } from "../../assets/new-images/Filter icon (1).svg";
import { ReactComponent as ManageDoor } from "../../assets/new-images/Manage Door.svg";
import { ReactComponent as ManagePerson } from "../../assets/new-images/Manage Person.svg";
import { ReactComponent as Add } from "../../assets/new-images/Plus icon.svg";
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import ConfirmModal from '../../components/layout-components/ConfirmModal';
import DataTable from '../../components/layout-components/DataTable';
import RightViewLayout from '../../components/RighViewLayout';
import { REACT_ADD_GROUP_ACCESS_TIME, REACT_EDIT_GROUP_ACCESS_TIME, REACT_URL_ACCESS_GROUP_DOOR, REACT_URL_ACCESS_GROUP_PERSON } from '../../constants/ReactEndPoints';
import { deleteAcccessGroupById, getFilterAccessGroup } from '../../services/AccessGroupService';
import CommonUtil from '../../util/CommonUtils';
import { GroupAccessTimeFilter } from "./GroupAccessTimeFilter";
import { useSnackbar } from 'notistack';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { DatePicker, Grid } from 'antd';
import add from 'date-fns/add/index';
import { useTranslation } from 'react-i18next';
import CustomDialogBox from '../../components/CustomDialogBox';
import PaginationContext from '../../components/layout-components/PaginationContext';
import { SearchOutlined } from '@mui/icons-material';
import moment from 'moment';
import Clear from '@mui/icons-material/Clear';
import { ReactComponent as SearchIcon } from '../../assets/SearchIcon.svg'
import SearchBar from '../../components/SearchBar';

export default function GroupAccessTime() {
    const { t } = useTranslation()
    const location = useLocation();
    const { manageGATPageNumber, setManageGATPageNumber, manageGATPageSize, setManageGATPageSize } = useContext(PaginationContext);
    const [page, setPage] = React.useState(manageGATPageNumber ? manageGATPageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(manageGATPageSize ? manageGATPageSize : 10);
    const [accessGroup, setAccessGroup] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [clear, setClear] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const defaultFilter = {
        name: '',
        startTime: '',
        endTime: ''
    };

    const [filterData, setFilterData] = useState(defaultFilter);
    const [change, setChange] = React.useState(false);
    const [disabled, setDisabled] = useState(true);
    const [reload, setReload] = useState(false);
    const [clearAndRefresh, setClearAndRefresh] = useState(false);
    const [filter, setFilter] = useState(false);
    const [load, setLoad] = useState(true);
    const [ids, setIds] = useState([]);
    const [del, SetDel] = useState(false);
    const [delId, setDelId] = useState([]);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [pageSize, setPageSize] = useState(0);

    const [roles, setRoles] = React.useState(['']);

    const screenWidth = useMediaQuery('(min-width:1600px)')
    const [manageAccess, setManageAccess] = React.useState(true);
    const [manageDoor, setManageDoor] = React.useState(false);
    const [managePerson, setManagePerson] = React.useState(false);
    const [addAccessGroup, setAddAccessGroup] = React.useState(false);
    const [deleteAccessGroup, setDeleteAccessGroup] = React.useState(false);

    const [eventDate, setEventDate] = React.useState({
        startTime: '',
        endTime: '',
    });

    useEffect(() => {

        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            var l = [];
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });
            setRoles(l);

            setManageAccess(l.includes("MANAGE_ACCESS_GROUP_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setManageDoor(l.includes("MANAGE_DOOR_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setManagePerson(l.includes("MANAGE_PERSONS_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setAddAccessGroup(l.includes("ADD_EDIT_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setDeleteAccessGroup(l.includes("DELETE_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        });

    }, []);

    useEffect(() => {
        if (load && !filter)
            if (roles.includes("MANAGE_ACCESS_GROUP_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
                loadAccessGroup(page, rowsPerPage, defaultFilter);
            }
    }, [page, rowsPerPage])

    useEffect(() => {
        if (filter && !load && reload) {
            setPage(1);
            loadAccessGroup(1, rowsPerPage, filterData);
            setReload(false)
        }
    }, [filter, reload]);

    const loadAccessGroup = (page, size, filter) => {
        if (filter.name) {
            searchQueue.current = []
        }
        setIds([]);
        getFilterAccessGroup(page, size, filter).then((res) => {
            if (
                res?.status === 200 &
                res != null &&
                !CommonUtil.isEmpty(res) &&
                res?.data?.code === 'DMSI0000' &&
                !CommonUtil.isEmpty(res?.data?.data) &&
                res.data.data.accessGroups instanceof Array
            ) {
                setClear(false);
                setAccessGroup(res.data.data.accessGroups);
                setPageSize(res.data.data.pageSize);
                setTotalRecords(res.data.data.totalCount);
            } else {
                setAccessGroup([]);
                setTotalRecords(0);
            }
            setClearAndRefresh(false);
        });
    };

    const openAccessGroupLevelDialog = (rowData) => {
        navigate(REACT_ADD_GROUP_ACCESS_TIME, { state: { pageNumber: page, pageSize: rowsPerPage } });
    };

    const gotoAccessGroupPersonPage = (rowData) => {
        navigate(REACT_URL_ACCESS_GROUP_PERSON + `/${rowData.row.id}`, { state: { data: rowData.row, pageNumber: page, pageSize: rowsPerPage }, });
    };

    const gotoAccessGroupDoorPage = (rowData) => {
        navigate(REACT_URL_ACCESS_GROUP_DOOR + `/${rowData.row.id}`, { state: { data: rowData.row, pageNumber: page, pageSize: rowsPerPage } });
    };

    const openEditPage = (rowData) => {
        navigate(REACT_EDIT_GROUP_ACCESS_TIME + `/${rowData.row.id}`, { state: { data: rowData.row, pageNumber: page, pageSize: rowsPerPage } });
    };

    const formateDate = (date) => {
        let dt = ''; let tm = '';
        dt = date.split('T')[0];
        let year, month, day, hh, mm;
        year = dt.split("-")[0];
        month = dt.split("-")[1];
        day = dt.split("-")[2];

        tm = date.split('T')[1];
        tm = tm.replace("Z", "");
        hh = tm.split(":")[0];
        mm = tm.split(":")[1];
        return hh + ":" + mm + " " + day + "-" + month + "-" + year;
    }

    const columns = [
        {
            field: 'name',
            hideable: false,
            headerName: t('COMMON006'),
            flex: 1,
            valueGetter: (params) => params.row.name,

        },
        {
            field: 'schedulerName',
            headerName: t('AccTime013'),
            flex: 1,
            valueGetter: (params) => params.row.schedulerName
        },

        {
            field: 'startTime',
            headerName: t('AccGroupAccessTime004'),
            flex: 1,
            valueGetter: (params) => (params.row.startTime ? formateDate(params.row.startTime) : '-')
        },
        {
            field: 'endTime',
            headerName: t('AccGroupAccessTime005'),
            flex: 1,
            valueGetter: (params) => (params.row.endTime ? formateDate(params.row.endTime) : '-')
        },
        {
            field: 'actions',
            headerClassName: 'no-sort-filter',
            headerName: t('COMMON019'),
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            flex: 1,
            renderCell: (rowData) => [
                <GridActionsCellItem
                    sx={{ marginRight: { xl: "20px", xs: "10px" } }}
                    icon={manageDoor ? (<Tooltip title={t("AccGroupAccessTime006")}>
                        <ManageDoor id="icon-groupaccesstime-managedoor" style={{ cursor: 'pointer' }} onClick={() => gotoAccessGroupDoorPage(rowData)} />
                    </Tooltip>) : <></>}
                />,
                <GridActionsCellItem
                    sx={{ marginRight: { xl: "20px", xs: "10px" } }}
                    icon={managePerson ? (<Tooltip title={t("AccGroupAccessTime007")}>
                        <ManagePerson id="icon-groupaccesstime-manageperson" style={{ cursor: 'pointer' }}
                            onClick={() => gotoAccessGroupPersonPage(rowData)} />
                    </Tooltip>) : <></>}
                />,
                <GridActionsCellItem
                    sx={{ marginRight: { xl: "20px", xs: "10px" } }}
                    icon={addAccessGroup ? (<Tooltip title={t('AccGroupAccessTime008')}>
                        <Edit id="icon-groupaccesstime-edit" style={{ cursor: 'pointer' }}
                            onClick={() => openEditPage(rowData)}
                            disabled={rowData.operationName === '24-Hours General'}
                        />
                    </Tooltip>) : <></>}
                />,
                < GridActionsCellItem
                    icon={deleteAccessGroup ? (<Tooltip title={t('AccGroupAccessTime009')}>
                        < Delete id="icon-groupaccesstime-delete" style={{ cursor: 'pointer' }}
                            onClick={() => handleDelete([rowData])}
                            color={rowData.operationName === '24-Hours General' ? "disable" : 'secondary'} />
                    </Tooltip>) : <></>}
                    disable={rowData.operationName === '24-Hours General'}
                />,
            ],
        },
    ];

    // This for Delete function
    const handleDelete = (data) => {
        setOpenDelete(!openDelete);
        setDelId(data);
    }

    const handleRefresh = () => {
        setClearAndRefresh(true);
        if (page === 1) {
            // setFilterData(defaultFilter);
            // setDisabled(true)
            // setFilter(false);
            setLoad(true);
            loadAccessGroup(1, manageGATPageSize, filterData);
            setRowsPerPage(manageGATPageSize);
        }
        else {
            // setFilterData(defaultFilter);
            // setDisabled(true)
            // setFilter(false);
            setLoad(true);
            setPage(filter ? 1 : manageGATPageNumber);
            setRowsPerPage(manageGATPageSize);
            loadAccessGroup(filter ? 1 : manageGATPageNumber, manageGATPageSize, filterData);
        }
    };

    useEffect(() => {
        if (del === true && parseInt(pageSize) !== 1) {
            loadAccessGroup(page, rowsPerPage, defaultFilter);
            SetDel(false);
        } else {
            if (del === true) {
                loadAccessGroup(page - 1, rowsPerPage, defaultFilter);
                SetDel(false);
            }
        }
    }, [del]);

    useEffect(() => {
        if (CommonUtil.isEmpty(eventDate.startTime) &&
            CommonUtil.isEmpty(eventDate.endTime)) {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadAccessGroup(1, rowsPerPage, defaultFilter);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [eventDate]);

    const searchQueue = useRef([]);
    const [searchValue, setSearchValue] = useState('');

    const debounceTimer = useRef(null);
    const globalsearch = (searchedVal) => {
        var value = searchedVal.target.value;
        setSearchValue(value);
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        if (value === '') {
            setFilterData(prevFilterData => ({
                ...prevFilterData,
                name: value
            }));
            setFilter(false);
            var filter = {
                ...filterData,
                name: ''
            }
            loadAccessGroup(1, manageGATPageSize, filter);
            return;
        }

        if (value !== '' && value !== '-' && value !== '_' && value !== null) {
            searchQueue.current.push(value);
            debounceTimer.current = setTimeout(() => {
                searchQueue.current = value;
                processQueue();
            }, 300);
        } else if (searchedVal === '-' || searchedVal === '_') {
            setAccessGroup([]);
        }
        // else {
        //   loadData(1, rowsPerPage, data);
        // }
    };

    const processQueue = () => {
        if (searchQueue.current) {
            const searchValue = searchQueue.current;
            searchQueue.current = '';
            var filter = {
                ...filterData,
                name: searchValue
            }
            setFilterData({
                ...filterData,
                name: searchValue
            });
            setFilter(true);
            setPage(1);
            loadAccessGroup(1, rowsPerPage, filter);
        }
    };


    const filterField = () => (
        <SearchBar style={{ cursor: 'pointer' }}
            placeholder={t('AccGroupAccessTime015')}
            searchValue={searchValue}
            clearValue={() => {
                setPage(1);
                setSearchValue("");
                setFilterData({...filterData,name:""})
                loadAccessGroup(page, rowsPerPage, { ...filterData, name: "" });
            }}
            handleSearchChange={(e) => globalsearch(e)}
            width={{ xl: "280px", xs: "250px" }}
        />
    );

    const clearFilter = () => {
        setFilterData({ ...defaultFilter, name: searchValue });
        setEventDate({
            startTime: '',
            endTime: ''
        });
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadAccessGroup(1, rowsPerPage, { ...defaultFilter, name: searchValue });
        }
        else setPage(1);
    }

    const handleSubmitFilter = () => {
        setFilter(true);
        setLoad(false);
        setReload(true);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitFilter();
        }
    };
    useEffect(() => {
        if (!open && !disabled) {
            setPage(1);
            setEventDate({
                startTime: '',
                endTime: ''
            });
            setChange(false);
            setFilterData(defaultFilter);
            loadAccessGroup(1, manageGATPageSize, defaultFilter);
        }
    }, [open])

    const handlePageChange = (e) => {
        if (filter) {
            setPage(e);
            setManageGATPageNumber(e);
            loadAccessGroup(e, rowsPerPage, { ...filterData, name: searchValue });
        }
        else {
            setFilter(false);
            setLoad(true);
            setPage(e);
            setManageGATPageNumber(e);
        }
    }

    const handlePageSize = (e) => {
        if (filter) {
            setRowsPerPage(e);
            setManageGATPageSize(e);
            setPage(1);
            loadAccessGroup(1, e, { ...filterData, name: searchValue });
        }
        else {
            setFilter(false);
            setLoad(true);
            setRowsPerPage(e);
            setManageGATPageSize(e);
            setPage(1);
        }
    }
    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleSubmitDelete = () => {
        if (delId.length < 1) {
            enqueueSnackbar(t('AccGroupAccessTime014'), { variant: 'warning' })
        }
        var ids = '';
        var name = delId[0].row.name;
        delId.map((record, index) => {
            if (index === delId.length - 1) ids = ids + record.id;
            else ids = ids + record.id + ',';
        });

        deleteAcccessGroupById(ids, name).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                enqueueSnackbar(t('AccGroupAccessTime012'), { variant: 'success' });
                SetDel(true);
            }
            if (res?.data?.code === 'DMSE00543') {
                enqueueSnackbar(t('AccGroupAccessTime011'), { variant: 'error' });
            }
            if (res?.data?.code === 'DMSE00542') {
                enqueueSnackbar(t('AccGroupAccessTime010'), { variant: 'error' })
            }
            setOpenDelete(false);
        })
    };

    const fieldStartTime = () => (
        <>
            <DatePicker
                showTime
                placeholder={t('AccGroupAccessTime024')}
                value={eventDate.startTime}
                label={t('AccGroupAccessTime004')}
                onChange={(startTime) => {
                    setFilterData({
                        ...filterData,
                        startTime: startTime?.format("YYYY-MM-DDTHH:mm:ssZ"),
                    });
                    setEventDate({
                        ...eventDate,
                        startTime: startTime,
                    });
                    setChange(true);
                }}
                style={{
                    width: '100%',
                    height: screenWidth ? "54px" : "40px",
                    marginTop: "0px",
                    cursor: 'pointer'
                }}
            />
        </>
    );

    const fieldEndDate = () => (
        <>
            <DatePicker
                showTime
                placeholder={t('AccGroupAccessTime025')}
                value={eventDate.endTime}
                label={t('AccGroupAccessTime005')}
                onChange={(endTime) => {
                    setFilterData({
                        ...filterData,
                        endTime: endTime?.format("YYYY-MM-DDTHH:mm:ssZ"),
                    });
                    setEventDate({
                        ...eventDate,
                        endTime: endTime,
                    });
                }}
                disabledDate={disabledEndDate}
                style={{
                    width: '100%',
                    marginTop: "0px",
                    height: screenWidth ? "54px" : "40px",
                    cursor: 'pointer'
                }}
            />
        </>
    )

    const disabledEndDate = (current) => {
        // Can not select days before today and today
        return current < (eventDate.startTime);
    };

    return (
        <>
            {manageAccess ? (<>

                <Box
                    id="title-bar"
                    sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        height: { xl: "50px", xs: "40px" }
                    }}
                >
                    <Box item flexGrow={1} sx={{ display: { xl: "block", xs: "none" } }} id={"module-title"}>
                        <Typography variant={"moduleHeaderDesktop"}>{t("AccGroupAccessTime001")}</Typography>
                    </Box>
                    <Box item flexGrow={1} sx={{ display: { xl: "none", xs: "block" } }} id={"module-title"}>
                        <Typography variant={"moduleHeaderLaptop"}>{t("AccGroupAccessTime001")}</Typography>
                    </Box>

                    <Box item display={"flex"} >
                        <Box mr={2}>
                            {filterField()}
                        </Box>
                        <Tooltip title={t('ACCORG008')}>
                            <Box
                                aria-label="refresh"
                                onClick={() => handleRefresh()}
                                mr={2}
                                sx={{ display: { xl: "block", xs: "none" } }}
                                id={"refresh-Icon"}
                            >
                                <Refresh style={{ cursor: 'pointer' }} />
                            </Box>
                            <Box
                                aria-label="refresh"
                                onClick={() => handleRefresh()}
                                mr={2}
                                sx={{ display: { xl: "none", xs: "block" } }}
                                id={"refresh-Icon"}
                            >
                                <Refresh width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                            </Box>
                        </Tooltip>
                        <Tooltip title={t('COMMON003')}>
                            <Box
                                id="icon-groupaccesstime-filter"
                                aria-label="filter"
                                mr={2}
                                onClick={() => setOpen(!open)}
                                sx={{ display: { xl: "block", xs: "none" } }}
                            >
                                <Filter style={{ cursor: 'pointer' }} />
                            </Box>
                            <Box
                                id="icon-groupaccesstime-filter"
                                aria-label="refresh"
                                onClick={() => setOpen(!open)}
                                mr={2}
                                sx={{ display: { xl: "none", xs: "block" } }}
                            >
                                <Filter width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                            </Box>
                        </Tooltip>

                        {addAccessGroup && <Tooltip title={t('AccGroupAccessTime016')}>
                            <Box
                                id="icon-groupaccesstime-add"
                                aria-label="add"
                                onClick={openAccessGroupLevelDialog}
                                sx={{ display: { xl: "block", xs: "none" } }}
                            >
                                <Add style={{ cursor: 'pointer' }} />
                            </Box>
                            <Box
                                id="icon-groupaccesstime-add"
                                aria-label="add"
                                onClick={openAccessGroupLevelDialog}
                                sx={{ display: { xl: "none", xs: "block" } }}
                            >
                                <Add width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                            </Box>
                        </Tooltip>}
                    </Box>


                </Box>

                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open && <FilterComponent
                        firstField={fieldStartTime()}
                        secondField={fieldEndDate()}
                        clearFilter={clearFilter}
                        handleSubmit={handleSubmitFilter}
                        disabled={disabled}
                    />}
                    <DataTable
                        rows={accessGroup}
                        columns={columns}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        totalRecords={totalRecords}
                        page={page - 1}
                        getRowId={(row) => row.id}
                        checkboxSelection={true}
                        rowsPerPage={rowsPerPage}
                        onPageChange={(e) => handlePageChange(e + 1)}
                        onPageSizeChange={(e) => {
                            handlePageSize(e);
                        }}
                        noRecordText={t("AccGroupAccessTime002")}
                    />
                </Box>
            </>) : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={"center"}>{t("AccGroupAccessTime003")}</Typography></Box>)}
            <CustomDialogBox
                id="delete-access-group-time"
                open={openDelete}
                customWidth={'420px'}
                title={t("COMMON013")}
                message={t("AccGroupAccessTime013")}
                saveButtonName={t("COMMON008")}
                cancelButtonName={t("COMMON009")}
                onClose={handleCloseDelete}
                handleSubmit={handleSubmitDelete}
            />
        </>
    );
}
