import api from "../configurations/http-common";

export const loadDeviceAuxiliaryInput = async (page, size, filter) => {
    let query = 'pageNumber=' + page + '&pageSize=' + size + buildQuery(filter);
   return  api.securedAxios().get('/web/auxiliary_inputs?' + query);

   function buildQuery(filter) {
      let query = ''

      if (filter.doorName != '' && filter.doorName) {
          query = query + `&doorName= ${filter.doorName}`.replace(" ", "");

      }
      if (filter.deviceAlias != '' && filter.deviceAlias!=undefined) {
          query = query + `&deviceAlias= ${encodeURIComponent(filter.deviceAlias)}`.replace(" ", "");
      }

      if (filter.name != ''  && filter.name!=undefined) {
          query = query + `&name= ${filter.name}`.replace(" ", "");
      }
      return query

  }
}

export const loadDeviceAuxiliaryInputById = async (id) => {
   return  api.securedAxios().get('/web/auxiliary-in/' + id);
}

export const updateAuxIn = async ( payload) => {
   return api
   .securedAxios()
   .put('/web/auxiliary-in' ,  payload);
 };