import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import GoogleMapDialog from './GoogleMapDialog';



export default function ResponsiveDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <Dialog maxWidth='xl' fullWidth='xl' fullScreen={fullScreen} open={props.open} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
            <DialogContent>
                <GoogleMapDialog toggleFullScreen={() => handleClose()} />
            </DialogContent>

        </Dialog>
    )
}

