import React, { useEffect } from 'react'
import { ReactComponent as ExportIcon } from '../../assets/ExportIcon.svg';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Box, Menu, MenuItem, Tooltip } from '@mui/material';
import CommonUtil from '../../util/CommonUtils'
import SimpleDailogBox from '../../components/SimpleDailogBox';
import EventExportModal from './EventExportModal';
import { useNavigate } from "react-router-dom";
import { REACT_URL_GENERATED_REPORTS } from '../../constants/ReactEndPoints';
import { useTranslation } from "react-i18next";

export default function EventExport(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const [openExport, setOpenExport] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenExport(false);
    setIsSelected(false)
  };
  const onSubmitExportAll = () => {
    // if(!props.filtered){
    //   enqueueSnackbar(t('ACCEVENT011'), {
    //     variant: "error",
    //   });
    // } else if(props.startDateTime =='' || props.startDateTime == undefined  || props.endDateTime =='' || props.endDateTime == undefined) {
    //     enqueueSnackbar(t('ACCEVENT012'), {
    //         variant: "error",
    //       });
    // } else if (!isDifferenceLessThanThreeMonths(props.startDateTime,props.endDateTime)){
    //   enqueueSnackbar(t('ACCEVENT013'), {
    //     variant: "error",
    //   });
    // } else
    if (props.totalCount > 10000) {
      enqueueSnackbar(t('ACCEVENT014'), {
        variant: "error",
      });
    } else {
      setOpenExport(true);
    }
  };

  const onSubmitSelected = () => {
    if (props.totalCount > 10000) {
      enqueueSnackbar(t('ACCEVENT014'), {
        variant: "error",
      });
    } else {
      setOpenExport(true);
      setIsSelected(true)
    }
  };

  const isDifferenceLessThanThreeMonths = (start, end) => {

    var s = new Date(start);

    var e = new Date(end);
    console.log(s);
    console.log(e);
    // Calculate the difference in milliseconds between the two dates
    const differenceInMs = Math.abs(s - e);

    // Calculate the number of months between the two dates
    const monthsDifference = differenceInMs / (1000 * 60 * 60 * 24 * 30.4375);
    console.log(monthsDifference);
    // Check if the difference is less than or equal to 3 months
    return monthsDifference <= 3;
  };


  const onSubmitGenrated = () => {
    navigate(REACT_URL_GENERATED_REPORTS)
  };

  // alert(props.endDateTime)

  return (
    <>
      <Box
        aria-label='export'
        id="basic-button"
        sx={{ display: { xl: 'block', xs: 'none', cursor: 'pointer' } }}
        onClick={handleClick}
      >
        <ExportIcon
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        />
      </Box>

      <Box
        id="basic-button"
        onClick={handleClick}
        sx={{ display: { xl: 'none', xs: 'block', cursor: 'pointer' } }}
      >
        <ExportIcon
          width={40}
          height={40}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",

        }}
      >
        <MenuItem disabled={props.totalCount === 0} className="menu-item-popup" onClick={onSubmitExportAll}
          sx={{
            lineHeight: '21px!important', "&:hover": {
              backgroundColor: "#F4FCF7 !important",
              color: ' #76B745 !important'
            }
          }}
        >
          {t('ACCEVENT037')}
        </MenuItem>
        <MenuItem disabled={props.ids.length < 1 === true} className="menu-item-popup" onClick={onSubmitSelected}
          sx={{
            lineHeight: '21px!important', "&:hover": {
              backgroundColor: "#F4FCF7 !important",
              color: ' #76B745 !important'
            }
          }}
        >
          {t('ACCEVENT039')}
        </MenuItem>
        <MenuItem className="menu-item-popup" onClick={onSubmitGenrated}
          sx={{
            lineHeight: '21px!important', "&:hover": {
              backgroundColor: "#F4FCF7 !important",
              color: ' #76B745 !important'
            }
          }}
        >

          {t('ACCEVENT038')}
        </MenuItem>
      </Menu>

      <SimpleDailogBox
        open={openExport}
        onClose={handleClose}
        width={'500px'}
        title={t('ACCEVENT004')}>
        <EventExportModal
          filterData={props.filterData}
          handleClose={handleClose}
          selectedFields={"personId" + "," + props.selectedFields}
          isSelected={isSelected}
        />
      </SimpleDailogBox>

    </>
  )
}