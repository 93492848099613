import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem, GridFilterAltIcon } from '@mui/x-data-grid';
import OverflowTip from '../../components/tooltip';
import { useNavigate } from 'react-router-dom';
import RightViewLayout from '../../components/RighViewLayout';
import React, { useEffect, useState } from "react";
import CommonUtil from '../../util/CommonUtils';
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import { roleList } from '../../services/UserRolesService';
import { ReactComponent as Edit } from "../../assets/new-images/Edit Icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/ViewIcon.svg";
import { ReactComponent as LeftArrowIcon } from "../../assets/LeftArrow.svg";
import { REACT_URL_UPDATEROLES, REACT_URL_VIEWEROLES } from '../../constants/ReactEndPoints';
import { getPermissionsByRoleId, getRoleByUsers } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from 'react-i18next';
import IconHandaler from '../../components/IconHandaler';
import { NoResultFound } from '../../components/NoResultFound';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import Loader from '../../components/Loader';
import NoRecordFound from '../../components/NoRecordFound';
import SearchBar from '../../components/SearchBar';
const customDataGridStyle = {
  '& .MuiDataGrid-cell': {
    borderBottom: 'none !important',
  },
};
function Roles() {
  const { t } = useTranslation()
  let navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [searchRoles, setSearchRoles] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [listPermission, setListPermission] = useState(true);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [member, setmembers] = useState([]);
  const [expand, setExpanded] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [loder1, setLoader1] = React.useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const loadData = () => {
    const payload = {
      name: "",
      code: "",
    }
    roleList(payload).then((res) => {
      if (res?.data?.code === 'UASI0000') {
        if(searchValue!==""){
          __search_role_(searchValue, res.data?.data?.roles)
        }else{
          setRoles(res.data?.data?.roles);
          setSearchRoles(res.data.data.roles);
          setTotalRecords(res.data.data.totalCount);
        }
      }
    });
  }

  useEffect(() => {
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      var l = [];
      res.data.data.rolePermissions.map((item, index) => {
        l.push(item.code);
      });
      setListPermission(l.includes("ACC_VIEW_PERMISSION_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
      setViewPermission(l.includes("ACC_VIEW_PERMISSIONS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
      setEditPermission(l.includes("EDIT_USERROLE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
      if (l.includes("ACC_VIEW_PERMISSION_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
        loadData()
      }
    });
  }, []);

  const handleRowExpand = (row) => {
    if (row?.id) {
      const rowIndex = expandedRows.indexOf(row.id);
      setExpandedRows([]);
      setmembers([]);
      if (rowIndex === -1) {
        setExpandedRows([row.id]);
        console.info(row.id);
        getRoleByUsers(row.id)
          .then((res) => {
            if (res.data.code === 'UASI0000') {
              const uniqueUsers = res.data.data.users.reduce((acc, user) => {
                if (!acc.some((existingUser) => existingUser.id === user.id)) {
                  acc.push(user);
                }
                return acc;
              }, []);
              setmembers(uniqueUsers);
            }
          })
          .catch((error) => {
            console.error("Error fetching users:", error);
          });
      } else {
        setmembers([]);
      }
      if (expand === row.id) {
        setExpanded("id");
      } else {
        setExpanded(row.id);
      }
    } else {
      console.error("row id undefined or null");
    }
  };

  const isExpaded = (id) => {
    return expand === id ? true : false;
  };

  // This for search Role
  const superAdminList = ["super ", "super a", "super ad", "super adm", "super admi", "super admin"]

  const __search_role_=(searchValue,_role_list)=>{
    const filteredRoles = _role_list?.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchValue.toLowerCase())
          : "") ||
        (test.code
          ? test.code.toLowerCase().includes(searchValue.toLowerCase())
          : "")
      );
    });
    if (superAdminList?.includes(searchValue?.toLowerCase())) {
      const values = searchValue?.split(" ");
      searchValue = values[0] + values[1];
      const superAdminV = _role_list?.filter((test) => {
        return (
          (test.name
            ? test.name.toLowerCase().includes(searchValue.toLowerCase())
            : "") ||
          (test.code
            ? test.code.toLowerCase().includes(searchValue.toLowerCase())
            : "")
        );
      });
      filteredRoles.push(superAdminV[0]);
    }
    setRoles(filteredRoles);
  }
  
  
  const handleSearch = (event) => {
    let value = event.target.value;
    setSearchValue(value);
    __search_role_(value,searchRoles);
  };

  const clearSearch = () => {
    setSearchValue("");
    __search_role_("", searchRoles);
  };

  const actions = () => (
    <>
      <Box item display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }} >
        <Box
          aria-label="Search-icon"
          mr={2}
          id={"Search-icon"}
        >
          <SearchBar style={{ cursor: 'pointer' }}
            placeholder={t('COMMON051')}
            searchValue={searchValue}
            clearValue={clearSearch}
            handleSearchChange={handleSearch}
            width={{xl:"300px",xs:"280px"}}
          />
        </Box>
        <Tooltip title={t('ACCORG008')}>
          <Box
            aria-label="refresh"
            onClick={() => refreshTableData()}
            sx={{ display: { xl: "block", xs: "none" } }}
            id={"refresh-Icon"}
          >
            <RefreshIcon style={{ cursor: 'pointer' }} />
          </Box>
          <Box
            aria-label="refresh"
            onClick={() => refreshTableData()}
            sx={{ display: { xl: "none", xs: "block" } }}
            id={"refresh-Icon"}
          >
            <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
          </Box>
        </Tooltip>
      </Box>
    </>
  );

  const refreshTableData = () => {
    loadData();
  };

  const CustomActionsCellItem = ({ icon, onClick }) => (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      {icon}
    </div>
  );

  const roleTable = () =>
    roles.map((e) => {
      return (
        <Grid item xs={12}>
          <Accordion
            expanded={isExpaded(e.id)}
            sx={{
              '&.MuiAccordion-root': {
                boxShadow: "0px 0px 0px 0px",
                // borderTop: "0.5px solid #EAEBEB",
              }
            }}
          >
            <AccordionSummary
              sx={{
                '&.MuiAccordionSummary-root': {
                  maxHeight: '48px !important',
                  minHeight: '48px !important',
                },
                flexDirection: 'row-reverse',
                // borderBottom: "0.5px solid #EAEBEB"
              }}
              expandIcon={
                <Box
                  style={{
                    transform: "rotate(270deg)",
                  }}
                >
                  <LeftArrowIcon
                    height={"10px"}
                    width={"14px"}
                  />
                </Box>
              }
              onClick={() => handleRowExpand(e)}
            >
              <Box sx={{ width: "100%", display: 'flex', justifyContent: "start", marginLeft: 5, borderBottom: 'none' }}>
                <Box sx={{ flex: "1.5", display: 'flex', alignItems: 'center', }} onClick={() => handleRowExpand(e)}  >
                  <OverflowTip value={e.name === "SuperAdmin" ? "Super Admin" : e.name} onClick={() => console.info()} />
                </Box >
                <Box sx={{ flex: "2", display: 'flex', alignItems: 'center', }} onClick={() => handleRowExpand(e)} >
                  <OverflowTip value={e.code === "SUPERADMIN" ? "SUPER ADMIN" : e.code} onClick={() => console.info()} />
                </Box > <Box sx={{ flex: "0.78", marginLeft: '128px', display: 'flex', alignItems: 'center', ...customDataGridStyle }}>

                  <>
                    < CustomActionsCellItem
                      icon={viewPermission ? <Tooltip title={t('COMMON004')}>
                        <ViewIcon id={"icon-userrole-list-view"} width={'100%'} style={{ cursor: 'pointer', marginTop: "0.5rem" }}
                          onClick={() => navigate(REACT_URL_VIEWEROLES + `/${e.code}`, { state: { data: e.id, name: e.name, code: e.code, ...e.row } })}
                        /></Tooltip> : <Box></Box>}
                    />
                    {e.code !== 'ORGANIZATION OWNER' && e.code !== 'SUPERADMIN' ? (
                      <CustomActionsCellItem
                        icon={editPermission ? (
                          <Tooltip title={t('COMMON005')}>
                            <Edit
                              id={"icon-userrole-list-edit"}
                              // width={'60px'}
                              style={{ cursor: 'pointer', marginLeft: "15px" }}
                              onClick={() =>
                                navigate(REACT_URL_UPDATEROLES + `/${e.code}`, {
                                  state: { data: e.id, name: e.name, code: e.code, ...e.row }
                                })
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Box></Box>
                        )}
                      />
                    ) : null}
                  </>
                </Box >
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box height={
                expandedRows?.length > 0
                  ? member?.length === 1
                    ? "15%"
                    : member?.length === 2
                      ? "30%"
                      : "30%"
                  : "30%"
              }
                width={member?.length > 3 ? "100%" : "100%"}
                // maxHeight={200}
                overflow={member?.length === 1 ? 'hidden' : 'auto'}
                sx={{
                  marginBottom: member?.length === 1 ? '-15px' : member?.length === 2 ? '-15px' : '-20px',
                  '&.MuiAccordionDetails-root': {
                    marginBottom: '-14px',
                  },
                  '.MuiTableCell-root': {
                    borderBottom: 'none !important',
                  },
                }} borderBottom={'none !important'}>
                {expandedRows?.length > 0 && (
                  <div style={{ width: '96%' }}>
                    {viewPermission ?
                      (
                        <Grid item xs={12}>
                          <Table>
                            <Box
                              sx={{
                                height: "48px",
                                backgroundColor: '#F5F6F6 !important',
                                color: '#304669 !important',
                                display: 'flex',
                                justifyContent: "space-between",
                                width: '100%',
                                alignItems: "center",
                                fontSize: '16px',
                                marginLeft: "25px",
                                borderBlockStartWidth: 'none'
                              }}>
                              <Box sx={{ flex: "1.5", marginLeft: "50px" }} id="personName" >
                                <Typography variant='fieldValue'>{t('ACCORG002')}</Typography>
                              </Box >
                              <Box sx={{ flex: "1.5" }} id="email">
                                <Typography variant='fieldValue'>{t('ACCORG004')}</Typography>
                              </Box >
                              <Box sx={{ flex: "1" }} id="mobile">
                                <Typography variant='fieldValue'>{t('ACCORG005')}</Typography>
                              </Box >
                              <Box sx={{ flex: "0.72" }} id="status">
                                <Typography variant='fieldValue'>{t('AccDevice051')}</Typography>
                              </Box >
                              {/* <Box sx={{ flex: "0.30" }} id="action">
                              <Typography variant='fieldValue'>{t('ZLINK00372')}</Typography>
                            </Box > */}
                            </Box>
                            {/* <TableHead >
                              <TableRow borderBottom={'none !important'} >
                                <TableCell style={{ padding: '2px 0px 2px 50px', fontSize: '0.8rem', textAlign: 'left', margin: '5px' }}>
                                  <span >{t('ACCORG002')}</span>
                                </TableCell>
                                <TableCell style={{ padding: '2px 10px 2px 15px', fontSize: '0.8rem', textAlign: 'left', margin: '5px' }}>
                                  <span >{t('ACCORG004')}</span>
                                </TableCell>
                                <TableCell style={{ padding: '2px 10px 2px 0px', fontSize: '0.8rem', textAlign: 'left', margin: '5px' }}>
                                  <span >{t('ACCORG005')}</span>
                                </TableCell>
                                <TableCell style={{ padding: '2px 10px 2px 15px',maxWidth:'100px', fontSize: '0.8rem', textAlign: 'left', margin: '5px' }}>
                                  <span >{t('Status')}</span>
                                </TableCell>
                              </TableRow>
                            </TableHead> */}
                            <TableBody>
                              {!CommonUtil.isEmpty(member) ? member.map((member) => (
                                <TableRow key={member.id} >
                                  <Box sx={{ width: "100%", display: 'flex', justifyContent: "start", fontSize: '16px', height: "48px", marginLeft: "25px", alignItems: "center" }}>
                                    <Tooltip title={member.firstName + " " + member.lastName}>
                                      <Box sx={{ flex: "1.15", marginLeft: "50px", fontSize: '16px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                                        <OverflowTip value={member.firstName + " " + member.lastName ? (member.firstName + " " + member.lastName?.length > 30 ? `${member.firstName + " " + member.lastName.substring(0, 30)}...` : member.firstName + " " + member.lastName) : ''} onClick={() => console.info()} />
                                      </Box >
                                    </Tooltip>
                                    <Tooltip title={member.email}>
                                      <Box sx={{ flex: "0.99", fontSize: '16px' }}>
                                        <OverflowTip value={member.email ? (member.email?.length > 20 ? `${member.email.substring(0, 20)}...` : member.email) : ''} onClick={() => console.info()} />
                                      </Box>
                                    </Tooltip>
                                    <Tooltip title={member.phone}>
                                      <Box sx={{ flex: "0.85", fontSize: '16px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                                        <OverflowTip value={member.phone} onClick={() => console.info()} />
                                      </Box >
                                    </Tooltip>
                                    <Tooltip title={member.verified === true ? 'Active' : 'Pending'}>
                                      <Box sx={{
                                        flex: "0.65",
                                        fontSize: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        opacity: '0.8',
                                      }}>
                                        <span
                                          onClick={() => console.info()}
                                          style={{
                                            color: member.verified === true ? '#76B745' : '#EDBD37',
                                            backgroundColor: member.verified === true ? ' #76B74526' : '#EDBD3726',
                                            borderRadius: '4px',
                                            padding: `${member.verified === true ? ' 8px 36px 8px 36px' : '8px 30px'}`,
                                            display: 'inline-block',
                                          }}
                                        >
                                          {member.verified === true ? 'Active' : 'Pending'}
                                        </span>
                                      </Box>
                                    </Tooltip>
                                  </Box >
                                </TableRow>
                              )) : <Box padding={' 10px 0px 10px 4px'}>
                                {<NoResultFound message={'No Records'} />}
                              </Box>}
                            </TableBody>

                          </Table>
                        </Grid>
                      )
                      : (
                        <div>
                          <Typography textAlign={'center'}>{t('ACCRolPerm005')}</Typography>
                        </div>
                      )}
                  </div>
                )}

              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });

  const setLoaderFunction = () => {
    let loader = localStorage.getItem('isLoading')
    setLoader(loader);
  }
  React.useEffect(() => {
    window.addEventListener('storage', setLoaderFunction);
    return () => {
      window.removeEventListener('storage', setLoaderFunction);
    };
  }, []);
  return (
    <>
      <RightViewLayout title={t("ACCRolPerm001")} type='table' navigateBack={true} >
        {actions()}
      </RightViewLayout>
      <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
        {listPermission ? (<>
          <Box
            sx={{
              height: "48px",
              backgroundColor: '#F5F6F6!important',
              color: '#474B4F!important',
              display: 'flex',
              justifyContent: "start",
              alignItems: "center",
              overflowY: 'hidden',
              borderBottom: 'none',
              width: '100%'
            }}>
            {loader ? <Loader open={loader} /> : <></>}
            <Box sx={{ flex: "1.5", marginLeft: '64px', display: 'flex', alignItems: 'center' }} id="roleName">
              <Typography variant='fieldValue'>{t('ACCRolPerm002')}</Typography>
            </Box>
            <Box sx={{ flex: "2", display: 'flex', alignItems: 'center' }} id="roleCode">
              <Typography variant='fieldValue'>{t('ACCRolPerm003')}</Typography>
            </Box>
            <Box sx={{ flex: "0.85", marginLeft: '105px', display: 'flex', alignItems: 'center' }} id="roleAction">
              <Typography variant='fieldValue'>{t('COMMON019')}</Typography>
            </Box>
          </Box>
          {roles !== null && roles?.length > 0 ? roleTable() :
            <Box marginTop={15}>
              {!loder1 && <NoRecordFound text={"No Role Found"} />}
            </Box>}
        </>) : (<Box marginTop={'150px'} width={'100%'}> <Typography textAlign={'center'}>{t('ACCRolPerm005')}</Typography></Box>)}
      </Box>
    </>
  );
}
export default Roles;
