import { Avatar, Box, Grid, Typography } from '@mui/material';
import React from "react";
import src from '../../assets/Pic.svg';
import CustomInput from '../../components/ViewText';
import RightViewLayout from '../../components/RighViewLayout';
import { useEffect, useState } from "react";
import { loadDeviceAuxiliaryInputById } from "../../services/AuxiliaryInputService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import CommonUtil from '../../util/CommonUtils';
import ViewPage from '../../components/ViewPage';
import { REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import Loader from '../../components/Loader';

const ViewAuxiliaryInput = (props) => {
    const { t } = useTranslation()
    const location = useLocation();
    const [aux, setAux] = useState([]);
    const [perViewAuxIN, setViewAuxIn] = useState(false);
    const navigate = useNavigate();
    const [permissions,setPermissions]= useState([]);
    const[loader,setLoader]=React.useState(true);
    const params = useParams();
    const deviceId = params.deviceId;


    React.useEffect(() => {

        if (CommonUtil.isEmpty(deviceId)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }
      
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if(res.data.code==="CACCZE001"){
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev=>[...prev,item.code])
                });
            }
            setViewAuxIn(true);
        });
    }, []);

    useEffect(()=>{
     if(checkPermission("ACC_AUXILIARY_VIEW")){
      setLoader(true);
        loadDeviceAuxiliaryInputById(deviceId).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setAux(res.data.data);
            }
            setLoader(false);
        });
     }
    },[permissions])

    const checkPermission = (permissionCode) => {  
        if(api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
          return true;
        else{
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
      }


    return (
        <>
           {perViewAuxIN ?checkPermission("ACC_AUXILIARY_VIEW") ? <ViewPage title={t('AccAuxIn005')} path={true}
             onClick={()=>navigate(
                '/auxiliaryinput',
                { state: {pageNumber:location?.state?.pageNumber,pageSize:location?.state?.pageSize}})}>
                    {loader?<Loader open={loader}/>:<></>}

                     <Grid direction='row' alignItems={'flex-start'} id={"auxin-view-grid"}  >
                        {CustomInput({ label: t('AccAuxIn007'), value: aux.name,index:1 })}
                        {CustomInput({ label: t("AccReader012"), value: aux.deviceName,index:2 })}
                        {CustomInput({ label: "SN:", value: aux.deviceSn,index:3 })}
                        {CustomInput({ label: t("AccAuxIn008"), value: aux.aux_no,index:4 })}
                        {CustomInput({ label: t("AccAuxIn009"), value: aux.printer_number,index:5 })}
                        {CustomInput({ label: t("COMMON024"), value: aux.remark,index:6 })}
                    </Grid>
            </ViewPage>
            :(<Box marginTop={'150px'} width={'100%'} id={"permission-box-auxin"}>
                <Typography textAlign={'center'} id={"permission-text-auxin"}>{t("AccAuxIn016")}</Typography>
             </Box>):<></>}

        </>
    )
};

export default ViewAuxiliaryInput;