import React from 'react'
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { ReactComponent as NoRecordFoundIcon } from '../assets/NoRecordFound.svg';

const NoRecordFound = (props) => {

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
          fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
          fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
          fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
          fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
          fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
          fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
      }));
  return (
    <StyledGridOverlay>
      <NoRecordFoundIcon width={props.width?props.width:"47.324px"} height={props.height?props.height:"64px"}/>
      <Box sx={{ mt: props.width ?0:1, 
        font:"normal normal normal 16px/19px Roboto",
        letterSpacing:"0.32px",
        color: 'rgba(71, 75, 79, 0.8)',
        whiteSpace: 'pre-line',
        textAlign:'center',
        
        
        }}>{props.text}</Box>
    </StyledGridOverlay>
  )
}

export default NoRecordFound
