import { Grid } from '@mui/material';
import { ReactComponent as LoginImage } from "../assets/LoginImage.svg";

function LoginLayoutZlink() {
    return (
        <Grid className='signIn-left'>
            <Grid item sm={6} xs={12}>
                <LoginImage />
            </Grid>
        </Grid>
    )
}

export default LoginLayoutZlink
