import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { remoteRegistration } from '../../../services/DeviceService';
import CommonUtil from '../../../util/CommonUtils';

const BioSpecForHandAndPalm = (props) => {
    const { enqueueSnackbar } = useSnackbar();
  
    const[state,setState]=useState({
      selectHand:'',
      fid:''
    });

    const [error, setError] = useState({
        selectHand: '',
        fid: ''
    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };
    const validate=()=>{
        if (CommonUtil.isEmptyString(state.selectHand)) {
            setError({
              ...error,
              selectHand: true,
            });
            enqueueSnackbar('Select your hand', {
                variant: 'error',
            });
            return ;
          }
      
          if (CommonUtil.isEmptyString(state.fid) && props.needFinger===true) {
            setError({
              ...error,
              fid: true,
            });
            enqueueSnackbar('Choose your finger', {
                variant: 'error',
            });
            return;
          }
            if (props.needFinger === true) {
      
                const payload = {
                    fid: state.fid
                };
                handleSubmit(payload);
            }
         else {
             const payload = {
                 fid: state.selectHand === 'right' ? 1 : 0
             };
             handleSubmit(payload);
         }
         
    }
    const handleSubmit = (data) => {
        if (props.needFinger===true) {
          let payload = {
            enrollType: "1",
            pin: props.pin.data.pin,
            fid: data.fid
          }
          let device=[props.pin.deviceDetails.ip,props.credential.firstName,props.credential.lastName]
          remoteRegistration(props.devId,device, payload, (data) => {
            if (data?.data?.code === 'DMSI0000') {
                enqueueSnackbar(data.data.msg, {
                    variant: 'success',
                });
                props.onClose();
            } else {
                enqueueSnackbar(data.data.msg, {
                    variant: 'error',
                });
                props.onClose();
            }
          })
        }
        else {
          let payload = {
            enrollType: "8",
            pin: props.pin.data.pin,
            fid: data.fid
          }
          let device=[props.pin.deviceDetails.ip,props.credential.firsName,props.credential.lastName]
          remoteRegistration(props.devId,device, payload, (data) => {
            if (data?.data?.code === 'DMSI0000') {
                enqueueSnackbar(data.data.msg, {
                    variant: 'success',
                });
                props.onClose();
            } else {
                enqueueSnackbar(data.data.msg, {
                    variant: 'error',
                });
                props.onClose();
            }
          })
        }
      }
    
    return (
    <>
       <Grid container justify='center' alignItems='center'> 
            <Grid item xs={12} sm={12}>
            <Typography fontSize={'13px'}>{'Select your hand'} <span style={{ color: 'red' }}>{'* '}</span></Typography>
                <Box display='flex' justifyContent='center' fullWidth my={1}>
                  <FormControl variant='outlined' fullWidth size='small'>
                    <Select name='selectHand' label='' 
                    onChange={handleChange}
                    >
                      <MenuItem value={'left'}>Left hand</MenuItem>
                      <MenuItem value={'right'}>Right hand</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid> 
              {props.needFinger===true&&state.selectHand &&(<Grid item xs={12} sm={12} lg={12}>
                <Typography fontSize={'13px'}>{'Select your finger'} <span style={{ color: 'red' }}>{'* '}</span></Typography>
                <Box display='flex' justifyContent='center' my={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <Select
                      error={error.fid}
                      fullWidth
                      // label={'Select your finger'}
                      name='fid'
                      onChange={handleChange}
                    >
                      {state.selectHand==='right' ? (
                        rightHandFinger.map((finger, index) => (
                          <MenuItem key={index} value={finger.value} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {finger.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                       leftHandFinger.map((finger, index) => (
                          <MenuItem key={index} value={finger.value} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {finger.name}
                            </Typography>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Box>
              
            </Grid>)}
            <Grid item xs={12} sm={12} lg={12} display={'flex'} marginTop={1.5}>
                <Button id="button-biospecforhandandpalm-save" size='large' fullWidth variant='contained' 
                style={{marginRight:'15px',textTransform:'none'}}
                onClick={()=>validate()}
                >Save</Button>
                <Button id= "button-biospecforhandandpalm-cancel" size='large' fullWidth variant='outlined' style={{textTransform:'none'}}
                  onClick={()=>props.onClose()}
                >Cancel</Button>
            </Grid>

        </Grid>
    </>
  )
}
const leftHandFinger = [
    {
        name: 'Left little finger',
        value: 0
    },
    {
        name: 'Left ring  finger',
        value: 1
    },
    {
        name: 'Left middle  finger',
        value: 2
    },
    {
        name: 'Left index finger',
        value: 3
    },
    {
        name: 'Left thumb',
        value: 4
    },
];

const rightHandFinger = [
    {
        name: 'Right little finger',
        value: 9
    },
    {
        name: 'Right ring  finger',
        value: 8
    },
    {
        name: 'Right middle  finger',
        value: 7
    },
    {
        name: 'Right index finger',
        value: 6
    },
    {
        name: 'Right thumb',
        value: 5
    },
]

export default BioSpecForHandAndPalm
