import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppStore } from "../../AppStore";
import SecuredRoute from "../../SecuredRoute";
import { BasicConfigurationApiForZlinkRedirect } from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import Header from "./Header";
import PaginationContext from "./PaginationContext";
import SidebarAccess from "./SidebarAccess";
import { useTheme } from "@emotion/react";

export default function MainLayout(props) {

  const [validSession, setValidSession] = useState(false);
  const location = useLocation()
  const [quickSetupPayload, setQuickSetupPayload] = useState({
    accessGroup: {
      name: "",
      schedulerId: "",
      startTime: "",
      endTime: ""
    },
    personIds: [],
    doorIds: []
  });
  const [errorQuickSetupPayload, setErrorQuickSetupPayload] = useState({
    accessGroup: {
      name: "",
      schedulerId: "",
      startTime: "",
      endTime: ""
    },
    personIds: '',
    doorIds: ''
  })
  const [data, setData] = useState();
  const [managePersonPageNumber, setManagePersonPageNumber] = useState(1);
  const [managePersonPageSize, setManagePersonPageSize] = useState(10);
  const [manageSitePageNumber, setManageSitePageNumber] = useState(1);
  const [manageSitePageSize, setManageSitePageSize] = useState(10);
  const [manageZonePageNumber, setManageZonePageNumber] = useState(1)
  const [manageZonePageSize, setManageZonePageSize] = useState(10);
  const [manageOperationLogPageNumber, setManageOperationLogPageNumber] = useState(1);
  const [manageOperationLogPageSize, setManageOperationLogPageSize] = useState(10);
  const [manageEventPageNumber, setManageEventPagenumber] = useState(1);
  const [manageEventPageSize, setManageEventPageSize] = useState(10);
  const [manageTimeSlotPageNumber, setManageTimeSlotPageNumber] = useState(1);
  const [manageTimeSlotPageSize, setManageTImeSlotPageSize] = useState(10);
  const [manageGATPageNumber, setManageGATPageNumber] = useState(1);
  const [manageGATPageSize, setManageGATPageSize] = useState(10);
  const [manageDeviceListPageNumber, setManageDeviceListPageNumber] = useState(1);
  const [manageDeviceListPageSize, setManageDeviceListPageSize] = useState(10);
  const [manageDoorPageNumber, setManageDoorPageNumber] = useState(1);
  const [manageDoorPageSize, setManageDoorPageSize] = useState(10);
  const [manageReaderPageNumber, setManageReaderPageNumber] = useState(1);
  const [manageReaderPageSize, setManageReaderPageSize] = useState(10);
  const [manageAUXINPageNumber, setManageAUXINPageNumber] = useState(1);
  const [manageAUXINPageSize, setManageAUXINPageSize] = useState(10);
  const [manageAUXOUTPageNumber, setManageAUXOUTPageNumber] = useState(1);
  const [manageAUXOUTPageSize, setManageAUXOUTPageSize] = useState(10);
  const [manageDATPageNumber, setManageDATPageNumber] = useState(1);
  const [manageDATPageSize, setManageDATPageSize] = useState(10);
  const [manageAGPersonPageNumber, setManageAGPersonPageNumber] = useState(1);
  const [manageAGPersonPageSize, setManageAGPersonPageSize] = useState(10);
  const [manageAddAGPersonPageNumber, setManageAddAGPersonPageNumber] = useState(1);
  const [manageAddAGPersonPageSize, setManageAddAGPersonPageSize] = useState(10);
  const [manageAGAddDoorPageNumber, setManageAGAddDoorPageNumber] = useState(1);
  const [manageAGAddDoorPageSize, setManageAGAddDoorPageSize] = useState(10);
  const [manageAGDoorPageNumber, setManageAGDoorPageNumber] = useState(1);
  const [manageAGDoorPageSize, setManageAGDoorPageSize] = useState(10);



  useEffect(() => {
    const jwtToken = CommonUtil.getCookie("zlink_token");

    if (jwtToken) {
      setValidSession(true);
    } else {
      BasicConfigurationApiForZlinkRedirect().then((data) => {
        if (data?.data?.code === "CAAI0001") {
          setData(data?.data?.data?.localUrl);
          window.location.href = data?.data?.data?.zlinkRedirectUrl;
        }
      });
    }
  }, []);
  const resetPaginationContext = (path) => {
    if (!path.includes('/person') && !path.includes('/personview')) {
      setManagePersonPageNumber(1);
      setManagePersonPageSize(10);
    }
    if (!path.includes('/zone') && !path.includes('/zoneview')) {
      setManageZonePageNumber(1);
      setManageZonePageSize(10);
    }
    if (!path.includes('/site') && !path.includes('/siteview')) {
      setManageSitePageNumber(1);
      setManageSitePageSize(10);
    }
    if (!path.includes('/operation-log')) {
      setManageOperationLogPageNumber(1);
      setManageOperationLogPageSize(10);
    }
    if (!path.includes('/time-slots') && !path.includes('/update-time-slot')) {
      setManageTimeSlotPageNumber(1);
      setManageTImeSlotPageSize(10);
    }
    if (!path.includes('/group-access-time') && !path.includes('/access-group-door') && !path.includes('/access-group-person') && !path.includes('/update-group-access-time')) {
      setManageGATPageNumber(1);
      setManageGATPageSize(10);
    }
    if (!path.includes('/device-list') && !path.includes('/update-device') && !path.includes('/person-in-device')) {
      setManageDeviceListPageNumber(1);
      setManageDeviceListPageSize(10);
    }
    if (!path.includes('/door-list') && !path.includes('/door-edit')) {
      setManageDoorPageNumber(1);
      setManageDoorPageSize(10);
    }
    if (!path.includes('/reader') && !path.includes('/readerview') && !path.includes('/readereader-editrview')) {
      setManageReaderPageNumber(1);
      setManageReaderPageSize(10);
    }

    if (!path.includes('/auxiliaryinput') && !path.includes('/auxiliary-input-view') && !path.includes('/auxiliary-input-edit')) {
      setManageAUXINPageNumber(1);
      setManageAUXINPageSize(10);
    }
    if (!path.includes('/auxiliaryoutput') && !path.includes('/auxiliary-output-view') && !path.includes('/auxiliary-output-edit')) {
      setManageAUXOUTPageNumber(1);
      setManageAUXOUTPageSize(10);
    }
    if (!path.includes('/door-access-time')) {
      setManageDATPageNumber(1);
      setManageDATPageSize(10);
    }
    if (!path.includes('/access-group-person')) {
      setManageAGPersonPageNumber(1);
      setManageAGPersonPageSize(10);
    }
    if (!path.includes('/access-group-add-person')) {
      setManageAddAGPersonPageNumber(1);
      setManageAddAGPersonPageSize(10);
    }
    if (!path.includes('/access-group-add-door')) {
      setManageAGAddDoorPageNumber(1);
      setManageAGAddDoorPageSize(10);
    }
    if (!path.includes('/access-group-door')) {
      setManageAGDoorPageNumber(1);
      setManageAGDoorPageSize(10);
    }

  }

  useEffect(() => {
    resetPaginationContext(location.pathname);
  }, [location.pathname])

  const value = {
    quickSetupPayload,
    setQuickSetupPayload,
    errorQuickSetupPayload,
    setErrorQuickSetupPayload,

    managePersonPageNumber,
    managePersonPageSize,
    manageSitePageNumber,
    manageSitePageSize,
    manageZonePageNumber,
    manageZonePageSize,
    manageOperationLogPageNumber, manageOperationLogPageSize,
    manageEventPageNumber, manageEventPageSize,
    manageTimeSlotPageNumber, manageTimeSlotPageSize,
    manageGATPageNumber, manageGATPageSize,
    manageDeviceListPageNumber, manageDeviceListPageSize,
    manageDoorPageNumber, manageDoorPageSize,
    manageReaderPageNumber, manageReaderPageSize,
    manageAUXINPageNumber, manageAUXINPageSize,
    manageAUXOUTPageNumber, manageAUXOUTPageSize,
    manageDATPageNumber, manageDATPageSize,
    manageAGPersonPageNumber, manageAGPersonPageSize,
    manageAddAGPersonPageNumber, manageAddAGPersonPageSize,
    manageAGAddDoorPageNumber, manageAGAddDoorPageSize,
    manageAGDoorPageNumber, manageAGDoorPageSize,


    setManagePersonPageNumber,
    setManagePersonPageSize,
    setManageSitePageNumber,
    setManageSitePageSize,
    setManageZonePageNumber,
    setManageZonePageSize,
    setManageOperationLogPageNumber, setManageOperationLogPageSize,
    setManageEventPagenumber, setManageEventPageSize,
    setManageTimeSlotPageNumber, setManageTImeSlotPageSize,
    setManageGATPageNumber, setManageGATPageSize,
    setManageDeviceListPageNumber, setManageDeviceListPageSize,
    setManageDoorPageNumber, setManageDoorPageSize,
    setManageReaderPageNumber, setManageReaderPageSize,
    setManageAUXINPageNumber, setManageAUXINPageSize,
    setManageAUXOUTPageNumber, setManageAUXOUTPageSize,
    setManageDATPageNumber, setManageDATPageSize,
    setManageAGPersonPageNumber, setManageAGPersonPageSize,
    setManageAddAGPersonPageNumber, setManageAddAGPersonPageSize,
    setManageAGAddDoorPageNumber, setManageAGAddDoorPageSize,
    setManageAGDoorPageNumber, setManageAGDoorPageSize

  }

  return validSession ? (
    <AppStore.Provider value={value}>
      <PaginationContext.Provider value={value}>
        <Box style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <SecuredRoute />
          <Header />
          <Box
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <SidebarAccess />

            <Grid
              container
              sx={{
                height: `calc(100% - 70px)`,
                overflowY: "auto",
                width: "100%",
                padding: { xl: "30px", xs: "15px" }
              }}
              alignContent={"flex-start"}
            >
              {props.children}
            </Grid>
          </Box>
        </Box>
      </PaginationContext.Provider>
    </AppStore.Provider>

  ) : (
    <></>
  );
}
