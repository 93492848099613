import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as Close } from '../assets/new-images/close icon.svg';

const SimpleDailogBox = (props) => {

    const styleDialog = {
        '& .MuiDialog-paper': {
            position: 'absolute',
            width:props.width?props.width: '550px',
            borderRadius: '10px',
            padding: {xl:"30px",xs:"20px"},
            top:'none',
        },
    };
    const headerStyle = {
        letterSpacing: '0px',
        color: 'rgba(71, 75, 79, 0.8)',
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "23px",
        fontFamily: "Roboto",
    }
  return (
    <>
    <Dialog sx={styleDialog} open={props.open} onClose={() => props.onClose()}>
            <Box>
                <Box display='flex' justifyContent={'space-between'}>
                    <Typography
                        sx={headerStyle}>
                        {props.title}
                    </Typography>
                    <Box onClick={props.onClose} style={{cursor:"pointer"}}>
                        <Close />
                    </Box>
                </Box>
                <Grid sx={{ 
                    marginTop:{xl:"28px",xs:"20px"} 
                }}>
                    {props.children}
                </Grid>
            </Box>
        </Dialog>
      
    </>
  )
}

export default SimpleDailogBox
