import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import RightViewLayout from '../../components/RighViewLayout';
import { loadDeviceReaderById, updateReader } from '../../services/ReaderService';
import CommonUtil from '../../util/CommonUtils';
import { useTranslation } from 'react-i18next';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import ViewPage from '../../components/ViewPage';
import { REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';

export default function EditReader(props) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [permissions, setPermissions] = useState([]);
  const { readerId } = useParams();
  const [perEditReader, setEditReader] = React.useState(false);
  const [viewErrorPage,setViewErrorPage]=useState(false)
  const[loader,setLoader]=React.useState(false);

  const [payload, setPayload] = React.useState({
    readerId: "",
    name: "",
    doorName: "",
    deviceName: "",
    deviceSn: "",
    readerNo: "",
    commType: "",
    commAddress: "",
    port: "",
    readerEncrypt: "",
    readerState: "",
    address: "",
    status: "",
  });

  const [error, setError] = React.useState({
    name: false,
    doorName: false,
    deviceName: false,
    deviceSn: false,
    readerNo: false,
    commType: false,
    commAddress: false,
    status:false
  });

  useEffect(() => {
    if (CommonUtil.isEmpty(readerId)) {
      navigate({
        pathname: REACT_URL_PAGE_NOT_FOUND,
      });
    }
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setPermissions(prev => [...prev, item.code])
        });
      }
      setEditReader(true);
    });
  }, []);

  const getReaderById=()=>{
    setLoader(true);
    loadDeviceReaderById(readerId).then((res) => {
      if (res?.data?.code === 'DMSI0000') {
        setPayload(res.data.data);

      }else{
        setViewErrorPage(true)
      }
      setLoader(false);
    });
  }

  useEffect(() => {
    if (checkPermission("ACC_READER_EDIT")) {
      getReaderById();
    }
  }, [permissions])

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  const resetFilter = () => {
    getReaderById();
    setError({
      ...error,
      readerName: false,
    })
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
         name: t('AccReader026'),
      });
      return;
    }


    const state = {
      name: payload.name,
      commAddress: payload.commAddress,
      commType: payload.commType,
      port: payload.port,
      readerEncrypt: payload.readerEncrypt,
      readerNo: payload.readerNo,
      readerState: payload.readerState,
      address: payload.address,
      status: payload.status
    };

    if (CommonUtil.isEmptyString(readerId)) {
    } else {
      setLoader(true);
      updateReader(readerId, state).then((res) => {
        if (res.data.code === 'DMSI0000') {
          enqueueSnackbar(t("AccReader018"), { variant: 'success' });
          handleClose();
        } else {
          enqueueSnackbar((res.data.msg), { variant: 'error' })
        }
        setLoader(false);
      });
    }
  };

  const navigate = useNavigate();
  const handleClose = () => {
    navigate(
      '/reader',
      { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } });
  };


  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: false,
    });
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const FieldStatus = () => (
    <CustomInput
      id="textfield-updatereader-status"
      disabled
      error={error.status}
      name='readerStatus'
      height={{xl:"64px",xs:"50px"}}
      padding={{xl:"0px 20px",xs:"0px 10px"}}
      label={t('AccReader017')}
      size='small'
      onKeyDown={handleKeyPress}
      placeholder={t('AccReader025')}
      value={payload.status == '0' ? t('COMMON020') : t('COMMON021')}
    />
  )

  const FieldReaderName = () => (
    <CustomInput
      id="textfield-updatereader-name"
      error={error.name}
      name='name'
      label={t('AccReader005')}
      required
      size='small'
      height={{xl:"64px",xs:"50px"}}
      padding={{xl:"0px 20px",xs:"0px 10px"}}
      onKeyDown={handleKeyPress}
      helperText={error.name}
      placeholder={t('AccReader019')}
      value={payload.name}
      handleChange={(data) => handleChange(data)}
      inputProps={{
        maxLength: 50,
      }}
    />
  )

  const FieldDoorname = () => (
    <CustomInput
      id="textfield-updatereader-doorname"
      disabled
      error={error.doorName}
      name='doorName'
      label={t('AccDMTopology011')}
      size='small'
      height={{xl:"64px",xs:"50px"}}
      padding={{xl:"0px 20px",xs:"0px 10px"}}
      onKeyDown={handleKeyPress}
      placeholder={t('AccReader020')}
      value={payload.doorName}
      inputProps={{
        maxLength: 50,
      }}
    />
  )
  const FieldDeviceName = () => (
    <CustomInput
      id="textfield-updatereader-devicename"
      disabled
      error={error.deviceName}
      name='deviceName'
      label={t('AccDevice014')}
      size='small'
      height={{xl:"64px",xs:"50px"}}
      padding={{xl:"0px 20px",xs:"0px 10px"}}
      onKeyDown={handleKeyPress}
      placeholder={t('AccReader021')}
      value={payload.deviceName}
      inputProps={{
        maxLength: 30,
      }}
    />
  )
  const FieldDeviceSerialNumber = () => (
    <CustomInput
      id="textfield-updatereader-devicesn"
      disabled
      error={error.deviceSn}
      name='sn'
      label={'SN'}
      height={{xl:"64px",xs:"50px"}}
      padding={{xl:"0px 20px",xs:"0px 10px"}}
      size='small'
      onKeyDown={handleKeyPress}
      placeholder={t('AccReader022')}
      value={payload.deviceSn}
    />
  )

  const FieldReaderNumber = () => (
    <CustomInput
      id="textfield-updatereader-number"
      disabled
      error={error.readerNo}
      name='number'
      height={{xl:"64px",xs:"50px"}}
      padding={{xl:"0px 20px",xs:"0px 10px"}}
      label={t('AccDMTopology008')}
      size='small'
      onKeyDown={handleKeyPress}
      placeholder={t('COMMON022')}
      value={payload.readerNo}
    />
  )

  const FieldCommType = () => (
    <CustomInput
      id="textfield-updatereader-communication-type"
      disabled
      error={error.commType}
      name='commType'
      label={t('AccDMTopology022')}
      size='small'
      height={{xl:"64px",xs:"50px"}}
      padding={{xl:"0px 20px",xs:"0px 10px"}}
      onKeyDown={handleKeyPress}
      placeholder={t('AccReader023')}
      value={payload.commType}
    />
  )

  const FieldCommAddress = () => (
    <CustomInput
      id="textfield-updatereader-communication-address"
      disabled
      error={error.commAddress}
      name='commAddr'
      label={t('AccDMTopology023')}
      size='small'
      height={{xl:"64px",xs:"50px"}}
      padding={{xl:"0px 20px",xs:"0px 10px"}}
      onKeyDown={handleKeyPress}
      placeholder={t('AccReader024')}
      value={payload.commAddress}
    />
  );

  return (
    <>

      {viewErrorPage?<PageNotFound></PageNotFound>:perEditReader ? checkPermission("ACC_READER_EDIT") ? (<ViewPage
        title={t("AccReader008")}
        path={true}
        onClick={() => navigate(
          '/reader',
          { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
      >
        {loader?<Loader open={loader}/>:<></>}
        <Grid container ml={-0.5}>
          <Grid item xs={12} md={6} sx={{pr:{xl:"30px",md:"20px"}}}>
            {FieldReaderName()}
          </Grid>
           <Grid item xs={12} md={6} >
            <FieldDoorname />
          </Grid>
           <Grid item xs={12} md={6} sx={{pr:{xl:"30px",md:"20px"}}}>
            <FieldDeviceName />
          </Grid>
           <Grid item xs={12} md={6} >
            <FieldDeviceSerialNumber />
          </Grid>
           <Grid item xs={12} md={6} sx={{pr:{xl:"30px",md:"20px"}}}>
            <FieldReaderNumber />
          </Grid>
           <Grid item xs={12} md={6} >
            {FieldCommType()}
          </Grid>
           <Grid item xs={12} md={6} sx={{pr:{xl:"30px",md:"20px"}}}>
            {FieldCommAddress()}
          </Grid>
           <Grid item xs={12} md={6} >
            {FieldStatus()}
          </Grid>
          <Grid item xs={12}
            sx={{
              mt: { xl: "10px", xs: "20px" },
              display: "flex",
              justifyContent: { lg: "flex-end", xs: "center" }
            }}
          >

            <Button
              id="button-updateauxiliaryinput-save"
              onClick={handleSubmit}
              variant='contained'
              sx={{
                width: { xl: "233px", xs: "180px" },
                height: { xl: "64px", xs: "50px" },
                marginRight: "30px"
              }}
            >
              {t('COMMON023')}
            </Button>

            <Button
              id="button-updateauxiliaryinput-clear"
              onClick={() => resetFilter()}
              variant='outlined'
              sx={{
                width: { xl: "233px", xs: "180px" },
                height: { xl: "64px", xs: "50px" },
              }}
            >
              {t('COMMON015')}
            </Button>
          </Grid>
        </Grid>
      </ViewPage>)
        : (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t("AccReader028")}</Typography>
        </Box>) : <></>}


    </>
  );
}
