import { useMediaQuery } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputLabel, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadImage } from '../../assets/uploadIcon.svg';
import { ContactUscountryList } from '../../components/ContactUscountryList';
import CustomInput from '../../components/CustomInput';
import CustomePhoneInput from '../../components/CustomPhoneInput';
import DropzoneComponent from '../../components/DropzoneComponent';
import ImageCard from '../../components/ImageCard';
import Pdfcard from '../../components/Pdfcard';
import VideoCard from '../../components/VideoCard';
import WordFilecard from '../../components/WordFilecard';
import ZipFilescard from '../../components/ZipFilescard';
import api from '../../configurations/http-common';
import { getCompanyDetails } from '../../services/CompanyService';
import { addAttachmentToTicket, createNewTicket } from '../../services/HelpDeskService';
import { getUserDetails } from '../../services/UserService';
import CommonUtil from '../../util/CommonUtils';
const HelpDesk = (props) => {
    const [open, setOpen] = React.useState(props.open);
    const [fullWidth, setFullWidth] = React.useState(true);
    const { t } = useTranslation();
    const [maxWidth, setMaxWidth] = React.useState(window.innerWidth >= 1900 ? 'lg' : 'md');
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useState({
        country: "",
        company: "",
    });

    const fieldName = {
        email: "",
        phone: "",
        description: "",
        summary: "",
        country: "",
        orgName: "",
        version: props.version,
        contactPerson: "",
        contactPhone: "",
        impact: "",
        stepToRepoduce: "",
        frequency: ""
    }
    // const [payload, setPayload] = React.useState({ ...fieldName }); 
    const [error, setError] = React.useState({ ...fieldName });
    const [countryCode, setCountryCode] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [contactCountryCode, setContactCountryCode] = React.useState("");
    const [contactPhoneNumber, setContactPhoneNumber] = React.useState("");
    const [attachmentID, setAttachmentID] = React.useState([]);
    const [fileName, setFileName] = React.useState([]);
    const [selectedImage, setSelectedImage] = React.useState([]);
    const [upload, setUpload] = React.useState(false);
    const [selectedImages, setSelectedImages] = React.useState([]);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [loader, setLoader] = useState(false);
    const [fileLoadingStates, setFileLoadingStates] = useState([]);
    const lg110 = useMediaQuery('(min-width: 1210px) and (max-width: 1410px)');

    const frequencyList = [
        { id: "10321", name: t("CONTACT0025"), value: "10321[Always]" },
        { id: "10938", name: t("CONTACT0026"), value: "10938[Usually]" },
        { id: "10939", name: t("CONTACT0027"), value: "10939[Sometimes]" },
        { id: "10322", name: t("CONTACT0028"), value: "10322[Occasionally]" },
        { id: "10323", name: t("CONTACT0029"), value: "10323[Seldom]" },
        { id: "10940", name: t("CONTACT0030"), value: "10940[Once]" },
    ]

    const impactList = [
        { id: "10000", name: t("CONTACT0031"), value: "10000[Extensive / Outrage]" },
        { id: "10001", name: t("CONTACT0032"), value: "10001[Significant / Critical]" },
        { id: "10002", name: t("CONTACT0033"), value: "10002[Moderate / High]" },
        { id: "10003", name: t("CONTACT0034"), value: "10003[Minor / Medium]" },
        { id: "10954", name: t("CONTACT0035"), value: "10954[Low]" }
    ]

    const fileFormat = [
        "image/png", "video/mp4", "image/jpeg", "video/quicktime", "video/webm", "image/bmp", "image/x-ms-bmp",
        "application/msword", "application/vnd.ms-excel", "application/vnd.ms-powerpoint",
        "application/pdf", "text/plain", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/zip", "application/docx", "application/x-zip-compressed", "video/mp4", "video/quicktime", "video/webm", "video/3gpp", "video/x-msvideo",
        "video/x-ms-wmv", "video/x-ms-asf", "video/x-pn-realvideo", "video/avi", "video/x-ms-asx", "video/x-ms-wmv", "video/x-ms-rmvb",
        "video/x-ms-rm", "application/vnd.rar", "application/x-rar-compressed", "application/vnd.rn-realmedia"
    ];

    const videoFormate = [
        "video/mp4", "video/quicktime", "video/webm", "video/3gpp", "video/x-msvideo",
        "video/x-ms-wmv", "video/x-ms-asf", "video/x-pn-realvideo", "video/avi", "video/x-ms-asx", "video/x-ms-wmv",
    ];

    const [payload, setPayload] = React.useState({
        ...fieldName,
        impact: impactList.find(option => option.id === "10003").id,
    });

    const handleClose = () => {
        props.close();
    };

    const handleDelete = (id, index) => {

        const updatedFiles = [...selectedImages];
        const deletedFile = updatedFiles[index];

        if (deletedFile && deletedFile.id) {
            const updatedAttachmentID = attachmentID.filter(id => id !== deletedFile.id);

            setAttachmentID(updatedAttachmentID);
        }

        updatedFiles.splice(index, 1);
        setSelectedImages(updatedFiles);

    };


    const getFile = (files) => {
        setUpload(false);
        // const initialLoadingStates = files.map(() => true);
        // setFileLoadingStates(initialLoadingStates);

        files.forEach((file, index) => {
            let size = parseInt(file.size);
            const fileType = file.type;

            if (size >= 100000000) {
                enqueueSnackbar("File size must be less than 100 MB.", {
                    variant: 'error',
                });
            }
            else if (!fileFormat.includes(fileType)) {
                enqueueSnackbar("Invalid file ttformat.", {
                    variant: 'error',
                });
            }
            else {

                const initialLoadingStates = files.map(() => true);
                setFileLoadingStates(initialLoadingStates);

                const name = file.name;
                const imageFile = file;

                setSelectedImages((prevImages) => [
                    ...prevImages,
                    { type: fileType, url: URL.createObjectURL(imageFile), name: name },
                ]);

                let bodyFormData = new FormData();
                bodyFormData.append('file', file);
                axios({
                    method: 'post',
                    url: process.env.REACT_APP_SERVER_URL + '/web/help-desk/attachment',
                    data: bodyFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then(function (response) {
                        if (response.data?.code === "CAHDI0001") {

                            setFileLoadingStates((prevLoadingStates) =>
                                prevLoadingStates.map((state, i) => (i === index ? false : state))
                            );

                            const newAttachmentId =
                                response.data?.data?.temporaryAttachments[0]?.temporaryAttachmentId;

                            setAttachmentID((prevAttachmentID) => [
                                ...prevAttachmentID,
                                newAttachmentId,
                            ]);

                            setSelectedImages((prevImages) =>
                                prevImages.map((image) =>
                                    image.name === name ? { ...image, id: newAttachmentId } : image
                                )
                            );

                            setUpload(true);
                        } else {
                            enqueueSnackbar(response.data?.message, {
                                variant: 'error',
                            });
                        }
                    })
                    .catch(function (response) {
                        enqueueSnackbar("Something went wrong, Please try again...", {
                            variant: 'error',
                        });
                    })
                    .finally(() => {
                        if (!fileLoadingStates.includes(true)) {
                            setLoader(false);
                        }
                    });
            }
        });
    }

    const updateAttachmentId = (id) => {
        const payload = {
            id: attachmentID,
            ticketId: id
        }
        addAttachmentToTicket(payload, (response) => {
            if (response.data.code === "CAHDI0001")
                enqueueSnackbar(t('CONTACT0021'), {
                    variant: 'success',
                });
        })
            .catch(function (response) {
                enqueueSnackbar("Something went wrong, Please try again...", {
                    variant: 'error',
                });
            });
    }
    const handleSubmit = () => {
        if (fileLoadingStates.includes(true)) {
            enqueueSnackbar("File is uploading, Please wait...", {
                variant: 'error',
            });
            return;
        }
        // if (upload) {
        if (!validateForm()) {
            return;
        }
        console.log("contact info", contactCountryCode + " " + contactPhoneNumber);
        if (!CommonUtil.isEmpty(contactCountryCode) || !CommonUtil.isEmpty(contactPhoneNumber)) {
            if (contactCountryCode.replace('+', '').length == 2 && !(contactPhoneNumber.length >= 6)) {

                setError({
                    ...error,
                    contactPhone: t('CONTACT0040'),
                });
                return;
            }

            if (contactCountryCode.replace('+', '').length == 3 && !(contactPhoneNumber.length >= 5)) {
                setError({
                    ...error,
                    contactPhone: t('CONTACT0040'),
                });
                return;
            }

            if (contactCountryCode.replace('+', '').length == 4 && !(contactPhoneNumber.length >= 4)) {
                setError({
                    ...error,
                    contactPhone: t('CONTACT0040'),
                });
                return;
            }

            if (contactPhoneNumber.length + contactCountryCode.replace('+', '').length > 23 || contactPhoneNumber.length + contactCountryCode.replace('+', '').length < 8) {
                setError({
                    ...error,
                    contactPhone: t('CONTACT0040'),
                });
                return;
            }
        }

        const originalString = payload.summary;
        const textWithoutLineBreaks = originalString.replace(/\n/g, "");

        const state = {
            "requestFieldValues": {
                "description": payload.description,
                "summary": textWithoutLineBreaks,
                "customfield_10207": {
                    "value": payload.country
                },
                "customfield_10225": { id: "10766" },
                "customfield_10245": payload.orgName,
                "customfield_10090": payload.version,
                "customfield_10170": payload.contactPerson,
                "customfield_10172": !CommonUtil.isEmpty(contactCountryCode) ? "+" + contactCountryCode + contactPhoneNumber : "",
                "customfield_10004": {
                    "id": payload.impact
                },
                "customfield_10203": {
                    "id": payload.frequency
                },
                "customfield_10313": payload.stepToRepoduce
            },
            "raiseOnBehalfOf": payload.email,
        }
        createNewTicket(state,
            (response) => {
                props.close();
                if (response.data.code === "CAHDI0001" && CommonUtil.isEmpty(attachmentID)) {
                    enqueueSnackbar(t('CONTACT0021'), {
                        variant: 'success',
                    });
                } else if (response.data.code === "CAHDI0001" && !CommonUtil.isEmpty(attachmentID)) {
                    updateAttachmentId(response.data.data.issueKey);
                } else {
                    enqueueSnackbar(response.msg, {
                        variant: 'error',
                    });
                }
            })
            .catch(function (response) {
                enqueueSnackbar("Something went wrong, Please try again...", {
                    variant: 'error',
                });
            });
        // } 
    }

    const validateForm = () => {
        if (CommonUtil.isEmptyString(payload.orgName)) {
            setError({
                ...error,
                orgName: t('COMMON017'),
            });
            return;
        }
        if (CommonUtil.isEmptyString(payload.contactPerson)) {
            setError({
                ...error,
                contactPerson: t('COMMON017'),
            });
            return;
        }
        if (CommonUtil.isEmptyString(payload.country)) {
            setError({
                ...error,
                country: t('COMMON017'),
            });
            return;
        }
        if (CommonUtil.isEmptyString(payload.email)) {
            setError({
                ...error,
                email: t('COMMON017'),
            });
            return;
        } else {
            if (!CommonUtil.isValidEmail(payload.email)) {
                setError({
                    ...error,
                    email: t('CONTACT0041'),
                });
                return false;
            }
        }
        if (!CommonUtil.isEmpty(contactPhoneNumber)) {
            if (CommonUtil.isEmpty(contactCountryCode)) {
                setError({
                    ...error,
                    contactPhone: t('CONTACT0042'),
                });
                return false;;
            }
        }
        if (!CommonUtil.isEmpty(contactCountryCode)) {
            if (CommonUtil.isEmpty(contactPhoneNumber)) {
                setError({
                    ...error,
                    contactPhone: t('CONTACT0040'),
                });
                return false;;
            }
        }

        if (CommonUtil.isEmptyString(payload.summary)) {
            setError({
                ...error,
                summary: t('COMMON017'),
            });
            return;
        }
        if (CommonUtil.isEmptyString(payload.description)) {
            setError({
                ...error,
                description: t('COMMON017'),
            });
            return;
        }
        if (CommonUtil.isEmptyString(payload.impact)) {
            setError({
                ...error,
                impact: t('COMMON017'),
            });
            return;
        }
        if (CommonUtil.isEmptyString(payload.frequency)) {
            setError({
                ...error,
                frequency: t('COMMON017'),
            });
            return;
        }
        return true;
    }
    useEffect(() => {
        let userdetail = api.decoded();
        if (userdetail) {
            getUserDetails(userdetail.id).then((res) => {
                if (res.data.code === "UASI0033") {
                    console.info(res);
                    //   setUser(res.data.data);
                }
            });
        }
        // BasicConfigurationApiForZlinkRedirect().then((data) => {
        //   if (data?.data?.code === "CAAI0001") {
        //     setData(data?.data?.data?.localUrl);
        //     window.location.href = data?.data?.data?.zlinkRedirectUrl;
        //   }
        // });
    }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const userdetail = api.decoded();
            if (userdetail) {
                try {
                    const res = await getUserDetails(userdetail.id);
                    const rescompany = await getCompanyDetails(userdetail.companyId);
                    console.info(rescompany);
                    if (res.data.code === "UASI0033") {
                        const userData = res.data.data;
                        const { firstName, lastName, phone, email, country } = userData;
                        if (phone) {
                            const mobile = phone.split('-');
                            if (mobile.length > 1) {
                                setContactCountryCode(mobile[0]);
                                setContactPhoneNumber(mobile[1]);
                            }
                        }
                        setPayload((prevPayload) => ({
                            ...prevPayload,
                            email: email,
                            country: rescompany.data.data.country,
                            contactPerson: `${firstName} ${lastName}`,
                            orgName: rescompany.data.data.name,
                        }));
                    }
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            }
        };

        fetchUserDetails();
    }, []);

    const handleChange = (event) => {
        const name = event.target.name;
        setPayload({
            ...payload,
            [name]: event.target.value,
        });
        setError({ ...error, [name]: '' });
    }

    const handleContactPersonPhoneNumberChange = (value) => {
        var mobile = '+' + contactCountryCode.replace('+', '') + '-' + value;
        setPayload({ ...payload, contactPhone: mobile });
        setError({ ...error, contactPhone: '' });
        setContactPhoneNumber(value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            validateForm();
        }
    };
    const findImpact = (id) => {
        var impactObj = { id: '', name: "" };
        impactList.map((imp) => {
            if (imp.id === id) { impactObj = imp; }
        });
        return impactObj;
    };

    const findFrequency = (id) => {
        var freOject = { id: '', name: "" };
        frequencyList.map((freq) => {
            if (freq.id === id) { freOject = freq }
        });
        return freOject;
    };

    const findCountry = (name) => {
        var country = { id: '', country: "" };
        ContactUscountryList.map((val) => {
            if (val.country === name) { country = val; }
        });
        return country;
    };

    const EmailConfirmationField = (key) => (
        <CustomInput
            marginBottom={true}
            labelMarginTop={true}
            id="textfield-ticket-EmailConfirmationField"
            placeholder={t('CONTACT0024')}
            type={'text'}
            required
            height={{ xl: "64px", xs: "50px" }}
            padding={{ xl: "0px 20px", xs: "0px 13px" }}
            size={'small'}
            autoComplete='off'
            fullWidth
            onKeyDown={handleKeyPress}
            error={!CommonUtil.isEmptyString(error.email)}
            resetError={() => setError({ ...error, email: "" })}
            name='email'
            label={t('CONTACT0023')}
            value={payload.email}
            variant='outlined'
            handleChange={handleChange}
            helperText={error.email}
            validation='email'
            inputProps={{
                maxLength: 100,
            }}
        />
    );

    const SummaryField = (key) => (
        <CustomInput
            marginBottom={true}
            labelMarginTop={true}
            id="textfield-ticket-summaryfield"
            placeholder={t('CONTACT0038')}
            type={'text'}
            required
            size={'small'}
            autoComplete='off'
            fullWidth
            onKeyDown={handleKeyPress}
            error={!CommonUtil.isEmptyString(error.summary)}
            resetError={() => setError({ ...error, summary: '' })}
            name='summary'
            label={t('CONTACT0037')}
            value={payload.summary}
            variant='outlined'
            handleChange={handleChange}
            helperText={error.summary}
            inputProps={{
                maxLength: 255,
                sx: { padding: { xl: "7px 7px !important", xs: "8px 0 !important" } }
            }}
            multiline
            rows={3}
        />
    );
    const Description = (key) => (
        <CustomInput
            marginBottom={true}
            labelMarginTop={true}
            id="textfield-ticket-description"
            placeholder={t('CONTACT0039')}
            type={'text'}
            required
            size={'small'}
            autoComplete='off'
            fullWidth
            onKeyDown={handleKeyPress}
            error={!CommonUtil.isEmptyString(error.description)}
            resetError={() => setError({ ...error, description: '' })}
            name='description'
            label={t('ACCSITE003')}
            value={payload.description}
            variant='outlined'
            handleChange={handleChange}
            helperText={error.description}
            inputProps={{
                maxLength: 5000,
                sx: { padding: { xl: "7px 7px !important", xs: "8px 0 !important" } }
            }}
            multiline
            rows={3}
        />
    );
    const FieldCountry = (key) => (
        <Autocomplete
            id="dropdown-ticket-country"
            fullWidth
            size={'small'}
            getOptionLabel={(option) => option.country}
            clearText='Clear'
            value={payload?.country ? findCountry(payload.country) : null}
            options={ContactUscountryList}
            // onKeyDown={handleKeyPress}
            renderInput={(params) => <CustomInput
                marginBottom={true}
                labelMarginTop={true}
                cursor='pointer'
                handleChange={(e) => console.log()}
                resetError={() => setError({ ...error, country: '' })}
                placeholder={t('CONTACT0022')}
                height={{ xl: "46px", xs: "31px" }}
                padding={{ xl: "0px 20px !important", xs: "0px 13px !important" }}
                required
                helperText={error.country}
                error={error.country}
                {...params}
                label={t('ACCDA0024')} />}
            onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                    setPayload({
                        ...payload,
                        country: newValue.country,
                    });
                    setError({ ...error, country: '' });
                } else {
                    setPayload({
                        ...payload,
                        country: '',
                    });
                }
            }}
        />
    );

    const CompanyNameField = () => (
        <CustomInput
            marginBottom={true}
            labelMarginTop={true}
            id={"textfield-ticket-organizationName"}
            required
            size={'small'}
            label={t('CONTACT006')}
            type={'text'}
            value={payload.orgName}
            height={{ xl: "62px", xs: "50px" }}
            padding={{ xl: "0px 20px", xs: "0px 13px" }}
            fullWidth
            name='orgName'
            onKeyDown={handleKeyPress}
            style={{ fontSize: '161px' }}
            error={error.orgName}
            resetError={() => setError({ ...error, orgName: '' })}
            helperText={error.orgName}
            handleChange={handleChange}
            inputProps={{
                maxLength: 255,
            }}
            placeholder={t('CONTACT007')}
        />
    );
    const SoftwareVersionField = (key) => (
        <CustomInput
            marginBottom={true}
            labelMarginTop={true}
            id="textfield-ticket-SoftwareVersionField"
            type={'text'}
            disabled
            size={'small'}
            fullWidth
            label={t('CONTACT008')}
            value={props.version}
        />
    );
    const ContactPersonField = (key) => (
        <CustomInput
            marginBottom={true}
            labelMarginTop={true}
            id="textfield-ticket-contact-person"
            required
            size={'small'}
            placeholder={t('CONTACT009')}
            type={'text'}
            height={{ xl: "64px", xs: "50px" }}
            padding={{ xl: "0px 20px", xs: "0px 13px" }}
            fullWidth
            onKeyDown={handleKeyPress}
            style={{ fontSize: '161px' }}
            error={!CommonUtil.isEmptyString(error.contactPerson)}
            resetError={() => setError({ ...error, contactPerson: '' })}
            name='contactPerson'
            label={t('CONTACT010')}
            value={payload.contactPerson}
            variant='outlined'
            handleChange={handleChange}
            helperText={error.contactPerson}
            inputProps={{
                maxLength: 255,
            }}
        />
    );

    const ContactNumberField = (key) => (
        <CustomePhoneInput
            id="textfield-contact-person-mobile"
            error={error.contactPhone}
            handleCountryCode={(data) => {
                setContactCountryCode(data.dialCode);
                setError({ ...error, contactPhone: '' })
            }}
            req={true}
            countryCode={contactCountryCode}
            label={t('CONTACT0011')}
            placeholder={t('CONTACT0011')}
            height={{ xl: "64px", xs: "50px" }}
            padding={{ xl: "0px 20px", xs: "0px 13px" }}
            phoneNumber={contactPhoneNumber}
            resetError={() => setError({ ...error, contactPhone: '' })}
            name={'phone'}
            onKeyDown={handleKeyPress}
            handleChange={(e) => handleContactPersonPhoneNumberChange(e)}
        />
    );
    const ImpactField = () => (
        <Autocomplete
            id="dropdown-ticket-impact"
            fullWidth
            size={'small'}
            getOptionLabel={(option) => option.name}
            clearText='false'
            value={payload?.impact ? findImpact(payload.impact) : null}
            options={impactList}
            // onKeyDown={handleKeyPress}
            renderInput={(params) => <CustomInput
                marginBottom={true}
                labelMarginTop={true}
                cursor='pointer'
                padding={{ xl: "0px 20px !important", xs: "0px 13px !important" }}
                handleChange={(e) => console.log()}
                resetError={() => setError({ ...error, impact: '' })}
                placeholder={t('CONTACT0012')}
                height={{ xl: "48px", xs: "34px" }}
                required helperText={error.impact}
                error={error.impact}
                {...params}
                label={t('CONTACT0013')} />}
            onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                    setPayload({
                        ...payload, impact: newValue.id,
                    });
                    setError({ ...error, impact: '' });
                } else {
                    setPayload({
                        ...payload, impact: "",
                    });
                    setError({ ...error, impact: '' });
                }
            }}
        />
    );
    const FrequencyField = () => (
        <Autocomplete
            id="dropdown-ticket-frequency"
            fullWidth
            size={'small'}
            getOptionLabel={(option) => option.name}
            clearText='false'
            value={payload?.frequency ? findFrequency(payload.frequency) : null}
            options={frequencyList}
            // onKeyDown={handleKeyPress}
            renderInput={(params) => <CustomInput
                marginBottom={true}
                labelMarginTop={true}
                cursor='pointer'
                padding={{ xl: "0px 20px !important", xs: "0px 13px !important" }}
                handleChange={(e) => console.log()}
                resetError={() => setError({ ...error, frequency: '' })}
                placeholder={t('CONTACT0014')}
                height={{ xl: "47px", xs: "33px" }}
                required helperText={error.frequency}
                error={error.frequency}
                {...params}
                label={t('CONTACT0015')} />}
            onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                    setPayload({ ...payload, frequency: newValue.id });
                    setError({ ...error, frequency: '' });
                } else {
                    setPayload({
                        ...payload, frequency: "",
                    });
                    setError({ ...error, frequency: '' });
                }
            }}
        />
    );
    const StepToRepoduceField = (key) => (
        <CustomInput
            marginBottom={true}
            labelMarginTop={true}
            id="textfield-ticket-stepRepoduce"
            placeholder={t('CONTACT0016')}
            type={'text'}
            size={'small'}
            autoComplete='off'
            fullWidth
            onKeyDown={handleKeyPress}
            error={!CommonUtil.isEmptyString(error.stepToRepoduce)}
            resetError={() => setError({ ...error, stepToRepoduce: '' })}
            name='stepToRepoduce'
            label={t('CONTACT0017')}
            value={payload.stepToRepoduce}
            variant='outlined'
            handleChange={handleChange}
            helperText={error.stepToRepoduce}
            multiline
            rows={3}
            inputProps={{
                maxLength: 5000,
                sx: { padding: { xl: "7px 7px !important", xs: "8px 0 !important" } }
            }}
        />
    );

    const Attachment = (key) => (
        <Box id="file-ticket-attachmet" height={"100%"} >

            <Typography sx={{
                color: 'rgba(71, 75, 79, 0.8)',
                fontSize: "14px",
                lineHeight: "21px",
                marginBottom: "3px"
            }}>{t('CONTACT0018')}</Typography>

            <DropzoneComponent getExcelFile={(file) => getFile(file)}>
                <Box p={1}
                    sx={{
                        border: '2px dashed  #76B745',
                        borderRadius: { xl: '8px',  xs:"6px" },
                        backgroundColor: "rgba(54, 201, 109,0.1)",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: lg110 ? "138px" : { xl: "149px", lg: "149px", md: "138px" },
                    }}>
                    <UploadImage style={{ margin: '20px 0px 10px 0px' }} />
                    <Typography sx={{ fontSize: "14px", textAlign: 'center', wordBreak: "break-all" }}><span>{t("CONTACT0019")}</span><br /><span style={{ opacity: 0.5 }}>{t("CONTACT0020")}</span></Typography>
                </Box>
            </DropzoneComponent>
        </Box>
    );
    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
            >
                <DialogTitle>
                    <Box display='flex' justifyContent={'space-between'} paddingLeft={2}>
                        <Typography variant='title' className="tooltip-css">{t('CONTACT001')}</Typography>
                        <IconButton sx={{
                            marginRight: '9px !important'
                        }} disableRipple onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Typography paddingLeft={2}>{t('CONTACT003')}<a style={{ color: " #76B745" }} href='mailto:techsupport@zkteco.in' target="_blank" rel="noreferrer" >{' techsupport@zkteco.in '}</a>{t('CONTACT004')}<span style={{ color: " #76B745" }}>{' +91-9901436424 '}</span></Typography>
                    {payload.country && payload.country === 'Singapore' ? (
                        <Typography pl={2} pt={1}>
                            {t('CONTACT005')} <span style={{ color: "#76B745" }}>{' +65 6031 3250 '}</span>
                        </Typography>
                    ) : ''}

                </DialogTitle>
                <DialogContent>
                    <Box paddingLeft={.8} sx={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
                        <Grid container spacing={1} padding={"10px"}>
                            <Grid item md={12} xs={12} >
                                {CompanyNameField()}
                            </Grid>
                            <Grid item md={6} xs={12} >
                                {ContactPersonField()}
                            </Grid>
                            <Grid item md={6} xs={12} >
                                {FieldCountry()}
                            </Grid>
                            <Grid item md={6} xs={12} >
                                {EmailConfirmationField()}
                            </Grid>
                            <Grid item md={6} xs={12} >
                                {ContactNumberField()}
                            </Grid>
                            <Grid item md={12} xs={12} >
                                {SummaryField()}
                            </Grid>
                            <Grid item md={12} xs={12} >
                                {Description()}
                            </Grid>
                            <Grid item md={6} xs={12} >
                                {ImpactField()}
                            </Grid>
                            <Grid item md={6} xs={12} >
                                {FrequencyField()}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                {StepToRepoduceField()}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                {Attachment()}
                            </Grid>
                            {/* {!CommonUtil.isEmpty(attachmentID) && <Grid item md={12} xs={12}>
                  <Grid container display={'flex'}>
                    {
                      selectedImage && selectedImage.map((file) =>{
                       return <>
                        <Grid item lg={2.4} md={4} sm={12} xs={12} xl={2} marginBottom={1.5}>
                          {!videoFormate.includes(file.type)?(<ImageCard src={file.url} fileName={file.name}/>):(<VideoCard src={file.url} fileName={file.name}/>)}
                        </Grid>
                        </>
                      })}
                  </Grid>
                </Grid>} */}
                            {
                                !CommonUtil.isEmpty(attachmentID) &&
                                <Grid item md={12} xs={12} display={'flex'}>
                                    <Grid container display={'flex'} marginRight={'2px'}>
                                        {selectedImages &&
                                            selectedImages.map((file, index) => {
                                                return (
                                                    < React.Fragment key={index}>
                                                        <Grid item lg={2.4} md={2} sm={12} xs={12} xl={2} marginBottom={1.5} display={'flex'}>
                                                            {!videoFormate.includes(file.type) ? (
                                                                !fileFormat.includes(file.type) ? (
                                                                    <ImageCard loading={fileLoadingStates[index]} key={index} src={file.url} attachmentId={attachmentID} fileName={file.name} index={index} cancel={handleDelete}
                                                                    />
                                                                ) : file.type === "application/pdf" ? (
                                                                    <Pdfcard loading={fileLoadingStates[index]} key={index} src={file.url} attachmentId={attachmentID} index={index} cancel={handleDelete} fileName={file.name} />
                                                                ) : file.type === "application/zip" ? (
                                                                    <ZipFilescard loading={fileLoadingStates[index]} key={index} src={file.url} attachmentId={attachmentID} index={index} cancel={handleDelete} fileName={file.name} />
                                                                ) : file.type === "application/msword" || file.type === "application/xml" || file.type === "text/plain" ||
                                                                    file.type === "application/vnd.ms-powerpoint" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                                                    <WordFilecard loading={fileLoadingStates[index]} key={index} src={file.url} attachmentId={attachmentID} index={index} cancel={handleDelete} fileName={file.name} />
                                                                ) : (
                                                                    <ImageCard loading={fileLoadingStates[index]} key={index} src={file.url} attachmentId={attachmentID} index={index} cancel={handleDelete} fileName={file.name} />
                                                                )
                                                            ) : (
                                                                <VideoCard loading={fileLoadingStates[index]} key={index} src={file.url} attachmentId={attachmentID} index={index} cancel={handleDelete} fileName={file.name} />
                                                            )}
                                                        </Grid>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </Grid>
                                </Grid>
                            }
                        </Grid>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', width: { md: "45%", xs: "90%" }, justifyContent: { md: 'flex-end', xs: "center" }, margin: '12px 37px' }}>
                        <Button
                            id={"button-site-list-cancel"}
                            onClick={() => handleClose()}
                            variant='outlined'
                            sx={{
                                width: '50%',
                                height: { xl: '50px', xs: "45px" },
                                marginRight: '20px',
                            }}
                        >
                            {t('COMMON009')}
                        </Button>
                        <Button
                            id={"button-site-list-delete"}
                            variant='contained'
                            onClick={() => handleSubmit()}
                            sx={{
                                width: '50%',
                                height: { xl: '50px', xs: "45px" },
                            }}
                        >
                            {t('COMMON029')}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default HelpDesk