import React from 'react';
import { InputAdornment, Box, Tooltip, Zoom } from '@mui/material';
import CustomInput from './CustomInput'; // Adjust the import path as needed
import { Clear } from '@mui/icons-material';
import { ReactComponent as SearchIcon } from '../assets/SearchIcon.svg'
import { useTranslation } from 'react-i18next';

const SearchBar = (props) => {
    const { t } = useTranslation();
    return (
        <Box width={props.width ? props.width : "280px"}>
            <CustomInput
                id="textfield-time-slot-name"
                placeholder={props.placeholder ? props.placeholder : ""}
                value={props.searchValue}
                size='small'
                height={{ xl: "50px", xs: "40px" }}
                padding={{ xl: "0px 20px", xs: "0px 10px" }}
                fullWidth
                name='name'
                variant='outlined'
                handleChange={(e) => props.handleSearchChange(e)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Box display={"flex"}>
                            {props.searchValue ? (
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <Tooltip title={t("Clear")} TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                            <Clear
                                                style={{
                                                    cursor: 'pointer', fontWeight: 800, fontSize: '24px',
                                                    color: 'rgba(71, 75, 79, 0.8)', marginRight: '5px'
                                                }}
                                                onClick={props.clearValue}
                                            />
                                        </Tooltip>
                                    </Box>
                                ) : (
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <Tooltip title={t("COMMON050")} TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                            <SearchIcon />
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                        </InputAdornment>
                    )
                }}
                inputProps={{
                    maxLength: 30,
                }}

            />
        </Box>
    );
};

export default SearchBar;
