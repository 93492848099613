import api from "../configurations/http-common";

export const generatedReports = async (payload, callBack) => {
    try {
        const res = await api.securedAxios().get('/web/reportsList?pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize + buildQuery(payload.filter));
        callBack(res);
    } catch (error) { }
};

const buildQuery = (filter) => {
    let query = '';
    if (filter.status) {
        let encodedoperator = encodeURIComponent(filter.status);
        return query = query + `&status=${encodedoperator}`;
    }
    if (filter.type) {
        let encodedExecutingState = encodeURIComponent(filter.type);
        return query = query + `&type=${encodedExecutingState}`;
    }
    if (filter.objectKey) {
        let encodedExecutingState = encodeURIComponent(filter.objectKey);
        return query = query + `&object_Key=${encodedExecutingState}`;
    }
    return query;
}

export const downloadExcel = async (payload, callBack) => {
    try {
        const res = await api.securedAxios().post('/web/report/download?objectKey=' + payload.objectKey);
        callBack(res);
    } catch (error) {

    }
};