import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Typography, Zoom } from '@mui/material';

const OverFlowTypography = (props) => {
    const textElementRef = useRef();
    const [hoverStatus, setHover] = useState(false);

    const compareSize = () => {
        const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHover(compare);
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    });

    // remove resize listener again on "componentWillUnmount"
    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    });

    const TypographyStyle = {
        font: props.font ? props.font : { xl: "normal normal normal 16px/25px Roboto", xs: "normal normal normal 14px/21px Roboto" },
        letterSpacing: props.letterSpacing ? props.letterSpacing : "0.4px",
        textOverflow: "ellipsis", // Corrected typo
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "90%",
        color: "#474B4F",
        opacity: props.opacity ? props.opacity : 1,
        ...props.sx
    };

    return (
        <>
            <Tooltip
                title={props.value}
                interactive
                disableHoverListener={!hoverStatus}
            >
                <Typography ref={textElementRef} sx={TypographyStyle} >{props.value}</Typography>
            </Tooltip>
        </>
    );
};

export default OverFlowTypography;
