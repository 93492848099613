export const defaultPermissionCode = {

    'ACC_DASHBOARD': false,
    'VIEW_ACC_DASHBOARD': false,
    'VIEW_EVENT_OVERVIEW_GRAPH': false,
    'VIEW_SITE_OVERVIEW_MAP': false,
    'ACC_ORGANIZATION': false,
    'ACC_VIEW_PERSONLIST': false,
    'ACC_VIEW_PERMISSION_LIST': false,
    'ACC_VIEW_PERMISSIONS': false,
    'ACC_VIEW_PERSON_PROFILE': false,
    'SITE_MANAGMENT_DETAILS': false,
    'ACC_VIEW_SITE_LISTS': false,
    'ACC_VIEW_SITE_DETAILS': false,
    'ACC_VIEW_ZONE_LIST': false,
    'ACC_VIEW_ZONE': false,
    'VIEW_DEVICE_LIST_DETAILS': false,
    'ACC_VIEW_DEVICE_TOPOLOGY': false,
    'EDIT_DEVICE_LIST_DETAILS': false,
    'ACC_DEVICE_DELETE': false,
    'ACC_REBOOT_DEVICE': false,
    'ACC_DOOR_LIST': false,
    'ACC_VIEW_DOOR': false,
    'ACC_EDIT_DOOR': false,
    'ACC_READER_LIST': false,
    'ACC_READER_VIEW': false,
    'ACC_READER_EDIT': false,
    'ACC_AUXILIARY_LIST': false,
    'ACC_AUXILIARY_VIEW': false,
    'ACC_AUXILIARY_EDIT': false,
    'ACC_AUXILIARY__OUTPUT_LIST': false,
    'ACC_AUXILIARY__OUTPUT_VIEW': false,
    'ACC_AUXILIARY_OUTPUT_EDIT': false,
    'ACCESS_CONTROL': false,
    'TIMESLOT_LIST': false,
    'VIEW_TIMESLOT': false,
    'ADD_TIMESLOT': false,
    'VIEW_DOOR_ACCESS_TIME_LIST': false,
    'ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN': false,
    'EDIT_ACCESS_GROUP': false,
    'MANAGE_ACCESS_GROUP': false,
    'MANAGE_ACCESS_GROUP_LIST': false,
    'ADD_EDIT_ACCESS_GROUP': false,
    'DELETE_ACCESS_GROUP': false,
    'MANAGE_DOOR_ACCESS_GROUP': false,
    'ADD_DOOR_ACCESS_GROUP': false,
    'MANAGE_PERSONS_ACCESS_GROUP': false,
    'ADD_PERSONS_ACCESS_GROUP': false,
    'ACC_REPORT': false,
    'VIEW_ACC_REPORT': false,
    'EXPORT_ACC_REPORT': false,
    'ACC_LOGS': false,
    'OPERATOR_LOGS': false,
};

export const parentSite = [
    'SITE_MANAGMENT_DETAILS',
    'ACC_VIEW_SITE_LISTS',
    'ACC_VIEW_SITE_DETAILS',
    'ACC_VIEW_ZONE_LIST',
    'ACC_VIEW_ZONE'
];

export const parentOrg = [
    // 'ACC_ORGANIZATION',
    'ACC_VIEW_PERSONLIST',
    'ACC_VIEW_PERMISSION_LIST',
    'ACC_VIEW_PERMISSIONS',
    'ACC_VIEW_PERSON_PROFILE',
];

export const parentDevice = [
    // "DEVICE_MANAGEMENT",
    'VIEW_DEVICE_LIST_DETAILS',
    'ACC_VIEW_DEVICE_TOPOLOGY',
    'EDIT_DEVICE_LIST_DETAILS',
    'ACC_DEVICE_DELETE',
    'ACC_REBOOT_DEVICE',
    'ACC_DOOR_LIST',
    // 'ACC_VIEW_DOOR',
    'ACC_EDIT_DOOR',
    'ACC_READER_LIST',
    'ACC_READER_VIEW',
    'ACC_READER_EDIT',
    'ACC_AUXILIARY_LIST',
    'ACC_AUXILIARY_VIEW',
    'ACC_AUXILIARY_EDIT',
    'ACC_AUXILIARY__OUTPUT_LIST',
    'ACC_AUXILIARY__OUTPUT_VIEW',
    'ACC_AUXILIARY_OUTPUT_EDIT'
];

export const parentAccess = [
    // 'ACCESS_CONTROL',
    'TIMESLOT_LIST',
    'VIEW_TIMESLOT',
    'ADD_TIMESLOT',
    'VIEW_DOOR_ACCESS_TIME_LIST',
    'ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN',
    'EDIT_ACCESS_GROUP',
    'MANAGE_ACCESS_GROUP',
    'MANAGE_ACCESS_GROUP_LIST',
    'ADD_EDIT_ACCESS_GROUP',
    'DELETE_ACCESS_GROUP',
    'MANAGE_DOOR_ACCESS_GROUP',
    'ADD_DOOR_ACCESS_GROUP',
    'MANAGE_PERSONS_ACCESS_GROUP',
    'ADD_PERSONS_ACCESS_GROUP'
];


export const parentdashboard = [
    // "ACC_DASHBOARD",
    "VIEW_ACC_DASHBOARD",
    "VIEW_EVENT_OVERVIEW_GRAPH",
    "VIEW_SITE_OVERVIEW_MAP",
]