import { Box, Typography } from "@mui/material"
import { memo } from "react";
import { ReactComponent as InformationICon } from '../../assets/ic_dangerous.svg';

const ContainerField=memo((props)=>{
  const inforStyle={
    textAlign: 'left',
     font: {xl:'normal normal normal 12px/23px Roboto',
      xs:"normal normal normal 12px/18px Roboto",
     },
     letterSpacing: '0px',
     color:' #474B4F',
      opacity: 0.8,
  }
    return(
        <Box display={'flex'} key={props.key}>
            <Box padding={"10px 0px"} minWidth={{ xl: '34.5%', lg: '35%', md: '100%', sm: '100%', xs: '100%' }}>
              <Typography variant='inputFieldLabel' paddingBottom={1} >{props.label}</Typography>
              {props.field}
            </Box>
            <Box
              sx={{
                display: { xl: 'flex', lg: 'flex', md: 'none', sm: 'none', xs: 'none' },
                alignItems: 'center',
                paddingLeft: '15px',
                paddingTop: '20px'
              }}>
              <Box marginRight={1}>
                <InformationICon />
              </Box>
              <Typography sx={inforStyle} textAlign="justify">{props.informationMessage}</Typography>
            </Box>
          </Box>
    )
})

export default ContainerField