import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'
import { ReactComponent as NoResultFoundPIC } from '../assets/noResultFound.svg';

export const NoResultFound = (props) => {
  return (
    <>
     <Paper  elevation={0} style={{ minHeight:'100%',backgroundColor: '#ffffff', borderRadius: '8px', paddingTop: '16px', paddingBottom: '16px', overflow: 'hidden' }}>
       <Box display={'flex'}justifyContent='center'>
         <NoResultFoundPIC height={50}/>
        </Box>  
       <Typography textAlign={'center'}>
       {props.message?props.message: 'No Result Found'}
        </Typography>
     </Paper>
    </>
  )
}