import api from '../configurations/http-common';
import CommonUtil from '../util/CommonUtils';

export const filterDoors = async (page, rowsPerPage, filter) => {
  let query = '';
  if (!CommonUtil.isEmptyString(filter.siteId)) {
    query = '&siteId=' + filter.siteId
  }
  if (!CommonUtil.isEmptyString(filter.deviceName)) {
    query = '&deviceName=' + filter.deviceName
  }
  if (!CommonUtil.isEmptyString(filter.deviceSn)) {
    query = '&deviceSn=' + filter.deviceSn
  }

  return api.securedAxios().post('/web/door?page=' + page + '&size=' + rowsPerPage + query);
};



export const getAllDoor = async (payload, callBack) => {
  try {
    const res = await api.securedAxios().post('/web/door/filter?page=' + payload.pageNumber + '&size=' + payload.pageSize + buildQuery(payload.filter));
    callBack(res);
  } catch (error) { }
};

export const getAllDoorWithoutLoader = async (payload, callBack) => {
  try {
    const res = await api.securedAxiosWithoutLoader().post('/web/door/filter?page=' + payload.pageNumber + '&size=' + payload.pageSize + buildQuery(payload.filter));
    callBack(res);
  } catch (error) { }
};

const buildQuery = (filter) => {
  let query = '';
  if (filter.deviceSn) {
    let encodedSN = encodeURIComponent(filter.deviceSn);
    return query = query + `&deviceSn=${encodedSN}`;
  }
  if (filter.zoneId) {
    let zoneIdSn = encodeURIComponent(filter.zoneId);
    return query = query + `&zoneId=${zoneIdSn}`;
  }
  return query;
}

export const doorOperation = async (deviceId,doorName, payload) => {
  return api.securedAxios().post('/web/door/' + deviceId + '/control?doorName='+doorName, payload);
};


export const filterDoorsFetch = async (page, rowsPerPage, filter) => {
  let query = '';

  if (!CommonUtil.isEmptyString(filter.siteId)) {
    query = '&siteId=' + filter.siteId;
  } else {
    query = '';
  }
  return api.securedAxios().post('/web/door?page=' + page + '&size=' + rowsPerPage + query);
};

export const getDeviceVerificationMode = async (devId, callBack) => {
  try {
    const res = await api.securedAxios().get('/web/device/verification-mode?deviceId=' + devId);
    callBack(res);
  } catch (error) { }
};

export const updateDoor = async (doorId, payload, callBack) => {
  try {
    const res = await api.securedAxios().put('/web/door/' + doorId, payload);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};

export const updateDoorTimeSlot = async (doorId,timeSlotName, payload, callBack) => {
  try {
    const res = await api.securedAxios().put('/web/door/time-slot/' + doorId+'?timeSlotName='+timeSlotName, payload);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};


export const updateDoorAccessTime = async (doorId, payload) => {
  return api.securedAxios().put('/web/door/' + doorId, payload);
};

export const filterDoorAccessTime = async (page, rowsPerPage, filter) => {
  let query = '';

  if (!CommonUtil.isEmptyString(filter.deviceSn)) {
    query = '&deviceSn=' + filter.deviceSn;
  }
  return api.securedAxios().post('/web/door/filter?page=' + page + '&size=' + rowsPerPage + query);
};

export const getDoorById = async (id) => {
  try {
    const res = api.securedAxios().get('/web/door/' + id)
    return res
  }
  catch (error) {}
}

export const filterDoorsAccessGroup = async (page, rowsPerPage, filter) => {
  let query = '';
  if (!CommonUtil.isEmptyString(filter.siteId)) {
    query = '&siteId=' + filter.siteId
  }
  if (!CommonUtil.isEmptyString(filter.deviceName)) {
    query = '&deviceAlias=' + encodeURIComponent(filter.deviceName);
  }
  if (!CommonUtil.isEmptyString(filter.deviceSn)) {
    query = '&deviceSn=' + encodeURIComponent(filter.deviceSn);
  }

  return api.securedAxios().post('/web/door/filter?page=' + page + '&size=' + rowsPerPage + query);
};

export const doorenableOrDisableOperation= async (deviceId,payload) => {
  return api.securedAxios().post('/web/door/enableOrDisable/'+ deviceId, payload);
};