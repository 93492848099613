import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import PageNotFound from '../../components/PageNotFound';
import RightViewLayout from '../../components/RighViewLayout';
import DataTable from '../../components/layout-components/DataTable';
import api from '../../configurations/http-common';
import { REACT_URL_ACCESS_GROUP_DOOR } from '../../constants/ReactEndPoints';
import { addDoorsToAccessGroup, getFilterAccessGroup } from '../../services/AccessGroupService';
import { filterDoorsAccessGroup } from '../../services/DoorService';
import { getPermissionsByRoleId } from '../../services/RoleService';
import CommonUtil from '../../util/CommonUtils';
import PaginationContext from '../../components/layout-components/PaginationContext';

export default function AddAccessGroupDoor() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const location = useLocation();
  const { manageAGAddDoorPageNumber, setManageAGAddDoorPageNumber, manageAGAddDoorPageSize, setManageAGAddDoorPageSize } = useContext(PaginationContext);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [Filtered, setFiltered] = React.useState(false);
  const [selectedRecords, onSelectionChange] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [viewErrorPage, setViewErrorPage] = useState(false);

  const [filter, setFilter] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  const [disabled, setDisabled] = React.useState(true);
  const [change, setChange] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [clearAndRefresh, setClearAndRefresh] = React.useState(false);
  const [accessId, setAccessId] = useState("");

  const [defaultFilter, setDefaultFilter] = React.useState({
    deviceName: '',
    deviceSn: '',
    siteId: ''
  });

  const [filterData, setFilterData] = React.useState({
    ...defaultFilter
  });

  const DEFAULT_PAGE_SIZE = 10;

  const resetFilter = () => {
    if (page === 1) {
      loadData(1, manageAGAddDoorPageSize, filterData);
      setRowsPerPage(manageAGAddDoorPageSize);
    } else {
      setPage(filter ? 1 : manageAGAddDoorPageNumber);
      loadData(filter ? 1 : manageAGAddDoorPageNumber, manageAGAddDoorPageSize, filterData);
      setRowsPerPage(manageAGAddDoorPageSize);
    }

  };

  const [addDoorAccessGroupList, setAddDoorAccessGroupList] = React.useState(true);

  useEffect(() => {
    if (accessId !== "")
      getPermissionsByRoleId(api.decoded().roleId).then((res) => {
        var l = [];
        res.data.data.rolePermissions.map((item, index) => {
          l.push(item.code);
        });
        setAddDoorAccessGroupList(l.includes("ADD_DOOR_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        if (l.includes("ADD_DOOR_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
          loadData(page, rowsPerPage, filterData);
        }
      });
  }, [accessId]);

  useEffect(() => {
    loadAccessGroup();
  }, []);

  const loadAccessGroup = (page, size) => {
    const filter = {
      id: id,
    }
    getFilterAccessGroup(1, 1, filter).then((res) => {
      if (
        res?.status === 200 &
        res != null &&
        !CommonUtil.isEmpty(res) &&
        res?.data?.code === 'DMSI0000' &&
        !CommonUtil.isEmpty(res?.data?.data) &&
        res.data.data.accessGroups instanceof Array
      ) {
        setAccessId(res.data.data.accessGroups[0].id);
      } else {
        setViewErrorPage(true)
      }
    });
  };

  const loadData = (pageNumber, pageSize, filter) => {
    onSelectionChange([]);
    filterDoorsAccessGroup(pageNumber, pageSize, filter).then((res) => {
      if (res?.data?.code === 'DMSI0000') {
        setTotalRecords(res.data.data.totalCount);
        setData(res.data.data.doors);
      } else {
        setTotalRecords(0);
        setData([]);
      }
    });
  }

  const handleAddDoorsToAccessGroup = (selectedRecords) => {
    if (selectedRecords.length < 1) {
      enqueueSnackbar(t('AccGroupAccessTime046'), {
        variant: 'error',
      });
      return;
    } else {
      var doors = [];
      selectedRecords.map((door) => {
        data.map((doorData) => {
          if (door === doorData.doorId) {
            doors.push({ doorId: door, doorName: doorData.doorParameters.name });
          }
        })
      });
      addDoorsToAccessGroup(accessId, doors).then((res) => {
        if (res?.data?.code === 'DMSI0010') {
          enqueueSnackbar(t('AccGroupAccessTime031'), {
            variant: 'success',
          });
          navigate(REACT_URL_ACCESS_GROUP_DOOR + `/${id}`);
        } else{
          navigate(REACT_URL_ACCESS_GROUP_DOOR + `/${id}`);
        }
      });
    }
  };

  const columns = [
    {
      field: "doorParameters.name",
      headerName: t('AccDMTopology011'),
      minWidth: 140,
      flex: 1,
      valueGetter: (params) => params.row.doorParameters.name
    },
    {
      field: "deviceName",
      minWidth: 140,
      headerName: t('AccDevice014'),
      flex: 1,
    },
    {
      field: "deviceSn",
      minWidth: 140,
      headerName: t('AccDevice013'),
      flex: 1,
    },
    {
      field: "doorParameters.doorNo",
      minWidth: 140,
      headerName: t('AccDoor002'),
      flex: 1,
      valueGetter: (params) => params.row.doorParameters.doorNo
    },
    {
      field: "doorParameters.enabled",
      minWidth: 140,
      headerName: t('AccGroupAccessTime032'),
      flex: 1,
      renderCell: (params) => params.row.doorParameters.enabled === 'true' ? <CheckIcon style={{ color: 'green' }} className='refresh-btn' /> : <ClearIcon style={{ color: 'red' }} className='refresh-btn' />
    },
    {
      field: "doorParameters.verifyMode",
      minWidth: 140,
      headerName: t('AccDoor005'),
      flex: 1,
      valueGetter: (params) => params.row.doorParameters.verifyModeName
    }
  ]

  const actions = () => (
    <Box display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }}>
      <Tooltip title={t('COMMON002')}>
        <Box
          aria-label="refresh"
          onClick={() => resetFilter()}
          mr={2}
          sx={{ display: { xl: "block", xs: "none" } }}
        >
          <RefreshIcon style={{ cursor: 'pointer' }} />
        </Box>
        <Box
          aria-label="refresh"
          onClick={() => resetFilter()}
          mr={2}
          sx={{ display: { xl: "none", xs: "block" } }}
        >
          <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
        </Box>
      </Tooltip>
      <Tooltip title={t('COMMON003')}>
        <Box
          aria-label="filter"
          onClick={() => setOpen(!open)}
          sx={{ display: { xl: "block", xs: "none" } }}
        >
          <FilterIcon style={{ cursor: 'pointer' }} />
        </Box>
        <Box
          aria-label="refresh"
          onClick={() => setOpen(!open)}
          sx={{ display: { xl: "none", xs: "block" } }}
        >
          <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
        </Box>
      </Tooltip>
    </Box>
  );

  const FieldDeviceName = (key) => (
    <CustomInput
      placeholder={t('AccDevice014')}
      value={filterData.deviceName}
      size='small'
      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      onKeyDown={handleKeyPress}
      fullWidth
      name='deviceName'
      variant='outlined'
      handleChange={handleChange}
      inputProps={{
        maxLength: 30,
      }}
    />
  );

  const FieldSerialNumber = (key) => (
    <CustomInput
      placeholder={t('AccDevice013')}
      value={filterData.deviceSn}
      size='small'
      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      onKeyDown={handleKeyPress}
      fullWidth
      name='deviceSn'
      variant='outlined'
      handleChange={handleChange}
      inputProps={{
        maxLength: 30,
      }}

    />
  );

  const handleSubmitFilter = () => {
    setFilter(true);
    setLoad(false);
    setReload(true);
    loadData(1, rowsPerPage, filterData);
  }

  const clearFilter = () => {
    setFilterData(defaultFilter);
    setClearAndRefresh(true)
    setDisabled(true);
    setFilter(false);
    setLoad(true);
    if (page === 1) {
      loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
    else setPage(1);
    setRowsPerPage(DEFAULT_PAGE_SIZE);
  }

  useEffect(() => {
    if (filterData.deviceName === '' && filterData.deviceSn === '') {
      setDisabled(true);
      if (change) {
        setFilter(false); setLoad(true);
        if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
        else {
          if (!clearAndRefresh)
            setPage(1);
        }
      }
    } else setDisabled(false);
  }, [filterData]);

  const handleChange = (event) => {
    setChange(true);
    const name = event.target.name;
    setFilterData({
      ...filterData,
      [name]: event.target.value
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmitFilter();
    }
  };

  const handleEnterkeyPress = React.useCallback((event) => {
    if (event.key === "Enter" && selectedRecords.length > 0) {
      handleAddDoorsToAccessGroup(selectedRecords)
    }
  }, [selectedRecords])

  useEffect(() => {
    window.addEventListener("keydown", handleEnterkeyPress)
    return () => {
      window.removeEventListener("keydown", handleEnterkeyPress)
    }
  },[]);

  const onSelectChecxBoxChange = (id) => {
    onSelectionChange(id);
  }
  useEffect(() => {
    if (!open && !disabled) {
      setPage(1);
      setChange(false);
      setFilterData(defaultFilter);
      loadData(1, rowsPerPage, defaultFilter);
    }
  }, [open]);

  return (
    <>
      {viewErrorPage ? <PageNotFound /> :
        addDoorAccessGroupList ? (<><RightViewLayout title={t('AccGroupAccessTime029')}
        >
          {actions()}
        </RightViewLayout>
          <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
            {open && <FilterComponent
              firstField={FieldDeviceName()}
              secondField={FieldSerialNumber()}
              clearFilter={clearFilter}
              handleSubmit={handleSubmitFilter}
              disabled={disabled}
            />}
            <DataTable
              page={page - 1}
              totalRecords={totalRecords}
              rowsPerPage={rowsPerPage}
              getRowId={(row) => row.doorId}
              columns={columns}
              rows={data}
              checkboxSelection={true}
              onSelection={(e) => { onSelectChecxBoxChange(e); }}
              rowSelectionModel={selectedRecords}
              count={totalRecords}
              onPageChange={(e) => {
                const CurrentPage = CommonUtil.getPageNumber(e);
                setPage(CurrentPage);
                setManageAGAddDoorPageNumber(CurrentPage);
                if (Filtered) {
                  loadData(CurrentPage, rowsPerPage, filterData);
                } else {
                  loadData(CurrentPage, rowsPerPage, filterData);
                }
              }}
              onPageSizeChange={(e) => {
                // setFilterData({ ...defaultFilter });
                setRowsPerPage(e);
                setManageAGAddDoorPageSize(e);
                setPage(1);
                loadData(1, e, filterData);
              }}
              noRecordText={t("AccGroupAccessTime030")} />
            <Grid
              sx={{
                display: "flex",
                justifyContent: { xs: 'center', md: 'flex-end' },
                mt: { xl: '30px', xs: '15px' }
              }}
              display={'flex'}
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              paddingTop={'0px'}>
              <Button
                id="button-accessgroup-person-add"
                variant='contained'
                onClick={() => handleAddDoorsToAccessGroup(selectedRecords)}
                sx={{
                  width: { xl: "233px", xs: "180px" },
                  height: { xl: "64px", xs: "50px" },
                  marginRight: "30px"
                }}
              >
                {t("COMMON018")}
              </Button>
              <Button
                id="button-accessgroup-person-clear"
                onClick={() => resetFilter()}
                variant='outlined'
                sx={{
                  width: { xl: "233px", xs: "180px" },
                  height: { xl: "64px", xs: "50px" },
                }}
              >
                {t("COMMON015")}
              </Button>
            </Grid>

          </Box>
        </>) : (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t("AccGroupAccessTime041")}</Typography>
        </Box>)}
    </>
  );
}
