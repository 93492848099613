export const REACT_DASHBORD = '/dashboard';
export const REACT_USERS = '/users';
export const REACT_URL_PERSON = '/person';
export const REACT_TIME_SLOT = '/time-slots';
export const REACT_CREATE_TIMESLOT = '/create-time-slot';
export const REACT_UPDATE_TIMESLOT='/update-time-slot';
export const REACT_GROUP_ACCESS_TIME ='/group-access-time';
export const REACT_ADD_GROUP_ACCESS_TIME ='/create-group-access-time';
export const REACT_EDIT_GROUP_ACCESS_TIME ='/update-group-access-time';
export const REACT_URL_ACCESS_GROUP_V4 = '/acc/accessgroups';
export const REACT_URL_ACCESS_GROUP_FILTER_V4 = '/web/access_groups_filter';
export const REACT_URL_PERSON_VIEW = '/personview';
export const REACT_URL_SITE_VIEW = '/siteview';
export const REACT_URL_ZONE_VIEW = '/zoneview';
export const REACT_URL_SITE = '/site';
export const REACT_URL_ZONE = '/zone';
export const REACT_URL_READER = '/reader';
export const REACT_URL_AUXILIARY_INPUT = '/auxiliaryinput';
export const REACT_URL_AUXILIARY_OUTPUT = '/auxiliaryoutput';
export const REACT_URL_EVENT = '/events';
export const REACT_URL_ACCESS_GROUP_PERSON = '/access-group-person';
export const REACT_URL_ACCESS_GROUP_ADD_PERSON = '/access-group-add-person';
export const REACT_URL_ACCESS_GROUP_DOOR = '/access-group-door';
export const REACT_URL_ACCESS_GROUP_ADD_DOOR='/access-group-add-door';
export const REACT_URL_USERROLES = '/roles';
export const REACT_URL_UPDATEROLES='/update_role';
export const REACT_URL_VIEWEROLES='/view_role';
//Device
export const REACT_URL_DEVICE_LIST_V4="/device-list";
export const REACT_URL_ADD_DEVICE_INSTRUCTION="/device-instruction";
export const REACT_URL_BIND_DEVICE="/device-bind";
export const REACT_URL_DEVICE_TOPOLOGY="/device-topology";
export const REACT_URL_UPDATE_DEVICE="/update-device";
export const REACT_URL_PERSON_IN_DEVICE="/person-in-device";

//Door
export const REACT_URL_DOOR_LIST='/door-list';
export const REACT_URL_EDIT_DOOR='/door-edit';

//Door access time DoorAccessTime
export const REACT_URL_DOOR_ACCESS_TIME='/door-access-time';

export const REACT_URL_READER_VIEW = '/readerview';
export const REACT_URL_AUXILIARY_INPUT_VIEW = '/auxiliary-input-view';
export const REACT_URL_AUXILIARY_OUTPUT_VIEW = '/axiliary-output-view';
export const REACT_URL_READER_EDIT = '/reader-edit';
export const REACT_URL_AUXILIARY_INPUT_EDIT = '/auxiliary-input-edit';
export const REACT_URL_AUXILIARY_OUTPUT_EDIT = '/axiliary-output-edit';

//Operation-Log
export const REACT_URL_OPERATION_LOG_LIST= '/operation-log';

export const REACT_URL_GENERATED_REPORTS= '/generated-reports';

//Session expired
export const REACT_URL_SESSION_EXPIRED= '/access/error';

//page not found 
export const REACT_URL_PAGE_NOT_FOUND="/error/page_not_found";

export const REACT_URL_QUICK_SETUP="/access-control/quick-setup"

export const  REACT_URL_QUICK_SETUP_PAGE="/access-control/commonpage"
