import React from "react"
import { Box, Button, Grid, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"

export const EventsFilterComponents = (props) => {
    const { t } = useTranslation()
    return (
        <Paper elevation={0}
            style={{
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                border: '1px solid #DDDDDD',
                padding: '20px',
                margin: '1rem 0rem  1rem 0rem ',
                display: 'flex',
                alignItems: 'center',
                width: '100%', 
            }}
        >
                <Grid container justifyContent={'space-between'} alignContent={'space-between'} sx={{ flexDirection: { xl: "row", lg: "row", xs: "column" } }}>
                    <Grid item xs={2.9} sm={2.9}  >
                        {props.firstField}
                    </Grid>
                    <Grid item xs={2.9} sm={2.9}>
                        {props.secondField}
                    </Grid>
                    <Grid item xs={2.9} sm={2.9} >
                        {props.thirdField}
                    </Grid>
                    <Grid item xs={2.9} sm={2.9} >
                        {props.fourthField}
                    </Grid>
                    <Grid item xs={2.9} sm={2.9} mt={1}>
                        {props.fifthField}
                    </Grid>

                    <Grid item mt={1}  sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button fullWidth variant='contained'
                            disabled={props.disabled}
                            id={"button-page-filter-apply"}
                            onClick={() => props.handleSubmit()} sx={{ marginRight: '11px', textTransform: 'none', width: '100px', height: '40px' }}>
                            {t('COMMON014')}
                        </Button>
                        <Button fullWidth variant='outlined' sx={{ textTransform: 'none', width: '100px', height: '40px' }}
                            disabled={props.disabled}
                            id={"button-page-filter-clear"}
                            onClick={() => props.clearFilter()}>
                            {t('COMMON015')}
                        </Button>
                    </Grid>
                </Grid>
        </Paper>
    )
}