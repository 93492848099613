import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { useSnackbar } from 'notistack';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { updateDeviceRole } from '../../services/DeviceService';


const SelectUserRole = (props) => {
  const [person, setPerson] = useState({ role: '0' });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPerson({
      ...props.value,
      role: props.value?.role ? props.value.role : '0',
    });
   
    if (props.value.role === undefined || props.value.role === "") {
      setUserRole(props.value);
    }

  }, [props.value, props.device]);

  const setUserRole = (data) => {
    let count = 0;
    if (data.credentialCount.fingerPrint != 0) {
      count = 1;
    }
    if (data.credentialCount.visibleLightFace != 0) {
      count = 1;
    }
    if (data.credentialCount.palmPrint != 0) {
      count = 1;
    }
    if (data.credentialCount.fingerVein != 0) {
      count = 1;
    }
    if (data.credentialCount.palm != 0) {
      count = 1;
    }
    if (data.credentialCount.card != 0) {
      count = 1;
    }
    if (data.credentialCount.passcode != 0) {
      count = 1;
    }
    if (count === 1) {
      updateDeviceRole(props.devId, props.employeeId, "COMMONUSER", (data) => {
        if (data?.data?.code === 'CMSI0005') {
          setPerson({
            ...person,
            role: "COMMONUSER",
          });
        }
      });
    }
  }

  const handleChangeRole = (role) => {
  
    updateDeviceRole(props.devId, props.employeeId, role, (data) => {
      if (data?.data?.code === 'CMSI0005') {
        enqueueSnackbar("Role updated successfully", {
          variant: 'success',
        });
        setPerson({
          ...person,
          role: role,
        });
      } else {
        if (data?.data?.code === "CMSE0020") {
          enqueueSnackbar("Please Register a Credential to change User Role", {
            variant: 'error',
          });
        }
        else
          enqueueSnackbar(data.data.message, {
            variant: 'error',
          });
      }
    });
  };

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <FormControl fullWidth>
          <Select sx={{ height: { xl: '40px', xs: "30px" } }}
            labelId='demo-simple-select-label'
            value={person?.role}
            defaultValue={person?.role}
            onChange={(e) => handleChangeRole(e.target.value)}
          >
            {person.role === '0' && <MenuItem value={'0'}>Select User role</MenuItem>}
            <MenuItem value={'ADMINSTRATOR'}>Super admin</MenuItem>
            {/* <MenuItem value={'REGISTRAR'}>Enroller</MenuItem> */}
            <MenuItem value={'COMMONUSER'}>Normal user</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  )
}

export default SelectUserRole
