import { Box, Grid, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff !important',
      color: '#474B4F !important',
      maxWidth: 220,
      fontSize: '10px',
      border: '1px solid green !important',
    },
  }))(Tooltip);

const DoorInfoPage = (props) => {
    const {t} = useTranslation()

    const fieldName={
      textAlign: 'left',
      letterSpacing: '0.8px',
      color: 'rgba(71, 75, 79, 0.5)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight:  "normal",
      fontSize: {xl:"16px",xs:"14px"},
      lineHeight:{xl:"19px",xs:"17px"},
      fontFamily: "Roboto"
    }
  
    const fieldValue={
      textAlign: 'left',
      letterSpacing: '0.8px',
      color: 'rgba(71, 75, 79, 1)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight:  "normal",
      fontSize: {xl:"16px",xs:"14px"},
      lineHeight:{xl:"19px",xs:"17px"},
      fontFamily: "Roboto",
      overflow: 'hidden',
      textOverflow: "ellipsis",
      whiteSpace: 'nowrap',
    }
  return (
    <>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t("AccDMTopology010")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}>  {props.state.doorParameters.doorNo}</Typography>
              </Box>
            </Grid>
          <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t("AccDMTopology011")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}>  {props.state.doorParameters.name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t("AccDMTopology012")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}> {props.state.doorParameters.lockDelay}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                {t('AccDMTopology013')}
                  <HtmlTooltip arrow title={<>
                  {t('AccDMTopology014')}<br /><br />
                  {t('AccDMTopology015')}<br /><br />
                  {t('AccDMTopology016')}</>}>
                    <QuestionMarkIcon style={{ height: 16, width: 16 }} />
                  </HtmlTooltip>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={fieldValue}>{props.state.doorParameters.doorSensorStatus == '0' && 'None'}
                  {props.state.doorParameters.doorSensorStatus == '1' && 'Normal Open'}
                  {props.state.doorParameters.doorSensorStatus == '2' && 'Normal Close'}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology017')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={fieldValue}>{props.state.doorParameters.sensorDelay}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology018')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={fieldValue}> {props.state.doorParameters.delayOpenTime}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology019')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={fieldValue}> {props.state.doorParameters.forcePwd}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6} mb={3}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology020')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} mb={3}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={fieldValue}> {props.state.doorParameters.supperPwd}</Typography>
              </Box>
            </Grid>
          </Grid>
      
    </>
  )
}

export default DoorInfoPage
