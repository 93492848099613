import { Box, Button, Checkbox, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TitleBar from '../../components/TitleBar'
import { ReactComponent as Info1 } from '../../assets/DeviceIcon/Info1.svg';
import { ReactComponent as Info2 } from '../../assets/DeviceIcon/Info2.svg';
import { ReactComponent as Info3 } from '../../assets/DeviceIcon/Info3.svg'
import { useNavigate } from 'react-router';
import { REACT_URL_BIND_DEVICE } from '../../constants/ReactEndPoints';
import { useSnackbar } from 'notistack';
import RightViewLayout from '../../components/RighViewLayout';
import { useTranslation } from 'react-i18next';


import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import Loader from '../../components/Loader';
import CommonUtil from '../../util/CommonUtils';
import OverFlowTypography from '../../components/OverFlowTypography';

const DeviceInstructionPage = (props) => {
  const { t } = useTranslation()
  const [read, setRead] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = React.useState(false);
  const [permissions, setPermissions] = useState([]);
  const [perAddDevice, setAddDevice] = useState(false);

  const handleSubmit = () => {
    if (read)
      navigate(REACT_URL_BIND_DEVICE, { state: read });
    else {
      enqueueSnackbar(t("AccDevice023"), {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    setLoader(true);
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setPermissions(prev => [...prev, item.code])
        });
      }
      setLoader(false);
      setAddDevice(true);
    });
  }, []);

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  return (
    <>
      {perAddDevice ? checkPermission("EDIT_DEVICE_LIST_DETAILS") ? <><RightViewLayout
        navigateBack={false}
        title={t("AccDevice024")}
        actions={props.actions}
      />
        <Box marginLeft={-3}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={headerStyle} >{t("AccDevice025")}</Typography>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <DetailBox
                title={t("AccDevice026")}
                information={t('AccDevice027')}
                icon={<Info1 />}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <DetailBox
                title={t("AccDevice028")}
                information={t('AccDevice029')}
                icon={<Info2 />}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}

            >
              <DetailBox
                title={t("AccDevice030")}
                information={t('AccDevice031')}
                icon={<Info3 />}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box display={'flex'} marginLeft={'-2.1px'}>
                <Checkbox onChange={(e) => setRead(e.target.checked)} onKeyDown={handleSubmit}
                  sx={{
                    "&.MuiCheckbox-root": {
                      padding: "0px 14px 0px 0px !important"
                    }
                  }}
                />
                <Typography
                  style={{ fontWeight: 450, color: '#474B4F', marginLeft: '-10.6px', fontSize: '15px', opacity: '80%', alignSelf: 'center' }}>
                  {t('AccDevice032')}</Typography>
              </Box>
              <Button
                id="button-deviceinstruction-continue"
                fullWidth
                sx={{
                  width: { xl: "200px", xs: "180px" },
                  height: { xl: "64px", xs: "50px" },
                  fontSize: "16px"
                }}
                variant='contained'
                onClick={() => handleSubmit()}>
                {t('COMMON016')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </> : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={'center'}>{t('AccDevice055')} </Typography></Box>) : <></>
      }
      {loader ? <Loader open={loader} /> : <></>}
    </>
  )
}

const headerStyle = {
  textAlign: 'left',
  letterSpacing: '0px',
  color: 'rgba(71, 75, 79, 0.8)',
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  fontFamily: "Roboto",
}

const DetailBox = (props) => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      padding: { xl: "30px", xs: "30px" },
      border: '1px solid #DDDDDD',
      borderRadius: '8px',
      height: "339px",
      width: "100%"

    }}>
      <OverFlowTypography
        value={props.title}
        font={{ xl: "normal normal 450 18px/25px Roboto", xs: "normal normal 450 16px/23px Roboto" }}
        sx={{cursor:"default"}}
      />
      <Box
        sx={{
          minHeight: "120px"
        }}
      >
        <Typography
          sx={{
            marginTop: { xl: '20px', xs: "12px" },
            lineHeight: "25px",
            fontWeight: 420,
            color: '#474B4F',
            opacity: '50%'
          }}>{props.information}</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: { xl: '15px', xs: "10px" },
        }}
      >
        {props.icon}
      </Box>
    </Box>
  )
}

export default DeviceInstructionPage
