import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../assets/arrowV2.svg";

const TitleBar = (props) => {
  let navigate = useNavigate();
  return (
    <>
      <Box
        id="title-bar"
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          height: { xl: "50px", xs: "40px" }
        }}
      >
        {!props.navigateBack && (
          <Box item width={"30px"}
            style={{ cursor: 'pointer', display: 'flex', alignItems: "center" }}
            onClick={() => props.path ? props.onClick() : navigate(-1)}
          >
            <Tooltip title={'Back'}>
              <BackArrow id={"icon-header-back-button"} />
            </Tooltip>
          </Box>
        )}
        <Box item flexGrow={1} sx={{ display: { xl: "block", xs: "none" } }} id={"module-title"}>
          <Typography variant={"moduleHeaderDesktop"}>{props.title}</Typography>
        </Box>
        <Box item flexGrow={1} sx={{ display: { xl: "none", xs: "block" } }} id={"module-title"}>
          <Typography variant={"moduleHeaderLaptop"}>{props.title}</Typography>
        </Box>

        {props.children}
      </Box>
    </>
  );
};
export default TitleBar;
