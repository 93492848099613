import { Avatar, Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import maleProfile from '../../assets/MaleProfile.svg';
import defaultFemalePersonPic from '../../assets/defaultFemalePersonPic.svg';
import Loader from '../../components/Loader';
import PageNotFound from '../../components/PageNotFound';
import ViewPage from '../../components/ViewPage';
import CustomInput from '../../components/ViewText';
import api from '../../configurations/http-common';
import { REACT_URL_PAGE_NOT_FOUND, REACT_URL_PERSON } from '../../constants/ReactEndPoints';
import { getDepartmentById, getDesignationById, loadOrgPersonsById } from "../../services/PersonService";
import { getPermissionsByRoleId } from '../../services/RoleService';
import { getAddressByPersonId } from "../../services/SiteService";
import CommonUtil from '../../util/CommonUtils';
import defaultPersonProfile from '../../assets/defaultPersonProfile.svg';

const ViewPerson = (props) => {

    const location = useLocation();
    const { t } = useTranslation();
    const [person, setPerson] = useState([]);
    const [department, setDepartment] = useState("");
    const [designation, setDesignation] = useState("");
    const [address, setAddress] = useState("");
    const [viewPerson, setViewPerson] = useState(false);
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState([]);
    const params = useParams();
    const personId = params.personId;
    const [viewErrorPage, setViewErrorPage] = useState(false);
    const [loader, setLoader] = React.useState(true);

    React.useEffect(() => {

        if (CommonUtil.isEmpty(personId)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }

        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if (res.data.code === "CACCZE001") {
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev => [...prev, item.code])
                });
            }
            setViewPerson(true);
        });
    }, []);

    useEffect(() => {
        if (checkPermission("ACC_VIEW_PERSON_PROFILE")) {
            loadOrgPersonsById(personId).then((res) => {
                setLoader(true);
                if (res?.data?.code === 'OMSI0000') {
                    setPerson(res.data.data);
                    setAddress(res.data?.data?.address[0]);
                    getDepartmentById(res.data.data.departmentId).then((res) => {
                        if (res != null && res?.data?.code === 'OMSI0000') {
                            setDepartment(res.data.data);
                        }
                    });
                    getDesignationById(res?.data?.data?.designationId).then((res) => {
                        if (res?.data?.code === 'OMSI0000') {
                            setDesignation(res.data.data);
                        }
                    });
                    // getAddressByPersonId(res.data.data.id).then((res) => {
                    //     if (res.data?.code === 'LMSI0000') {
                    //         if (res?.data?.data?.address === undefined) { setAddress([]); }
                    //         else {
                    //             setAddress(res.data.data.address[0]);
                    //         }
                    //     }
                    // });
                } else {
                    setViewErrorPage(true);
                }
                setLoader(false);
            });
        }
    }, [permissions])

    const checkPermission = (permissionCode) => {
        if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
            return true;
        else {
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
    }

    const getGender = (value) => {
        var gender = '';
        if (value) {
            switch (value) {
                case 'M':
                    gender = 'Male';
                    break;
                case 'F':
                    gender = 'Female';
                    break;
                case 'O':
                    gender = 'Others';
                    break;
                default:
                    gender = '';
            }
        }
        return gender;
    };

    return (
        <>
            {viewErrorPage ? <PageNotFound></PageNotFound> : viewPerson ? checkPermission("ACC_VIEW_PERSON_PROFILE") ? <ViewPage title={t('ACCORG011')}
                path={true}
                onClick={() => navigate(
                    REACT_URL_PERSON,
                    { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
            >
                {loader ? <Loader open={loader} /> : <></>}
                <Box marginBottom={'30px'} id={"avatar-person"}>
                    <Avatar alt={t('ACCORG011')} src={location?.state?.picURL && location?.state?.picURL !== "" ? location?.state?.picURL : person.gender === 'F' ? defaultFemalePersonPic : person.gender === 'M' ? maleProfile : defaultPersonProfile}
                        sx={{
                            "&.MuiAvatar-root": {
                                width: "178px",
                                height: "178px"
                            }
                        }}
                    />
                </Box>
                <Grid direction='row' alignItems={'flex-start'} id={"person-view-grid"}  >
                    {CustomInput({ label: t('ACCORG012'), value: person.firstName, index: 1 })}
                    {CustomInput({ label: t('ACCORG013'), value: person.lastName, index: 2 })}
                    {CustomInput({ label: t('ACCORG003'), value: person.code, index: 3 })}
                    {CustomInput({ label: t('ACCORG004'), value: person.email, index: 4 })}
                    {CustomInput({ label: t('ACCORG005'), value: person.phone, index: 5 })}
                    {CustomInput({ label: t('ACCORG014'), value: department.name, index: 6 })}
                    {CustomInput({ label: t('ACCORG015'), value: designation.name, index: 7 })}
                    {CustomInput({ label: t('ACCORG016'), value: person.joinDate, index: 8 })}
                    {CustomInput({ label: t('ACCORG017'), value: person.dateOfBirth, index: 9 })}
                    {CustomInput({ label: t('ACCORG018'), value: getGender(person.gender), index: 10 })}
                    {CustomInput({ label: t('ACCDA0024'), value: address?.country, index: 11 })}
                    {CustomInput({ label: t('ACCORG019'), value: address?.state, index: 12 })}
                    {CustomInput({ label: t('ACCORG020'), value: address?.city, index: 13 })}
                    {CustomInput({ label: t('ACCORG021'), value: address?.addressLine1, index: 14 })}
                    {CustomInput({ label: t('ACCORG022'), value: address?.addressLine2, index: 15 })}
                    {CustomInput({ label: t('ACCORG023'), value: address?.areaCode, index: 16 })}
                </Grid>
            </ViewPage> : (<Box marginTop={'150px'} width={'100%'} id={"permission-box-person"}>
                <Typography textAlign={'center'}>
                    {t("ACCORG025")}
                </Typography>
            </Box>) : <></>}

        </>

    )
};

export default ViewPerson;