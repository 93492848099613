import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ReactComponent as NormallyOpenIcon } from "../../assets/DeviceIcon/OpenNormalyICon.svg";
import { ReactComponent as Filter } from "../../assets/new-images/Filter icon (1).svg";
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import { ReactComponent as RemoteClose } from "../../assets/new-images/RemotelyClose.svg";
import { ReactComponent as RemoteOpen } from "../../assets/new-images/RemotelyOpen.svg";
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import RightViewLayout from '../../components/RighViewLayout';
import SimpleDailogBox from '../../components/SimpleDailogBox';
import DataTable from "../../components/layout-components/DataTable";
import api from '../../configurations/http-common';
import { doorOperation, filterDoorAccessTime } from "../../services/DoorService";
import { getPermissionsByRoleId } from '../../services/RoleService';
import CommonUtil from '../../util/CommonUtils';
import { ReactComponent as EditOutlined } from './../../assets/new-images/Edit Icon.svg';
import DoorAccessTimeSlot from './DoorAccessTimeSlot';
import EditDoorAccessTime from './EditDoorAccessTime';
import RemoteOpenDoor from './RemoteOpenDoor';
import PaginationContext from '../../components/layout-components/PaginationContext';


export default function DoorAccessTime() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedRecords, onSelectionChange] = useState([]);
  const { manageDATPageNumber, setManageDATPageNumber, manageDATPageSize, setManageDATPageSize } = useContext(PaginationContext);
  const [page, setPage] = React.useState(manageDATPageNumber ? manageDATPageNumber : 1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(manageDATPageSize ? manageDATPageSize : 10);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openRemoteOPenlyDialog, setOpenRemoteOPenlyDialog] = React.useState(false);
  const [updateDoor, setUpdateDoor] = useState({});
  const [doorNo, setDoorNo] = React.useState('');
  const [deviceId, setDeviceId] = React.useState('');
  const [open, setOpen] = useState(false);
  const [openTimeSlot, setOpenTimeSlot] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [doorName, setDoorName] = useState('');
  const [passModeTime, setPassModeTime] = useState('');
  const navigate = useNavigate();

  const [filter, setFilter] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  const [disabled, setDisabled] = React.useState(true);
  const [change, setChange] = React.useState(false);
  const [reload, setReload] = useState(false);
  const [clearAndRefresh, setClearAndRefresh] = useState(false);

  const [roles, setRoles] = useState([]);
  const [editPermission, setEditPermission] = useState(false);
  const [normallyOpenPermission, setNormallyOpenPermission] = useState(false);
  const [dataTablePermission, setDataTablePermission] = useState(true)

  const defaultFilter = { deviceSn: '' };
  const [filterData, setFilterData] = useState(defaultFilter);

  useEffect(() => {
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      var l = [];
      res.data.data.rolePermissions.map((item, index) => {
        l.push(item.code);
      });
      setRoles(l);
      setEditPermission(l.includes("EDIT_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
      setNormallyOpenPermission(l.includes("ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
      setDataTablePermission(l.includes("VIEW_DOOR_ACCESS_TIME_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))
      if (l.includes("VIEW_DOOR_ACCESS_TIME_LIST")) {
        loadData(page, rowsPerPage, filterData);
      }
    });
  }, []);

  useEffect(() => {
    if (roles.includes("VIEW_DOOR_ACCESS_TIME_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      if (load && !filter) {
        loadData(page, rowsPerPage, defaultFilter);
      }
    }
    // else{
    //    navigate(-1);
    // }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (filter && !load && reload) {
      loadData(1, rowsPerPage, filterData);
      setReload(false)
    }
  }, [filter, reload]);

  const loadData = (page, rowsPerPage, filter) => {
    onSelectionChange([]);
    filterDoorAccessTime(page, rowsPerPage, filter).then((res) => {
      if (res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data)) {
        setTotalRecords(res.data.data.totalCount);
        setData(res.data.data.doors);
        setClearAndRefresh(false);
      } else {
        setTotalRecords(0);
        setData([]);
        setClearAndRefresh(false);
      }
    });
  };

  const remoteCloseOperation = (type) => {
    if (selectedRecords.length < 1) {
      enqueueSnackbar(t('AccDoorAccTime007'), { variant: 'warning' });
      return;
    }
    if (selectedRecords.length > 1) {
      enqueueSnackbar(t('AccDoorAccTime006'), { variant: 'warning' });
      return;
    }
    var val = getDeviceOnlineStatus(selectedRecords);
    if (val == '') {
      var payload = {
        operator: 0,
        type: type,
        triggerNum: doorNo,
        delayTime: 0,
      };

      doorOperation(deviceId, doorName, payload).then((res) => {
        if (res?.data?.code === 'DMSI0000') {
          enqueueSnackbar(t("AccDoorAccTime008"), { variant: 'success' });
        } else {
          enqueueSnackbar(res.data.msg, { variant: 'error' });
        }
      });
    } else {
      enqueueSnackbar(t('AccDoorAccTime009'), { variant: 'error' });
      return;
    }
  };

  useEffect(() => {
    data.map((door) => {
      if (selectedRecords[0] === door.doorId) {
        setDoorName(door.doorParameters.name);
        setPassModeTime(door.doorParameters.passModeTimeSegBusinessId);
      }
    })
  }, [selectedRecords]);

  const getDeviceOnlineStatus = (id) => {
    var ret = '';
    data.map((device, index) => {
      if (device.doorId == id && device.status == 0) {
        ret = device.deviceSn;
      }
    });
    return ret;
  }

  const handleClose = () => {
    setOpenTimeSlot(false);
  }

  const handleTimeSlot = () => {
    if (selectedRecords.length < 1) {
      enqueueSnackbar(t('AccDoorAccTime007'), { variant: 'warning' });
      return;
    }
    if (selectedRecords.length > 1) {
      enqueueSnackbar(t('AccDoorAccTime006'), { variant: 'warning' });
      return;
    }
    var status = getDeviceOnlineStatus(selectedRecords);
    if (status == '') {
      setOpenTimeSlot(true);
    } else {
      enqueueSnackbar(t('AccDoorAccTime010'), { variant: 'error' });
      return;
    }

  }

  const actions = () => (
    <Box item display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }} >
      {normallyOpenPermission && <Box display={"flex"}>
        <Tooltip title={t('AccDoorAccTime031')}>
          <Box
            aria-label="normally-open"
            onClick={() => handleTimeSlot()}
            mr={2}
            sx={{ display: { xl: "block", xs: "none" } }}
            id="icon-dooraccesstime-normally-open"
          >
            <NormallyOpenIcon style={{ cursor: 'pointer' }} />
          </Box>
          <Box
            aria-label="normally-open"
            onClick={() => handleTimeSlot()}
            mr={2}
            sx={{ display: { xl: "none", xs: "block" } }}
            id="icon-dooraccesstime-normally-open"
          >
            <NormallyOpenIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
          </Box>
        </Tooltip>
        <Tooltip title={t('AccDoorAccTime011')}>
          <Box
            id="icon-dooraccesstime-remote-open"
            aria-label="remote-close"
            mr={2}
            onClick={handleClickRemoteOpen}
            sx={{ display: { xl: "block", xs: "none" } }}
          >
            <RemoteOpen style={{ cursor: 'pointer' }} />
          </Box>
          <Box
            id="icon-dooraccesstime-remote-open"
            aria-label="remote-close"
            mr={2}
            onClick={handleClickRemoteOpen}
            sx={{ display: { xl: "none", xs: "block" } }}
          >
            <RemoteOpen width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
          </Box>
        </Tooltip>
        <Tooltip title={t('AccDoorAccTime012')}>
          <Box
            id="icon-dooraccesstime-remote-close"
            aria-label="remote-close"
            mr={2}
            onClick={() => remoteCloseOperation(0)}
            sx={{ display: { xl: "block", xs: "none" } }}
          >
            <RemoteClose style={{ cursor: 'pointer' }} />
          </Box>
          <Box
            id="icon-dooraccesstime-remote-close"
            aria-label="remote-close"
            mr={2}
            onClick={() => remoteCloseOperation(0)}
            sx={{ display: { xl: "none", xs: "block" } }}
          >
            <RemoteClose width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
          </Box>
        </Tooltip>
      </Box>}
      {dataTablePermission && <Box display={"flex"}>
        <Tooltip title={t('COMMON002')}>
          <Box
            aria-label="filter"
            onClick={() => refreshTableData()}
            mr={2}
            sx={{ display: { xl: "block", xs: "none" } }}
            id="icon-dooraccesstime-refresh"
          >
            <Refresh style={{ cursor: 'pointer' }} />
          </Box>
          <Box
            aria-label="refresh"
            onClick={() => refreshTableData()}
            mr={2}
            sx={{ display: { xl: "none", xs: "block" } }}
            id="icon-dooraccesstime-refresh"
          >
            <Refresh width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
          </Box>
        </Tooltip>

        <Tooltip title={t('COMMON003')}>
          <Box
            aria-label="filter"
            onClick={() => setOpen(!open)}
            sx={{ display: { xl: "block", xs: "none" } }}
            id="icon-dooraccesstime-filter"
          >
            <Filter style={{ cursor: 'pointer' }} />
          </Box>
          <Box
            aria-label="filter"
            onClick={() => setOpen(!open)}
            sx={{ display: { xl: "none", xs: "block" } }}
            id="icon-dooraccesstime-filter"
          >
            <Filter width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
          </Box>
        </Tooltip></Box>}

    </Box>
  );

  const handleClickRemoteOpen = () => {
    if (selectedRecords.length < 1) {
      enqueueSnackbar(t('AccDoorAccTime007'), { variant: 'warning' });
      return;
    }
    if (selectedRecords.length > 1) {
      enqueueSnackbar(t('AccDoorAccTime006'), { variant: 'warning' });
      return;
    }
    var val = getDeviceOnlineStatus(selectedRecords);

    if (val == '') {
      setOpenRemoteOPenlyDialog(true);
    } else {
      enqueueSnackbar(t('AccDoorAccTime005'), { variant: 'error' });
      return;
    }

  }

  const columns = [
    {
      field: "doorParameters.name",
      hideable: false,
      headerName: t('AccDMTopology011'),
      flex: 1,
      valueGetter: (params) => params.row.doorParameters.name
    },
    {
      field: "deviceName",
      headerName: t('AccDevice014'),
      flex: 1,
    },
    {
      field: "deviceSn",
      headerName: t('AccDevice013'),
      flex: 1,
    },
    {
      field: "doorParameters.doorNo",
      headerName: t('AccDoor002'),
      flex: 1,
      valueGetter: (params) => params.row.doorParameters.doorNo
    },
    {
      field: "doorParameters.enabled",
      headerName: t('AccDoor003'),
      flex: 1,
      renderCell: (params) => params.row.doorParameters.enabled === 'true' ? <CheckIcon style={{ color: 'green' }} className='refresh-btn' /> : <ClearIcon style={{ color: 'red' }} className='refresh-btn' />
    },
    {
      field: "doorParameters.activeTimeSegBusinessName",
      headerName: t('AccDoor004'),
      flex: 1,
      valueGetter: (params) => findDefaultTimeSlot(params.row.doorParameters)
    },
    {
      field: "doorParameters.verifyMode",
      headerName: t('AccDoor005'),
      flex: 1,
      valueGetter: (params) => params.row.doorParameters.verifyModeName
    },
    {
      field: "Actions",
      hideable: false,
      headerName: t('COMMON019'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            style={{ width: '20%', marginRight: '10px' }}
            icon={editPermission ?
              <Tooltip title={t('AccDoorAccTime004')}>
                <EditOutlined id="icon-dooraccesstime-edit-outlined" style={{ opacity: '0.8', cursor: 'pointer' }}
                  onClick={() => {
                    setOpenEditDialog(true);
                    setUpdateDoor(params.row);
                  }}
                />
              </Tooltip> : <Box></Box>}
          />
        </>
      )
    }
  ];

  const findDefaultTimeSlot = (door) => {
    if (door.activeTimeSegBusinessId === "9999") {
      return '24-Hours General';
    }
    else {
      return door.activeTimeSegBusinessName
    }
  }
  //Filter  Related
  const handleChange = (event) => {
    setChange(true);
    const name = event.target.name;
    setFilterData({
      ...filterData,
      [name]: event.target.value
    });
  };

  useEffect(() => {
    if (filterData.deviceSn === '') {
      setDisabled(true);
      if (change) {
        setFilter(false); setLoad(true);
        if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
        else {
          if (!clearAndRefresh)
            setPage(1);
        }
      }
    } else setDisabled(false);
  }, [filterData]);

  const DEFAULT_PAGE_SIZE = 10;

  const refreshTableData = () => {
    setClearAndRefresh(true);
    if (page === 1) {
      // setFilterData(defaultFilter);
      // setDisabled(true)
      // setFilter(false);
      setLoad(true);
      loadData(1, manageDATPageSize, filterData);
      setRowsPerPage(manageDATPageSize);
    }
    else {
      // setFilterData(defaultFilter);
      // setDisabled(true)
      // setFilter(false);
      setLoad(true);
      setPage(filter ? 1 : manageDATPageNumber);
      setRowsPerPage(manageDATPageSize);
      loadData(filter ? 1 : manageDATPageNumber, manageDATPageSize, filterData);
    }
  };


  const clearFilter = () => {
    setFilterData({ deviceSn: '' });
    setClearAndRefresh(true)
    setDisabled(true);
    setFilter(false);
    setLoad(true);
    if (page === 1) {
      loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
    else setPage(1);
    setRowsPerPage(DEFAULT_PAGE_SIZE);
  }

  const handleSubmitFilter = () => {
    setFilter(true);
    setLoad(false);
    setReload(true)
  }

  const handlePageChange = (e) => {
    if (filter) {
      setPage(e);
      setManageDATPageNumber(e);
      loadData(e, rowsPerPage, filterData);
    }
    else {
      setFilter(false);
      setLoad(true);
      setPage(e);
      setManageDATPageNumber(e);
    }
  }

  const handlePageSize = (e) => {
    if (filter) {
      setRowsPerPage(e);
      setManageDATPageSize(e);
      setPage(1);
      loadData(1, e, filterData);
    }
    else {
      setFilter(false);
      setLoad(true);
      setRowsPerPage(e);
      setManageDATPageSize(e);
      setPage(1);
    }
  }

  const FieldSerialNumber = (key) => (
    <CustomInput
      id="textfield-dooraccesstime-device-sn"
      placeholder={t('AccDevice013')}
      value={filterData.deviceSn}
      size='small'
      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      fullWidth
      onKeyDown={handleKeyPress}
      name='deviceSn'
      variant='outlined'
      handleChange={handleChange}
      inputProps={{
        maxLength: 30,
      }}

    />
  );


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmitFilter();
    }
  };

  useEffect(() => {
    if (!open && !disabled) {
      setPage(1);
      setChange(false);
      setFilterData(defaultFilter);
      loadData(1, manageDATPageSize, defaultFilter);
    }
  }, [open]);

  return (
    <>
      {dataTablePermission ? (<> <RightViewLayout title={t('AccDoorAccTime001')} navigateBack={true} >
        {actions()}
      </RightViewLayout>
        <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
          {open && <FilterComponent
            firstField={FieldSerialNumber()}
            clearFilter={clearFilter}
            handleSubmit={handleSubmitFilter}
            disabled={disabled}
          />}
          <DataTable
            page={page - 1}
            totalRecords={totalRecords}
            rowsPerPage={rowsPerPage}
            columns={columns}
            getRowId={(row) => row.doorId}
            rows={data}
            checkboxSelection={true}
            // toolbar={filters}
            rowSelectionModel={selectedRecords}
            onSelection={(ids) => {
              onSelectionChange(ids);
              data.filter((row) => {
                if (ids[0] === row.doorId) {
                  setDoorNo(row.doorParameters.doorNo)
                  setDeviceId(row.deviceId);
                  return;
                }
              });
            }}
            onPageChange={(e) => handlePageChange(e + 1)}
            onPageSizeChange={(e) => handlePageSize(e)}
            noRecordText={t('AccDoor007')}
          />

          {openEditDialog && (
            <SimpleDailogBox
              open={openEditDialog}
              onClose={() => {
                setOpenEditDialog(false);
                loadData(1, rowsPerPage, defaultFilter);
              }}
              width={'420px'}
              title={t('AccDoorAccTime030')}>
              <EditDoorAccessTime
                door={updateDoor}
                onClose={() => {
                  setOpenEditDialog(false);
                  loadData(1, rowsPerPage, defaultFilter);
                }}
              />
            </SimpleDailogBox>
          )}

          {openRemoteOPenlyDialog && (
            <SimpleDailogBox
              open={openRemoteOPenlyDialog}
              onClose={() => {
                setOpenRemoteOPenlyDialog(false);
              }}
              width={{ xl: '450px', xs: "350px" }}
              title={t('AccDoorAccTime022')}>
              <RemoteOpenDoor
                doors={selectedRecords}
                doorNo={doorNo}
                doorName={doorName}
                deviceId={deviceId}
                open={openRemoteOPenlyDialog}
                onClose={() => {
                  setOpenRemoteOPenlyDialog(false);
                  loadData(1, rowsPerPage, defaultFilter);
                }}
              />
            </SimpleDailogBox>
          )}
          {openTimeSlot && <SimpleDailogBox
            open={openTimeSlot}
            onClose={handleClose}
            width={{ xl: '650px', xs: "480px" }}
            title={t('AccDoorAccTime003')}>
            <DoorAccessTimeSlot
              onClose={handleClose}
              doorNo={doorNo}
              passModeTimeSegBusinessId={passModeTime}
              doorName={doorName}
              doorId={selectedRecords}
              setPassModeTime={setPassModeTime}
              deviceId={deviceId}
            />
          </SimpleDailogBox>}
        </Box>
      </>) :
        (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t("AccDoorAccTime002")}</Typography>
        </Box>)}
    </>
  );
}
