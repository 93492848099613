import { AccordionSummary, Checkbox, Grid, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import React from "react";
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { ReactComponent as LeftArrowIcon } from "../../assets/arrow.svg";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "8px",
  display: "contents",
  margin: "0px 24px",
  "& .MuiPaper-root-MuiAccordion-root.Mui-expanded(:first-of-type)": {
    marginTop: "12px !importent",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#FFFF",
  padding: "0px !importent",
}));


export default function CustomizedAccordions(props) {
  const [expand, setExpanded] = useState("");
  const isChecked = (codes) => {
    var checked = [];
    codes.map((code) => {
      if (props.permissions[code]) checked.push(code);
    });
    return checked.length === codes.length - [];
  };

  const handleChange = (codes, e) => {
    var newPermissions = { ...props.permissions };
    codes.map((code) => {
      newPermissions[code] = e.target.checked;
    });

    props.setPermissions({
      ...props.permissions,
      ...newPermissions,
    });
  };

  const permissionTree = [
    {
      parent: {
        title: "Dashboard",
        codes: [
          "ACC_DASHBOARD",
          "VIEW_ACC_DASHBOARD",
          "VIEW_EVENT_OVERVIEW_GRAPH",
          "VIEW_SITE_OVERVIEW_MAP",
        ],
      },
      child: [
        {
          title: "View the Counter buttons",
          code: "VIEW_ACC_DASHBOARD",
        },
        {
          title: "View Event Overview Graph",
          code: "VIEW_EVENT_OVERVIEW_GRAPH",
        },
        {
          title: "View Site Overview map",
          code: "VIEW_SITE_OVERVIEW_MAP",
        },
      ],
    },
    {
      parent: {
        title: "Organization",
        codes: [
          "ACC_ORGANIZATION",
          "ACC_VIEW_PERSONLIST",
          "ACC_VIEW_PERSON_PROFILE",
          'ACC_VIEW_PERMISSION_LIST',
          'ACC_VIEW_PERMISSIONS',
        ],
      },
      child: [
        {
          title: "View Person list",
          code: "ACC_VIEW_PERSONLIST",
        },
        {
          title: "View Person Profile",
          code: "ACC_VIEW_PERSON_PROFILE",
        },
        {
          title: "View Role and Permission List",
          code: "ACC_VIEW_PERMISSION_LIST",
        },
        {
          title: "View Role and Permissions",
          code: "ACC_VIEW_PERMISSIONS",
        },
      ],
    },
    {
      parent: {
        title: "Site Management",
        codes: [
          "SITE_MANAGMENT_DETAILS",
          "ACC_VIEW_SITE_LISTS",
          "ACC_VIEW_SITE_DETAILS",
          "ACC_VIEW_ZONE_LIST",
          "ACC_VIEW_ZONE",
        ],
      },
      child: [
        {
          title: "View Site list",
          code: "ACC_VIEW_SITE_LISTS",
        },
        {
          title: "View Site Details",
          code: "ACC_VIEW_SITE_DETAILS",
        },
        {
          title: "View Zone list",
          code: "ACC_VIEW_ZONE_LIST",
        },
        {
          title: "View Zone Details",
          code: "ACC_VIEW_ZONE",
        },
      ],
    },
    {
      parent: {
        title: "Device Management",
        codes: [
          "DEVICE_MANAGEMENT",//ok
          "VIEW_DEVICE_LIST_DETAILS", ///not
          "ACC_VIEW_DEVICE_TOPOLOGY",//ok
          "EDIT_DEVICE_LIST_DETAILS",//ok
          "ACC_DEVICE_DELETE",//ok
          "ACC_REBOOT_DEVICE",//ok
          "ACC_DOOR_LIST",//ok
          // "ACC_VIEW_DOOR",
          "ACC_EDIT_DOOR",//ok
          "ACC_READER_LIST",//not
          "ACC_READER_VIEW",//ok
          "ACC_READER_EDIT",//ok
          "ACC_AUXILIARY_LIST",//ok
          "ACC_AUXILIARY_VIEW",//ok
          "ACC_AUXILIARY_EDIT",//ok
          "ACC_AUXILIARY__OUTPUT_LIST",//not
          "ACC_AUXILIARY__OUTPUT_VIEW",//ok
          "ACC_AUXILIARY_OUTPUT_EDIT",//ok
        ],
      },
      child: [
        {
          title: "View Device Topology",
          code: "ACC_VIEW_DEVICE_TOPOLOGY",
        },
        {
          title: "View Device List",
          code: "VIEW_DEVICE_LIST_DETAILS",
        },
        {
          title: "Add device, Edit device details, Person In This Device",
          code: "EDIT_DEVICE_LIST_DETAILS",
        },
        {
          title: "Delete device",
          code: "ACC_DEVICE_DELETE",
        },
        {
          title: "Reboot device",
          code: "ACC_REBOOT_DEVICE",
        },
        {
          title: "View Door List",
          code: "ACC_DOOR_LIST",
        },
        // {
        //   title: "View door details",
        //   code: "ACC_VIEW_DOOR",
        // },
        {
          title: "Edit Door Details",
          code: "ACC_EDIT_DOOR",
        },

        {
          title: "View Reader list",
          code: "ACC_READER_LIST",
        },
        {
          title: "View Reader details",
          code: "ACC_READER_VIEW",
        },
        {
          title: "Edit Reader details",
          code: "ACC_READER_EDIT",
        },
        {
          title: "View Auxiliary Input list",
          code: "ACC_AUXILIARY_LIST",
        },
        {
          title: "View Auxiliary Input details",
          code: "ACC_AUXILIARY_VIEW",
        },
        {
          title: "Edit Auxiliary Input details",
          code: "ACC_AUXILIARY_EDIT",
        },
        {
          title: "View Auxiliary Output list",
          code: "ACC_AUXILIARY__OUTPUT_LIST",
        },
        {
          title: " View Auxiliary Output details",
          code: "ACC_AUXILIARY__OUTPUT_VIEW",
        },
        {
          title: "Edit Auxiliary Output details",
          code: "ACC_AUXILIARY_OUTPUT_EDIT",
        },
      ],
    },

    {
      parent: {
        title: "Access Control",
        codes: [
          "ACCESS_CONTROL",//ok //ok
          "TIMESLOT_LIST",//not //not
          "VIEW_TIMESLOT",//ok  //ok
          "ADD_TIMESLOT",//ok  //ok
          "VIEW_DOOR_ACCESS_TIME_LIST",//ok //not
          "ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN",//ok //ok
          "EDIT_ACCESS_GROUP",//ok //ok
          // "MANAGE_ACCESS_GROUP",
          "MANAGE_ACCESS_GROUP_LIST",//ok //ok
          "ADD_EDIT_ACCESS_GROUP",//ok //ok
          "DELETE_ACCESS_GROUP",//ok //ok
          "MANAGE_DOOR_ACCESS_GROUP",//ok
          "ADD_DOOR_ACCESS_GROUP",//ok //ok
          "MANAGE_PERSONS_ACCESS_GROUP",//ok //ok
          "ADD_PERSONS_ACCESS_GROUP",//ok //ok
        ],
      },
      child: [
        {
          title: "View Timeslots list",
          code: "TIMESLOT_LIST",
        },
        {
          title: "View Timeslot details",
          code: "VIEW_TIMESLOT",
        },
        {
          title: "Add, Edit, Delete Timeslots",
          code: "ADD_TIMESLOT",
        },
        {
          title: "View Door Access time list",
          code: "VIEW_DOOR_ACCESS_TIME_LIST",
        },
        {
          title: "Normally open door, Remotely Open door, Remotely Close Door",
          code: "ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN",
        },
        {
          title: "Edit Door Access time slot",
          code: "EDIT_ACCESS_GROUP",
        },
        {
          title: "View Group Access Time list",
          code: "MANAGE_ACCESS_GROUP_LIST",
        },
        // {
        //   title: "View Group Access time list which is the user is a member",
        //   code: "MANAGE_ACCESS_GROUP_LIST",
        // },
        {
          title: "Add and Edit Group Access time",
          code: "ADD_EDIT_ACCESS_GROUP",
        },
        {
          title: "Delete Group Access time",
          code: "DELETE_ACCESS_GROUP",
        },
        {
          title: "Manage Doors",
          code: "MANAGE_DOOR_ACCESS_GROUP",
        },
        {
          title: "Add, Delete Door",
          code: "ADD_DOOR_ACCESS_GROUP",
        },
        {
          title: "Manage Persons",
          code: "MANAGE_PERSONS_ACCESS_GROUP",
        },
        {
          title: "Add, Delete Persons",
          code: "ADD_PERSONS_ACCESS_GROUP",
        },
      ],
    },

    {
      parent: {
        title: "Reports",
        codes: ["ACC_REPORT", "VIEW_ACC_REPORT", "EXPORT_ACC_REPORT"],
      },
      child: [
        {
          title: "View Event list",
          code: "VIEW_ACC_REPORT",
        },
        {
          title: "Export Event list",
          code: "EXPORT_ACC_REPORT",
        },
      ],
    },

    {
      parent: {
        title: "Operation Log",
        codes: ["ACC_LOGS", "OPERATOR_LOGS"],
      },
      child: [
        {
          title: "Operator logs",
          code: "OPERATOR_LOGS",
        },
      ],
    },
  ];

  const isExpaded = (title) => {
    return expand === title ? true : false;
  };

  const selectPermission = (idOrCode, e) => {

    if (
      (idOrCode === "VIEW_EVENT_OVERVIEW_GRAPH" || idOrCode === "VIEW_SITE_OVERVIEW_MAP") &&
      idOrCode !== "VIEW_ACC_DASHBOARD"
    ) {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_ACC_DASHBOARD: true,
          ACC_DASHBOARD: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_ACC_DASHBOARD: e.target.checked,
          ACC_DASHBOARD: e.target.checked,
        });
      }

    } else if (idOrCode === "VIEW_ACC_DASHBOARD" && props.permissions['VIEW_ACC_DASHBOARD']) {
      props.setPermissions({
        ...props.permissions,
        VIEW_EVENT_OVERVIEW_GRAPH: false,
        VIEW_SITE_OVERVIEW_MAP: false,
        VIEW_ACC_DASHBOARD: false,
        ACC_DASHBOARD: false,
      });
    }
    else if (idOrCode !== "ACC_VIEW_PERSONLIST" &&
      idOrCode === "ACC_VIEW_PERSON_PROFILE") {

      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_VIEW_PERSONLIST: props.permissions['ACC_VIEW_PERSONLIST'],
          ACC_ORGANIZATION: props.permissions['ACC_VIEW_PERSONLIST'],
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_VIEW_PERSONLIST: e.target.checked,
          ACC_ORGANIZATION: e.target.checked,
        });
      }

    } else if (idOrCode === "ACC_VIEW_PERSONLIST" && !props.permissions['ACC_VIEW_PERSONLIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_VIEW_PERSONLIST: e.target.checked,
        ACC_ORGANIZATION: e.target.checked,
      });
    } else if (idOrCode === "ACC_VIEW_PERSONLIST" && props.permissions['ACC_VIEW_PERSONLIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_VIEW_PERSON_PROFILE: false,
        ACC_VIEW_PERSONLIST: false,
        ACC_ORGANIZATION: false,
      });
    }
    else if (idOrCode !== "ACC_VIEW_PERMISSION_LIST" &&
      idOrCode === "ACC_VIEW_PERMISSIONS") {

      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_VIEW_PERMISSION_LIST: props.permissions['ACC_VIEW_PERMISSION_LIST'],
          ACC_ORGANIZATION: props.permissions['ACC_VIEW_PERMISSION_LIST'],
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_VIEW_PERMISSION_LIST: e.target.checked,
          ACC_ORGANIZATION: e.target.checked,
        });
      }

    } else if (idOrCode === "ACC_VIEW_PERMISSION_LIST" && props.permissions['ACC_VIEW_PERMISSION_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_VIEW_PERMISSIONS: false,
        ACC_VIEW_PERMISSION_LIST: false,
        ACC_ORGANIZATION: false,
      });
    } else if (idOrCode === "ACC_VIEW_PERMISSION_LIST" && !props.permissions['ACC_VIEW_PERMISSION_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_VIEW_PERMISSION_LIST: e.target.checked,
        ACC_ORGANIZATION: e.target.checked,
      });
    }
    else if ((idOrCode !== "ACC_VIEW_SITE_LISTS" &&
      idOrCode === "ACC_VIEW_SITE_DETAILS")) {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_VIEW_SITE_LISTS: props.permissions['ACC_VIEW_SITE_LISTS'],
          SITE_MANAGMENT_DETAILS: props.permissions['ACC_VIEW_SITE_LISTS'],
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_VIEW_SITE_LISTS: e.target.checked,
          SITE_MANAGMENT_DETAILS: e.target.checked,
        });
      }

    }
    else if (idOrCode === "ACC_VIEW_SITE_LISTS" && !props.permissions['ACC_VIEW_SITE_LISTS']) {
      props.setPermissions({
        ...props.permissions,
        ACC_VIEW_SITE_LISTS: e.target.checked,
        SITE_MANAGMENT_DETAILS: e.target.checked,
      });
    } else if (idOrCode === "ACC_VIEW_SITE_LISTS" && props.permissions['ACC_VIEW_SITE_LISTS']) {
      props.setPermissions({
        ...props.permissions,
        ACC_VIEW_SITE_DETAILS: false,
        ACC_VIEW_SITE_LISTS: false,
        SITE_MANAGMENT_DETAILS: false,
      });
    }
    else if (
      idOrCode !== "ACC_VIEW_ZONE_LIST" &&
      idOrCode === "ACC_VIEW_ZONE") {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_VIEW_ZONE_LIST: props.permissions['ACC_VIEW_ZONE_LIST'],
          SITE_MANAGMENT_DETAILS: props.permissions['ACC_VIEW_ZONE_LIST'],
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_VIEW_ZONE_LIST: e.target.checked,
          SITE_MANAGMENT_DETAILS: e.target.checked,
        });
      }

    } else if (idOrCode === "ACC_VIEW_ZONE_LIST" && !props.permissions['ACC_VIEW_ZONE_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_VIEW_ZONE_LIST: e.target.checked,
        SITE_MANAGMENT_DETAILS: e.target.checked,
      });

    } else if (idOrCode === "ACC_VIEW_ZONE_LIST" && props.permissions['ACC_VIEW_ZONE_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_VIEW_ZONE: false,
        ACC_VIEW_ZONE_LIST: false,
        SITE_MANAGMENT_DETAILS: false,
      });

    }

    // Device Managment Permission.
    else if (
      (idOrCode === "EDIT_DEVICE_LIST_DETAILS" || idOrCode === "ACC_DEVICE_DELETE" ||
        idOrCode === "ACC_REBOOT_DEVICE") && idOrCode !== "VIEW_DEVICE_LIST_DETAILS"
    ) {

      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_DEVICE_LIST_DETAILS: true,
          DEVICE_MANAGEMENT: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_DEVICE_LIST_DETAILS: e.target.checked,
          DEVICE_MANAGEMENT: props.permissions['ACC_VIEW_DEVICE_TOPOLOGY'],
        });
      }
    }
    else if (idOrCode === "VIEW_DEVICE_LIST_DETAILS" && !props.permissions['VIEW_DEVICE_LIST_DETAILS']) {
      props.setPermissions({
        ...props.permissions,
        VIEW_DEVICE_LIST_DETAILS: e.target.checked,
        DEVICE_MANAGEMENT: e.target.checked,
      });
    }
    else if (idOrCode === "VIEW_DEVICE_LIST_DETAILS" && props.permissions['VIEW_DEVICE_LIST_DETAILS']) {
      props.setPermissions({
        ...props.permissions,
        VIEW_DEVICE_LIST_DETAILS: false,
        DEVICE_MANAGEMENT: props.permissions['ACC_VIEW_DEVICE_TOPOLOGY'],
        ACC_REBOOT_DEVICE: false,
        ACC_DEVICE_DELETE: false,
        EDIT_DEVICE_LIST_DETAILS: false

      });
    }
    else if (idOrCode === "ACC_VIEW_DEVICE_TOPOLOGY") {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          DEVICE_MANAGEMENT: e.target.checked,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          DEVICE_MANAGEMENT: e.target.checked,
        });
      }
    }

    else if (idOrCode === 'ACC_EDIT_DOOR' && idOrCode !== 'ACC_DOOR_LIST') {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_DOOR_LIST: true,
          DEVICE_MANAGEMENT: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_DOOR_LIST: e.target.checked,
          DEVICE_MANAGEMENT: e.target.checked,
        });
      }
    }
    else if (idOrCode === "ACC_DOOR_LIST" && !props.permissions['ACC_DOOR_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_DOOR_LIST: e.target.checked,
        DEVICE_MANAGEMENT: e.target.checked,
      });
    }
    else if (idOrCode === "ACC_DOOR_LIST" && props.permissions['ACC_DOOR_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_DOOR_LIST: false,
        ACC_EDIT_DOOR: false,
        DEVICE_MANAGEMENT: false,
      });
    }
    else if ((idOrCode === "ACC_AUXILIARY_VIEW" || idOrCode === "ACC_AUXILIARY_EDIT")
      && idOrCode !== "ACC_AUXILIARY_LIST") {

      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_AUXILIARY_LIST: true,
          DEVICE_MANAGEMENT: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_AUXILIARY_LIST: e.target.checked,
          DEVICE_MANAGEMENT: e.target.checked,
        });
      }
    } else if (idOrCode === "ACC_AUXILIARY_LIST" && !props.permissions['ACC_AUXILIARY_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_AUXILIARY_LIST: e.target.checked,
        DEVICE_MANAGEMENT: e.target.checked,
      });
    } else if (idOrCode === "ACC_AUXILIARY_LIST" && props.permissions['ACC_AUXILIARY_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_AUXILIARY_VIEW: false,
        ACC_AUXILIARY_EDIT: false,
        ACC_AUXILIARY_LIST: false,
        DEVICE_MANAGEMENT: false,
      });
    }
    else if ((idOrCode === "ACC_AUXILIARY__OUTPUT_VIEW" || idOrCode === "ACC_AUXILIARY_OUTPUT_EDIT")
      && idOrCode !== "ACC_AUXILIARY__OUTPUT_LIST"
    ) {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_AUXILIARY__OUTPUT_LIST: true,
          DEVICE_MANAGEMENT: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_AUXILIARY__OUTPUT_LIST: e.target.checked,
          DEVICE_MANAGEMENT: e.target.checked,
        });
      }
    } else if (idOrCode === "ACC_AUXILIARY__OUTPUT_LIST" && !props.permissions['ACC_AUXILIARY__OUTPUT_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_AUXILIARY__OUTPUT_LIST: e.target.checked,
        DEVICE_MANAGEMENT: e.target.checked,
      });
    } else if (idOrCode === "ACC_AUXILIARY__OUTPUT_LIST" && props.permissions['ACC_AUXILIARY__OUTPUT_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_AUXILIARY__OUTPUT_VIEW: false,
        ACC_AUXILIARY_OUTPUT_EDIT: false,
        ACC_AUXILIARY__OUTPUT_LIST: false,
        DEVICE_MANAGEMENT: false,
      });
    }
    else if ((idOrCode === "ACC_READER_VIEW" || idOrCode === "ACC_READER_EDIT")
      && idOrCode !== "ACC_READER_LIST"
    ) {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_READER_LIST: true,
          DEVICE_MANAGEMENT: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          ACC_READER_LIST: e.target.checked,
          DEVICE_MANAGEMENT: e.target.checked,
        });
      }
    } else if (idOrCode === "ACC_READER_LIST" && !props.permissions['ACC_READER_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_READER_LIST: e.target.checked,
        DEVICE_MANAGEMENT: e.target.checked,
      });
    } else if (idOrCode === "ACC_READER_LIST" && props.permissions['ACC_READER_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ACC_READER_VIEW: false,
        ACC_READER_EDIT: false,
        ACC_READER_LIST: false,
        DEVICE_MANAGEMENT: false,
      });
    }
    // Access Control Permission
    else if ((idOrCode === "ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN" || idOrCode === "EDIT_ACCESS_GROUP")
      && idOrCode !== "VIEW_DOOR_ACCESS_TIME_LIST") {

      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_DOOR_ACCESS_TIME_LIST: true,
          ACCESS_CONTROL: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_DOOR_ACCESS_TIME_LIST: e.target.checked,
          ACCESS_CONTROL: e.target.checked,
        });
      }
    } else if (idOrCode === "VIEW_DOOR_ACCESS_TIME_LIST" && props.permissions['VIEW_DOOR_ACCESS_TIME_LIST']) {
      props.setPermissions({
        ...props.permissions,
        EDIT_ACCESS_GROUP: false,
        ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN: false,
        VIEW_DOOR_ACCESS_TIME_LIST: false,
        ACCESS_CONTROL: false,
      });
    } else if (idOrCode === "VIEW_DOOR_ACCESS_TIME_LIST" && !props.permissions['VIEW_DOOR_ACCESS_TIME_LIST']) {
      props.setPermissions({
        ...props.permissions,
        VIEW_DOOR_ACCESS_TIME_LIST: e.target.checked,
        ACCESS_CONTROL: e.target.checked,
      });
    }
    else if ((idOrCode === "VIEW_TIMESLOT" || idOrCode === "ADD_TIMESLOT")
      && idOrCode !== "TIMESLOT_LIST") {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          TIMESLOT_LIST: true,
          ACCESS_CONTROL: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          TIMESLOT_LIST: e.target.checked,
          ACCESS_CONTROL: e.target.checked,
        });
      }
    } else if (idOrCode === "TIMESLOT_LIST" && props.permissions['TIMESLOT_LIST']) {
      props.setPermissions({
        ...props.permissions,
        VIEW_TIMESLOT: false,
        ADD_TIMESLOT: false,
        TIMESLOT_LIST: false,
        ACCESS_CONTROL: false,
      });
    } else if (idOrCode === "TIMESLOT_LIST" && !props.permissions['TIMESLOT_LIST']) {
      props.setPermissions({
        ...props.permissions,
        TIMESLOT_LIST: e.target.checked,
        ACCESS_CONTROL: e.target.checked,
      });
    }
    else if ((idOrCode === "ADD_EDIT_ACCESS_GROUP" || idOrCode === "DELETE_ACCESS_GROUP"
      || idOrCode === "MANAGE_DOOR_ACCESS_GROUP" || idOrCode === "ADD_DOOR_ACCESS_GROUP"
      || idOrCode === "MANAGE_PERSONS_ACCESS_GROUP" || idOrCode === 'ADD_PERSONS_ACCESS_GROUP')
      && idOrCode !== "MANAGE_ACCESS_GROUP_LIST") {

      if (idOrCode === "ADD_EDIT_ACCESS_GROUP" || idOrCode === "DELETE_ACCESS_GROUP") {
        if (props.permissions[idOrCode] === true) {
          props.setPermissions({
            ...props.permissions,
            [idOrCode]: e.target.checked,
            MANAGE_ACCESS_GROUP_LIST: props.permissions['MANAGE_ACCESS_GROUP_LIST'],
            ACCESS_CONTROL: props.permissions['MANAGE_ACCESS_GROUP_LIST'],
          });
        }
        else {
          props.setPermissions({
            ...props.permissions,
            [idOrCode]: e.target.checked,
            MANAGE_ACCESS_GROUP_LIST: true,
            ACCESS_CONTROL: true,
          });
        }
      }
      if (idOrCode === "ADD_DOOR_ACCESS_GROUP") {
        if (props.permissions[idOrCode] === true) {
          props.setPermissions({
            ...props.permissions,
            [idOrCode]: e.target.checked,
            MANAGE_ACCESS_GROUP_LIST: props.permissions['MANAGE_ACCESS_GROUP_LIST'],
            MANAGE_DOOR_ACCESS_GROUP: props.permissions['MANAGE_DOOR_ACCESS_GROUP'],
            ACCESS_CONTROL: props.permissions['MANAGE_ACCESS_GROUP_LIST'],
          });
        }
        else {
          props.setPermissions({
            ...props.permissions,
            [idOrCode]: e.target.checked,
            MANAGE_ACCESS_GROUP_LIST: true,
            MANAGE_DOOR_ACCESS_GROUP: true,
            ACCESS_CONTROL: true,
          });
        }
      }
      else {
        if (idOrCode === "MANAGE_DOOR_ACCESS_GROUP") {
          if (props.permissions['MANAGE_DOOR_ACCESS_GROUP']) {
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              ADD_DOOR_ACCESS_GROUP: false,
              MANAGE_ACCESS_GROUP_LIST: true,
              MANAGE_DOOR_ACCESS_GROUP: e.target.checked,
              ACCESS_CONTROL: props.permissions['MANAGE_ACCESS_GROUP_LIST'],
            });
          }
          else {
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              MANAGE_ACCESS_GROUP_LIST: true,
              ACCESS_CONTROL: true,
            });
          }

        }
      }
      if (idOrCode === 'ADD_PERSONS_ACCESS_GROUP') {
        if (props.permissions[idOrCode] === true) {
          props.setPermissions({
            ...props.permissions,
            [idOrCode]: e.target.checked,
            MANAGE_ACCESS_GROUP_LIST: true,
            MANAGE_PERSONS_ACCESS_GROUP: true,
            ACCESS_CONTROL: true,
          });
        }
        else {
          props.setPermissions({
            ...props.permissions,
            [idOrCode]: e.target.checked,
            MANAGE_ACCESS_GROUP_LIST: e.target.checked,
            MANAGE_PERSONS_ACCESS_GROUP: e.target.checked,
            ACCESS_CONTROL: e.target.checked,
          });
        }
      }
      else {
        if (idOrCode === "MANAGE_PERSONS_ACCESS_GROUP") {
          if (props.permissions[idOrCode] === true) {
            props.setPermissions({
              ...props.permissions,
              ADD_PERSONS_ACCESS_GROUP: false,
              MANAGE_ACCESS_GROUP_LIST: true,
              MANAGE_PERSONS_ACCESS_GROUP: false,
              ACCESS_CONTROL: props.permissions['MANAGE_ACCESS_GROUP_LIST'],
            });
          }
          else {
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              MANAGE_ACCESS_GROUP_LIST: true,
              ACCESS_CONTROL: true,
            });
          }
        }
      }
    }
    else if (idOrCode === "MANAGE_ACCESS_GROUP_LIST" && props.permissions['MANAGE_ACCESS_GROUP_LIST']) {
      props.setPermissions({
        ...props.permissions,
        ADD_EDIT_ACCESS_GROUP: false,
        DELETE_ACCESS_GROUP: false,
        MANAGE_DOOR_ACCESS_GROUP: false,
        ADD_DOOR_ACCESS_GROUP: false,
        MANAGE_PERSONS_ACCESS_GROUP: false,
        ADD_PERSONS_ACCESS_GROUP: false,
        MANAGE_ACCESS_GROUP_LIST: false,
        ACCESS_CONTROL: false,
      });
    }
    else if (idOrCode === "MANAGE_ACCESS_GROUP_LIST" && !props.permissions['MANAGE_ACCESS_GROUP_LIST']) {
      props.setPermissions({
        ...props.permissions,
        MANAGE_ACCESS_GROUP_LIST: e.target.checked,
        ACCESS_CONTROL: e.target.checked,
      });
    }
    else if (idOrCode === "EXPORT_ACC_REPORT" && idOrCode !== "VIEW_ACC_REPORT") {
      if (props.permissions[idOrCode] === true) {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_ACC_REPORT: true,
          ACC_REPORT: true,
        });
      }
      else {
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_ACC_REPORT: e.target.checked,
          ACC_REPORT: e.target.checked,
        });
      }
    } else if (idOrCode === "VIEW_ACC_REPORT" && props.permissions['VIEW_ACC_REPORT']) {
      props.setPermissions({
        ...props.permissions,
        EXPORT_ACC_REPORT: false,
        VIEW_ACC_REPORT: false,
        ACC_REPORT: false,
      });
    } else if (idOrCode === "VIEW_ACC_REPORT" && !props.permissions['VIEW_ACC_REPORT']) {
      props.setPermissions({
        ...props.permissions,
        VIEW_ACC_REPORT: e.target.checked,
        ACC_REPORT: e.target.checked,
      });
    }
    else if ((idOrCode === "ACC_LOGS" || idOrCode === "OPERATOR_LOGS")) {
      props.setPermissions({
        ...props.permissions,
        [idOrCode]: e.target.checked,
        ACC_LOGS: e.target.checked,
      });
    }
    else {
      props.setPermissions({
        ...props.permissions,
        [idOrCode]: e.target.checked,
      });
    }

  };




  const checkPermissionByCodeorId = (idOrCode) => {

    if (props.role === "ORGANIZATION OWNER" || props.role === "SUPERADMIN") return true;
    if (idOrCode === "VIEW_ACC_DASHBOARD") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_EVENT_OVERVIEW_GRAPH"] ||
        props.permissions["VIEW_SITE_OVERVIEW_MAP"] ||
        props.permissions["VIEW_ACC_DASHBOARD"]
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (idOrCode === "ACC_VIEW_PERSONLIST" || idOrCode === "ACC_VIEW_PERMISSION_LIST") {
      if (props.permissions[idOrCode] || props.permissions["ACC_VIEW_PERSON_PROFILE"] && idOrCode !== "ACC_VIEW_PERMISSION_LIST") {
        return true;
      }
      else if (props.permissions[idOrCode] || props.permissions["ACC_VIEW_PERMISSIONS"] && idOrCode !== "ACC_VIEW_PERSONLIST") {
        return true;
      }
      else {
        return false;
      }
    }
    if (idOrCode === "ACC_VIEW_SITE_LIST" || idOrCode === "ACC_VIEW_ZONE_LIST") {
      if (props.permissions[idOrCode] || props.permissions["ACC_VIEW_SITE"] && idOrCode !== "ACC_VIEW_ZONE_LIST") {
        return true;
      }
      else if (props.permissions[idOrCode] || props.permissions["ACC_VIEW_ZONE"] && idOrCode !== "ACC_VIEW_SITE_LIST") {
        return true;
      }
      else {
        return false;
      }
    }

    if (idOrCode === "VIEW_DEVICE_LIST_DETAILS") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["EDIT_DEVICE_LIST_DETAILS"] ||
        props.permissions["ACC_DEVICE_DELETE"] ||
        props.permissions["ACC_REBOOT_DEVICE"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "ACC_DOOR_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["ACC_VIEW_DOOR"] ||
        props.permissions["ACC_EDIT_DOOR"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "ACC_AUXILIARY_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["ACC_AUXILIARY_VIEW"] ||
        props.permissions["ACC_AUXILIARY_EDIT"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "ACC_AUXILIARY__OUTPUT_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["ACC_AUXILIARY__OUTPUT_VIEW"] ||
        props.permissions["ACC_AUXILIARY_OUTPUT_EDIT"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "ACC_READER_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["ACC_READER_VIEW"] ||
        props.permissions["ACC_READER_EDIT"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "TIMESLOT_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_TIMESLOT"] ||
        props.permissions["ADD_TIMESLOT"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "VIEW_DOOR_ACCESS_TIME_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN"] ||
        props.permissions["EDIT_ACCESS_GROUP"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "MANAGE_ACCESS_GROUP_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["ADD_EDIT_ACCESS_GROUP"] ||
        props.permissions["DELETE_ACCESS_GROUP"] || props.permissions["MANAGE_DOOR_ACCESS_GROUP"]
        || props.permissions["ADD_DOOR_ACCESS_GROUP"] || props.permissions["MANAGE_PERSONS_ACCESS_GROUP"] || props.permissions["ADD_PERSONS_ACCESS_GROUP"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "MANAGE_DOOR_ACCESS_GROUP") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["ADD_DOOR_ACCESS_GROUP"]
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (idOrCode === "MANAGE_PERSONS_ACCESS_GROUP") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["ADD_PERSONS_ACCESS_GROUP"]
      ) {
        return true;
      } else {
        return false;
      }
    }

    return props.permissions[idOrCode];
  };


  const handleKeyPress1 = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      props.handleSubmit();
    }
  };

  const parentStyle = {
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'rgba(71, 75, 79, 0.8)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "29px",
    fontFamily: "Roboto",
  }

  const childStyle = {
    "& .MuiTypography-root": {
      textAlign: 'left',
      letterSpacing: '0px',
      color: 'rgba(71, 75, 79, 0.8)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "29px",
      fontFamily: "Roboto",
    }
  }

  const accordionTree = () =>
    permissionTree.map((e) => {
      return (
        <Grid
          item
          xs={12}
          sx={{
            border: "1px solid #EAEBEB",
            width: "100%",
            borderRadius: "8px",
            margin: "10px 0px",
            '&:hover': {
              boxShadow: "2px 0px 4px #0000001A, -2px 0px 4px #0000001A, 0px 2px 4px #0000001A, 0px -2px 4px #0000001A",
              cursor: 'pointer',
              borderRadius: "8px",
            },
          }}
        >
          <Accordion
            expanded={isExpaded(e.parent.title === 'Video Intercom' ? null : e.parent.title)}
            onChange={() => {
              if (expand === e.parent.title) {
                setExpanded(!expand);
              } else {
                setExpanded(e.parent.title);
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                e.parent.title !== 'Video Intercom' ? (
                  <LeftArrowIcon
                    style={{ transform: "rotate(270deg)", margin: "0px 8px" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (expand === e.parent.title) {
                        setExpanded(!expand);
                      } else {
                        setExpanded(e.parent.title);
                      }
                    }}
                  />)
                  : ''}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={e.parent.title !== 'Video Intercom' ?
                      !isChecked(e.parent.codes) === true &&
                      !defaultRoles.includes(props.role)
                      : ''}
                    onKeyDown={handleKeyPress1}
                    disabled={props.disabled}
                    value={props.role === "ORGANIZATION OWNER" || props.role === "SUPERADMIN"
                      ? true
                      : props.permissions[e.parent.codes[0]] ||
                      isChecked(e.parent.codes)}
                    checked={
                      props.role === "ORGANIZATION OWNER" || props.role === "SUPERADMIN"
                        ? true
                        : props.permissions[e.parent.codes[0]] ||
                        isChecked(e.parent.codes)
                    }
                    onChange={(event) => {
                      handleChange(e.parent.codes, event);
                    }}
                  />
                }
              />
              <Typography sx={{ ...parentStyle, paddingTop: "10px", marginRight: "1px" }}>
                {e.parent.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ borderRadius: "8px" }}>
              <Grid ml={4}>
                {e.child.map((c) => {
                  return (
                    <FormControlLabel
                      sx={childStyle}
                      label={c.title}
                      indeterminate={!isChecked(e.parent.codes) === true}
                      control={
                        <Checkbox
                          disabled={props.disabled}
                          value={props.permissions[c.code]}
                          checked={checkPermissionByCodeorId(c.code)}
                        />
                      }
                      onKeyDown={handleKeyPress1}
                      onChange={(e) => {
                        selectPermission(c.code, e);
                        // props.setPermissions({
                        //   ...props.permissions,
                        //   [c.code]: e.target.checked,
                        // });
                      }}
                    />
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  const defaultRoles = ["SUPERADMIN", "ORGANIZATION OWNER"]
  return <Grid style={{ width: "100%" }}>{accordionTree()}</Grid>;
}
