import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import LoginLayoutZlink from './LoginLayoutZlink';
import CommonUtil from '../util/CommonUtils';

export default function SessionOut() {
    const redirectKey = CommonUtil.getCookie('zlink_redirect_url');
    return (

        <Grid container>
            <Grid item sm={6} xs={12}>
                <LoginLayoutZlink />
            </Grid>
            <Grid item sm={6} className='signIn-right' xs={12}>
                <Grid component={'main'} container justifyContent='center' alignItems='center'>
                    <Grid sm={8} sx={{ position: 'relative', marginTop: '200px' }}>
                        <Typography variant='h5' component='div' gutterBottom style={{ color: '#474A4E' }}>
                            Session timeout,
                        </Typography>
                        <Button
                            style={{ width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF' }}
                            // onClick={() => {
                            //     let zlinkUrl = '';
                            //     if (window.location.href.includes('staging')) {
                            //       zlinkUrl = 'https://zlink-staging.minervaiotdev.com';
                            //     } else if (window.location.href.includes('10.10.10.142')) {
                            //       zlinkUrl = 'http://10.10.10.142:8021';
                            //     } 
                            //     // else if (window.location.href.includes('dev')) {
                            //     //     zlinkUrl = 'http://localhost:3000/zlink/login';
                            //     // } 
                            //     else if (window.location.href.includes('dev')) {
                            //       zlinkUrl = 'https://zlink-web.minervaiotdev.com';
                            //     } else {
                            //       zlinkUrl = 'https://zlink.minervaiot.com';
                            //     }
                            //     window.location = zlinkUrl;
                            //   }}
                        >
                            <Typography>Login with zlink</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}
