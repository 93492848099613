import { Autocomplete, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import Loader from '../../components/Loader';
import ViewPage from '../../components/ViewPage';
import api from '../../configurations/http-common';
import { getFilterAccessGroup, updateAccessGroup } from '../../services/AccessGroupService';
import { getPermissionsByRoleId } from '../../services/RoleService';
import { getTimeZoneIntervalList } from '../../services/SchedulesService';
import CommonUtil from '../../util/CommonUtils';
import CustomButton from '../../components/CustomButton';

export default function EditGroupAccessTime(props) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const [schedules, setSchedules] = React.useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const accessName = useParams();
  const [loader, setLoader] = React.useState(true);
  const screenWidthsp = useMediaQuery('(min-width: 1536px) and (max-width: 1600px)');
  const [payload, setPayload] = React.useState({
    id: location.state?.data.id,
    name: location.state?.data.name,
    schedulerId: location.state?.data.schedulerId,
    startTime: moment.utc(location.state?.startTime),
    endTime: moment.utc(location.state?.endTime)
  });
  const [error, setError] = React.useState({
    name: false,
    schedulerId: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadSchedules(0, 1000, '');
  }, []);

  const handleClose = () => {
    navigate(
      '/group-access-time',
      { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })
  };

  const [addAccessGroup, setAddAccessGroup] = React.useState(false);

  React.useEffect(() => {
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setPermissions(prev => [...prev, item.code])
        });
      }
      setAddAccessGroup(true);
    });
  }, []);

  const loadAccessGroup = (page, size) => {
    const filter = {
      id: accessName.acecssName,
    }
    setLoader(true);
    getFilterAccessGroup(1, 1, filter).then((res) => {
      if (
        res?.status === 200 &
        res != null &&
        !CommonUtil.isEmpty(res) &&
        res?.data?.code === 'DMSI0000' &&
        !CommonUtil.isEmpty(res?.data?.data) &&
        res.data.data.accessGroups instanceof Array
      ) {

        setPayload({
          id: res.data.data.accessGroups[0].id,
          name: res.data.data.accessGroups[0].name,
          schedulerId: res.data.data.accessGroups[0].schedulerId,
          startTime: moment.utc(res.data.data.accessGroups[0].startTime),
          endTime: moment.utc(res.data.data.accessGroups[0].endTime)
        });
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    if (checkPermission("ADD_EDIT_ACCESS_GROUP")) {
      loadAccessGroup()
    }
  }, [permissions])

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  const resetFilter = () => {
    loadAccessGroup();
    setError({
      ...error,
      startTime: false,
      endOf: false,
      name: false,
      schedulerId: false
    })
  };

  const handleSubmit = () => {
    if (!payload.schedulerId) {
      setError((prevError) => ({
        ...prevError,
        schedulerId: true,
      }));
      console.error("Scheduler ID is required");
      return; // Stop submission if schedulerId is not valid
    }

    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime017'), { variant: 'error' })
      return;
    }
    if (CommonUtil.isEmptyString(payload.schedulerId)) {
      setError({
        ...error,
        schedulerId: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime018'), { variant: 'error' })
      return;
    }

    if (CommonUtil.isEmptyString(payload.startTime)) {
      setError({
        ...error,
        startTime: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime019'), { variant: 'error' })
      return;
    }
    if (CommonUtil.isEmptyString(payload.endTime)) {
      setError({
        ...error,
        endOf: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime020'), { variant: 'error' })
      return;
    }

    if (payload.startTime.isAfter(payload.endTime)) {
      setError({
        ...error,
        endOf: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime038'), { variant: 'error' })
      return;
    }

    if (moment(payload.startTime).isSame(moment("Fri Jan 01 2038 00:00:00 GMT+0000"))) {
      setError({
        ...error,
        startTime: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    if (moment(payload.endTime).isSame(moment("Fri Jan 01 2038 00:00:00 GMT+0000"))) {
      setError({
        ...error,
        endOf: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    if (payload.startTime.isAfter(moment.utc('2038-01-01', 'YYYY-MM-DD'))) {
      setError({
        ...error,
        startTime: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    if (payload.endTime.isAfter(moment.utc('2038-01-01', 'YYYY-MM-DD'))) {
      setError({
        ...error,
        endOf: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    const state = {
      name: payload.name,
      schedulerId: payload.schedulerId,
      startTime: convertDate(payload.startTime),
      endTime: convertDate(payload.endTime)
    };

    if (CommonUtil.isEmptyString(payload.id)) {
    } else {
      setLoader(true);
      setIsLoading(true);
      updateAccessGroup(payload.id, state).then((res) => {
        if (res?.data?.code === 'DMSI0000') {
          setIsLoading(false);
          enqueueSnackbar(t("AccGroupAccessTime026"), { variant: 'success' })
          handleClose();
        } else {
          setIsLoading(false);
          enqueueSnackbar((res.data.msg), { variant: 'error' })
        }
        setLoader(false);
      });
    }
  };

  const convertDate = (dateString) => {
    if (dateString) {
      const dat = moment(dateString);
      const offset = (0 >= 0 ? '+' : '-') + Math.abs(0).toString().padStart(2, '0') + ':00';
      return dat.format('YYYY-MM-DDTHH:mm:ss') + offset;
    }
  }

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: false,
    });
  };

  const loadSchedules = (page, size, name) => {
    getTimeZoneIntervalList(page + 1, size, name).then((res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000'
        && !CommonUtil.isEmpty(res?.data?.data) && res?.data?.data?.timeZone instanceof Array) {
        setSchedules(res.data.data.timeZone);
      } else {
        setSchedules([]);
      }
    });
  };

  const disabledEndDate = (current) => {
    const currentv1 = new Date(current);
    const startTime1 = new Date(payload.startTime);
    startTime1.setDate(startTime1.getDate() - 1);
    return current && (currentv1 < startTime1);
  };

  const findTimeSlotById = (id) => {
    if (CommonUtil.isEmptyString(id)) {
      return { id: "", operationName: "" };
    }
    var timeSlot = { id: "", operationName: "" };
    schedules.map((s) => {
      if (s.id === id) {
        timeSlot = s;
      }
    });
    return timeSlot;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isLoading) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const FieldName = () => (
    <CustomInput
      id="textfield-updategroupaccesstime-name"
      error={error.name}
      name='name'
      label={t('COMMON006')}
      required
      size='small'
      height={{ xl: "64px", xs: "50px" }}
      padding={screenWidthsp ? "0px 10px" : { xl: "0px 20px", xs: "0px 10px" }}
      onKeyDown={handleKeyPress}
      placeholder={t('AccGroupAccessTime022')}
      value={payload.name}
      handleChange={(data) => handleChange(data)}
      inputProps={{
        maxLength: 250,
      }}
    />
  );

  const FieldTimeSlot = () => (
    <>
      <Autocomplete
        id="dropdown-updategroupaccesstime-timeslot"
        fullWidth
        size='small'
        // onKeyDown={handleKeyPress}
        value={payload.schedulerId ? findTimeSlotById(payload.schedulerId) : { id: "", operationName: "" }}
        options={schedules ? schedules : []}
        getOptionLabel={(option) => option.operationName}
        renderInput={(params) =>
          <CustomInput
            label={t('AccTime013')}
            required
            height={{ xl: "46px", xs: "33px" }}
            padding={{ xl: "0px 20px", xs: "0px 5px" }}
            handleChange={(e) => console.log()}
            {...params}
            variant='outlined'
            placeholder={t('AccGroupAccessTime023')}
            error={error.schedulerId}
            cursor='pointer'
          />}
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => handleValueChange(event, newValue)}
        onBlur={() => {
          if (!payload.schedulerId) {
            setError({ ...error, schedulerId: true });
          }
        }}
      />
    </>
  );

  const handleValueChange = (event, newValue) => {

    if (newValue?.id) {
      setPayload((prevPayload) => ({
        ...prevPayload,
        schedulerId: newValue.id,
      }));
      setError((prevError) => ({
        ...prevError,
        schedulerId: false,
      }));
    } else {
      setPayload((prevPayload) => ({
        ...prevPayload,
        schedulerId: '',
      }));
      setError((prevError) => ({
        ...prevError,
        schedulerId: true,
      }));
    }
  };

  const screenWidth = useMediaQuery('(min-width: 1600px)')
  const disabledStartDate = (current) => {
    return current && current < moment().startOf('day');
  };
  const FieldStartTime = () => (
    <>
      <Typography variant='inputFieldLabel' >
        {t('AccGroupAccessTime004')} <span style={{ color: 'red' }}>*</span>
      </Typography>
      <DatePicker
        showTime
        placeholder={t('AccGroupAccessTime024')}
        // value={payload.startTime}
        value={isNaN(new Date(payload.startTime).getTime()) ? null : payload.startTime}
        onKeyDown={handleKeyPress}
        label={t('AccGroupAccessTime004')}
        onChange={(startTime) => {
          setPayload({
            ...payload,
            startTime: startTime,
          });
          setError({
            ...error,
            startTime: false
          });
        }}
        // format={yyyy-MM-dd'T'HH:mm:ssXXX"}
        disabledDate={disabledStartDate}
        style={{
          width: '100%',
          height: screenWidth ? "64px" : "50px",
          marginTop: "10px",
          borderColor: error.startTime ? 'red' : 'rgba(0, 0, 0, 0.23)',
          cursor: 'pointer'
        }}
      />
    </>
  );

  const FieldEndDate = () => (
    <>
      <Typography variant='inputFieldLabel' >
        {t('AccGroupAccessTime005')} <span style={{ color: 'red' }}>*</span>
      </Typography>
      <DatePicker
        showTime
        placeholder={t('AccGroupAccessTime025')}
        // value={payload.endTime}
        value={isNaN(new Date(payload.endTime).getTime()) ? null : payload.endTime}
        onKeyDown={handleKeyPress}
        label={t('AccGroupAccessTime005')}
        onChange={(endTime) => {
          setPayload({
            ...payload,
            endTime: endTime
          });
          setError({
            ...error,
            endOf: false
          });
        }}
        minDateTime={payload.startTime}
        disabledDate={disabledEndDate}
        style={{
          width: '100%',
          marginTop: "10px",
          height: screenWidth ? "64px" : "50px",
          borderColor: error.endOf ? 'red' : 'rgba(0, 0, 0, 0.23)',
          cursor: 'pointer'
        }}
      />
    </>
  );

  return (
    <>
      {addAccessGroup ? checkPermission("ADD_EDIT_ACCESS_GROUP") ?
        <ViewPage
          title={t("AccGroupAccessTime008")}
          path={true}
          onClick={() => navigate('/group-access-time',
            { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
        >
          {loader ? <Loader open={loader} /> : <></>}
          <Grid container ml={-0.5} >
            <Grid item xs={12} md={6} sx={{ pr: { xl: "30px", md: "20px" } }}>
              {FieldName()}
            </Grid>
            <Grid item xs={12} md={6} >
              {FieldTimeSlot()}
            </Grid>
            <Grid item xs={12} md={6} sx={{ pr: { xl: "30px", md: "20px" } }}>
              {FieldStartTime()}
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { xl: "0px", md: "0px", xs: "20px" } }} >
              {FieldEndDate()}
            </Grid>
            <Grid item xs={12}
              sx={{
                mt: { xl: "40px", xs: "30px" },
                display: "flex",
                justifyContent: { lg: "flex-end", xs: "center" }
              }}
            >

              {/* <Button
                id="button-updategroupaccesstime-save"
                onClick={handleSubmit}
                variant='contained'
                sx={{
                  width: { xl: "233px", xs: "180px" },
                  height: { xl: "64px", xs: "50px" },
                  marginRight: "30px"
                }}
              >
                {t('COMMON023')}
              </Button> */}
              <CustomButton
                label={t('COMMON023')}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
              />

              <Button
                id="button-updategroupaccesstime-clear"
                onClick={() => resetFilter()}
                variant='outlined'
                sx={{
                  width: { xl: "233px", xs: "180px" },
                  height: { xl: "64px", xs: "50px" },
                }}
              >
                {t('COMMON015')}
              </Button>

            </Grid>
          </Grid>
        </ViewPage>
        : (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t("AccGroupAccessTime045")}</Typography>
        </Box>) : <></>}
    </>
  );
}
