import { Box, Collapse, Grid, IconButton, List, ListItemText, Typography } from '@mui/material';
import { Popover } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as OperationLogIcon } from '../../assets/OperationLogIcon.svg';
import { ReactComponent as AccessIconwithName } from '../../assets/ZKBio-cloud-access.svg';
import { ReactComponent as CloudAccess } from '../../assets/cloud2.svg';
import { ReactComponent as Access_Icon } from '../../assets/new-images/accessIcon.svg';
import { ReactComponent as DashBoard } from '../../assets/new-images/dashboardIcon.svg';
import { ReactComponent as Device_Icon } from '../../assets/new-images/deviceIcon.svg';
import { ReactComponent as Expand } from '../../assets/new-images/expandIcon.svg';
import { ReactComponent as Organization_Icon } from '../../assets/new-images/organizationIcon.svg';
import { ReactComponent as Report_Icon } from '../../assets/new-images/reportIcon.svg';
import { ReactComponent as Site_Icon } from '../../assets/new-images/siteIcon.svg';
import { ReactComponent as ArrowDown } from '../../assets/new-images/upArrow.svg';
import { ReactComponent as FilledDashboardIcon } from '../../assets/new-images/FilledDashboardIcon.svg';
import { ReactComponent as FilledOrgIcon } from '../../assets/new-images/FilledOrgIcon.svg';
import { ReactComponent as FilledSiteIcon } from '../../assets/new-images/FilledSiteIcon.svg';
import { ReactComponent as FilledDeviceIcon } from '../../assets/new-images/FilleddeviceIcon.svg';
import { ReactComponent as FilledAccessIcon } from '../../assets/new-images/FilledAccessIcon.svg';
import { ReactComponent as FilledReportIcon } from '../../assets/new-images/FilledReportIcon.svg';
import { ReactComponent as FilledOperationIcon } from '../../assets/new-images/FilledOperationLogIcon.svg';

import api from '../../configurations/http-common';
import {
  REACT_ADD_GROUP_ACCESS_TIME, REACT_CREATE_TIMESLOT, REACT_DASHBORD, REACT_EDIT_GROUP_ACCESS_TIME, REACT_TIME_SLOT, REACT_UPDATE_TIMESLOT, REACT_URL_ACCESS_GROUP_ADD_DOOR, REACT_URL_ACCESS_GROUP_ADD_PERSON, REACT_URL_ACCESS_GROUP_DOOR, REACT_URL_ACCESS_GROUP_PERSON, REACT_URL_ADD_DEVICE_INSTRUCTION, REACT_URL_AUXILIARY_INPUT, REACT_URL_AUXILIARY_INPUT_EDIT, REACT_URL_AUXILIARY_INPUT_VIEW, REACT_URL_AUXILIARY_OUTPUT, REACT_URL_AUXILIARY_OUTPUT_EDIT, REACT_URL_AUXILIARY_OUTPUT_VIEW, REACT_URL_BIND_DEVICE, REACT_URL_DEVICE_LIST_V4,
  REACT_URL_DEVICE_TOPOLOGY, REACT_URL_DOOR_ACCESS_TIME, REACT_URL_DOOR_LIST, REACT_URL_EDIT_DOOR, REACT_URL_EVENT, REACT_URL_OPERATION_LOG_LIST, REACT_URL_PERSON, REACT_URL_PERSON_IN_DEVICE, REACT_URL_PERSON_VIEW, REACT_URL_QUICK_SETUP, REACT_URL_QUICK_SETUP_PAGE, REACT_URL_READER, REACT_URL_READER_EDIT, REACT_URL_READER_VIEW, REACT_URL_SITE, REACT_URL_SITE_VIEW, REACT_URL_UPDATEROLES, REACT_URL_UPDATE_DEVICE, REACT_URL_USERROLES, REACT_URL_VIEWEROLES, REACT_URL_ZONE, REACT_URL_ZONE_VIEW
} from '../../constants/ReactEndPoints';
import { applicationConfigsApi } from '../../services/OnboardService';
import { getPermissionsByRoleId } from '../../services/RoleService';

export default function SidebarAccess(props) {
  let navigate = useNavigate();
  // const [contextMenu, setContextMenu] = React.useState(true);
  const [contextMenu, setContextMenu] = React.useState(localStorage.getItem("sidebar") === 'true');
  const [selectedMenu, setSelectedMenu] = useState("1");
  const [selectedSubMenu, setSelectedSubMenu] = React.useState({ child: "1", parent: "1" });
  const [roles, setRoles] = React.useState([]);
  const [version, setVersion] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopup, setOpenPopUp] = React.useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const dataEn = [
    {
      id: '1',
      name: t("SIDEBAR001"),
      link: `${REACT_DASHBORD}`,
      Icon: DashBoard,
      filledIcon: FilledDashboardIcon
    },
    {
      id: '2',
      name: t("SIDEBAR002"),
      Icon: Organization_Icon,
      filledIcon: FilledOrgIcon,
      items: [
        {
          id: '3',
          name: t("SIDEBAR003"),
          link: `${REACT_URL_PERSON}`,
          _sub_link: [REACT_URL_PERSON_VIEW],

        },
        {
          id: '4',
          name: t("SIDEBAR004"),
          link: `${REACT_URL_USERROLES}`,
          _sub_link: [REACT_URL_UPDATEROLES, REACT_URL_VIEWEROLES],
        },
      ],
    },
    {
      id: '5',
      name: t("SIDEBAR005"),
      Icon: Site_Icon,
      filledIcon: FilledSiteIcon,
      items: [
        {
          id: '6',
          name: t("SIDEBAR006"),
          link: `${REACT_URL_SITE}`,
          _sub_link: [REACT_URL_SITE_VIEW],
        },

        {
          id: '7',
          name: t("SIDEBAR007"),
          link: `${REACT_URL_ZONE}`,
          _sub_link: [REACT_URL_ZONE_VIEW],
        },
      ],
    },
    {
      id: '8',
      name: t("SIDEBAR008"),
      Icon: Device_Icon,
      filledIcon: FilledDeviceIcon,
      items: [
        {
          id: '9',
          name: t("SIDEBAR009"),
          link: REACT_URL_DEVICE_TOPOLOGY,
          _sub_link: [],
        },
        {
          id: '10',
          name: t("SIDEBAR010"),
          link: REACT_URL_DEVICE_LIST_V4,
          _sub_link: [REACT_URL_ADD_DEVICE_INSTRUCTION,
            REACT_URL_BIND_DEVICE, REACT_URL_PERSON_IN_DEVICE,
            REACT_URL_UPDATE_DEVICE],

        },
        {
          id: '11',
          name: t("SIDEBAR011"),
          link: REACT_URL_DOOR_LIST,
          _sub_link: [REACT_URL_EDIT_DOOR],
        },

        {
          id: '12',
          name: t("SIDEBAR012"),
          link: `${REACT_URL_READER}`,
          _sub_link: [REACT_URL_READER_VIEW,
            REACT_URL_READER_EDIT]
        },
        {
          id: '13',
          name: t("SIDEBAR013"),
          link: `${REACT_URL_AUXILIARY_INPUT}`,
          _sub_link: [REACT_URL_AUXILIARY_INPUT_VIEW, REACT_URL_AUXILIARY_INPUT_EDIT],
        },
        {
          id: '14',
          name: t("SIDEBAR014"),
          link: `${REACT_URL_AUXILIARY_OUTPUT}`,
          _sub_link: [REACT_URL_AUXILIARY_OUTPUT_EDIT, REACT_URL_AUXILIARY_OUTPUT_VIEW],
        },
      ],
    },
    {
      id: '15',
      name: t("SIDEBAR015"),
      Icon: Access_Icon,
      filledIcon: FilledAccessIcon,
      items: [
        {
          id: '22',
          name: t("SIDEBAR022"),
          link: REACT_URL_QUICK_SETUP,
          _sub_link: [REACT_URL_QUICK_SETUP_PAGE],
        },
        {
          id: '16',
          name: t("SIDEBAR016"),
          link: REACT_TIME_SLOT,
          _sub_link: [REACT_CREATE_TIMESLOT, REACT_UPDATE_TIMESLOT],
        },
        {
          id: '17',
          name: t("SIDEBAR017"),
          link: REACT_URL_DOOR_ACCESS_TIME,
          _sub_link: [],
        },
        {
          id: '18',
          name: t("SIDEBAR018"),
          link: '/group-access-time',
          _sub_link: [REACT_ADD_GROUP_ACCESS_TIME, REACT_EDIT_GROUP_ACCESS_TIME,
            REACT_URL_ACCESS_GROUP_DOOR, REACT_URL_ACCESS_GROUP_ADD_DOOR, REACT_URL_ACCESS_GROUP_ADD_PERSON,
            REACT_URL_ACCESS_GROUP_PERSON],
        },
      ],
    },
    {
      id: '19',
      name: t("SIDEBAR019"),
      Icon: Report_Icon,
      filledIcon: FilledReportIcon,
      items: [
        {
          id: '20',
          name: t("SIDEBAR020"),
          link: `${REACT_URL_EVENT}`,
          _sub_link: [],
        },
      ],
    },
    {
      id: '21', // don't this id 21 and 1
      name: t("SIDEBAR021"),
      Icon: OperationLogIcon,
      filledIcon: FilledOperationIcon,
      link: `${REACT_URL_OPERATION_LOG_LIST}`,
    },
  ];

  const siteMenu = ['ACC_VIEW_SITE_LISTS', 'ACC_VIEW_ZONE_LIST'];

  useEffect(() => {
    if (api.decoded()?.roleId !== undefined) {
      applicationConfigsApi().then((res) => {
        if (res.data?.code === 'CASI0001') {
          setVersion(res.data?.data?.version);
        }
      });
      getPermissionsByRoleId(api.decoded()?.roleId).then((res) => {
        var l = new Set();
        res.data?.data?.rolePermissions.map((item, index) => {
          if (siteMenu.includes(item.code)) {
            l.add("SITE_MANAGMENT_DETAILS");
          }
          if (item.code === 'ACC_VIEW_PERSONLIST') {
            l.add("ACC_ORGANIZATION");
          }
          if (item.code === "VIEW_DEVICE_LIST_DETAILS") {
            l.add("DEVICE_MANAGEMENT")
          }
          l.add(item.code);
        });
        setRoles(Array.from(l));
      });
    }
  }, []);

  const checkPermission = (code) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      return roles.includes(code);
    }
  }

  const handleMenu = (menu) => {
    if (!contextMenu && (menu.id === '1' || menu.id === '21')) {
      setSelectedSubMenu({ child: menu.id, parent: menu.id });
      setSelectedMenu(menu.id);
      navigate(menu.link);
    }
    else {
      if (selectedMenu === menu.id) {
        setSelectedMenu('');
      } else {
        setSelectedMenu(menu.id);
      }
    }
  };

  const handleSubMenu = (submenu, menu) => {
    setAnchorEl("")
    setSelectedSubMenu({ child: submenu.id, parent: menu.id });
    if (submenu.link) navigate(submenu.link);
  };

  const handleClickIcon = () => {
    setSelectedMenu('1')
    setSelectedSubMenu({ child: "1", parent: "1" });
    navigate(REACT_DASHBORD);
  }

  const navMaps = {
    '3': 'ACC_VIEW_PERSONLIST',
    '4': 'ACC_VIEW_PERMISSION_LIST',
    '9': 'ACC_VIEW_DEVICE_TOPOLOGY',
    '10': 'VIEW_DEVICE_LIST_DETAILS',
    '11': 'ACC_DOOR_LIST',
    '12': 'ACC_READER_LIST',
    '13': 'ACC_AUXILIARY_LIST',
    '14': 'ACC_AUXILIARY__OUTPUT_LIST',
    '16': 'TIMESLOT_LIST',
    '17': 'VIEW_DOOR_ACCESS_TIME_LIST',
    '18': 'MANAGE_ACCESS_GROUP_LIST',
    '20': 'VIEW_ACC_REPORT',
    '21': 'OPERATOR_LOGS',
    '6': 'ACC_VIEW_SITE_LISTS',
    '7': 'ACC_VIEW_ZONE_LIST',
    '8': 'DEVICE_MANAGEMENT',
    '2': 'ACC_ORGANIZATION',
    '5': 'SITE_MANAGMENT_DETAILS',
    '15': 'ACCESS_CONTROL',
    '19': 'ACC_REPORT',
    '1': 'ACC_DASHBOARD',
    "22": "ADD_EDIT_ACCESS_GROUP"
  }

  const checkDashboard = (item) => {
    if (item == 'ACC_DASHBOARD') {
      if (checkPermission("VIEW_EVENT_OVERVIEW_GRAPH")) {
        return true;
      }
      if (checkPermission("VIEW_SITE_OVERVIEW_MAP")) {
        return true;
      }
      if (checkPermission("VIEW_ACC_DASHBOARD")) {
        return true;
      }
    }
    return false;
  }

  const checkSubModule = (items) => {
    var ret = false;
    if (items instanceof Array) {
      items.map((cItem, cIndex) => {
        if (checkPermission(navMaps[cItem.id])) {
          ret = true;
          return ret;
        }
      });
    }
    return ret;
  }

  useEffect(() => {
    if (location.pathname === REACT_DASHBORD) {
      setSelectedSubMenu({ child: "1", parent: "1" });
      setSelectedMenu("1");
    } else if (location.pathname === REACT_URL_OPERATION_LOG_LIST) {
      setSelectedSubMenu({ child: "21", parent: "21" });
      setSelectedMenu("21");
    } else {
      getPathSelect(location.pathname);
    }
  }, [location.pathname])

  const getPathSelect = (path) => {
    dataEn.map((item) => {
      if (item.items instanceof Array)
        (item.items).map((cItem) => {
          if (cItem.link === path) {
            setSelectedMenu(item.id);
            setSelectedSubMenu({ child: cItem.id, parent: item.id });
            return true
          } else {
            if (cItem._sub_link instanceof Array) {
              (cItem._sub_link).map((url) => {
                if ((path).includes(url)) {
                  setSelectedMenu(item.id);
                  setSelectedSubMenu({ child: cItem.id, parent: item.id });
                  return true;
                }
              })
            }
          }
        })
    })
  };

  const handleHoverChange = (open, id) => {
    if (contextMenu) {
      if (open)
        setAnchorEl(id)
      else
        setAnchorEl("")
    }
  };

  const contentUI = (item, value) => (
    <>
      {!(item.id === "1" || item.id === "21") ? (value instanceof Array &&
        value.map((cItem, cIndex) => (
          <Box display={"flex"} alignItems={"center"} key={cIndex}
            onClick={() => handleSubMenu(cItem, item)}
            sx={{
              ":hover": {
                backgroundColor: '#76B7451f',
                borderRadius: "6px"
              },
              cursor: 'pointer'
            }}
          >
            {checkPermission(navMaps[cItem.id]) &&
              (<List component='div' disablePadding style={{ marginLeft: selectedSubMenu.child === cItem.id ? '-5.5px' : '-3.5px' }} >
                {contextMenu === true && (
                  <ListItemText
                    primary={
                      <ul style={{ margin: "0px" }} >
                        <div style={{
                          width: selectedSubMenu.child === cItem.id ? '8px' : '4px',
                          height: selectedSubMenu.child === cItem.id ? '8px' : '4px',
                          borderRadius: '50%',
                          background: selectedSubMenu.child === cItem.id ? '#76B745' : '#76B745',
                          opacity: selectedSubMenu.child === cItem.id ? '1' : '.5',
                          display: 'inline-block',
                          marginRight: selectedSubMenu.child === cItem.id ? '19.2px' : '21.5px',
                          marginBottom: selectedSubMenu.child === cItem.id ? '1px' : '2px'
                        }}>

                        </div>
                        <span style={{ marginLeft: '5px' }}>{cItem.name}</span>
                      </ul>
                    }
                    primaryTypographyProps={{
                      fontWeight: selectedSubMenu.child === cItem.id,
                      color: selectedSubMenu.child === cItem.id ? '#76B745' : '#474B4F',
                      fontSize: '14px',
                      lineHeight: "18px",
                      fontFamily: "Roboto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    sx={{
                      "&.MuiListItemText-root": {
                        padding: "10px 0px !important",
                        margin: "0px !important"
                      }
                    }}
                  />
                )}
              </List>)}
          </Box>
        ))) : (<Box p={"10px"}
          sx={{
            ":hover": {
              backgroundColor: '#76B7451f',
              borderRadius: "6px"
            },
            cursor: 'pointer'
          }}
        ><Typography
          onClick={() => { navigate(item.link); setAnchorEl("") }}
          sx={{
            fontWeight: selectedSubMenu.parent === item.id,
            color: selectedSubMenu.parent === item.id ? '#76B745' : '#474B4F',
            fontSize: '14px',
            lineHeight: "18px",
            fontFamily: "Roboto",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >{item.name}</Typography></Box>)}
    </>
  )

  return (
    <Grid
      sx={{
        display: 'flex',
        backgroundColor: '#FFFFFF',
        boxShadow: "0px 1px 4px #0000001A",
        position: 'relative',
        bottom: '60px',
        borderRight: '1px solid #DDDDDD',
        minWidth: !contextMenu ? '250px' : '70px',
        transition: "all 0.3s ease-in-out",
        flexDirection: "column",
      }}
    ><>
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#fff',
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            minHeight: "60px",
            paddingLeft: "10px"
          }}>
          <CloudAccess
            style={{
              cursor: 'pointer'
            }}
            onClick={() => handleClickIcon()}
          />

          <AccessIconwithName
            style={{
              marginLeft: !contextMenu ? '10px' : "0px",
              width: !contextMenu ? '103.821px' : '0px',
              height: "18px",
              transition: "all 0.3s ease-in-out",
              cursor: 'pointer'
            }}
            onClick={() => handleClickIcon()}
          />
        </Box>
      </>
      <PerfectScrollbar>
        <Box sx={{ paddingTop: { xl: "30px", xs: "10px" }, cursor: 'pointer' }}>
          {dataEn.map((item, index) => (
            <>
              {(checkPermission(navMaps[item.id]) || checkDashboard(navMaps[item.id]) || checkSubModule(item.id)) ? <>
                {" "}
                <Popover
                  key={index}
                  placement="right"
                  title={(item.id === "1" || item.id === "21") ? "" : item.name}
                  content={contentUI(item, item.items)}
                  open={anchorEl === item.id}
                  onOpenChange={(e) => handleHoverChange(e, item.id)}
                >
                  <Box
                    sx={{
                      backgroundColor: selectedSubMenu.parent === item.id ? 'rgba(118, 183, 69, 12%)' : '',
                      borderInlineEndWidth: selectedSubMenu.parent === item.id ? '4px' : '0px',
                      borderInlineEndStyle: selectedSubMenu.parent === item.id ? 'solid' : 'none',
                      borderInlineEndColor: selectedSubMenu.parent === item.id ? "#76B745" : '#fff',
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      paddingLeft: "20px",
                      ':hover': {
                        backgroundColor: 'rgba(118, 183, 69, 12%)',
                      },
                      cursor: "pointer"
                    }}
                    onClick={() => handleMenu(item)}
                  >
                    <IconButton
                      disableRipple
                      children={selectedSubMenu.parent === item.id ? <item.filledIcon /> : <item.Icon fill={'#474B4F'} />}
                    />
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        width: !contextMenu ? "100%" : "0%",
                        transition: "all 0.3s ease-in-out",
                        marginLeft: !contextMenu ? "12px" : "0px"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: selectedSubMenu.parent === item.id,
                          color: selectedSubMenu.parent === item.id ? "#76B745" : '#474B4F',
                          fontSize: '16px',
                          lineHeight: "21px",
                          fontFamily: "Roboto",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: !contextMenu ? '150px' : '0px',
                          transition: "all 0.3s ease-in-out",
                          cursor: 'pointer',
                          marginLeft: item.name === 'Report' ? '3px' : '0px'
                        }}
                      >{item.name}</Typography>
                      {item.items instanceof Array && item.items.length > 0 &&
                        (<ArrowDown
                          style={{
                            display: !contextMenu ? 'inline' : 'none',
                            transform: selectedMenu === item.id ? "rotate(0deg)" : 'rotate(-180deg)',
                            transition: 'all 0.3s ease ',
                            marginRight: !contextMenu ? "15px" : '0px',
                            marginTop: !contextMenu ? "6px" : '0px',
                            cursor: "pointer"
                          }} />)}
                    </Box>
                  </Box>
                </Popover>
                <Collapse in={selectedMenu === item.id} timeout='auto' unmountOnExit>
                  {item.items instanceof Array &&
                    item.items.map((cItem, cIndex) => (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                          ':hover': {
                            backgroundColor: 'rgba(118, 183, 69, 12%)',
                          },
                          cursor: 'pointer'
                        }}
                        onClick={() => handleSubMenu(cItem, item)}
                      >
                        {checkPermission(navMaps[cItem.id]) &&
                          (<List component='div' disablePadding style={{ marginLeft: selectedSubMenu.child === cItem.id ? '-5.5px' : '-3.5px' }}>
                            {contextMenu === false && (
                              <ListItemText
                                primary={
                                  <ul style={{ margin: "0px" }} >
                                    <div style={{
                                      width: selectedSubMenu.child === cItem.id ? '8px' : '4px',
                                      height: selectedSubMenu.child === cItem.id ? '8px' : '4px',
                                      borderRadius: '50%',
                                      background: selectedSubMenu.child === cItem.id ? '#76B745' : '#76B745',
                                      opacity: selectedSubMenu.child === cItem.id ? '1' : '.5',
                                      display: 'inline-block',
                                      marginRight: selectedSubMenu.child === cItem.id ? '19.2px' : '21.5px',
                                      marginBottom: selectedSubMenu.child === cItem.id ? '1px' : '2px'
                                    }}>

                                    </div>
                                    <span style={{ marginLeft: '5px' }}>{cItem.name}</span>
                                  </ul>
                                }
                                primaryTypographyProps={{
                                  fontWeight: selectedSubMenu.child === cItem.id,
                                  color: selectedSubMenu.child === cItem.id ? ' #76B745' : '#474B4F',
                                  fontSize: '16px',
                                  lineHeight: "21px",
                                  fontFamily: "Roboto",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                sx={{
                                  "&.MuiListItemText-root": {
                                    padding: "14.5px 0px 14.5px 0px !important",
                                    margin: "0px !important"
                                  }
                                }}
                              />
                            )}
                          </List>)}
                      </Box>
                    ))}
                </Collapse>
              </>
                : <></>}
            </>
          ))}
        </Box>
        <Box height={'65px'}></Box>
      </PerfectScrollbar>
      <Box sx={{
        bottom: "0px",
        position: 'absolute',
        width: "100%"
      }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            alignItems: "center",
            paddingLeft: !contextMenu ? "20px" : '0px',
            transition: "all 0.3s ease-in-out",
            paddingRight: "10px",
            paddingBottom: '10px',
          }}>
          {<Typography
            variant='subtitle4'
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: !contextMenu ? '100%' : '0%',
              transition: "all 0.3s ease-in-out",
              paddingLeft: "7px"
            }}
          >
            version V {version}
          </Typography>}
          <Box
            sx={{
              background: "rgba(245, 246, 246, 1)",
              height: "50px",
              minWidth: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px"
            }}
          >
            <Expand
              style={{
                transform: contextMenu ? "rotate(0deg)" : 'rotate(180deg)', /* Initial rotation */
                transition: 'transform 0.3s ease ',
                cursor: "pointer"
              }}
              onClick={() => { setContextMenu(!contextMenu); localStorage.setItem("sidebar", !contextMenu) }}

            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
