import { Link } from "react-router-dom";

function Users() {
    return (
        <div>
            <nav>
                <Link to="me">My Profile</Link>
            </nav>
        </div>
    );
}

export default Users;