import React from 'react';
import { ReactComponent as Icon404 } from '../assets/404Icon.svg'
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
    const { t } = useTranslation();

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "100px"
        }}
        id={"error-box"}
      >
        <Box sx={{
          display: { xl: "flex", xs: "none" },
          flexDirection: "column",
          alignItems: "center",
        }}>
          <Icon404 />
          <Box mt={9} >
            <Typography variant='labelV2'>{t("COMMON037")}</Typography>
          </Box>
        </Box>
        <Box sx={{
          display: { xl: "none", xs: "flex" },
  
          flexDirection: "column",
          alignItems: "center",
        }}>
          <Icon404 width={500} height={250} />
          <Box mt={3} >
            <Typography variant='labelV2'>{t("COMMON037")}</Typography>
          </Box>
        </Box>
  
  
      </Box >
    )
}

export default PageNotFound
