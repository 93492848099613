import { Avatar, Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/new-images/upArrow.svg";
import { getCompanyDetails } from "../../services/CompanyService";
import api from "../../configurations/http-common";
import { ReactComponent as CompanyPic } from "../../assets/new-images/companiesLogo.svg";
import OverFlowText from "../OverFlowText";
import OverFlowTypography from "../OverFlowTypography";

export default function CompanyProfileMenu() {
  const [company, setCompany] = useState(null);
  useEffect(() => {
    let user = api.decoded();
    if (user) {
      getCompanyDetails(user.companyId).then((res) => {
        if (res.data.code === 'UASI0026') setCompany(res.data.data);
      });
    }
  }, []);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: { lg: "200px", xs: "180px" },
          margin: "0 10px 0 0"
        }}
      >
        <Avatar
          sx={{
            width: "40px",
            height: "40px",
            margin: "0px 10px",
            backgroundColor: "#F2F2F4"
          }}
          src={company?.imagePreSignedURL && company?.imagePreSignedURL}>
          <CompanyPic width={"75%"} height={"70%"} />
        </Avatar>
        <OverFlowTypography
          value={company?.name && `${company?.name.charAt(0).toUpperCase()}${company?.name.slice(1)}`}
          font={"normal normal normal 16px/19px Roboto"}
          sx={{ cursor: "default" }}
        />
      </Box>
    </React.Fragment>
  );
}
