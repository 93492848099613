import { Box, Divider, InputLabel, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import CommonUtil from '../util/CommonUtils';
import CustomInput from './CustomInput';
import { makeStyles } from '@material-ui/core/styles';
import 'react-phone-input-nagaraj/lib/material.css';
import PhoneInput from 'react-phone-input-nagaraj';
import { ReactComponent as DownArrow } from '../assets/downArrow.svg';

const CustomePhoneInput = (props) => {
    const { t } = useTranslation();
    const useStyles = makeStyles((theme) => ({
        borderClass: {
            "&.react-tel-input .selected-flag .flag": {
                display: CommonUtil.isEmpty(props.countryCode) ? 'none' : 'block'
            },
            "&.react-tel-input .form-control": {
                textAlign: CommonUtil.isEmpty(props.countryCode) ? 'left !important' : 'center !important',
                paddingLeft: CommonUtil.isEmpty(props.countryCode) ? '20px !important' : '0px !important'
            },
            [theme.breakpoints.between(0, 1600)]: {
                "&.react-tel-input .form-control": {
                    textAlign: CommonUtil.isEmpty(props.countryCode) ? 'left !important' : 'center !important',
                    paddingLeft: CommonUtil.isEmpty(props.countryCode) ? '13px !important' : '0px !important'
                },
            }
        },
        customInput: {
            "& .MuiOutlinedInput-root ": {
                paddingLeft: '0px'
            },
            "& .MuiOutlinedInput-input": {
                padding: "0px !important"
            }
        }
    }));
    const classes = useStyles();

    return (
        <Box variant='standard' style={{ width: '100%' }} >
            {props.extraLabel ? <></> : <Typography style={labelStyle} sx={{ marginBottom: '4px' }}>
                <span >{props.label ? props.label : t('ZLINK0076')}</span> {props.req ? "" : <span style={{ color: '#FE5050' }}>*</span>}
            </Typography>}
            <Box sx={{ width: '100%' }}>
                {props.disabled ? <CustomInput
                    disabled
                    type={'text'}
                    placeholder={props.placeholder}
                    autoComplete='off'
                    fullWidth
                    className={classes.customInput}
                    error={props.error}
                    resetError={() => props.resetError()}
                    name={props.name}
                    value={props.phoneNumber}
                    variant='outlined'
                    size={props.size ? props.size : 'small'}
                    handleChange={(e) => props.handleChange(e.target.value)}
                    helperText={props.error}
                    validation='mobile'
                    InputProps={{
                        startAdornment: (
                            <>
                                <PhoneInput
                                    disabled={true}
                                    // onlyCountries={CommonUtil.getcounrylist()}
                                    autoFormat={true}
                                    countryCodeEditable={true}
                                    enableSearch={true}
                                    searchPlaceholder={'Search'}
                                    searchNotFound={'No Options Found'}
                                    onChange={(value, data) => {
                                        props.handleCountryCode(data)
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        sx: {
                                            height: props.height ? props.height : { xl: "64px", xs: "50px" },
                                            font: { xl: 'normal normal normal 16px/19px Roboto', xs: 'normal normal normal 14px/17px Roboto' },
                                            padding: props.padding ? props.padding : { xl: "0px 20px", xs: "0px 13px" },
                                            background: 'white !important',
                                            cursor: props.cursor
                                        }
                                    }}
                                    value={props.countryCode}
                                    containerClass={classes.borderClass}
                                    placeholder={t('CONTACT0036')}
                                    specialLabel={false}
                                    // isValid={CommonUtil.isEmptyString(props.error)}
                                    style={{ width: 'auto' }}
                                    inputStyle={{ marginBottom: '0px' }}
                                />
                                {<DownArrow style={{ paddingRight: '10px', cursor: 'default' }} />}
                                <Divider orientation="vertical" flexItem style={{ marginRight: '10px' }} />
                            </>
                        ),
                        sx: {
                            height: props.height ? props.height : { xl: "64px", xs: "50px" },
                            font: { xl: 'normal normal normal 16px/19px Roboto', xs: 'normal normal normal 14px/17px Roboto' },
                            padding: props.padding ? props.padding : { xl: "0px 20px", xs: "0px 13px" },
                            background: 'white !important',
                            cursor: props.cursor
                        }
                    }}
                /> : <CustomInput
                    type={'text'}
                    placeholder={props.placeholder}
                    autoComplete='off'
                    fullWidth
                    className={classes.customInput}
                    error={props.error}
                    resetError={() => props.resetError()}
                    name={props.name}
                    value={props.phoneNumber}
                    variant='outlined'
                    size={props.size ? props.size : 'small'}
                    handleChange={(e) => props.handleChange(e.target.value)}
                    helperText={props.error}
                    validation='mobile'
                    InputProps={{
                        startAdornment: (
                            <>
                                <PhoneInput
                                    autoFormat={true}
                                    // onlyCountries={CommonUtil.getcounrylist()}
                                    countryCodeEditable={true}
                                    enableSearch={true}
                                    searchPlaceholder={'Search'}
                                    searchNotFound={'No Options Found'}
                                    onChange={(value, data) => {
                                        props.handleCountryCode(data)
                                    }}
                                    inputProps={{
                                        disabled: true,
                                    }}
                                    value={props.countryCode}
                                    containerClass={classes.borderClass}
                                    placeholder={t('CONTACT0036')}
                                    specialLabel={false}
                                    // isValid={CommonUtil.isEmptyString(props.error)}
                                    style={{ width: 'auto' }}
                                    inputStyle={{ marginBottom: '0px' }}
                                />
                                {<DownArrow style={{ paddingRight: '10px', cursor: 'default' }} />}
                                <Divider orientation="vertical" flexItem style={{ marginRight: '10px' }} />
                            </>
                        ),
                        sx: {
                            height: props.height ? props.height : { xl: "64px", xs: "50px" },
                            font: { xl: 'normal normal normal 16px/19px Roboto', xs: 'normal normal normal 14px/17px Roboto' },
                            padding: props.padding ? props.padding : { xl: "0px 20px", xs: "0px 13px" },
                            background: 'white !important',
                            cursor: props.cursor
                        }
                    }}
                />}
                {/* </Box> */}
            </Box>
        </Box>
    )
}

const labelStyle = {
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'rgba(71, 75, 79, 0.8)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "Roboto",
}

export default CustomePhoneInput;
