import { styled } from '@mui/material';
import i18n from 'i18next';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AppRoutes from './AppRoutes';
import AppLanguage from './util/AppLanguages';

function App() {
  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    language = language === null ? 'en' : language;
    i18n.changeLanguage(language);
  }, []);

  const[loader,setLoader]=useState(false);
  const setLoaderFunction = () => {
    let loader= localStorage.getItem('isLoading')
    setLoader(loader);
   }
 useEffect(() => {
   window.addEventListener('storage', setLoaderFunction);
   return () => {
     window.removeEventListener('storage', setLoaderFunction);
   };
 }, []);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      background: 'transparent linear-gradient(104deg, #76B745 0%, #5D9435 100%) 0% 0% no-repeat padding-box',
    }
  }));

  return (
    <BrowserRouter basename='/'>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        Components={{
          success: StyledMaterialDesignContent,
        }}
      >
        <AppRoutes />
        {/* {loader?<Loader open={loader}/>:<></>} */} 
        {/* <ToastContainer autoClose={2000} position='top-center' hideProgressBar className='toast-container' toastClassName='dark-toast' /> */}
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
