import { Autocomplete, Box, Grid, Tooltip, Typography, styled, tooltipClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchBar from './SearchBar'
import { useTranslation } from 'react-i18next';
import { getDepartmentList, getPersonListByDepartmentId } from '../../../services/PersonService';
import CommonUtil from '../../../util/CommonUtils';
import CustomInput from '../../../components/CustomInput';
import { GetContext } from '../../../AppStore';
import QuickSetupTable from './QuickSetupTable';
import { ReactComponent as DeleteOne } from "../../../assets/new-images/Delete Icon.svg";
import { Cancel } from '@mui/icons-material';
import { Popover } from 'antd';

const PersonPage = () => {
  const { t } = useTranslation();
  const [departments, setDepatments] = useState([]);
  const [persons, setPersons] = useState([]);
  const [searchedPerson, setSearchPerson] = useState([]);
  const [selectedDepartment, setSelectedDepatment] = useState("");
  const [totalPerson, setTotalPerson] = useState([])
  const [nameAndId, setNameAndId] = useState("");
  const [searchError, setSerachError] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const value = GetContext();

  const [state, setState] = React.useState({
    id: '',
    personId: []
  });
  const [error, setError] = React.useState({
    id: '',
    personId: []
  });

  useEffect(() => {
    const allDepartmentOj = {
      id: "all",
      code: "ALL",
      name: "All",
      parentId: "",
      createdAt: "",
      updateAt: ""
    }
    getDepartmentList({ pageNumber: 1, pageSize: 1000 }).then((res) => {
      if (res?.data?.code === 'OMSI0000') {
        let department = [];
        res?.data?.data?.department.map((depObj, index) => {
          if (index == 0) {
            department.push(allDepartmentOj)
          }
          department.push(depObj);
        })
        setDepatments(department)
      }
      else {
        setDepatments([]);
      }
    });
  }, []);

  const loadPerson = (id) => {
    setLoader(true)
    getPersonListByDepartmentId({ pageNumber: 1, pageSize: 1000, departmentId: id !== "all" ? id : "" }).then((res) => {
      if (res?.data?.code === 'OMSI0000') {
        setPersons(res.data.data.employees)
        setSearchPerson(res.data.data.employees);
        setTotalCount(res.data.data.totalCount > 0 ? res.data.data.totalCount : -1)
      }
      else {
        setPersons([]);
        setSearchPerson([])
        setNameAndId("")
        setTotalCount(-1)
      }
      setLoader("fetched")
    });
  }

  useEffect(() => {
    setTotalPerson(prev => {
      const uniquePersonIds = new Set(prev.map(person => person.id));
      const newPersons = persons.filter(person => !uniquePersonIds.has(person.id));
      return [...prev, ...newPersons];
    });
  }, [persons]);

  const findDepartment = (id) => {
    var dept = {};
    departments.map((department) => {
      if (department.id === id) {
        dept = department;
      }
    });
    return dept;
  };

  const departmentListField = () => (
    <Autocomplete
      fullWidth
      error={error.siteId}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={state.id ? findDepartment(state.id) : null}
      id="dropdown-department"
      getOptionLabel={(option) => (option.name ? option.name : "")}
      options={CommonUtil.isEmpty(departments) ? [] : departments}
      renderOption={(props, option) => (
        <Box component="li" sx={{ wordBreak: 'break-all' }} {...props}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => <CustomInput
        placeholder={t('COMMON039')}
        handleChange={(e) => console.log()}
        {...params}
        helperText={error.siteId}
        error={error.siteId}
        size="small"
        height={{ xl: "33px", xs: "23px" }}
        padding={{ xl: "0px 20px", xs: "0px 13px" }}
        cursor='pointer'
      />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            id: newValue.id,

          });
          setError({
            ...error,
            id: ''
          });
          loadPerson(newValue.id);
          setSelectedDepatment(newValue.id);
          setNameAndId("");
        } else {
          setPersons([])
          setState({
            ...state,
            id: "",
          });
          setError({
            ...error,
            id: ''
          });

          setSelectedDepatment("");
          setPersons([]);
          setSearchPerson([])
          setNameAndId("");
          setTotalCount(0)
        }
      }}
      ListboxProps={{ style: { maxHeight: '250px' } }}
    />
  );

  useEffect(() => {
    if (nameAndId !== "") {
      if (CommonUtil.isEmpty(selectedDepartment)) {
        setSerachError("Please Select Department");
      } else {
        const filteredRows = persons.filter((test) => {
          return (
            (test.firstName
              ? test?.firstName.toLowerCase().includes(nameAndId?.toLowerCase())
              : "") ||
            (test.lastName
              ? test?.lastName.toLowerCase().includes(nameAndId?.toLowerCase())
              : "") ||
            test?.code.toLowerCase().includes(nameAndId?.toLowerCase()) ||
            `${test.firstName} ${test.lastName}`
              .toLowerCase()
              .includes(nameAndId.toLowerCase())
          );
        });
        setSearchPerson(filteredRows);
        setTotalCount(filteredRows.length === 0 ? -1 : filteredRows.length)
      }
    } else {
      setSearchPerson(persons)
      setSerachError("");
      setTotalCount(0);
    }
  }, [nameAndId]);


  const handleHoverChange = (open) => {
    if (value?.quickSetupPayload?.personIds?.length > 0) {
      if (open)
        setAnchorEl(true)
      else
        setAnchorEl(false)
    } else {
      setAnchorEl(false)
    }

  };

  const contentUI = (item, value) => (
    <Box maxHeight={"300px"} maxWidth={"180px"} mr={-2} overflow={"auto"}>
      {
        item?.map((personId, cIndex) => {
          const matchingPerson = totalPerson.find((person) => person.id === personId);
          if (matchingPerson) {
            return (
              <Box
                key={cIndex}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  ":hover": {
                    backgroundColor: '#76B7451f',
                    borderRadius: "6px"
                  },
                  cursor: 'pointer',
                  padding: "2px"
                }}
              >
                <Typography pl={"5px"}>
                  {`${matchingPerson.firstName} ${matchingPerson.lastName}`}
                </Typography>

              </Box>
            );
          }
          return null;
        })
      }
    </Box>
  );


  return (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid #DDDDDD',
        width: '100%',
        borderRadius: { xl: '8px', xs: "6px" },
        padding: { xl: '40px 30px 30px 30px', xs: '35px 25px 25px 25px' },
        font: "normal normal medium 16px/19px Roboto",
        '&::after': {
          content: '" Assigning Person "',
          position: 'absolute',
          top: '-13px',
          left: '30px',
          backgroundColor: '#fff',
          padding: '0 5px',
          whiteSpace: 'nowrap',
          color: "rgba(71, 75, 79, 0.8)",
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: "Roboto"
        },
        '&:hover': {
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }
      }}
      id=" assigning-person-page"
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container sx={{ height: { xl: "68px", xs: "54px" } }}>
            <Grid item xs={6} sx={{ pr: { xl: "70px", xs: "50px" } }}>{departmentListField()}</Grid>
            <Grid item xs={6} sx={{ pl: { xl: "30px", xs: "20px" }, display: "flex", justifyContent: "end" }}>
              <SearchBar
                placeholder={"Search by Name and ID"}
                clearSearch={() => setNameAndId("")}
                onSearch={() => console.log()}
                error={searchError}
                name={"search"}
                resetError={() => setSerachError("")}
                helperText={searchError}
                handleChange={(e) => {
                  setNameAndId(e.target.value);
                }}
                value={nameAndId}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box height={"36px"} width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Popover
              placement="right"
              title={"Selected Persons " + value?.quickSetupPayload?.personIds?.length}
              content={contentUI(value?.quickSetupPayload?.personIds)}
              open={anchorEl}
              onOpenChange={(e) => handleHoverChange(e)}
            >
              <Typography sx={{
                cursor: "default",
                color: "#76B745"
              }}>
                Selected - {value?.quickSetupPayload?.personIds?.length <= 1 ? value?.quickSetupPayload?.personIds?.length + " Person"
                  : value?.quickSetupPayload?.personIds?.length + " Persons"}
              </Typography>
            </Popover>
            {value?.quickSetupPayload?.personIds?.length > 0 && <Tooltip title={t("COMMON038")}>
              <Cancel id="icon-devicelist-delete"
                style={{ cursor: 'pointer', color: "rgba(71, 75, 79, 0.5)" }}
                onClick={() => {
                  value.setQuickSetupPayload({
                    ...value.quickSetupPayload,
                    personIds: []
                  });
                  setTotalPerson(persons)
                }}
              /></Tooltip>}
          </Box>
          <QuickSetupTable
            totalCount={totalCount}
            rows={searchedPerson}
            checkboxSelection={true}
            isDepartmentSelected={selectedDepartment}
            loader={loader}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default PersonPage
