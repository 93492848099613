import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as ViewIcon } from '../../assets/ViewIcon.svg';
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import DataTable from '../../components/layout-components/DataTable';
import PaginationContext from '../../components/layout-components/PaginationContext';
import RightViewLayout from '../../components/RighViewLayout';
import OverflowTip from '../../components/tooltip';
import api from '../../configurations/http-common';
import { REACT_URL_AUXILIARY_INPUT_EDIT, REACT_URL_AUXILIARY_INPUT_VIEW } from '../../constants/ReactEndPoints';
import { loadDeviceAuxiliaryInput } from '../../services/AuxiliaryInputService';
import { getPermissionsByRoleId } from '../../services/RoleService';

function AuxiliaryInput() {
    const { t } = useTranslation()
    let navigate = useNavigate();
    const [auxIn, setAuxIn] = useState([]);
    const location = useLocation();
    const { manageAUXINPageNumber, setManageAUXINPageNumber, manageAUXINPageSize, setManageAUXINPageSize } = useContext(PaginationContext);
    const [page, setPage] = React.useState(manageAUXINPageNumber ? manageAUXINPageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(manageAUXINPageSize ? manageAUXINPageSize : 10);
    const [perViewAuxIN, setViewAuxIn] = useState(true);
    const [perEditAuxIN, setEditAuxIn] = useState(true);
    const [perListAuxIN, setListAuxIn] = useState(true);
    const [ids, setIds] = useState([]);

    const [open, setOpen] = useState(false);

    const [filter, setFilter] = React.useState(false);
    const [load, setLoad] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [change, setChange] = React.useState(false);
    const [reload, setReload] = useState(false);
    const [clearAndRefresh, setClearAndRefresh] = useState(false);

    const defaultFilter = { doorName: '', deviceAlias: '', name: '' };
    const [filterData, setFilterData] = useState({ ...defaultFilter, });

    React.useEffect(() => {
        if (load && !filter) {
            getPermissionsByRoleId(api.decoded().roleId).then((res) => {
                var l = [];
                res.data.data.rolePermissions.map((item, index) => {
                    l.push(item.code);
                });
                setRoles(l);
                setViewAuxIn(l.includes("ACC_AUXILIARY_VIEW") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                setEditAuxIn(l.includes("ACC_AUXILIARY_EDIT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                setListAuxIn(l.includes("ACC_AUXILIARY_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

                if (l.includes("ACC_AUXILIARY_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
                    loadData(page, rowsPerPage, defaultFilter);
                }

            });
        }

    }, [page, rowsPerPage]);

    useEffect(() => {
        if (filter && !load && reload) {
            loadDeviceAuxiliaryInput(1, rowsPerPage, filterData).then((res) => {
                if (res?.data?.code === 'DMSI0000') {
                    setAuxIn(res.data.data.doors);
                    setTotalRecords(res.data.data.totalCount);
                } else {
                    setAuxIn([]);
                    setTotalRecords(0)
                }
            });
            setReload(false)
        }
    }, [filter, reload]);

    const [roles, setRoles] = React.useState(['']);

    const columns = [
        {
            field: 'name',
            hideable: false,
            headerName: t('AccAuxIn002'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccAuxIn002')} >
                    <span>
                        {t("AccAuxIn002")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.name}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'device',
            headerName: t('AccDevice014'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDevice014')} >
                    <span>
                        {t("AccDevice014")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.deviceName}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'sn',
            headerName: t('AccDoor001'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDoor001')} >
                    <span>
                        {t('AccDoor001')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.deviceSn}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'number',
            headerName: t('AccAuxIn010'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccAuxIn010')} >
                    <span>
                        {t('AccAuxIn010')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.aux_no}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'printerNumber',
            headerName: t('AccDMTopology009'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDMTopology009')} >
                    <span>
                        {t('AccDMTopology009')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.printer_number}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'remarks',
            headerName: t('COMMON007'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('COMMON007')}>
                    <span>
                        {t("COMMON007")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.remark}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },

        {
            headerName: t('COMMON019'),
            hideable: false,
            sortable: false,
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('COMMON019')} >
                    <span>
                        {t('COMMON019')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <>
                    <Box sx={{ flex: "0.78", display: 'flex', alignItems: 'center', }}>
                        {perViewAuxIN && (<GridActionsCellItem
                            sx={{ marginRight: { xl: "10px", xs: "10px" } }}
                            icon={<Tooltip title={t('AccAuxIn005')}>
                                <ViewIcon id="icon-auxiliaryinput-visibility"
                                    onClick={() => navigate(REACT_URL_AUXILIARY_INPUT_VIEW + `/${e.row.aux_id}`, { state: { id: e.row.aux_id, pageNumber: page, pageSize: rowsPerPage } })} />
                            </Tooltip>} />)}

                        {perEditAuxIN && (<GridActionsCellItem
                            sx={{ marginRight: { xl: "10px", xs: "10px" } }}
                            icon={<Tooltip title={t('AccAuxIn006')}>
                                <EditIcon id="icon-auxiliaryinput-edit"
                                    onClick={() => navigate(REACT_URL_AUXILIARY_INPUT_EDIT + `/${e.row.aux_id}`, { state: { aux: e.row, pageNumber: page, pageSize: rowsPerPage } })} />
                            </Tooltip>} />)}
                    </Box>
                </>
            ),
        },
    ];


    const loadData = (pageNumber, pageSize, filterData) => {
        setIds([]);
        loadDeviceAuxiliaryInput(pageNumber, pageSize, filterData).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setAuxIn(res.data.data.doors);
                setTotalRecords(res.data.data.totalCount);
            } else {
                setAuxIn([]);
                setTotalRecords(0)
            }
            setClearAndRefresh(false);
        });
    };

    const DEFAULT_PAGE_SIZE = 10;

    const refreshTableData = () => {
        setClearAndRefresh(true);
        if (page === 1) {
            // setFilterData(defaultFilter);
            // setDisabled(true)
            // setFilter(false);
            setLoad(true);
            loadData(1, manageAUXINPageSize, filterData);
            setRowsPerPage(manageAUXINPageSize);
        }
        else {
            // setFilterData(defaultFilter);
            // setDisabled(true)
            // setFilter(false);
            setLoad(true);
            setPage(filter ? 1 : manageAUXINPageNumber);
            setRowsPerPage(manageAUXINPageSize);
            loadData(filter ? 1 : manageAUXINPageNumber, manageAUXINPageSize, filterData);
        }
    };
    //Filter  Related
    const handleChange = (event) => {
        setChange(true);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
    };

    useEffect(() => {
        if (filterData.name === '' && filterData.deviceAlias === '') {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [filterData]);

    const clearFilter = () => {
        setFilterData(defaultFilter);
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else setPage(1);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
    }

    const handleSubmitFilter = () => {
        setFilter(true);
        setLoad(false);
        setReload(true)
    }

    const actions = () => (
        <Box item display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }} >
            <Tooltip title={t('ACCORG008')}>
                <Box
                    aria-label="refresh"
                    onClick={() => refreshTableData()}
                    mr={2}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"refresh-Icon"}
                >
                    <RefreshIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => refreshTableData()}
                    mr={2}
                    sx={{ display: { xl: "none", xs: "block" } }}
                >
                    <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
            <Tooltip title={t('ACCORG009')}>
                <Box
                    aria-label="filter"
                    onClick={() => setOpen(!open)}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => setOpen(!open)}
                    sx={{ display: { xl: "none", xs: "block" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
        </Box>
    );

    const FieldAuxInName = (key) => (
        <>
            <Grid item lg={12} xs={11.8}   >
                <CustomInput
                    id="textfield-auxiliaryinput-name"
                    placeholder={t('AccAuxIn002')}

                    height={{ xl: "50px", xs: "40px" }}
                    padding={{ xl: "0px 20px", xs: "0px 10px" }}
                    value={filterData.name}
                    onKeyDown={handleKeyPress}
                    size='small'
                    fullWidth
                    name='name'
                    //label='Person ID'
                    variant='outlined'
                    handleChange={handleChange}
                    inputProps={{
                        maxLength: 50,
                    }}

                />
            </Grid>
        </>
    );

    const FieldDoorName = (key) => (
        <>
            <Grid item lg={12} xs={11.8}   >
                <CustomInput
                    id="textfield-auxiliaryinput-doorname"
                    placeholder={t('AccDMTopology011')}
                    style={{ margin: '2px' }}
                    value={filterData.doorName}
                    onKeyDown={handleKeyPress}
                    size='small'
                    fullWidth
                    name='doorName'
                    //label='Person ID'
                    variant='outlined'
                    handleChange={handleChange}
                    inputProps={{
                        maxLength: 50,
                    }}

                />
            </Grid>
        </>
    );

    const FieldDeviceName = (key) => (
        <>
            <Grid item lg={15} xs={11.8}   >
                <CustomInput
                    id="textfield-auxiliaryinput-devicename"
                    placeholder={t('AccDevice014')}

                    height={{ xl: "50px", xs: "40px" }}
                    padding={{ xl: "0px 20px", xs: "0px 10px" }}
                    value={filterData.deviceAlias}
                    size='small'
                    onKeyDown={handleKeyPress}
                    fullWidth
                    name='deviceAlias'
                    //label='Person ID'
                    variant='outlined'
                    handleChange={handleChange}
                    inputProps={{
                        maxLength: 30,
                    }}

                />
            </Grid>
        </>
    );

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitFilter();
        }
    };

    useEffect(() => {
        if (!open && !disabled) {
            setPage(1);
            setChange(false);
            setFilterData(defaultFilter);
            loadData(1, manageAUXINPageSize, defaultFilter);
        }
    }, [open]);

    const handlePageChange = (e) => {
        if (filter) {
            setPage(e);
            setManageAUXINPageNumber(e);
            loadData(e, rowsPerPage, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setPage(e);
            setManageAUXINPageNumber(e);
        }
    }

    const handlePageSize = (e) => {
        if (filter) {
            setRowsPerPage(e);
            setManageAUXINPageSize(e);
            setPage(1);
            loadData(1, e, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setRowsPerPage(e);
            setManageAUXINPageSize(e);
            setPage(1);
        }
    }

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    return (
        <>
            {perListAuxIN ? (<><RightViewLayout title={t("AccAuxIn001")} type='table' navigateBack={true} >
                {actions()}
            </RightViewLayout>
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open && <FilterComponent
                        firstField={FieldAuxInName()}
                        // children={FieldDoorName()}
                        secondField={FieldDeviceName()}
                        clearFilter={clearFilter}
                        handleSubmit={handleSubmitFilter}
                        disabled={disabled}
                    />}

                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        columns={columns}
                        getRowId={(row) => row.aux_id}
                        rows={auxIn}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        onPageChange={(e) => handlePageChange(e + 1)}
                        onPageSizeChange={(e) => handlePageSize(e)}
                        noRecordText={t("AccAuxIn003")} />
                </Box>
            </>) : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={'center'}>{t("AccAuxIn004")}</Typography></Box>)}
        </>
    );
}

export default AuxiliaryInput;