import { Autocomplete, Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import CustomInput from '../../components/CustomInput';
import CommonUtil from '../../util/CommonUtils';
import { ReactComponent as InformationICon } from '../../assets/ic_dangerous.svg';
import { useSnackbar } from 'notistack';
import { REACT_URL_DOOR_LIST, REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import { getTimeZoneIntervalList } from '../../services/SchedulesService';
import { doorenableOrDisableOperation, getDeviceVerificationMode, getDoorById, updateDoor } from '../../services/DoorService';
import ContainerField from './ContainerField';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from 'react-i18next';
import ViewPage from '../../components/ViewPage';
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';

const EditDoorPage = () => {
    const location = useLocation();
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [timeSlot, setTimeSlot] = useState([]);
    const [verificationMode, setVerificationMode] = useState([]);
    const [error, setError] = useState(defaultValue);
    const { enqueueSnackbar } = useSnackbar();
    const [perEditDoor, setEditDoor] = useState(false);
    // const [defaultTimeSlot,setDefaultTimeSlot]=useState(location?.state?.doorParameters?.activeTimeSegBusinessId)
    const [permissions, setPermissions] = useState([]);
    const [loader, setLoader] = React.useState(false);
    const { doorId } = useParams();
    const [viewErrorPage, setViewErrorPage] = useState(false)
    const [deviceId, setDeviceId] = useState(false);
    const [state, setState] = useState({
        id: "",
        name: "",
        deviceName: "",
        enabled: "",
        doorNo: "",
        verifyMode: "",
        activeTimeSegBusinessId: "",
        lockDelay: "",  // lockDelay is same as lock open duration
        sensorDelay: "",
        /**
         * doorSensorStatus is sensor Type.
         * doorSensorStatus: 0-None, 1-Normal open, 2- Normal closed
         */
        doorSensorStatus: "",
        actionInterval: ""

    });

    const inforStyle = {
        textAlign: 'left',
        font: {
            xl: 'normal normal normal 12px/23px Roboto',
            xs: "normal normal normal 12px/18px Roboto",
        },
        letterSpacing: '0px',
        color: ' #474B4F',
        opacity: 0.8,
    }

    React.useEffect(() => {
        if (CommonUtil.isEmpty(doorId)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if (res.data.code === "CACCZE001") {
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev => [...prev, item.code])
                });
            }
            setEditDoor(true);
        });
    }, []);

    const loadDoorById = () => {
        setLoader(true);
        getDoorById(doorId).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setDeviceId(res.data.data.deviceId);
                setState({
                    id: res.data.data.doorId,
                    name: res.data.data.doorParameters.name,
                    deviceName: res.data.data.deviceName,
                    enabled: res.data.data.doorParameters.enabled,
                    doorNo: res.data.data.doorParameters.doorNo,
                    verifyMode: res.data.data.doorParameters.verifyMode,
                    activeTimeSegBusinessId: res.data.data.doorParameters.activeTimeSegBusinessId,
                    lockDelay: res.data.data.doorParameters.lockDelay,  // lockDelay is same as lock open duration
                    sensorDelay: res.data.data.doorParameters.sensorDelay,
                    /**
                     * doorSensorStatus is sensor Type.
                     * doorSensorStatus: 0-None, 1-Normal open, 2- Normal closed
                     */
                    doorSensorStatus: res.data.data.doorParameters.doorSensorStatus,
                    actionInterval: res.data.data.doorParameters.actionInterval
                })
            } else {
                setViewErrorPage(true);
            }
            setLoader(false);
        });
    }

    useEffect(() => {
        if (checkPermission("ACC_EDIT_DOOR")) {
            loadDoorById()
        }
    }, [permissions])

    const checkPermission = (permissionCode) => {
        if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
            return true;
        else {
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
    }
    useEffect(() => {
        loadSchedules(1, 1000, '');
        loadDeviceSpec();
    }, []);

    const handleChange = (event) => {
        var name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value
        })
        setError({
            ...error,
            [name]: ''
        })
    };

    const handleClear = () => {
        loadDoorById()
    };

    const loadDeviceSpec = () => {
        getDeviceVerificationMode(location?.state?.deviceId, (data) => {
            if (data?.data?.code === 'DMSI0000') {
                setVerificationMode(data.data.data);
            }
        });
    }

    useEffect(() => {
        if (timeSlot instanceof Array && !CommonUtil.isEmpty(timeSlot)) {
            if (state?.activeTimeSegBusinessId == '9999') {
                timeSlot.map((data) => {
                    if (data?.operationName === '24-Hours General') {
                        setState({ ...state, activeTimeSegBusinessId: data.id })
                    }
                })
            }

        }

    }, [timeSlot, state]);

    const loadSchedules = (page, size, name) => {
        getTimeZoneIntervalList(1, 10000, '').then((res) => {
            if (res?.data?.code === 'DMSI0000' && res?.data?.data?.timeZone instanceof Array) {
                setTimeSlot(res.data.data.timeZone);
            } else {
                setTimeSlot([]);
            }
        });
    };

    const findTimeSlot = (activeTimeSegBusinessId) => {
        var timeSt = { id: "", operationName: "" };
        timeSlot.map((value) => {
            if (value.id === activeTimeSegBusinessId) {
                timeSt = value;
            }
        });
        return timeSt;
    };

    const findDoorStatus = (data) => {
        var doorSt = { id: '', name: '', enabled: '' };
        doorStatus.map((value) => {
            if (value.enabled === data) {
                doorSt = value;
            }
        });
        return doorSt;
    }

    const findSensorType = useCallback((doorSensorStatus) => {
        var sensor = { id: '' };
        doorSensorType.map((value) => {
            if (value.id === doorSensorStatus) {
                sensor = value;
            }
        });
        return sensor;
    }, []);

    const findVerificationMode = (verifyMode) => {
        var varification = { name: '' };
        verificationMode.map((mode) => {
            if (mode.value == verifyMode) {
                varification = mode;
            }
        });
        return varification;
    };


    const handleSubmit = () => {

        if (CommonUtil.isEmptyString(state.name)) {
            setError({ ...error, name: "Door name is required." })
            return;
        }
        if (CommonUtil.isEmptyString(state.enabled)) {
            setError({ ...error, enabled: "Door Status is required" })
            return;
        }

        if (CommonUtil.isEmptyString(state.activeTimeSegBusinessId)) {
            setError({ ...error, activeTimeSegBusinessId: "Active time slot is required" });
            return;
        }
        if (CommonUtil.isEmptyString(state.verifyMode)) {
            setError({ ...error, verifyMode: "Verification mode is required field." });
            return;
        }
        if (state.lockDelay < 1 || state.lockDelay > 254) {
            setError({ ...error, lockDelay: "Please enter a value between 1 and 254" })
            return;
        }

        if (CommonUtil.isEmptyString(state.doorSensorStatus)) {
            setError({ ...error, doorSensorStatus: "lock Sensor Type is Required." });
            return;
        }

        let payloadObj = {};
        payloadObj.parameters = state;
        setLoader(true);
        updateDoor(state.id, payloadObj, (res) => {
            if (res?.data?.code === 'DMSI0000') {
                enqueueSnackbar(t('ACCRolPerm063'), {
                    variant: 'success',
                });
                navigate(
                    REACT_URL_DOOR_LIST,
                    { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })
            } else {
                enqueueSnackbar(res.data.msg, {
                    variant: 'error',
                });
            }
            setLoader(false);
        });
    };



    const DoorStatusAutocomplete = () => {
        return (
            <Autocomplete
                id="dropdown-updatedoor-status"
                fullWidth
                size='small'
                value={findDoorStatus(state.enabled)}
                options={doorStatus}
                // onKeyDown={handleKeyPress}
                getOptionLabel={(option) => { return option.name }}
               
                renderInput={(params) => 
                <TextField {...params}
                        sx={{
                            '& .MuiInputBase-input': {
                                cursor: 'pointer'
                            },
                        }}
                    helperText={error.enabled}
                    error={error.enabled}
                />
                }
                onChange={(event, newValue) => {
                    if (newValue !== null) {
                        const action = newValue.id;
                        setState({ ...state, enabled: newValue.enabled });
                        setError({ ...error, enabled: '' });
                        doorenableOrDisableOperation(deviceId, { action });
                    }else{
                        setState({ ...state, enabled: "" });
                    }
                }}
            />
        )
    }

    const TimeSlotAutocomplete = () => {
        return (
            <Autocomplete
                id="dropdown-updatedoor-time-slot"
                fullWidth
                size='small'
                // onKeyDown={handleKeyPress}
                value={state.activeTimeSegBusinessId ? findTimeSlot(state.activeTimeSegBusinessId) : { id: "", operationName: "" }}
                options={CommonUtil.isEmpty(timeSlot) ? [] : timeSlot}
                getOptionLabel={(option) => { return option.operationName }}
                renderInput={(params) => <TextField {...params}
                sx={{
                    '& .MuiInputBase-input':{
                        cursor:'pointer'
                    }
                }}
                    helperText={error.activeTimeSegBusinessId}
                    error={error.activeTimeSegBusinessId}
                />}
                forcePopupIcon={true}
                sx={{ [`& .MuiAutocomplete-popupIndicator`]: { transform: ' none !important' } }}
                // popupIcon={<Box bgcolor={'#FFF'}><TimeSlot /></Box>}
                onChange={(event, newValue) => {

                    if (newValue !== null) {
                        setState({ ...state, activeTimeSegBusinessId: newValue.id });
                        setError({ ...error, activeTimeSegBusinessId: '' });
                    }else{
                        setState({ ...state, activeTimeSegBusinessId: "" });  
                    }
                }}
            />
        )
    }
    const DoorSensorTypeAutocomplete = () => {
        return (
            <Autocomplete
                id="dropdown-updatedoor-sensor-type"
                fullWidth
                size='small'
                // onKeyDown={handleKeyPress}
                value={state.doorSensorStatus ? findSensorType(state.doorSensorStatus) : null}
                options={CommonUtil.isEmpty(doorSensorType) ? [] : doorSensorType}
                getOptionLabel={(option) => { return option.name }}
                renderInput={(params) => <TextField {...params}
                 sx={{'& .MuiInputBase-input':{
                    cursor:'pointer'
                 }}}
                    helperText={error.doorSensorStatus}
                    error={error.doorSensorStatus}
                />}
                onChange={(event, newValue) => {
                    if (newValue !== null) {
                        setState({ ...state, doorSensorStatus: newValue.id });
                        setError({ ...error, doorSensorStatus: '' });
                    }else{
                        setState({ ...state, doorSensorStatus: "" });
                    }
                }}

            />

        )
    };

    const VerificationModeAutocomplete = () => {
        return (
            <Autocomplete
                id="dropdown-updatedoor-verification-mode"
                fullWidt
                size='small'
                // onKeyDown={handleKeyPress}
                value={state.verifyMode ? findVerificationMode(state.verifyMode) : null}
                options={CommonUtil.isEmpty(verificationMode) ? [] : verificationMode}
                getOptionLabel={(option) => { return option.name !== undefined ? option.name : '' }}
                renderInput={(params) => <TextField {...params}
                 sx={{' .MuiInputBase-input':{
                    cursor:'pointer'
                 }}}
                    helperText={error.verifyMode}
                    error={error.verifyMode}
                />}
                onChange={(event, newValue) => {
                    if (newValue !== null) {
                        setState({ ...state, verifyMode: newValue.value });
                        setError({ ...error, verifyMode: '' });
                    }else{
                        setState({ ...state, verifyMode: "" });
                    }
                    
                }}
            />
        )
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };


    return (
        <>
            {viewErrorPage ? <PageNotFound></PageNotFound> : perEditDoor ? checkPermission("ACC_EDIT_DOOR") ? (<ViewPage
                navigateBack={false}
                title={t('AccDoor006')}
                path={true}
                onClick={() => navigate(
                    REACT_URL_DOOR_LIST,
                    { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
            >
                {loader ? <Loader open={loader} /> : <></>}
                <Grid container>
                    <Grid item sm={12} xs={12} >

                        <ContainerField
                            key={0}
                            label={<>Door Name<span style={{ color: 'red' }}>*</span></>}
                            field={<TextField
                                id="textfield-updatedoor-name"
                                size='small'
                                onKeyDown={handleKeyPress}
                                fullWidth
                                required
                                variant='outlined'
                                name='name'
                                error={error.name}
                                value={state.name}
                                helperText={error.name}
                                onChange={(event) => handleChange(event)}
                                inputProps={{
                                    maxLength: 30,
                                }}
                            />}
                            informationMessage={informationMessage[0].InfoText}
                        />

                        <ContainerField
                            key={1}
                            label={<>Device Name<span style={{ color: 'red' }}>*</span></>}
                            field={<TextField
                                id="textfield-updatedoor-device-name"
                                size='small'
                                onKeyDown={handleKeyPress}
                                fullWidth
                                variant='outlined'
                                name='deviceName'
                                disabled
                                value={state.deviceName}
                            />}
                            informationMessage={informationMessage[1].InfoText}
                        />

                        <ContainerField
                            key={2}
                            label={<>Door Number</>}
                            field={<TextField
                                id="textfield-updatedoor-name"
                                fullWidth
                                error={error.doorNo}
                                name="doorNo"
                                variant="outlined"
                                size="small"
                                onKeyDown={handleKeyPress}
                                value={state.doorNo}
                                required
                                disabled
                            />}
                            informationMessage={informationMessage[2].InfoText}
                        />

                        <ContainerField
                            key={3}
                            label={<>Door Status<span style={{ color: 'red' }}>*</span></>}
                            field={DoorStatusAutocomplete()}
                            // onKeyDown={handleKeyPress}
                            informationMessage={informationMessage[3].InfoText}
                            
                        />

                        <ContainerField
                            key={4}
                            label={<>Door Active Time Slot<span style={{ color: 'red' }}>*</span></>}
                            field={TimeSlotAutocomplete()}
                            // onKeyDown={handleKeyPress}
                            informationMessage={informationMessage[4].InfoText}
                        />

                        <ContainerField
                            key={5}
                            label={<>Verification Mode<span style={{ color: 'red' }}>*</span></>}
                            field={VerificationModeAutocomplete()}
                            // onKeyDown={handleKeyPress}
                            informationMessage={informationMessage[5].InfoText}
                        />

                        <ContainerField
                            key={6}
                            label={<>Lock Open Duration (1-254 seconds)<span style={{ color: 'red' }}>*</span></>}
                            field={<CustomInput
                                id="textfield-updatedoor-lock-delay"
                                fullWidth
                                error={error.lockDelay}
                                name="lockDelay"
                                variant="outlined"
                                size="small"
                                // onKeyDown={handleKeyPress}
                                value={state.lockDelay}
                                validation={'numericWithoutDot'}
                                handleChange={(e) => {
                                    setState({ ...state, lockDelay: e.target.value });

                                    setError({ ...error, lockDelay: '' })

                                }}
                                helperText={error.lockDelay}
                            />}
                            informationMessage={informationMessage[6].InfoText}
                        />

                        <ContainerField
                            key={7}
                            label={<>Lock Sensor Type<span style={{ color: 'red' }}>*</span></>}
                            field={DoorSensorTypeAutocomplete()}
                            // onKeyDown={handleKeyPress}
                            informationMessage={informationMessage[7].InfoText}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: { lg: 'flex-start', xs: "center" },
                                margin: '20px 0px',
                                padding: "10px 0px",
                                width: { xl: '34.5%', lg: '35%', md: '100%', sm: '100%', xs: '100%' }
                            }}
                        >
                            <Button id="button-updatedoor-save"
                                variant='contained' sx={{ width: "50%", height: { xl: "64px", xs: "50px" } }}
                                onClick={() => handleSubmit()}
                            >{t('COMMON023')}</Button>
                            <Button id="button-updatedoor-clear" variant='outlined'
                                sx={{ marginLeft: '20px', width: "50%", height: { xl: "64px", xs: "50px" } }}
                                onClick={() => handleClear()}
                            >{t('COMMON015')}</Button>
                        </Box>

                    </Grid>
                    <Grid item display={{ xl: 'none', lg: 'none', md: 'inline', sm: 'inline', sx: 'flex' }} >
                        {informationMessage.map((data) =>
                            <Box padding={1} sx={{ display: 'flex', alignContent: 'center' }}>
                                <Box marginRight={1}>
                                    <InformationICon />
                                </Box>
                                <Typography sx={inforStyle} textAlign="justify">{data.InfoText}</Typography>
                            </Box>)}
                    </Grid>
                </Grid>
            </ViewPage>)
                : (<Box marginTop={'150px'} width={'100%'}>
                    <Typography textAlign={'center'}>{t("AccDoor008")}</Typography>
                </Box>) : <></>}

        </>
    )
}

const informationMessage = [
    {
        id: "0",
        InfoText: 'Usually, a Lock relay open one Door Lock. There will be a Lock # on the Device to indicate these relays. Choose a memorable name (usually with a location) as the name of the Door.'
    },
    {
        id: "1",
        InfoText: 'This Door physically wired to one Lock relay of this Device. One Device may support 1-4 Doors.'
    },
    {
        id: "2",
        InfoText: 'Device mark a Lock relay number for Door Lock, so that it can know what action happen to which Door Lock.'
    },
    {
        id: "3",
        InfoText: 'Disable Door, Person can not pass the Door and Device will tips "Door is disabled" when Person verify on Device or remote open Door in Zlink. But edit Door command can send to Device when Device is disabled.'
    },
    {
        id: "4",
        InfoText: 'The Default value is 24-Hours General, it means all the Person sync to this Device can pass the Door after verification. If you want this Door working in limited Time Slots, choose one Active Time for this Door, then only during this Time Slots, this Door Lock will open.'
    },
    {
        id: "5",
        InfoText: 'All Person in this Device have to use the combination verification way.'
    },
    {
        id: "6",
        InfoText: 'By Default, the COM and NC of the Lock Relay are connected. “Open“ means the COM and NO of the Lock Relay connected. According to the type of the Door Lock, some Door Lock is closed when the power is on, and some Door Lock is closed when the power is off. Please check Door Lock is correctly connected to the Lock Relay.'
    },
    {
        id: "7",
        InfoText: 'The Door Lock Sensor Type defines the default operation that the Lock is set.'
    },
];

const doorSensorType = [
    {
        id: '0',
        name: 'None'
    },
    {
        id: '1',
        name: 'Normally Open'
    },
    {
        id: '2',
        name: 'Normally Closed'
    }
];

const lockSensorType = [
    {
        id: '0',
        name: 'None'
    },
    {
        id: '1',
        name: 'Normally Open'
    },
    {
        id: '2',
        name: 'Normally Closed'
    }
];
const doorStatus = [
    {
        id: '0',
        enabled: "false",
        name: 'Disable'
    },
    {
        id: '1',
        enabled: "true",
        name: 'Enable'
    }
]

const defaultValue = {
    id: '',
    name: '',
    deviceName: '',
    doorNo: '',
    verifyMode: '',
    activeTimeSegBusinessId: '',
    lockDelay: '',
    sensorDelay: '',
    enabled: '',
    doorSensorStatus: '',
    actionInterval: ''
}
const val = {
    "id": "",
    "name": "",
    "deviceName": "",
    "doorNo": "",
    "verifyMode": "",
    "activeTimeSegBusinessId": "",
    "lockDelay": "",
    "sensorDelay": "",
    "doorSensorStatus": "",
    "actionInterval": ""
}
export default EditDoorPage
