import React, { useEffect, useState } from 'react';
import { Box, InputLabel, TextField, Typography } from '@mui/material';
import CommonUtil from '../util/CommonUtils';
import { useTranslation } from 'react-i18next';

export default function CustomInput(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const { t } = useTranslation();

  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  const handleChange = (e) => {
    let val = e.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    if (props.regex === 'none' && props.validation) {
      switch (props.validation) {
        case 'alpha-numeric':
          handleAlphaNumeric(e);
          break;
        case 'numeric':
          handleNumeric(e);
          break;
        case 'email':
          handleEmail(e);
          break;
        case 'password':
          handlePassword(e);
          break;
        case 'code':
          handleCode(e);
          break;
        case 'mobile':
          handleMobile(e);
          break;
        case 'postcode':
          handlePostCode(e);
          break;
        case 'alpha-numeric-underscore':
          handleCodeUnderscore(e);
          break;
        case 'alpha-numeric-space':
          handleAlphaNumericSpace(e);
          break;
        case 'numericWithoutDot':
          handleNumericWithoutDot(e);
          break; 
        default:
          props.handleChange(e);
      }
    }
  };

  const handleAlphaNumeric = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText((props.name && props.name === "sn") ? t("COMMON045") : t(' COMMON046'));
    }
  };

  const handleCodeUnderscore = (e) => {
    if (CommonUtil.isAlphaNumericUnderscore(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('Only accepts alphabets, numerics and underscore'));
    }
  };

  const handleAlphaNumericSpace = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('Only accepts alphabets, numerics , space and underscore'));
    }
  };
  const handleNumericWithoutDot = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText('Only accepts numerics');
    }
  };

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('PP0004'));
    }
  };

  const handlePostCode = (e) => {
    if (CommonUtil.isValidCode(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('PP0002'));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('PP0005'));
    }
  };

  const handleEmail = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('CONTACT0041'));
    }
  };
  const handleMobile = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('PP0005'));
    }
  };

  const handlePassword = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidPassword(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('PP0009'));
    }
  };
  const inputFieldLabel={
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'rgba(71, 75, 79, 0.8)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    fontFamily: "Roboto",
    cursor:'auto'
  }

  return (
    <Box letiant='standard' sx={{ width: '100%', marginBottom: props.label ? props.marginBottom ? { xl: "15px", xs: "10px" } : { xl: "30px", xs: "20px" } :"0px"}}>
      <Typography sx={inputFieldLabel}>
        {props.label} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </Typography>
      <Box display={'flex'}>
      {props?.left===true&&props.children}
      <TextField {...props} autoComplete='new-password'  fullWidth id={props.id}
       sx={{
        "& .MuiInputBase-input": {
          height:props.height? props.height:"100%",
          padding:props.height && props.padding? props.padding:"auto auto",
          cursor:props.cursor
        },
        '& .Mui-disabled': { 
          background: '#F5F6F6',
          borderRadius:{xl:"8px", xs:"6px"}
        },
        '& .MuiOutlinedInput-root':{
        cursor:'pointer'
        },
        marginTop:props.label?props.labelMarginTop?"5px":"10px":"0px"
      }}
       label={''} value={props.value} helperText={helperText} error={error} onChange={(e) => handleChange(e)} />
         {props?.right===true&&props.children}
      </Box>
    </Box>
  );
}

CustomInput.defaultProps = {
  validation: 'none',
  regex: 'none',
};
