import React from "react";
import { AccordionSummary, Checkbox, Grid, InputLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ReactComponent as LeftArrowIcon } from "../../assets/arrow.svg";
import { margin } from "@mui/system";
import { defaultPermissionCode } from "./defaultPermissionCode";
import { useEffect } from "react";
import { useState } from "react";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "8px",
  display: "contents",
  margin: "0px 24px",
  "& .MuiPaper-root-MuiAccordion-root.Mui-expanded(:first-of-type)": {
    marginTop: "12px !importent",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#FFFF",
  padding: "0px !importent",
}));


export default function OwnerViewAccordions(props) {
  const [expand, setExpanded] = useState("");

  const permissionTree = [
    {
      parent: {
        title: "Dashboard",
        codes: [
          "ACC_DASHBOARD",
          "VIEW_ACC_DASHBOARD",
          "VIEW_EVENT_OVERVIEW_GRAPH",
          "VIEW_SITE_OVERVIEW_MAP",
        ],
      },
      child: [
        {
          title: "View the Counter buttons",
          code: "VIEW_ACC_DASHBOARD",
        },
        {
          title: "View Event Overview Graph",
          code: "VIEW_EVENT_OVERVIEW_GRAPH",
        },
        {
          title: "View Site Overview map",
          code: "VIEW_SITE_OVERVIEW_MAP",
        },
      ],
    },
    {
      parent: {
        title: "Organization",
        codes: [
          "ACC_ORGANIZATION",
          "ACC_VIEW_PERSONLIST",
          "ACC_VIEW_PERSON_PROFILE",
          'ACC_VIEW_PERMISSION_LIST',
          'ACC_VIEW_PERMISSIONS',
        ],
      },
      child: [
        {
          title: "View Person list",
          code: "ACC_VIEW_PERSONLIST",
        },
        {
          title: "View Person Profile",
          code: "ACC_VIEW_PERSON_PROFILE",
        },
        {
          title: "View Role and Permission List",
          code: "ACC_VIEW_PERMISSION_LIST",
        },
        {
          title: "View Role and Permissions",
          code: "ACC_VIEW_PERMISSIONS",
        },
      ],
    },
    {
      parent: {
        title: "Site Management",
        codes: [
          "SITE_MANAGMENT_DETAILS",
          "ACC_VIEW_SITE_LISTS",
          "ACC_VIEW_SITE_DETAILS",
          "ACC_VIEW_ZONE_LIST",
          "ACC_VIEW_ZONE",
        ],
      },
      child: [
        {
          title: "View Site list",
          code: "ACC_VIEW_SITE_LISTS",
        },
        {
          title: "View Site Details",
          code: "ACC_VIEW_SITE_DETAILS",
        },
        {
          title: "View Zone list",
          code: "ACC_VIEW_ZONE_LIST",
        },
        {
          title: "View Zone Details",
          code: "ACC_VIEW_ZONE",
        },
      ],
    },
    {
      parent: {
        title: "Device Management",
        codes: [
          "DEVICE_MANAGEMENT",
          "VIEW_DEVICE_LIST_DETAILS",
          "ACC_VIEW_DEVICE_TOPOLOGY",
          "EDIT_DEVICE_LIST_DETAILS",
          "ACC_DEVICE_DELETE",
          "ACC_REBOOT_DEVICE",
          "ACC_DOOR_LIST",
          // "ACC_VIEW_DOOR",
          "ACC_EDIT_DOOR",
          "ACC_READER_LIST",
          "ACC_READER_VIEW",
          "ACC_READER_EDIT",
          "ACC_AUXILIARY_LIST",
          "ACC_AUXILIARY_VIEW",
          "ACC_AUXILIARY_EDIT",
          "ACC_AUXILIARY__OUTPUT_LIST",
          "ACC_AUXILIARY__OUTPUT_VIEW",
          "ACC_AUXILIARY_OUTPUT_EDIT",
        ],
      },
      child: [
        {
          title: "View Device Topology",
          code: "ACC_VIEW_DEVICE_TOPOLOGY",
        },
        {
          title: "View Device List",
          code: "VIEW_DEVICE_LIST_DETAILS",
        },
        {
          title: "Add device, Edit device details, Person In This Device",
          code: "EDIT_DEVICE_LIST_DETAILS",
        },
        {
          title: "Delete device",
          code: "ACC_DEVICE_DELETE",
        },
        {
          title: "Reboot device",
          code: "ACC_REBOOT_DEVICE",
        },
        {
          title: "View Door List",
          code: "ACC_DOOR_LIST",
        },
        // {
        //   title: "View door details",
        //   code: "ACC_VIEW_DOOR",
        // },
        {
          title: "Edit Door Details",
          code: "ACC_EDIT_DOOR",
        },

        {
          title: "View Reader list",
          code: "ACC_READER_LIST",
        },
        {
          title: "View Reader details",
          code: "ACC_READER_VIEW",
        },
        {
          title: "Edit Reader details",
          code: "ACC_READER_EDIT",
        },
        {
          title: "View Auxiliary Input list",
          code: "ACC_AUXILIARY_LIST",
        },
        {
          title: "View Auxiliary Input details",
          code: "ACC_AUXILIARY_VIEW",
        },
        {
          title: "Edit Auxiliary Input details",
          code: "ACC_AUXILIARY_EDIT",
        },
        {
          title: "View Auxiliary Output list",
          code: "ACC_AUXILIARY__OUTPUT_LIST",
        },
        {
          title: " View Auxiliary Output details",
          code: "ACC_AUXILIARY__OUTPUT_VIEW",
        },
        {
          title: "Edit Auxiliary Output details",
          code: "ACC_AUXILIARY_OUTPUT_EDIT",
        },
      ],
    },

    {
      parent: {
        title: "Access Control",
        codes: [
          "ACCESS_CONTROL",
          "TIMESLOT_LIST",
          "VIEW_TIMESLOT",
          "ADD_TIMESLOT",
          "VIEW_DOOR_ACCESS_TIME_LIST",
          "ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN",
          "EDIT_ACCESS_GROUP",
          // "MANAGE_ACCESS_GROUP",
          "MANAGE_ACCESS_GROUP_LIST",
          "ADD_EDIT_ACCESS_GROUP",
          "DELETE_ACCESS_GROUP",
          "MANAGE_DOOR_ACCESS_GROUP",
          "ADD_DOOR_ACCESS_GROUP",
          "MANAGE_PERSONS_ACCESS_GROUP",
          "ADD_PERSONS_ACCESS_GROUP",
        ],
      },
      child: [
        {
          title: "View Timeslots list",
          code: "TIMESLOT_LIST",
        },
        {
          title: "View Timeslot details",
          code: "VIEW_TIMESLOT",
        },
        {
          title: "Add, Edit, Delete Timeslots",
          code: "ADD_TIMESLOT",
        },
        {
          title: "View Door Access time list",
          code: "VIEW_DOOR_ACCESS_TIME_LIST",
        },
        {
          title: "Normally open door, Remotely Open door, Remotely Close Door",
          code: "ACCESS_GROUP_NORMAL_AND_REMOTE_CLOSE_OPEN",
        },
        {
          title: "Edit Door Access time slot",
          code: "EDIT_ACCESS_GROUP",
        },
        {
          title: "View Group Access Time list",
          code: "MANAGE_ACCESS_GROUP_LIST",
        },
        // {
        //   title: "View Group Access time list which is the user is a member",
        //   code: "MANAGE_ACCESS_GROUP_LIST",
        // },
        {
          title: "Add and Edit Group Access time",
          code: "ADD_EDIT_ACCESS_GROUP",
        },
        {
          title: "Delete Group Access time",
          code: "DELETE_ACCESS_GROUP",
        },
        {
          title: "Manage Doors",
          code: "MANAGE_DOOR_ACCESS_GROUP",
        },
        {
          title: "Add, Delete Door",
          code: "ADD_DOOR_ACCESS_GROUP",
        },
        {
          title: "Manage Persons",
          code: "MANAGE_PERSONS_ACCESS_GROUP",
        },
        {
          title: "Add, Delete Persons",
          code: "ADD_PERSONS_ACCESS_GROUP",
        },
      ],
    },

    {
      parent: {
        title: "Reports",
        codes: ["ACC_REPORT", "VIEW_ACC_REPORT", "EXPORT_ACC_REPORT"],
      },
      child: [
        {
          title: "View Event list",
          code: "VIEW_ACC_REPORT",
        },
        {
          title: "Export Event list",
          code: "EXPORT_ACC_REPORT",
        },
      ],
    },

    {
      parent: {
        title: "Operation Log",
        codes: ["ACC_LOGS", "OPERATOR_LOGS"],
      },
      child: [
        {
          title: "Operator logs",
          code: "OPERATOR_LOGS",
        },
      ],
    },
  ];

  const isExpaded = (title) => {
    return expand === title ? true : false;
  };





  const parentStyle = {
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'rgba(71, 75, 79, 0.8)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "29px",
    fontFamily: "Roboto",
  }

  const childStyle = {
    "& .MuiTypography-root": {
      textAlign: 'left',
      letterSpacing: '0px',
      color: 'rgba(71, 75, 79, 0.8)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "29px",
      fontFamily: "Roboto",
    }
  }

  const accordionTree = () =>
    permissionTree.map((e) => {
      return (
        <Grid
          item
          xs={12}
          style={{
            border: "1px solid #EAEBEB",
            width: "100%",
            borderRadius: "8px",
            margin: "10px 0px",
          }}
        >

          <Accordion
            expanded={isExpaded(e.parent.title)}
            onChange={() => {
              if (expand === e.parent.title) {
                setExpanded(!expand);
              } else {
                setExpanded(e.parent.title);
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                <LeftArrowIcon
                  style={{ transform: "rotate(270deg)", margin: "0px 8px" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (expand === e.parent.title) {
                      setExpanded(!expand);
                    } else {
                      setExpanded(e.parent.title);
                    }
                  }}
                />
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={false}

                    disabled={true}
                    checked={true}
                  />
                }
              />
              <Typography sx={{ ...parentStyle, paddingTop: "10px", marginRight: "1px" }}>
                {e.parent.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid ml={4}>
                {e.child.map((c) => {
                  return (
                    <FormControlLabel
                      sx={childStyle}
                      label={c.title}
                      indeterminate={true}
                      control={
                        <Checkbox
                          disabled={props.disabled}
                          value={true}
                          checked={true}
                        />
                      }
                    />
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  const defaultRoles = ["SUPERADMIN", "ORGANIZATION OWNER"]
  return <Grid style={{ width: "100%" }}>{accordionTree()}</Grid>;
}
