import { Autocomplete, Box, Grid, IconButton, Tooltip, Typography, styled, tooltipClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { filterSite } from '../../../services/SiteService';
import CustomInput from '../../../components/CustomInput';
import CommonUtil from '../../../util/CommonUtils';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBar from './SearchBar';
import { ReactComponent as PrevIcon } from "../../../assets/prevIcon.svg"
import { ReactComponent as NextIcon } from "../../../assets/nextIcon.svg"
import { ReactComponent as ZoneIcon } from "../../../assets/zoneIcon.svg"
import { ReactComponent as ZoneIconColored } from "../../../assets/zoneIconColored.svg";
import { ReactComponent as AccDeviceIcon } from "../../../assets/AccDeviceIcon.svg";
import { ReactComponent as AccDoorIcon } from "../../../assets/AccDoorIcon.svg"
import { filterZoneWithoutLoader } from '../../../services/ZoneService';
import { ReactComponent as DeleteOne } from "../../../assets/new-images/Delete Icon.svg";
import { Cancel } from '@mui/icons-material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NoRecordFound from '../../../components/NoRecordFound';
import { getAllDoorWithoutLoader } from '../../../services/DoorService';
import Loader from './Loader';
import { GetContext } from '../../../AppStore';
import { Popover } from 'antd';


const DeviceAndDoorPage = () => {
    const { t } = useTranslation();
    const [site, setSite] = useState([]);
    const [zone, setZone] = useState([]);
    const [selectedSite, setSelectedSite] = useState("");
    const [selectedZone, setSelectedZone] = useState("");
    const [doorAndDeviceName, setDoorAndDeviceName] = useState("");
    const [deviceList, setDeviceList] = useState([]);
    const [searchDevice, setSearchDevice] = useState([]);
    const [selectedDoorList, setSelectedDoorList] = useState([]);
    const [iszoneloading, setIsZoneLoading] = useState(false)
    const [isDeviceloading, setIsDeviceLoading] = useState(false);
    const [searchError, setSerachError] = useState("");
    const [totalDoor, setTotalDoor] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const value = GetContext();

    const [error, setError] = React.useState({
        deviceId: '',
        sn: '',
        siteId: '',
        zoneId: '',
    });

    useEffect(() => {
        const payload = {
            pageNumber: 1,
            pageSize: 0
        }
        const allSiteObject = {
            id: "all",
            name: "All",
            code: "All",
            description: "",
            siteTypeId: "",
            siteTypeName: "",
            address: {},
            createdAt: "2024-05-13 11:09:13"
        }
        filterSite(payload, (response) => {
            if (response?.data?.code === 'LMSI0000') {
                let site = [];
                response?.data?.data?.site.map((siteObj, index) => {
                    if (index == 0) {
                        site.push(allSiteObject)
                    }
                    site.push(siteObj);
                })
                setSite(site);
            }
        })
    }, []);

    const loadZoneList = (id) => {
        setIsZoneLoading(true)
        const payload = {
            pageNumber: 1,
            pageSize: 0,
            filter: { siteId: id !== "all" ? id : "" }
        }
        filterZoneWithoutLoader(payload, (response) => {
            if (response?.data?.code === 'LMSI6000') {
                setZone(response.data.data.zones)
                if (id === 'all') {
                    loadData(1, 0, id);
                    setSelectedZone("all");
                    setSerachError()
                }

            };
            setIsZoneLoading(false)
        })
    }

    const findSite = (id) => {
        let siteObj = { id: '', name: '' };
        site.map((data) => {
            if (data.id === id)
                siteObj = data;
        });
        return siteObj;
    }

    const loadData = (pageNumber, pageSize, id) => {
        setIsDeviceLoading(true)
        const payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            filter: { zoneId: id == "all" ? "" : id }
        }
        getAllDoorWithoutLoader(payload, (res) => {
            if (res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data)) {
                if (res.data.data.doors instanceof Array) {
                    const filteredData = (res.data.data.doors).reduce((acc, curr) => {
                        const existingDevice = acc.find(item => item.deviceId === curr.deviceId);
                        if (existingDevice) {
                            existingDevice.doors.push({
                                doorId: curr.doorId,
                                doorParameters: curr.doorParameters
                            });
                        } else {
                            acc.push({
                                deviceId: curr.deviceId,
                                deviceName: curr.deviceName,
                                doors: [{
                                    doorId: curr.doorId,
                                    doorParameters: curr.doorParameters
                                }]
                            });
                        }
                        return acc;
                    }, []);
                    setSearchDevice(filteredData)
                    setDeviceList(filteredData)
                    // setTotalDoor(prev=>[...prev,...filteredData])
                }
            } else {
                setDeviceList([]);
                setSearchDevice([]);
            }
            setIsDeviceLoading(false);
        });
    }

    useEffect(() => {
        let uniqueNames = [];
        setTotalDoor(prev => {
            const uniquePersonIds = new Set(prev.map(door => door.doorId));
            if (deviceList instanceof Array) {
                deviceList.map((device) => {
                    const newPersons = device.doors.filter(door => !uniquePersonIds.has(door.doorId));
                    if (!CommonUtil.isEmpty(newPersons)) {
                        newPersons.map((dr) => {
                            uniqueNames.push(dr)
                        })
                    }
                })
            }
            return [...prev, ...uniqueNames];
        })
    }, [deviceList])

    const siteListField = () => (
        <Autocomplete
            fullWidth
            error={error.siteId}
            disablePortal
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedSite ? findSite(selectedSite) : null}
            id="dropdown-bind-device-site"
            getOptionLabel={(option) => option.name}
            options={site}
            renderOption={(props, option) => (
                <Box component="li" sx={{ wordBreak: 'break-all' }} {...props}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => <CustomInput
                placeholder={t('COMMON040')}
                {...params}
                helperText={error.siteId}
                error={error.siteId}
                handleChange={(e) => console.log()}
                size="small"
                height={{ xl: "33px", xs: "23px" }}
                padding={{ xl: "0px 20px", xs: "0px 13px" }}
                cursor='pointer'
            />}
            onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                    setSelectedZone("")
                    setDeviceList({})
                    setSelectedSite(newValue.id)
                    loadZoneList(newValue.id)

                } else {
                    setSelectedSite("")
                    setZone([])
                    setSelectedZone("")
                }
                setDoorAndDeviceName("")
            }}
            ListboxProps={{ style: { maxHeight: '250px' } }}
        />
    );

    useEffect(() => {
        if (doorAndDeviceName !== "") {
            if (CommonUtil.isEmpty(selectedSite)) {
                setSerachError("Please select Site");
            } else if (CommonUtil.isEmpty(selectedZone)) {
                setSerachError("Please select Zone ");
            } else {
                const filteredRows = deviceList.filter((test) => {

                    const deviceNameMatches = test.deviceName
                        ? test.deviceName.toLowerCase().includes(doorAndDeviceName.toLowerCase())
                        : false;

                    const doorNameMatches = test.doors.some((door) => {
                        return door.doorParameters?.name.toLowerCase().includes(doorAndDeviceName.toLowerCase());
                    });

                    return deviceNameMatches || doorNameMatches;
                });
                setSearchDevice(filteredRows);
            }
        } else {
            setSearchDevice(deviceList);
            setSerachError("");
        }
    }, [doorAndDeviceName]);


    useEffect(() => {
        if (selectedDoorList) {
            value.setQuickSetupPayload({
                ...value.quickSetupPayload,
                doorIds: selectedDoorList
            });
            value.setErrorQuickSetupPayload({
                ...value.errorQuickSetupPayload,
                doorIds: ''
            });
        }

    }, [selectedDoorList]);

    const toolTipStyle = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "rgba(71, 75, 79, 0.8) !important",
        letterSpacing: "0.4px",
        whiteSpace: "nowrap",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: { xl: '16px', lg: "14px", md: "14px", sm: "14px", xs: "14px" },
        lineHeight: { xl: '19px', lg: "17px", md: "17px", sm: "17px", xs: "17px" },
        fontFamily: "Roboto",
    }

    const handleHoverChange = (open) => {
        if (value?.quickSetupPayload?.doorIds?.length > 0) {
            if (open)
                setAnchorEl(true)
            else
                setAnchorEl(false)
        } else {
            setAnchorEl(false)
        }
    };

    const contentUI = (item, value) => (
        <Box maxHeight={"300px"} maxWidth={"180px"} mr={-1.4} overflow={"auto"}>
            {
                item?.map((doorId, cIndex) => {
                    const matchingDoor = totalDoor.find((door) => door.doorId === doorId);
                    if (matchingDoor) {
                        return (
                            <Box
                                key={cIndex}
                                display={"flex"}
                                alignItems={"center"}
                                sx={{
                                    ":hover": {
                                        backgroundColor: '#76B745',
                                        borderRadius: "6px"
                                    },
                                    cursor: 'pointer',
                                    padding: "2px"
                                }}
                            >
                                <Typography pl={"5px"}>
                                    {`${matchingDoor.doorParameters.name}`}
                                </Typography>

                            </Box>
                        );
                    }
                    return null;
                })
            }
        </Box>
    );

    return (
        <Box
            sx={{
                position: 'relative',
                border: '1px solid #DDDDDD',
                width: '100%',
                borderRadius: { xl: '8px', xs: "6px" },
                padding: { xl: '40px 30px 30px 30px', xs: '35px 25px 25px 25px' },
                font: "normal normal medium 16px/19px Roboto",
                '&::after': {
                    content: '"Select Door"',
                    position: 'absolute',
                    top: '-13px',
                    left: '30px',
                    backgroundColor: '#fff',
                    padding: '0 5px',
                    whiteSpace: 'nowrap',
                    color: "rgba(71, 75, 79, 0.8)",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Roboto"
                },
                '&:hover': {
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }
            }}
            id="select-Door-page"
        >
            <Grid container>
                <Grid item xs={12}>
                    <Grid container sx={{ height: { xl: "68px", xs: "54px" } }}>
                        <Grid item xs={6} sx={{ pr: { xl: "70px", xs: "50px" } }}>  {siteListField()}</Grid>
                        <Grid item xs={6} sx={{ pl: { xl: "30px", xs: "20px" }, display: "flex", justifyContent: "end" }}>
                            <SearchBar
                                placeholder={"Search by Device Name, Door Name"}
                                clearSearch={() => setDoorAndDeviceName("")}
                                onSearch={() => console.log()}
                                error={searchError}
                                name={"search"}
                                resetError={() => setSerachError("")}
                                helperText={searchError}
                                handleChange={(e) => {
                                    setDoorAndDeviceName(e.target.value);
                                }}
                                value={doorAndDeviceName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>

                    <Box height={"36px"} width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Popover
                            placement="right"
                            title={"Selected Doors " + value?.quickSetupPayload?.doorIds?.length}
                            content={contentUI(value?.quickSetupPayload?.doorIds)}
                            open={anchorEl}
                            onOpenChange={(e) => handleHoverChange(e)}
                        >
                            <Typography color={"#76B745"}>
                                Selected -  {value?.quickSetupPayload?.doorIds?.length <= 1 ? value?.quickSetupPayload?.doorIds?.length + " Door"
                                    : value?.quickSetupPayload?.doorIds?.length + " Doors"}
                            </Typography>
                        </Popover>
                        {value?.quickSetupPayload?.doorIds?.length > 0 && <Tooltip title={t("COMMON038")}>
                            < Cancel id="icon-devicelist-delete" style={{ cursor: 'pointer', color: "rgba(71, 75, 79, 0.5)" }}
                                onClick={() => {
                                    value.setQuickSetupPayload({
                                        ...value.quickSetupPayload,
                                        doorIds: []
                                    });
                                    setSelectedDoorList([]);
                                    setTotalDoor(deviceList);
                                }}
                            /></Tooltip>}
                    </Box>
                    <Box
                        className={"zone-scroll-container"}
                        sx={{
                            position: "relative",
                            border: "1px solid #DDDDDD",
                            width: "100%",
                            borderRadius: '8px',
                            display: "flex",
                            alignItems: "center",
                            height: "118px",
                            justifyContent: "space-between",
                            margin: { xl: "0 0 30px 0px", xs: "0 0 15px 0px" }
                            // margin: { xl: "30px 0 30px 0px", xs: "15px 0px" }
                        }}
                        id={"view-zone-page"}
                    >
                        {/* <PrevIcon style={{transform:'rotate(-180deg)', position:"absolute",top:"0px"}}/> */}
                        {selectedSite !== "" ? (!iszoneloading) ? zone instanceof Array && (!CommonUtil.isEmpty(zone)) ? <Box display={"flex"}>
                            {zone instanceof Array && zone.map((zoneData, zoneIndex) => (
                                <Box
                                    key={zoneIndex}
                                    onClick={() => { setSelectedZone(zoneData.id); loadData(1, 0, zoneData.id); setSerachError() }}
                                    sx={{
                                        borderBlockEnd: selectedZone === zoneData.id ? '2px' : '0px',
                                        borderBlockEndStyle: selectedZone === zoneData.id ? 'solid' : 'none',
                                        borderBlockEndColor: selectedZone === zoneData.id ? '#76B745' : '#fff',
                                        padding: "8px 20px"
                                    }} >
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center', width: "100px", cursor: "pointer" }}>
                                        {selectedZone === zoneData.id ? <ZoneIconColored /> : <ZoneIcon />}
                                        <Tooltip title={zoneData.name}>
                                            <Typography
                                                width={"98px"}
                                                textAlign={"center"}
                                                paddingTop={"4px"}
                                                color={selectedZone === zoneData.id ? "#76B745" : "rgba(107, 111, 114, 1)"}
                                                sx={toolTipStyle}>{zoneData.name}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                </Box>))}
                        </Box> : <Box width={"100%"}><NoRecordFound width={"30px"} text={t(" No Zones Found ")} /></Box>
                            : <Loader></Loader>
                            : <Box width={"100%"}><NoRecordFound width={"30px"} text={t("COMMON043")} /></Box>}

                        {/* <NextIcon /> */}
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Box
                        className={"zone-scroll-container"}
                        sx={{
                            border: "1px solid #DDDDDD",
                            width: "100%",
                            borderRadius: '8px',
                            padding: selectedZone !== "" ? { xl: "30px 30px 0px 30px", xs: "15px 15px 0px 15px" } : "0px",
                            height: { xl: "332px", xs: "347px" },
                        }}
                        id={"view-device-door-page"}
                    >
                        {<Grid container>
                            {selectedZone !== "" ? (!isDeviceloading) ? !CommonUtil.isEmpty(searchDevice) ? (searchDevice.map((data, index) => (
                                <Grid item md={6} xs={12} key={index}
                                    sx={{
                                        pr: { md: `${(index % 2) === 0 ? "10px" : "0px"}`, xs: "" },
                                        pl: { md: `${(index % 2) !== 0 ? "10px" : "0px"}`, xs: "" },
                                        pb: { xl: "30px", xs: "20px" },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            padding: "15px",
                                            borderRadius: '8px',
                                            border: "1px solid #DDDDDD"
                                        }}>
                                        <Box display={"flex"} alignItems={"center"} mb={1}>
                                            <AccDeviceIcon />
                                            <Tooltip title={data.deviceName}>
                                                <Typography
                                                    width={"98px"}
                                                    textAlign={"center"}
                                                    sx={toolTipStyle}>{data.deviceName}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                        <Box
                                            className={"zone-scroll-container"}
                                            maxHeight={"110px"} pl={2}>
                                            {data.doors.map((door, innerIndex) => (
                                                <Box
                                                    onClick={() => {
                                                        if (selectedDoorList instanceof Array && selectedDoorList.includes(door.doorId)) {
                                                            setSelectedDoorList(prev => prev.filter(id => id !== door.doorId));
                                                        } else {
                                                            if (selectedDoorList instanceof Array) {
                                                                setSelectedDoorList(prev => [...prev, door.doorId]);
                                                            } else {
                                                                setSelectedDoorList([door.doorId])
                                                            }
                                                        }
                                                    }}
                                                    display={"flex"}
                                                    justifyContent={"space-between"} p={1} sx={{ cursor: "pointer" }}>
                                                    <Box key={innerIndex} display={"flex"} alignItems={"center"}>
                                                        <AccDoorIcon />
                                                        <Tooltip title={door.doorParameters.name}>
                                                            <Typography
                                                                width={"98px"}
                                                                textAlign={"center"}
                                                                sx={toolTipStyle}>{door.doorParameters.name}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Box>
                                                    {selectedDoorList instanceof Array && selectedDoorList.includes(door.doorId) ? <CheckCircleIcon style={{ color: "#76B745" }} />
                                                        : <Box sx={{
                                                            height: "23px",
                                                            width: "23px",
                                                            border: "2px solid #D6D6D6",
                                                            borderRadius: "50%"
                                                        }}></Box>}
                                                </Box>))}
                                        </Box>
                                    </Box>

                                </Grid>
                            ))) : <Box sx={{
                                width: "100%",
                                mt: "100px"
                            }}><NoRecordFound text={t(" No Device Found ")} /></Box>
                                : <Box width={"100%"} mt={"30px"} ><Loader /></Box>
                                : <Box sx={{
                                    width: "100%",
                                    mt: "100px"
                                }}><NoRecordFound text={t("COMMON044")} /></Box>
                            }
                        </Grid>}
                    </Box>

                </Grid>
            </Grid>
        </Box>
    )
}

export default DeviceAndDoorPage

const demodoors = [
    {
        "doorId": "584e9b269f153d2da4997edd2815f975",
        "deviceId": "e93a65d51a2943f09a31f50bc7b2edf3",
        "doorParameters": { "name": "Door-13" },
        "deviceName": "b0126453",
    },
    {
        "doorId": "584e9b269f153d2da4997edd2815f919",
        "deviceId": "e93a65d51a2943f09a31f50bc7b2edf3",
        "doorParameters": { "name": "Door-11" },
        "deviceName": "b0126453",
    },
    {
        "doorId": "584e9b269f153d2da4997edd2815f918",
        "deviceId": "e93a65d51a2943f09a31f50bc7b2edc1",
        "doorParameters": { "name": "Door-1" },
        "deviceName": "b012",
    },
    {
        "doorId": "584e9b269f153d2da4997edd2815f933",
        "deviceId": "e93a65d51a2943f09a31f50bc7b2edb1",
        "doorParameters": { "name": "Door-133" },
        "deviceName": "b01244",
    },
    {
        "doorId": "584e9b269f153d2da4997edd2815f944",
        "deviceId": "e93a65d51a2943f09a31f50bc7b2edb1",
        "doorParameters": { "name": "Door-144" },
        "deviceName": "b01244",
    }
];
