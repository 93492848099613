import api from "../configurations/http-common";


export const filterOperation = async (payload, callBack) => {
    try {
      const res = await api.securedAxios().get('/web/operation-log?pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize + buildQuery(payload.filter));
      callBack(res);
    } catch (error) { }
  };

export const getOperationLogReport= async (payload,callBack) =>{
  try{
    const res = await api.securedAxios().post('/web/operation-log/report',payload);
  callBack(res)
  }catch(error){}
}

  const buildQuery = (filter) => {
    let query = '';
    if (filter.operator) {
      let encodedoperator = encodeURIComponent(filter.operator);
      query = query + `&operator=${encodedoperator}`;
    }
    if (filter.executingState) {
      let encodedExecutingState = encodeURIComponent(filter.executingState);
      query = query + `&executingState=${encodedExecutingState}`;
    }
    return query;
  }