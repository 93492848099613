import api from "../configurations/http-common";
import CommonUtil from "../util/CommonUtils";

export const filterDevicesWithoutLoader = async (payload, callBack) => {
  try {
    const res = await api.securedAxiosWithoutLoader().post('/web/devices/list?page=' + payload.pageNumber + '&size=' + payload.pageSize + buildQuery(payload.filter));
    callBack(res);
  } catch (error) { }
};

export const filterDevices = async (payload, callBack) => {
  try {
    const res = await api.securedAxios().post('/web/devices/list?page=' + payload.pageNumber + '&size=' + payload.pageSize + buildQuery(payload.filter));
    callBack(res);
  } catch (error) { }
};
const buildQuery = (filter) => {
  let query = '';
  if (filter.deviceSn) {
    let encodedSN = encodeURIComponent(filter.deviceSn);
    query = query + `&deviceSn=${encodedSN}`;
  }
  if (filter.deviceAlias) {
    let encodedName = encodeURIComponent(filter.deviceAlias);
   query = query + `&deviceAlias=${encodedName}`;
  }
  if (filter.zoneId) {
   query = query + `&zoneId=${filter.zoneId}`;
  }
  return query;
}

export const rebootDevice = async (id,deviceNames, callBack) => {
  try {
    const res = await api.securedAxios().post(`/web/devices/${id}/reboot`,deviceNames);
    callBack(res);
  } catch (error) { }
};

export const deleteDevice = async (ids,deviceName, callBack) => {
  try {
    const res = await api.securedAxios().post(`/web/devices/delete/${ids}`,deviceName);
    callBack(res);
  } catch (error) { }
};

export const findDeviceBySn = async (sn, callBack) => {
  try {
    const res = await api.securedAxios().get('/web/devices/' + sn + '/pre_register');
    callBack(res);
  } catch (error) { }
};

export const bindDevice = async (payload, callBack) => {
  try {
    const res = await api.securedAxios().put('/web/devices/bind', payload);
    callBack(res);
  } catch (error) { }
};

export const updateDevice = async (payload) => {
  return api.securedAxios().put('/web/device/' + payload.id, payload);
};

export const getDoor = async (deviceId, callBack) => {

  try {
    const res = await api.securedAxios().get('/web/door/device/' + deviceId);
    callBack(res)
  }
  catch (error) { }
}

export const findReader = async (deviceId, callBack) => {
  try {
    const res = await api.securedAxios().get('/web/reader/device/' + deviceId);
    callBack(res);
  } catch (error) { }
};

export const findAuxInDetails = async (deviceId, callBack) => {
  try {
    const res = await api.securedAxios().get('/web/auxiliary_in/' + deviceId);
    callBack(res);
  } catch (error) { }
};
export const findAuxOutDetails = async (deviceId, callBack) => {
  try {
    const res = await api.securedAxios().get('/web/auxiliary_out/' + deviceId);
    callBack(res);
  } catch (error) { }
};

export const filterDevicesFetch = async (page, rowsPerPage, filter) => {
  if (!CommonUtil.isEmptyString(page) && !CommonUtil.isEmptyString(rowsPerPage)) {
       return api.securedAxios().post('/web/devices/list?' + 'page=' + page + '&size=' + rowsPerPage+'&siteId='+filter.siteId);
  }
};

export const getDeviceSpecs = async (devId, sn, callBack) => {
  try {
    const res = await api.securedAxiosWithoutLoader().get('/web/device/specs?deviceId=' + devId + '&sn=' + sn);
    callBack(res);
  } catch (error) {}
};

export const personsFromDevice = async (deviceId, page, size, callBack) => {
  try {
    const res = await api.securedAxios().get('/web/person/getPersonsFromDevcie?deviceId=' + deviceId + '&page=' + page + '&size=' + size);
    callBack(res);
  } catch (error) {}
};

export const updateDeviceRole = async (devId, personId, role, callBack) => {
  try {
    const res = await api.securedAxios().put('/web/person/updatePersonCredentials?devId=' + devId + '&personId=' + personId + '&role=' + role);
    callBack(res);
  } catch (error) {}
};

export const remoteRegistration = async (devId,device, payload, callBack) => {
  try {
    const res = await api.securedAxios().post('/web/devices/' + devId + '/remoteRegistration?device='+device, payload);
    callBack(res);
  } catch (error) {}
};
export const updateDevicePasscode = async (devId, personId, passcode,device, callBack) => {
  const payload={
    devId:devId,
    personId:personId,
    passcode:passcode,
    device:device
  }
  try {
    const res = await api.securedAxios().put('/web/person/updatePersonCredentialspasscode',payload);
    callBack(res);
  } catch (error) {}
};

export const deletePersonsCredentials= async (personId, callBack) => {
  try {
    const res = await api.securedAxios().delete('/web/person/deletePersonCredentialsFromCMS?personId=' + personId);
    callBack(res);
  } catch (error) {}
};

export const deleteIndividualCredentials= async (credId, callBack) => {
  try {
    const res = await api.securedAxios().delete('/web/person/deleteIndividualCredentials?credId=' + credId);
    callBack(res);
  } catch (error) {}
};
export const deletetCredentialByEmpCodeAndCredTypeId= async (empCode,credType, callBack) => {
  try {
    const res = await api.securedAxios().delete(`/web/person/credential?empCode=${empCode}&credType=${credType}`);
    callBack(res);
  } catch (error) {}
};

export const getCredentialByEmpCodeAndCredTypeId= async (empCode,credType, callBack) => {
  try {
    const res = await api.securedAxios().get(`/web/person/getIndividualCredential?empCode=${empCode}&credType=${credType}`);
    callBack(res);
  } catch (error) {}
};

export const deletePersonFromDevice= async (payload, callBack) => {
  try {
    const res = await api.securedAxios().delete(`/web/device/delete-person?empId=${payload.empId}&deviceId=${payload.deviceId}&ip=${payload.ip}&lastName=${payload.lastName}&firstName=${payload.firstName}`);
    callBack(res);
  } catch (error) {}
};

export const allDevices = async (callBack) => {
  try {
    const res = await api.securedAxios().get('/web/devices');
    return res;
  } catch (error) { }
};