
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem } from '@mui/x-data-grid';
import lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as ViewIcon } from '../../assets/ViewIcon.svg';
import DataTable from '../../components/layout-components/DataTable';
import PaginationContext from '../../components/layout-components/PaginationContext';
import RightViewLayout from '../../components/RighViewLayout';
import SearchBar from '../../components/SearchBar';
import OverflowTip from '../../components/tooltip';
import api from '../../configurations/http-common';
import { REACT_URL_SITE_VIEW } from '../../constants/ReactEndPoints';
import { getPermissionsByRoleId } from '../../services/RoleService';
import { filterSitelist, loadLocSite } from '../../services/SiteService';
import { timeZoneList } from '../../services/TimeZone';
import CommonUtil from '../../util/CommonUtils';

function Site() {
    let navigate = useNavigate();
    const [site, setSite] = useState([]);
    const { t } = useTranslation();
    const { manageSitePageNumber, setManageSitePageNumber, manageSitePageSize, setManageSitePageSize } = useContext(PaginationContext)
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [perViewSite, setViewSite] = useState(true);
    const [perListSite, setListSite] = useState(true);
    const [ids, setIds] = useState([]);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const getTimezoneName = (value) => {
        var name = '';
        timeZoneList.map((t) => {
            if (value == t.value) {
                name = t.name;
            }
        });
        return name;
    };

    const loadData = (pageNumber, pageSize, searchData) => {
        const searchValue = {
            name: searchData ? searchData : ""
        }
        loadLocSite(pageNumber, pageSize, searchValue).then((res) => {
            if (res?.data?.code === 'LMSI6000') {
                setSite(res.data.data.sites);
                setTotalRecords(res.data.data.totalCount);
            }
        });
    };

    const searchSite = (pageNumber, pageSize, searchData) => {
        const searchValue = {
            companyIds: [],
            ids: [],
            name: searchData ? searchData : "",
            parentId: "",
            siteTypeId: "",
            city: "",
            state: "",
            country: ""
        }
        filterSitelist(pageNumber, pageSize, searchValue).then((res) => {
            if (res?.data?.code === 'LMSI6000') {
                setSite(res.data.data.sites);
                setTotalRecords(res.data.data.totalCount);
            }
        });
    };

    useEffect(() => {
        var l = [];
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });

            setViewSite(l.includes("ACC_VIEW_SITE_DETAILS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setListSite(l.includes("ACC_VIEW_SITE_LISTS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

            if (l.includes("ACC_VIEW_SITE_LISTS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
                loadData(manageSitePageNumber, manageSitePageSize, "");
            }
        });

    }, []);

    const searchData = useCallback(lodash.throttle((pageNumber, pageSize, searchValue) => {
        searchSite(pageNumber, pageSize, searchValue)
    }, 1200, { leading: false, trailing: true }), []);

    useEffect(() => {
        var list = [];
        if (site && site.length > 0)
            site.map((item, index) => {
                list.push(
                    {
                        "name": item.name,
                        "siteTimeZone": item.address ? getTimezoneName(item.address.timeZone) : '-',
                        "country": item.address ? item.address.country : '-',
                        "provinceState": item.address ? item.address.state : '-',
                        "city": item.address ? item.address.city : '-',
                        "addressLine1": item.address ? item.address.addressLine1 : '-',
                        "id": item.id
                    }
                );
            })

        setData(list)
    }, [site])
    const columns = [
        {
            field: 'name',
            hideable: false,
            headerName: t('ACCSITE001'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCSITE001')} >
                    <span>
                        {t('ACCSITE001')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.name}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'siteTimeZone',
            headerName: t('ACCSITE002'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCSITE002')}>
                    <span>
                        {t('ACCSITE002')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.siteTimeZone}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'country',
            headerName: t('ACCDA0024'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCDA0024')}>
                    <span>
                        {t('ACCDA0024')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.country}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'provinceState',
            headerName: t('ACCLANG008'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCLANG008')}>
                    <span>
                        {t('ACCLANG008')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.provinceState}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'city',
            headerName: t('ACCORG020'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG020')}>
                    <span>
                        {t('ACCORG020')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.city}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'addressLine1',
            headerName: t('ACCLANG007'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCLANG007')}>
                    <span>
                        {t('ACCLANG007')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.addressLine1}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        // {
        //     field: 'description',
        //     headerName: t('ACCSITE003'),
        //     flex: 1,
        //     renderHeader: (e) => (
        //         <Tooltip title={t('ACCSITE003')} >
        //             <span>
        //             {t('ACCSITE003')}
        //             </span>
        //         </Tooltip>
        //     ),
        //     renderCell: (e) => (
        //         <OverflowTip
        //             value={e.row.description}
        //             onClick={() => console.info()}
        //             style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 alignItems: 'center',
        //             }}
        //         ></OverflowTip>
        //     ),
        // },
        {
            headerName: t('ACCORG006'),
            hideable: false,
            sortable: false,
            headerClassName: 'no-sort-filter',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG006')} >
                    <span>
                        {t('ACCORG006')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <>
                    {perViewSite && <GridActionsCellItem
                        icon={<Tooltip title={t('ACCSITE004')} >
                            <ViewIcon id={"icon-site-list-view"} style={{ cursor: 'pointer' }}
                                onClick={() => navigate(REACT_URL_SITE_VIEW + `/${e.row.id}`, { state: { data: e.row, pageNumber: manageSitePageNumber, pageSize: manageSitePageSize } })} />
                        </Tooltip>
                        }
                    />}
                </>
            ),
        },
    ];

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setManageSitePageNumber(1);
        searchData(1, manageSitePageSize, value);
    };

    const clearSearch = () => {
        setSearchValue('');
        if (searchValue !== "") {
            setManageSitePageNumber(1);
            loadData(1, manageSitePageSize, "");
        }
    };

    const actions = () => (
        <>

            <Box
                aria-label="Search-icon"
                mr={2}
                id={"Search-icon"}
            >
                <SearchBar
                    placeholder={t('COMMON049')}
                    searchValue={searchValue}
                    clearValue={clearSearch}
                    handleSearchChange={handleSearch}
                    width={{xl:"300px",xs:"280px"}}
                />
            </Box>
            <Box sx={{ height: { xl: "50px", xs: "40px" } }}>
                <Tooltip title={t('ACCORG008')}>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        sx={{ display: { xl: "block", xs: "none" } }}
                    >
                        <RefreshIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        sx={{ display: { xl: "none", xs: "block" } }}
                    >
                        <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
            </Box>
        </>
    );

    const refreshTableData = () => {
        loadData(manageSitePageNumber, manageSitePageSize, searchValue);
    };

    const handlePageChange = (e) => {
        loadData(e, manageSitePageSize, searchValue);
        setManageSitePageNumber(e);
    }

    const handlePageSize = (e) => {
        setManageSitePageNumber(1);
        setManageSitePageSize(e)
        loadData(manageSitePageNumber, e, searchValue);
    }

    return (
        <>
            {perListSite ? (<> <RightViewLayout title={t('ACCSITE006')} type='table' navigateBack={true} >{actions()}</RightViewLayout>
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    <DataTable
                        page={manageSitePageNumber - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={manageSitePageSize}
                        columns={columns}
                        rows={data}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        onPageChange={(e) => handlePageChange(CommonUtil.getPageNumber(e))}
                        onPageSizeChange={(e) => handlePageSize(e)}
                        noRecordText={t('ACCSITE007')} />
                </Box>
            </>) : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={'center'}>{t('ACCSITE008')}</Typography></Box>)}
        </>
    );
}

export default Site;