import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { TimePicker } from 'antd';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localeID from 'antd/es/date-picker/locale/id_ID';
import localeJA from 'antd/es/date-picker/locale/ja_JP';
import localeRU from 'antd/es/date-picker/locale/ru_RU';
import localeTH from 'antd/es/date-picker/locale/th_TH';
import localeZH from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import RightViewLayout from '../../components/RighViewLayout';
import { getScheduleById, updateSchedule } from '../../services/SchedulesService';
import BrowserLanguage from '../../util/BrowserLanguage';
import CommonUtil from '../../util/CommonUtils';
import 'antd/dist/antd.min.css';
import { useSnackbar } from 'notistack';

import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { REACT_TIME_SLOT, REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import ViewPage from '../../components/ViewPage';
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';
import CustomButton from '../../components/CustomButton';


export default function EditTimeSlot() {
  const { t } = useTranslation();
  const location = useLocation();
  const language = BrowserLanguage.getDefaultLanguage();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState([]);
  const { timeslotId } = useParams();
  const [perEditTimeslot, setEditTimeslot] = useState(false);
  const [viewErrorPage, setViewErrorPage] = useState(false)
  const [loader, setLoader] = React.useState(true);


  const [copyMonday, setCopyMonday] = useState(false);
  const [copyWeekend, setCopyWeekend] = useState(false);

  const [monday1, setMonday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [monday2, setMonday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [monday3, setMonday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday1, setTuesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday2, setTuesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday3, setTuesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday1, setWednesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday2, setWednesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday3, setWednesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday1, setThursday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday2, setThursday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday3, setThursday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday1, setFriday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday2, setFriday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday3, setFriday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday1, setSaturday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday2, setSaturday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday3, setSaturday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday1, setSunday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday2, setSunday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday3, setSunday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);

  const [error, setError] = useState({
    name: false,
    remark: false,
  });
  const localeMap = {
    en: localeEN,
    es: localeES,
    zh: localeZH,
    ru: localeRU,
    ja: localeJA,
    th: localeTH,
    idn: localeID,
  };
  const defaultPayload = {
    id: '',
    companyId: '',
    name: '',
    remark: '',
    sundayStart1: '',
    sundayEnd1: '',
    sundayStart2: '',
    sundayEnd2: '',
    sundayStart3: '',
    sundayEnd3: '',
    mondayStart1: '',
    mondayEnd1: '',
    mondayStart2: '',
    mondayEnd2: '',
    mondayStart3: '',
    mondayEnd3: '',
    tuesdayStart1: '',
    tuesdayEnd1: '',
    tuesdayStart2: '',
    tuesdayEnd2: '',
    tuesdayStart3: '',
    tuesdayEnd3: '',
    wednesdayStart1: '',
    wednesdayEnd1: '',
    wednesdayStart2: '',
    wednesdayEnd2: '',
    wednesdayStart3: '',
    wednesdayEnd3: '',
    thursdayStart1: '',
    thursdayEnd1: '',
    thursdayStart2: '',
    thursdayEnd2: '',
    thursdayStart3: '',
    thursdayEnd3: '',
    fridayStart1: '',
    fridayEnd1: '',
    fridayStart2: '',
    fridayEnd2: '',
    fridayStart3: '',
    fridayEnd3: '',
    saturdayStart1: '',
    saturdayEnd1: '',
    saturdayStart2: '',
    saturdayEnd2: '',
    saturdayStart3: '',
    saturdayEnd3: '',
  };
  const [payload, setPayload] = React.useState(defaultPayload);
  const [schedule, setSchedule] = React.useState({});
  const [timezones, setTimezones] = React.useState([]);
  const [clear, setClear] = useState(false);
  const [name,setName]=useState("")
  const [isLoading, setIsLoading] = useState(false);

  const getTimeSlotById = () => {
    setLoader(true);
    getScheduleById(timeslotId).then((res) => {
      if (res?.data?.code === 'DMSI0000') {
        if (!CommonUtil.isEmpty(res?.data?.data)) {
          setSchedule(res.data.data);
          setTimezones(res.data.data.timezones);
          setName(res.data.data.name)
          setPayload({
            ...payload,
            name: res.data.data.name,
          });
          let count1 = 1; let count2 = 1; let count3 = 1;
          let countWeekend1 = 0; let countWeekend2 = 0; let countWeekend3 = 0;
          let previousInterval1 = ""; let previousInterval2 = ""; let previousInterval3 = "";
          let previousIntervalweekend1 = ""; let previousIntervalweekend2 = ""; let previousIntervalweekend3 = "";
          res.data.data.timezones.map((timezone) => {
            switch (timezone.intervals) {
              case 1:
                {
                  switch (timezone.type) {
                    case 1: {
                      previousIntervalweekend1 = timezone.startTime + "-" + timezone.endTime
                      break;
                    }
                  }
                }
                break;
              case 2:
                {
                  switch (timezone.type) {
                    case 1: {
                      previousIntervalweekend2 = timezone.startTime + "-" + timezone.endTime
                      break;
                    }
                  }
                }
                break;
              case 3:
                {
                  switch (timezone.type) {
                    case 1: {
                      previousIntervalweekend3 = timezone.startTime + "-" + timezone.endTime
                      break;
                    }
                  }
                }
                break;
              default:
                break;
            }
          });
          
          res.data.data.timezones.map((timezone) => {
            switch (timezone.intervals) {
              case 1:
                {
                  switch (timezone.type) {
                    case 1: {
                      if (previousInterval1 === timezone.startTime + "-" + timezone.endTime) {
                        count1 = count1 + 1;
                      }
                      previousInterval1 = timezone.startTime + "-" + timezone.endTime;
                      setMonday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 2: {
                      if (previousInterval1 === timezone.startTime + "-" + timezone.endTime) {
                        count1 = count1 + 1;
                      }
                      previousInterval1 = timezone.startTime + "-" + timezone.endTime

                      setTuesday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 3: {
                      if (previousInterval1 === timezone.startTime + "-" + timezone.endTime) {
                        count1 = count1 + 1;
                      }
                      previousInterval1 = timezone.startTime + "-" + timezone.endTime
                      setWednesday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 4: {
                      if (previousInterval1 === timezone.startTime + "-" + timezone.endTime) {
                        count1 = count1 + 1;
                      }
                      previousInterval1 = timezone.startTime + "-" + timezone.endTime
                      setThursday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 5: {
                      if (previousInterval1 === timezone.startTime + "-" + timezone.endTime) {
                        count1 = count1 + 1;
                      }
                      previousInterval1 = timezone.startTime + "-" + timezone.endTime
                      setFriday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 6: {
                      if (previousIntervalweekend1 === timezone.startTime + "-" + timezone.endTime) {
                        countWeekend1 = countWeekend1 + 1;
                      }
                      setSaturday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 0: {
                      if (previousIntervalweekend1 === timezone.startTime + "-" + timezone.endTime) {
                        countWeekend1 = countWeekend1 + 1;
                      }
                      setSunday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                  }
                }
                break;
              case 2:
                {
                  switch (timezone.type) {
                    case 1: {
                      if (previousInterval2 === timezone.startTime + "-" + timezone.endTime) {
                        count2 = count2 + 1;
                      }
                      previousInterval2 = timezone.startTime + "-" + timezone.endTime;
                      setMonday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 2: {
                      if (previousInterval2 === timezone.startTime + "-" + timezone.endTime) {
                        count2 = count2 + 1;
                      }
                      previousInterval2 = timezone.startTime + "-" + timezone.endTime
                      setTuesday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 3: {
                      if (previousInterval2 === timezone.startTime + "-" + timezone.endTime) {
                        count2 = count2 + 1;
                      }
                      previousInterval2 = timezone.startTime + "-" + timezone.endTime
                      setWednesday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 4: {
                      if (previousInterval2 === timezone.startTime + "-" + timezone.endTime) {
                        count2 = count2 + 1;
                      }
                      previousInterval2 = timezone.startTime + "-" + timezone.endTime
                      setThursday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 5: {
                      if (previousInterval2 === timezone.startTime + "-" + timezone.endTime) {
                        count2 = count2 + 1;
                      }
                      previousInterval2 = timezone.startTime + "-" + timezone.endTime
                      setFriday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 6: {
                      if (previousIntervalweekend2 === timezone.startTime + "-" + timezone.endTime) {
                        countWeekend2 = countWeekend2 + 1;
                      }
                      setSaturday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 0: {
                      if (previousIntervalweekend2 === timezone.startTime + "-" + timezone.endTime) {
                        countWeekend2 = countWeekend2 + 1;
                      }
                      setSunday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                  }
                }
                break;
              case 3:
                {
                  switch (timezone.type) {
                    case 1: {
                      if (previousInterval3 === timezone.startTime + "-" + timezone.endTime) {
                        count3 = count3 + 1;
                      }
                      previousInterval3 = timezone.startTime + "-" + timezone.endTime;
                      setMonday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 2: {
                      if (previousInterval3 === timezone.startTime + "-" + timezone.endTime) {
                        count3 = count3 + 1;
                      }
                      previousInterval3 = timezone.startTime + "-" + timezone.endTime
                      setTuesday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 3: {
                      if (previousInterval3 === timezone.startTime + "-" + timezone.endTime) {
                        count3 = count3 + 1;
                      }
                      previousInterval3 = timezone.startTime + "-" + timezone.endTime
                      setWednesday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 4: {
                      if (previousInterval3 === timezone.startTime + "-" + timezone.endTime) {
                        count3 = count3 + 1;
                      }
                      previousInterval3 = timezone.startTime + "-" + timezone.endTime
                      setThursday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 5: {
                      if (previousInterval3 === timezone.startTime + "-" + timezone.endTime) {
                        count3 = count3 + 1;
                      }
                      previousInterval3 = timezone.startTime + "-" + timezone.endTime
                      setFriday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 6: {
                      if (previousIntervalweekend3 === timezone.startTime + "-" + timezone.endTime) {
                        countWeekend3 = countWeekend3 + 1;
                      }
                      setSaturday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 0: {
                      if (previousIntervalweekend3 === timezone.startTime + "-" + timezone.endTime) {
                        countWeekend3 = countWeekend3 + 1;
                      }
                      setSunday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                  }
                }
                break;
              default:
                break;
            }
          });
            
          if (count1 === 5 && count2 === 1 && count3 === 1) {
            setCopyMonday(true);
          } else if (count1 === 1 && count2 === 5 && count3 === 1) {
            setCopyMonday(true);
          } else if (count1 === 1 && count2 === 1 && count3 === 5) {
            setCopyMonday(true);
          } else if (count1 === 5 && count2 === 5 && count3 === 1) {
            setCopyMonday(true);
          } else if (count1 === 1 && count2 === 5 && count3 === 5) {
            setCopyMonday(true);
          } else if (count1 === 5 && count2 === 5 && count3 === 5) {
            setCopyMonday(true);
          }
          if (countWeekend1 === 2 && countWeekend2 === 0 && countWeekend3 === 0) {
            setCopyWeekend(true);
          } else if (countWeekend1 === 0 && countWeekend2 === 2 && countWeekend3 === 0) {
            setCopyWeekend(true);
          } else if (countWeekend1 === 0 && countWeekend2 === 0 && countWeekend3 === 2) {
            setCopyWeekend(true);
          } else if (countWeekend1 === 2 && countWeekend2 === 2 && countWeekend3 === 0) {
            setCopyWeekend(true);
          } else if (countWeekend1 === 0 && countWeekend2 === 2 && countWeekend3 === 2) {
            setCopyWeekend(true);
          } else if (countWeekend1 === 2 && countWeekend2 === 2 && countWeekend3 === 2) {
            setCopyWeekend(true);
          }
        }
      } else {
        setViewErrorPage(true)
      }
      setLoader(false);
    });
  }

  useEffect(() => {
    if (CommonUtil.isEmpty(timeslotId)) {
      navigate({
        pathname: REACT_URL_PAGE_NOT_FOUND,
      });
    }
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setPermissions(prev => [...prev, item.code])
        });
      }
      setEditTimeslot(true);
    });
  }, []);
  useEffect(() => {
    if (checkPermission("ADD_TIMESLOT") || checkPermission("VIEW_TIMESLOT")) {
      getTimeSlotById();
    }
  }, [permissions])

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  useEffect(() => {
    if (copyMonday) handleCopyMonday(monday1, monday2, monday3);
    if (copyWeekend) handleCopyWeekend(monday1, monday2, monday3)
  }, [monday1, monday2, monday3]);

  const timeFormat = 'HH:mm';

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    if (event.target.name === "copyMonday") {
      setCopyMonday(event.target.checked);
      var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
      if (event.target.checked) handleCopyMonday(monday1, monday2, monday3);
      else handleCopyMonday(defaultTime, defaultTime, defaultTime);
    }
    if (event.target.name === "copyWeekend") {
      setCopyWeekend(event.target.checked);
      var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
      if (event.target.checked) handleCopyWeekend(monday1, monday2, monday3);
      else handleCopyWeekend(defaultTime, defaultTime, defaultTime);
    }
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t('COMMON027'),
      });
      return;
    }

    if (monday1[1].isAfter(monday2[0]) && monday2[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (monday2[1].isAfter(monday3[0]) && monday3[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (tuesday1[1].isAfter(tuesday2[0]) && tuesday2[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (tuesday2[1].isAfter(tuesday3[0]) && tuesday3[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (wednesday1[1].isAfter(wednesday2[0]) && wednesday2[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (wednesday2[1].isAfter(wednesday3[0]) && wednesday3[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (thursday1[1].isAfter(thursday2[0]) && thursday2[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (thursday2[1].isAfter(thursday3[0]) && thursday3[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (friday1[1].isAfter(friday2[0]) && friday2[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (friday2[1].isAfter(friday3[0]) && friday3[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (saturday1[1].isAfter(saturday2[0]) && saturday2[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (saturday2[1].isAfter(saturday3[0]) && saturday3[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (sunday1[1].isAfter(sunday2[0]) && sunday2[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    if (sunday2[1].isAfter(sunday3[0]) && sunday3[0].format('HH:mm') != '00:00') {
      enqueueSnackbar(t('AccTime024'), { variant: 'error' });
      return;
    }

    const state = {
      name: payload.name,
      timezones: timezones,
    };

    // if (payload.name.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)) {
    //   setError({ ...error, name: true });
    //   toast.error(t('SCHEDULES002'));
    //   return;
    // }
    setLoader(true);
    setIsLoading(true);
    updateSchedule(timeslotId, state,).then((res) => {
      if (res?.status === 200 && res?.data?.code === 'DMSI0008') {
        setIsLoading(false);
        enqueueSnackbar(t('AccTime006'), { variant: 'success' });
        navigate(
          '/time-slots',
          { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })
      } else if (res?.data?.code === 'DMSE00065') {
        setIsLoading(false);
        enqueueSnackbar(t('AccTime007'), { variant: 'error' })
      } else if (res?.data?.code === 'CASE0101') {
        setIsLoading(false);
        setError({
          ...error,
          name: true,
        });
        enqueueSnackbar(res.data.msg, { variant: 'error' })
      } else {
        setIsLoading(false);
        enqueueSnackbar(res.data.msg, { variant: 'error' })
      }
      setLoader(false);
    });
  };
  var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
  const handleClose = () => {
    getTimeSlotById();
    setCopyMonday(false);
    setCopyWeekend(false);
    setMonday1(defaultTime);
    setMonday2(defaultTime);
    setMonday3(defaultTime);
    setPayload(defaultPayload);
    setThursday1(defaultTime);
    setWednesday1(defaultTime);
    setTuesday1(defaultTime);
    setFriday1(defaultTime);
    setSunday1(defaultTime);
    setSaturday1(defaultTime);

    setSunday2(defaultTime);
    setTuesday2(defaultTime);
    setWednesday2(defaultTime);
    setThursday2(defaultTime);
    setFriday2(defaultTime);
    setSaturday2(defaultTime);

    setTuesday3(defaultTime);
    setWednesday3(defaultTime);
    setThursday3(defaultTime);
    setFriday3(defaultTime);
    setSaturday3(defaultTime);
    setSunday3(defaultTime);
  };

  const handleCopyMonday = (monday1, monday2, monday3) => {
    setThursday1(monday1);
    setWednesday1(monday1);
    setTuesday1(monday1);
    setFriday1(monday1);
    handleTimeIntervalChange(1, 2, monday1);
    handleTimeIntervalChange(1, 3, monday1);
    handleTimeIntervalChange(1, 4, monday1);
    handleTimeIntervalChange(1, 5, monday1);

    setTuesday2(monday2);
    setWednesday2(monday2);
    setThursday2(monday2);
    setFriday2(monday2);
    handleTimeIntervalChange(2, 2, monday2);
    handleTimeIntervalChange(2, 3, monday2);
    handleTimeIntervalChange(2, 4, monday2);
    handleTimeIntervalChange(2, 5, monday2);

    setTuesday3(monday3);
    setWednesday3(monday3);
    setThursday3(monday3);
    setFriday3(monday3);
    handleTimeIntervalChange(3, 2, monday3);
    handleTimeIntervalChange(3, 3, monday3);
    handleTimeIntervalChange(3, 4, monday3);
    handleTimeIntervalChange(3, 5, monday3);

  };

  const handleCopyWeekend = (monday1, monday2, monday3) => {

    setSunday1(monday1);
    setSaturday1(monday1);
    handleTimeIntervalChange(1, 6, monday1);
    handleTimeIntervalChange(1, 0, monday1);

    setSunday2(monday2);
    setSaturday2(monday2);
    handleTimeIntervalChange(2, 6, monday2);
    handleTimeIntervalChange(2, 0, monday2);

    setSaturday3(monday3);
    setSunday3(monday3);
    handleTimeIntervalChange(3, 6, monday3);
    handleTimeIntervalChange(3, 0, monday3);
  }

  const formatTime = (time) => {
    if (CommonUtil.isEmptyString(time)) return '00:00';
    else return time;
  };

  const handleTimeIntervalChange = (interval, type, time) => {
    var tempTimeZones = timezones;
    var index = tempTimeZones.findIndex((timezone) => timezone.intervals === interval && timezone.type === type);
    if (index === -1) {
      const newObj = {
        intervals: interval,
        type: type,
        startTime: time[0].format('HH:mm'),
        endTime: time[1].format('HH:mm'),
      };
      tempTimeZones.push(newObj);
    } else {
      tempTimeZones[index].startTime = time[0].format('HH:mm');
      tempTimeZones[index].endTime = time[1].format('HH:mm');
    }

    setTimezones(tempTimeZones);
  };



  const labelStyle = {
    textAlign: 'left',
    color: 'rgba(71, 75, 79, 0.8)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "21px",
    fontFamily: "Roboto",
  }

  const checkStyle = {
    "& .MuiTypography-root": {
      textAlign: 'left',
      color: 'rgba(71, 75, 79, 0.8)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      fontFamily: "Roboto",
    }

  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isLoading) {
      handleSubmit();
    }
  };

  return (
    <>
      {viewErrorPage ? <PageNotFound></PageNotFound> : perEditTimeslot ? (checkPermission("ADD_TIMESLOT") || checkPermission("VIEW_TIMESLOT")) ? (<ViewPage
        title={name === '24-Hours General' ? t("AccTime003") : t("AccTime002")}
        path={true}
        onClick={() => navigate(
          '/time-slots',
          { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
      >
        {loader ? <Loader open={loader} /> : <></>}
        <>
          <Grid container mb={3}>
            <Grid item md={4} xs={12} >
              <CustomInput
                id="textfield-update-time-slot-name"
                label='Name'
                disabled={name === '24-Hours General' ? true : false}
                required
                size='small'
                name='name'
                placeholder={t('AccTime004')}
                height={{ xl: "64px", xs: "50px" }}
                padding={{ xl: "0px 20px", xs: "0px 15px" }}
                value={payload.name}
                handleChange={(event) => {
                  setPayload({ ...payload, name: event.target.value });
                  setError({ ...error, name: false });
                }}
                onKeyDown={handleKeyPress}
                validation="name"
                error={error.name}
                helperText={error.name}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>

          <Grid container mb={3}>
            <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop: { lg: "0px", xs: "10px" } }}>
                <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime005')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4}
              sx={{
                paddingLeft: { xl: "50px", xs: "20px" },
                marginTop: { lg: "0px", xs: "10px" },
                position: "relative"
              }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={monday1}
                  onChange={(time, timeString) => {
                    setMonday1(time);
                    handleTimeIntervalChange(1, 1, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[('00:00'), ('00:00')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={monday2}
                  onChange={(time, timeString) => {
                    setMonday2(time);
                    handleTimeIntervalChange(2, 1, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={monday3}
                  onChange={(time, timeString) => {
                    setMonday3(time);
                    handleTimeIntervalChange(3, 1, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container mb={3}>
            <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop: { lg: "0px", xs: "10px" } }}>
                <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime015')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  locale={localeMap[language]}
                  disabled={name === '24-Hours General' ? true : false}
                  format={timeFormat}
                  value={tuesday1}
                  onChange={(time, timeString) => {
                    setTuesday1(time);
                    handleTimeIntervalChange(1, 2, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: "relative" }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={tuesday2}
                  onChange={(time, timeString) => {
                    setTuesday2(time);
                    handleTimeIntervalChange(2, 2, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={tuesday3}
                  onChange={(time, timeString) => {
                    setTuesday3(time);
                    handleTimeIntervalChange(3, 2, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container mb={3}>
            <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop: { lg: "0px", xs: "10px" } }}>
                <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime016')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={wednesday1}
                  onChange={(time, timeString) => {
                    setWednesday1(time);
                    handleTimeIntervalChange(1, 3, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={wednesday2}
                  onChange={(time, timeString) => {
                    setWednesday2(time);
                    handleTimeIntervalChange(2, 3, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={wednesday3}
                  onChange={(time, timeString) => {
                    setWednesday3(time);
                    handleTimeIntervalChange(3, 3, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container mb={3}>
            <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop: { lg: "0px", xs: "10px" } }}>
                <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime017')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={thursday1}
                  onChange={(time, timeString) => {
                    setThursday1(time);
                    handleTimeIntervalChange(1, 4, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={thursday2}
                  onChange={(time, timeString) => {
                    setThursday2(time);
                    handleTimeIntervalChange(2, 4, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={thursday3}
                  onChange={(time, timeString) => {
                    setThursday3(time);
                    handleTimeIntervalChange(3, 4, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container mb={3}>
            <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop: { lg: "0px", xs: "10px" } }}>
                <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime018')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={friday1}
                  onChange={(time, timeString) => {
                    setFriday1(time);
                    handleTimeIntervalChange(1, 5, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }}>
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={friday2}
                  onChange={(time, timeString) => {
                    setFriday2(time);
                    handleTimeIntervalChange(2, 5, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={friday3}
                  onChange={(time, timeString) => {
                    setFriday3(time);
                    handleTimeIntervalChange(3, 5, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container mb={3}>
            <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop: { lg: "0px", xs: "10px" } }}>
                <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime019')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={saturday1}
                  onChange={(time, timeString) => {
                    setSaturday1(time);
                    handleTimeIntervalChange(1, 6, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={saturday2}
                  onChange={(time, timeString) => {
                    setSaturday2(time);
                    handleTimeIntervalChange(2, 6, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={saturday3}
                  onChange={(time, timeString) => {
                    setSaturday3(time);
                    handleTimeIntervalChange(3, 6, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container mb={3}>
            <Grid item xs={12} md={12} lg={1.8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Box id="time-picker-text1" sx={{ paddingLeft: { xl: "30px", xs: "15px" }, marginTop: { lg: "0px", xs: "10px" } }}>
                <Typography sx={{ ...labelStyle, verticalAlign: 'center' }}>{t('AccTime020')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={sunday1}
                  onChange={(time, timeString) => {
                    setSunday1(time);
                    handleTimeIntervalChange(1, 0, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={sunday2}
                  onChange={(time, timeString) => {
                    setSunday2(time);
                    handleTimeIntervalChange(2, 0, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3.4} sx={{ paddingLeft: { xl: "50px", xs: "20px" }, marginTop: { lg: "0px", xs: "10px" }, position: 'relative' }}>
              <Box display='flex' justifyContent='flex-end' id="time-picker-field1" sx={{ cursor: 'pointer' }} >
                <TimePicker.RangePicker
                  size="large"
                  allowClear={false}
                  disabled={name === '24-Hours General' ? true : false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={sunday3}
                  onChange={(time, timeString) => {
                    setSunday3(time);
                    handleTimeIntervalChange(3, 0, time);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container
            sx={{
              display: "flex",
              justifyContent: { lg: "space-between", xs: "flex-start" },
              alignItems: "center"
            }}
            direction="row" >

            {name !== '24-Hours General' && <>
              <Box
                sx={{
                  width: { lg: "auto", xs: "100%" }
                }}
              >
                <Box padding={"10px 10px 0px 0px"} flexGrow={1}>
                  <FormControlLabel control={<Checkbox id="check-box-updatetimeslot-copy-monday" checked={copyMonday} onChange={handleChange} name='copyMonday' />}
                    label={t('AccTime008')}
                    sx={checkStyle}
                    disabled={name === '24-Hours General' ? true : false}
                  />
                </Box>
                <Box padding={"0px 10px 10px 0px"} flexGrow={1}>
                  <FormControlLabel control={<Checkbox id="check-box-updatetimeslot-copy-weekend" checked={copyWeekend} onChange={handleChange} name='copyWeekend' />}
                    label={t('AccTime009')}
                    sx={checkStyle}
                    disabled={name === '24-Hours General' ? true : false}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { lg: "auto", xs: "100%" },
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <Box>
                  {/* <Button id="button-updatetimeslot-save" onClick={handleSubmit} color='primary'
                    variant='contained'
                    sx={{
                      width: { xl: "233px", xs: "180px" },
                      height: { xl: "64px", xs: "50px" },
                      marginRight: "30px"
                    }}
                  >
                    {t('COMMON023')}
                  </Button> */}
              <CustomButton
                label= {t('COMMON023')}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
              />
                </Box>
                <Box>
                  <Button id="button-updatetimeslot-clear" onClick={handleClose} color='primary' variant='outlined'
                    sx={{
                      width: { xl: "233px", xs: "180px" },
                      height: { xl: "64px", xs: "50px" },
                    }}>
                    {t('COMMON015')}
                  </Button>
                </Box>
              </Box>
            </>}
          </Grid>
        </>
      </ViewPage>) :
        (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{location.state?.name === '24-Hours General' ? t('AccTime023') : t('AccTime022')}
          </Typography></Box>) : <></>}
    </>
  );
}
