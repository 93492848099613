import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReactComponent as Close } from '../../../assets/close.svg';
import { useTranslation } from 'react-i18next';
import CreateNewTimeSlot from '../../AccessControl/CreateNewTimeSlot';
import CustomButton from '../../../components/CustomButton';

const AddTimeSlot = (props) => {
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const { t } = useTranslation();
    const [handleDailogSubmit,setHandleDailogSubmit]=useState(false);
    const [handleCloseDailog,setHandleCloseDailog]=useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        setOpen(props.open)
    },[props.open])

    const handleClose = () => {
        props.close()
        setOpen(false);
        setHandleDailogSubmit(false);
        setIsLoading(false);
        setHandleCloseDailog(true)
     };

     const handleAfterSubmit=()=>{
      setHandleDailogSubmit(false);
      setIsLoading(false);
     }

  const handleAfterClear = () => {
    setHandleCloseDailog(false);
  };

  const handleSubmit = () => {
    setIsLoading(true); 
    setHandleDailogSubmit(true); 
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={"lg"}
        open={open}
      >
        <DialogTitle>
          <Box display='flex' justifyContent={'space-between'} sx={{padding:"10px 0px"}} >
            <Typography variant='title' className="tooltip-css">{t('COMMON041')}</Typography>
            <IconButton disableRipple onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
       </DialogTitle>
        <DialogContent>
          <CreateNewTimeSlot 
            buttonReq={false} 
            handleDailogSubmit={handleDailogSubmit} 
            handleCloseDialog={handleCloseDailog}
            handleAfterSubmit={handleAfterSubmit}
            handleAfterClear={handleAfterClear}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions>
          <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: '0px 20px 20px 0px' }}>
            <Button
              id={"button-time-zone-cancel"}
              onClick={() => setHandleCloseDailog(true)}
              variant='outlined'
              sx={{
                width: {xl:'233px',xs:"180px"},
                height: {xl:'64px',xs:"50px"},
                marginRight: '30px',
              }}
            >
              {t('COMMON015')}
            </Button>
            <CustomButton
              label={t('COMMON018')}
              handleSubmit={handleSubmit} 
              isLoading={isLoading} 
            />
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default AddTimeSlot
