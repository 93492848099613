import { createContext } from 'react';

const PaginationContext = createContext({
    managePersonPageNumber: {},
    managePersonPageSize: {},
    manageSitePageNumber: {},
    manageSitePageSize: {},
    manageZOnePageNumber: {},
    manageZonePageSize: {},
    manageOperationLogPageNumber: {},
    manageOperationLogPageSize: {},
    manageEventPageNumber: {},
    manageEventPageSize: {},
    manageTimeSlotPageNumber: {},
    manageTimeSlotPageSize: {},
    manageGATPageNumber: {},
    manageGATPageSize: {},
    manageDeviceListPageNumber: {},
    manageDeviceListPageSize: {},
    manageDoorPageNumber: {},
    manageDoorPageSize: {},
    manageReaderPageNumber: {},
    manageReaderPageSize: {},
    manageAUXINPageNumber: {},
    manageAUXINPageSize: {},
    manageAUXOUTPageNumber: {},
    manageAUXOUTPageSize: {},
    manageDATPageNumber: {},
    manageDATPageSize: {},
    manageAGPersonPageNumber: {},
    manageAGPersonPageSize: {},
    manageAddAGPersonPageNumber: {},
    manageAddAGPersonPageSize: {},
    manageAGAddDoorPageNumber: {},
    manageAGAddDoorPageSize: {},
    manageAGDoorPageNumber: {},
    manageAGDoorPageSize: {},


    setManagePersonPageNumber: () => { },
    setManagePersonPageSize: () => { },
    setManageSitePageNumber: () => { },
    setManageSitePageSize: () => { },
    setManageZonePageNumber: () => { },
    setManageZonePageSize: () => { },
    setManageOperationLogPageNumber: () => { },
    setManageOperationLogPageSize: () => { },
    setManageEventPagenumber: () => { },
    setManageEventPageSize: () => { },
    setManageTimeSlotPageNumber: () => { },
    setManageTImeSlotPageSize: () => { },
    setManageGATPageNumber: () => { },
    setManageGATPageSize: () => { },
    setManageDeviceListPageNumber: () => { },
    setManageDeviceListPageSize: () => { },
    setManageDoorPageNumber: () => { },
    setManageDoorPageSize: () => { },
    setManageReaderPageNumber: () => { },
    setManageReaderPageSize: () => { },
    setManageAUXINPageNumber: () => { },
    setManageAUXINPageSize: () => { },
    setManageAUXOUTPageNumber: () => { },
    setManageAUXOUTPageSize: () => { },
    setManageDATPageNumber: () => { },
    setManageDATPageSize: () => { },
    setManageAGPersonPageNumber: () => { },
    setManageAGPersonPageSize: () => { },
    setManageAddAGPersonPageNumber: () => { },
    setManageAddAGPersonPageSize: () => { },
    setManageAGAddDoorPageNumber: () => { },
    setManageAGAddDoorPageSize: () => { },
    setManageAGDoorPageNumber: () => { },
    setManageAGDoorPageSize: () => { },
});
export default PaginationContext;


