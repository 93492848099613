import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
export default function Loader(props) {

  const [state, setState] = useState(false);
  const storedValue = localStorage.getItem("sidebar");
  const useStyles = makeStyles({
    loaderContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      zIndex: 9999,
    },
    loader:{
      position: 'absolute',
      top: props.top ? props.top:'55%',
      left: storedValue === 'true' ? props.left ? props.left: '51%' : '57.9%',
      zIndex: 9999,
    }
  });
const classes=useStyles();
  useEffect(() => {
    setState(props.open)
  }, [props.open])
  if(state + '' === 'true'){
    return (
      <div className={classes.loaderContainer}>
        <div className={classes.loader} >
          <CircularProgress />
        </div>
      </div>
    )
  }else{
    return null;
  }
}