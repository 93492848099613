import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReactComponent as PDFIcon } from "../../assets/PDF.svg";
import { ReactComponent as XLSXIcon } from "../../assets/XLS.svg";
import api from '../../configurations/http-common';
import CommonUtil from '../../util/CommonUtils';

const EventExportModal = (props) => {


  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [type, setType] = useState(0);

  const handleSubmit = (type) => {
    enqueueSnackbar(t('COMMON047'), {
      variant: 'success'
    })
    try {
      let selectedFields =
        props.selectedFields === "personId," ?
          ['personId', 'personName', 'deviceAlias', 'deviceSn', 'eventTime', 'eventAddress', 'eventName', 'verificationMode'] :
          props.selectedFields.split(",");

      selectedFields = !props.isSelected ? selectedFields.filter(field => !field.includes('&ids=')) : selectedFields;
      selectedFields = selectedFields.map(field => field.trim());
      const payloadArray = selectedFields;

      api.securedAxios().post('/web/device/event/export?type=' + type + buildQuery(props.filterData), payloadArray).then((response) => {
        if (response.data.code === "CASI301") {
          enqueueSnackbar(t('ACCEVENT015'), {
            variant: "success",
          });
        }
      });
    } catch (err) {
      enqueueSnackbar(t('COMMON034'), {
        variant: "error",
      });
    }
  }


  const handleChange = (type) => {
    setType(type);
  };
  return (
    <Grid marginTop={'10px'}>
      <Box>
        <Typography sx={{ fontSize: "18px" }}>
          {t('ACCEVENT016')}</Typography>
      </Box>
      <Grid style={{ display: 'flex', justifyContent: 'center', margin: '0px' }}>

        <Grid item md={6} sm={12} xs={12}>
          <Box mt={2} p={1}
            sx={{
              border: type == 0 ? '2px dashed #76B745' : '2px dashed #707070',
              borderRadius: '5px',
              backgroundColor: type == 0 ? "rgba(54, 201, 109,0.1)" : 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '200px',

            }}
            onClick={() => handleChange(0)}
          >
            <XLSXIcon style={{ width: '50px', height: '50px', marginRight: '20px' }} />

          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box mt={2} ml={2} p={1}
            sx={{
              border: type == 1 ? '2px dashed #76B745' : '2px dashed #707070',
              borderRadius: '5px',
              backgroundColor: type == 1 ? "rgba(54, 201, 109,0.1)" : 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '200px',
            }}
            onClick={() => handleChange(1)}
          >
            <PDFIcon style={{ width: '50px', height: '50px' }} />

          </Box>
        </Grid>

      </Grid>
      <br />
      <Grid item md={12} xs={12} display={'flex'} justifyContent={{ xs: 'center', md: 'flex-end' }} paddingTop={'0px'}>
        <Button
          id="button-eventexport-export"
          size='large'
          variant='contained'
          onClick={(rowData) => handleSubmit(type)}
          // disabled={!validateForm}
          sx={{ width: { xs: '200px', md: '240px' }, marginRight: '10px', textTransform: 'none' }}
        >
          {t("ACCEVENT004")}
        </Button>
        <Button
          id="button-eventexport-cancel"
          size='large'
          onClick={() => props.handleClose()}
          variant='outlined'
          sx={{ width: { xs: '200px', md: '240px', textTransform: 'none' } }}
        >
          {t("COMMON009")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default EventExportModal;

function buildQuery(filter) {
  let query = ''

  if (filter.startDateTime != '') {
    query = query + `&startDateTime=${filter.startDateTime}`.trimRight();

  }

  if (!filter.endDateTime.includes('NaN') && filter.endDateTime != '') {
    query = query + `&endDateTime=${filter.endDateTime}`.trimRight();
  }

  if (filter.eventType != '') {
    query = query + `&eventType=${filter.eventType}`.trimRight();
  }

  if (filter.sn != '') {
    query = query + `&sn=${filter.sn}`.trimRight();
  }
  if (filter.deviceName != '') {
    query = query + `&deviceName=${filter.deviceName}`.trimRight();
  }
  return query

}