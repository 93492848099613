import DataTable from '../../components/layout-components/DataTable';
import { Autocomplete, Grid, Icon, IconButton, InputLabel, TextField } from '@mui/material';
import TitleBar from '../../components/TitleBar';
import { ReactComponent as Filter } from "../../assets/new-images/Filter icon (1).svg";
import { Avatar } from '@mui/material';
import OverFlowText from '../../components/OverFlowText';
import OverflowTip from '../../components/tooltip';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import RightViewLayout from '../../components/RighViewLayout';
import React, { useContext, useDebugValue, useEffect, useState } from "react";
import { loadEvents } from "../../services/EventService";
import CommonUtil from '../../util/CommonUtils';
import { EventsFilter } from './EventsFilter';
import { loadOrgPersonsById } from "../../services/PersonService";
import { DatePicker } from 'antd';
import moment from 'moment';
import { FilterComponent } from '../../components/FilterComponent';
import CustomInput from '../../components/CustomInput';
import { allDevices } from '../../services/DeviceService';
import ExportIcon from './EventExport';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useSnackbar } from 'notistack';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { EventsFilterComponents } from "../Events/EventsFilterComponents"
import PaginationContext from '../../components/layout-components/PaginationContext';

function Events() {
    const DEFAULT_PAGE_SIZE = 10;
    const [events, setEvents] = useState([]);
    const { t } = useTranslation();
    const { manageEventPageNumber, setManageEventPagenumber, manageEventPageSize, setManageEventPageSize } = useContext(PaginationContext);
    const [page, setPage] = React.useState(manageEventPageNumber ? manageEventPageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(manageEventPageSize ? manageEventPageSize : 10);
    const [open, setOpen] = useState(false);
    const [names, setNames] = useState([]);
    const [Filtered, setFiltered] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [filter, setFilter] = useState(false);
    const [deviceList, setDeviceList] = useState([]);
    const [exportPerm, setExprtPerm] = useState(true);
    const [eventListPerm, setEventListPerm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [ids, setIds] = useState([]);

    const [reportView, setReportView] = React.useState(null)

    const [eventTypeList, setEventTypeList] = useState([
        {
            "id": "0e104c470ce54c299eeb03560b21b134",
            "name": "Cancel alarm"
        },
        {
            "id": "51a19840ed134fc0a228e5c390c4b77f",
            "name": "Delayed door opening authentication successful"
        },
        {
            "id": "6f60cc15c6054b93a6e2c9b098e67250",
            "name": "Illegal time period"
        },
        {
            "id": "244df497e9464d949151a4e7aeae9e49",
            "name": "Door locked"
        },
        {
            "id": "383a47a3efd44406af198b800f26c49a",
            "name": "Unregistered Personnel"
        },
        {
            "id": "62bdb28fe47247488f1a68ad7e734b14",
            "name": "Door open timeout"
        },
        {
            "id": "7a4cc09a841845fd9bfc58ea7c733d6c",
            "name": "Tamper alarm"
        },
        {
            "id": "e5a9f9536c794fb199a1c70830fd3120",
            "name": "Person expired"
        },
        {
            "id": "51a25d8a382e4cc3bb82a6a8aa512a1e",
            "name": "No permission"
        },
        {
            "id": "1fe64b913b8c4bf1851cf5e9d32bd0a4",
            "name": "Open door normally (exit switch)"
        },
        {
            "id": "149d255862a1404089993a487c04f035",
            "name": "Open the door normally (verified successfully)"
        },
        {
            "id": "0bc9439896744f6194e75f2eb2d13081",
            "name": "Door Opened"
        },
        {
            "id": "6929c735a1e549f092e13e1ce6edf28e",
            "name": "End of normally open period (door)"
        },
        {
            "id": "c08de66e6b4b40b9b5c45dc1d74f792e",
            "name": "Door closed"
        },
        {
            "id": "e00f667a8c2f470c8153b75af7ea9599",
            "name": "End of normally open period (auxiliary output)"
        },
        {
            "id": "583e5c3cffee4db880c98b1f5da32765",
            "name": "Enable the normal open time period of the day"
        },
        {
            "id": "5bed91f4ec1744d588ab350a04272fb3",
            "name": "Remote Open (Door)"
        },
        {
            "id": "92673938254b4f70a70ae7c1f59789c9",
            "name": "Remote Closure (Door)"
        },
        {
            "id": "a8f43b089a264ad1b57d341746334c54",
            "name": "Reader Online"
        }
    ]);

    const defaultFilter = {
        startDateTime: '',
        endDateTime: '',
        deviceName: '',
        sn: '',
        eventType: ''
    };

    const [filterData, setFilterData] = useState({
        ...defaultFilter,
    });

    const [eventDateData, setEventDateData] = useState({
        ...defaultFilter,
    });

    useEffect(() => {
        // async function fetchData() {
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            var l = [];
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });
            setEventListPerm(l.includes("VIEW_ACC_REPORT") ||
                (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setExprtPerm(l.includes("EXPORT_ACC_REPORT") ||
                (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

            {/*Author:Syed Mudassir 
             added implementation to handle DataTable and permisssion message
            */}
            if (l.includes("VIEW_ACC_REPORT") ||
                (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
                setReportView(true)
            } else {
                if (l.includes("VIEW_ACC_REPORT") === true) {
                    setReportView(true)
                }
                else {
                    setReportView(false)
                }
            }
            {/**end*/ }
        });
        loadEvents(page, rowsPerPage, filterData).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setEvents(res.data.data.list);
                setTotalRecords(res.data.data.totalCount);

                allDevices().then((res) => {
                    if (res?.data?.code === 'DMSI0000') {
                        setDeviceList(res.data.data.devicesList);
                    }
                });

            }
        });

        // }
        // fetchData();
    }, []);

    async function getPersonName(personCode) {
        var name = '';
        await loadOrgPersonsById(personCode).then((res) => {
            if (res?.data?.code === 'OMSI0232') {
                name = res.data.data.firstName + " " + res.data.data.lastName;
                // console.log(name);
            }
        });

        return name;
    }

    const columns = [
        {
            field: 'personId',
            headerName: t('COMMON032'),
            hideable: false,
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('COMMON032')} >
                    <span>
                        {t('COMMON032')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.personnel == '0' ? '' : e.row.personnel}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'personName',
            headerName: t('ACCORG002'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG002')} >
                    <span>
                        {t('ACCORG002')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.personnelName}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'deviceAlias',
            headerName: t('AccDevice014'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDevice014')} >
                    <span>
                        {t('AccDevice014')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.deviceAlias}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },

        {
            field: 'deviceSn',
            headerName: t('AccDevice013'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDevice013')} >
                    <span>
                        {t('AccDevice013')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.sn}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'eventTime',
            headerName: t('ACCEVENT001'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCEVENT001')}>
                    <span>
                        {t('ACCEVENT001')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.deviceEventTimeUtc === null ? e.row.eventTime : moment(CommonUtil.getLocalDateTimeInYearFormat(e.row.deviceEventTimeUtc)).format("YYYY-MM-DD HH:mm:ss")}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'eventAddress',
            headerName: t("ACCEVENT002"),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCEVENT002')} >
                    <span>
                        {t('ACCEVENT002')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.eventAddress}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'eventName',
            headerName: t('ACCEVENT003'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCEVENT003')} >
                    <span>
                        {t('ACCEVENT003')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.eventName}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        // {
        //     field: 'epName',
        //     headerName: 'Event Point Name',
        //     flex: 1,
        //     renderHeader: (e) => (
        //         <Tooltip title='Event Point Name' >
        //             <span>
        //                 Event Point Name
        //             </span>
        //         </Tooltip>
        //     ),
        //     renderCell: (e) => (
        //         <OverflowTip
        //             value={e.row.epName}
        //             onClick={() => console.info()}
        //             style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 alignItems: 'center',
        //             }}
        //         ></OverflowTip>
        //     ),
        // },
        {
            field: 'verificationMode',
            headerName: t('AccDoor005'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDoor005')} >
                    <span>
                        {t('AccDoor005')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.mode}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
    ];

    const resetFilterData = () => {
        setFilterData({ ...defaultFilter });
        setEventDateData({ ...defaultFilter });
        setPage(1);
        loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
        setFiltered(false);
        setDisabled(true);
        setFilter(false)
    };

    const handleFilterDataChange = (filter) => {
        setFilterData({
            ...filter,
        });
        loadData(1, rowsPerPage, filter);
        setFiltered(true);
        // setOpen(false);
    };

    const loadData = (pageNumber, pageSize, filterData) => {
        setIds([]);
        loadEvents(pageNumber, pageSize, filterData).then((res) => {

            if (res?.data?.code === 'DMSI0000') {
                // const eventList = res.data.data.list.map((item, index) => {
                //     const newObj = { ...item };
                //     getPersonName(item.personnel).then((result) => {
                //         newObj.personName = result;
                //     });
                //     return newObj;
                // });
                setEvents(res.data.data.list);
                setTotalRecords(res.data.data.totalCount);
            }
        });
    };

    const refreshTableData = () => {
        if (page === 1) {
            loadData(1, manageEventPageSize, filterData);
        }
        else {
            setPage(manageEventPageNumber);
            loadData(manageEventPageNumber, manageEventPageSize, filterData);
        }
    };

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    useEffect(() => {
        if (!open) {
            setFilterData({ ...defaultFilter });
            setEventDateData({ ...defaultFilter });
            setPage(1);
            loadData(1, manageEventPageSize, defaultFilter);
        }
    }, [open])

    const [columnVisibility, setColumnVisibility] = useState([]);
    const [selectedDataColumn, setSelectedDataColumn] = useState(null)
    /**
     * Author:Syed Mudassir
     * Implementation added to rest object,which are not false form columnVisibility during selection 
     */
    let selectedFileds = [
        'personName',
        'deviceAlias',
        'deviceSn',
        'eventTime',
        'eventAddress',
        'eventName',
        'verificationMode'
    ]

    useEffect(() => {
        const getSelectedFields = () => {
            let unCheckedValues = []
            Object.entries(columnVisibility).forEach(([key, value]) => {
                if (value === false) {
                    unCheckedValues.push(key)
                }
            })
            const updateSelectedValues = selectedFileds.filter(column => !unCheckedValues.includes(column))
            setSelectedDataColumn(updateSelectedValues)
        }
        getSelectedFields()
    }, [columnVisibility])

    let sendIds = ids.filter(id => id !== "NaN");
    let formattedIds = sendIds.map(id => "&ids=" + id).join('');
    let selectedFields = formattedIds + ',' + selectedDataColumn

    const actions = () => (
        <Box item display={'flex'} sx={{ height: { xl: "50px", xs: "40px" } }}>
            <Tooltip title={t('COMMON002')} arrow>
                <Box
                    aria-label='refresh'
                    id="icon-events-refresh"
                    onClick={() => refreshTableData()}
                    sx={{ display: { xl: 'block', xs: 'none' } }}
                    mr={2}
                >
                    <Refresh style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label='refresh'
                    id="icon-events-refresh"
                    onClick={() => refreshTableData()}
                    sx={{ display: { xl: 'none', xs: 'block' } }}
                    mr={2}
                >
                    <Refresh width={40} height={40} style={{ cursor: 'pointer' }} />
                </Box>

            </Tooltip>

            <Tooltip title={t('COMMON003')} arrow>
                <Box
                    aria-label='filter'
                    id="icon-events-filter"
                    onClick={() => {
                        setOpen(!open);
                        setDisabled(true);
                        setEventDateData({
                            ...defaultFilter,
                        });
                    }}
                    sx={{ display: { xl: 'block', xs: 'none' } }}
                    mr={2}
                >
                    <Filter style={{ cursor: 'pointer' }} />
                </Box>

                <Box
                    aria-label='filter'
                    id="icon-events-filter"
                    onClick={() => {
                        setOpen(!open);
                        setDisabled(true);
                        setEventDateData({
                            ...defaultFilter,
                        });
                    }}
                    sx={{ display: { xl: 'none', xs: 'block' } }}
                    mr={2}
                >
                    <Filter width={40} height={40} style={{ cursor: 'pointer' }} />
                </Box>

            </Tooltip>

            <Tooltip title={t('ACCEVENT004')} arrow>
                {exportPerm ?
                    <ExportIcon id="icon-events-export" width={40} style={{ marginRight: '20px', cursor: 'pointer' }} totalCount={totalRecords} filtered={Filtered}
                        filterData={filterData} ids={ids} selectedFields={selectedFields} />
                    :
                    <IconButton></IconButton>
                }

            </Tooltip>
        </Box>
    );

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    };

    const FieldStartDate = (key) => (
        <DatePicker
            showTime
            format={'YYYY-MM-DD HH:mm'}
            placeholder={t('ACCEVENT005')}
            value={eventDateData.startDateTime}
            onChange={(startDateTime) => {
                setEventDateData({ ...eventDateData, startDateTime: startDateTime });
                handleDisable();
                setFilterData({ ...filterData, startDateTime: startDateTime.format('YYYY-MM-DD HH:mm:00') });

            }}
            allowClear={false}
            minDateTime={eventDateData.startDateTime}
            disabledDate={disabledDate}
            onKeyDown={handleKeyPress}
            style={{ height: '40px', borderRadius: '8px', width: '100%', border: '1px solid #D1D1D1', cursor: 'pointer' }}
        />
    );
    const FieldSerialNumber = (key) => (
        <CustomInput
            id="textfield-events-devicesn"
            height={24}
            placeholder={t('AccDevice013')}
            value={filterData.sn}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='sn'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 30,
            }}
        />

    );

    const handleChange = (event) => {
        setFilter(false);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
        setDisabled(false);
    };

    const handleDisable = () => {
        if (eventDateData.startDateTime != null || eventDateData.endDateTime != null) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const FieldEndDate = (key) => (
        <DatePicker
            showTime
            format={'YYYY-MM-DD HH:mm'}
            placeholder={t('ACCEVENT006')}
            value={eventDateData.endDateTime}
            onChange={(endDateTime) => {
                setEventDateData({ ...eventDateData, endDateTime: endDateTime });
                handleDisable();
                setFilterData({ ...filterData, endDateTime: endDateTime.format('YYYY-MM-DD HH:mm:00') });

            }}
            allowClear={false}
            minDateTime={eventDateData.startDateTime}
            disabledDate={disabledEndDate}
            onKeyDown={handleKeyPress}
            style={{ height: '40px', borderRadius: '8px', border: '1px solid #D1D1D1', cursor: 'pointer' }}

        />

    );

    const getDeviceName = (name) => {
        var ret;
        deviceList.map((item, index) => {
            if (name == item.deviceAlias) {
                ret = item;
            }
        });
        return ret;
    }

    const getEventTypeName = (id) => {
        var ret;
        eventTypeList.map((item, index) => {
            if (id == item.id) {
                ret = item;
            }
        });
        return ret;
    }

    useEffect(() => {
        if (filterData.deviceName === '' && filterData.sn === '' && filterData.eventType === ''
            && filterData.startDateTime === '' && (filterData.endDateTime === '' || filterData.endDateTime.includes('NaN'))) {
            setDisabled(true);
            if (open)
                loadData(1, rowsPerPage, defaultFilter);
        }
        else { setDisabled(false); }
    }, [filterData]);

    const FieldDeviceName = (key) => (

        <Autocomplete
            id="dropdown-events-device"
            fullWidth
            size='small'
            onKeyDown={handleKeyPress}
            value={CommonUtil.isEmpty(filterData.deviceName) ? { id: '0', deviceAlias: '' } : getDeviceName(filterData.deviceName)}
            options={deviceList ? deviceList : []}
            getOptionLabel={(option) => option.deviceAlias}
            renderInput={(params) =>
                <TextField {...params}
                    variant='outlined'
                    placeholder={t('ACCEVENT007')}
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '13px',
                            //padding: '15px',
                            height: '22px!important',
                            cursor: 'pointer'
                        },
                    }} />

            }
            onChange={(event, newValue) => {
                if (!CommonUtil.isEmptyString(newValue)) {
                    setFilterData({
                        ...filterData,
                        deviceName: newValue.deviceAlias
                    });
                    setDisabled(false);
                } else {
                    setFilterData({
                        ...filterData,
                        deviceName: ''
                    });
                    if (filterData.startDateTime == '' && (filterData.endDateTime == '' || filterData.endDateTime.includes('NaN'))
                        && filterData.sn == '' && filterData.eventType == '' && filterData.deviceName == '') {
                        setDisabled(true);
                    }
                    const payload = {
                        startDateTime: filterData.startDateTime,
                        endDateTime: filterData.endDateTime,
                        deviceName: '',
                        sn: filterData.sn,
                        eventType: filterData.eventType
                    }
                    loadData(1, rowsPerPage, payload);
                }
            }}
        />

    );

    const FieldEventType = (key) => (

        <Autocomplete
            id="dropdown-events-type"
            fullWidth
            size='small'
            onKeyDown={handleKeyPress}
            value={CommonUtil.isEmpty(filterData.eventType) ? { id: '0', name: '' } : getEventTypeName(filterData.eventType)}
            options={eventTypeList ? eventTypeList : []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) =>
                <TextField {...params}
                    variant='outlined'
                    placeholder={t('ACCEVENT008')}
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '13px!important',
                            height: '22px!important',
                            cursor: 'pointer'
                        },
                    }}
                />
            }
            onChange={(event, newValue) => {
                if (!CommonUtil.isEmptyString(newValue)) {
                    setFilterData({

                        ...filterData,
                        eventType: newValue.id
                    });
                    setDisabled(false);
                } else {
                    setFilterData({
                        ...filterData,
                        eventType: ''
                    });
                    if (filterData.startDateTime == '' && (filterData.endDateTime == '' || filterData.endDateTime.includes(NaN))
                        && filterData.sn == '' && filterData.deviceName == '' && filterData.eventType == '') {
                        setDisabled(true);
                    }

                    const payload = {
                        startDateTime: filterData.startDateTime,
                        endDateTime: filterData.endDateTime,
                        deviceName: filterData.deviceName,
                        sn: filterData.sn,
                        eventType: ''
                    }
                    loadData(1, rowsPerPage, payload);
                }
            }}
        />

    );



    const handleSubmit = () => {
        console.log(eventDateData.startDateTime);
        console.log(eventDateData.endDateTime);
        if (eventDateData.startDateTime != '' && eventDateData.startDateTime != null && eventDateData.endDateTime != null && eventDateData.endDateTime != '' && eventDateData.startDateTime > eventDateData.endDateTime) {
            enqueueSnackbar(t('ACCEVENT010'), {
                variant: "error",
            });
            return;
        }
        var startDateTime = '';
        var endDateTime = '';
        var startDate = eventDateData.startDateTime;
        var endDate = eventDateData.endDateTime;
        var filterStartDate = new Date(startDate === null ? '' : startDate);
        var filterEndDate = new Date(endDate === null ? '' : endDate);

        var dto = '';

        if (eventDateData.endDateTime !== null) {
            endDateTime =
                filterEndDate.getFullYear() +
                formatDayAndMonth(filterEndDate.getMonth() + 1) +
                formatDayAndMonth(filterEndDate.getDate()) +
                ' ' +
                filterEndDate.getHours() +
                ':' +
                filterEndDate.getMinutes() +
                ':' +
                filterEndDate.getSeconds();

            setFilterData({
                ...filterData,
                endDateTime: endDateTime,
            });
            setFilter(true);

        }

        if (eventDateData.startDateTime !== null && startDate != '') {

            startDateTime =
                filterStartDate.getFullYear() +
                formatDayAndMonth(filterStartDate.getMonth() + 1) +
                formatDayAndMonth(filterStartDate.getDate()) +
                ' ' +
                filterStartDate.getHours() +
                ':' +
                filterStartDate.getMinutes() +
                ':' +
                filterStartDate.getSeconds();


            setFilterData({
                ...filterData,
                startDateTime: startDateTime,
            });
            setFilter(true);

        }
        setPage(1);
        loadData(1, rowsPerPage, filterData);
        setFiltered(true);
    }

    const disabledEndDate = (current) => {
        // Can not select days before today and today
        return current < (eventDateData.startDateTime) || current > moment().endOf('day');
    };

    const formatDayAndMonth = (numValue) => {
        if (numValue <= 0) {
            return '-' + (numValue + 12);
        }

        if (numValue < 10) {
            return '-0' + numValue;
        } else {
            return '-' + numValue;
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };


    return (
        <>
            {reportView ? (<> <RightViewLayout title={t('ACCDA0018')} type='table' navigateBack={true} >
                {actions()}
            </RightViewLayout>
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open &&
                        <EventsFilterComponents
                            firstField={FieldStartDate()}
                            secondField={FieldEndDate()}
                            thirdField={FieldEventType()}
                            fourthField={FieldDeviceName()}
                            fifthField={FieldSerialNumber()}
                            clearFilter={() => resetFilterData()}
                            handleSubmit={() => handleSubmit()}
                            disabled={disabled}
                        />
                    }
                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        columns={columns}
                        getRowId={(row) => row.id}
                        rows={events}
                        // toolbar={filters}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        onPageChange={(e) => {
                            const CurrentPage = CommonUtil.getPageNumber(e);
                            setPage(CurrentPage);
                            setManageEventPagenumber(CurrentPage);
                            if (Filtered) {
                                loadData(CurrentPage, rowsPerPage, filterData);
                            } else {
                                loadData(CurrentPage, rowsPerPage, defaultFilter);
                            }
                        }}
                        onPageSizeChange={(e) => {
                            setFilterData({ ...filterData });
                            setRowsPerPage(e);
                            setManageEventPageSize(e);
                            setPage(1);
                            loadData(1, e, filterData);
                        }}
                        onColumnVisibilityModelChange={(newVisibilityModel) => {
                            setColumnVisibility(newVisibilityModel)
                        }}
                        noRecordText={t('ACCEVENT009')} />
                </Box>
            </>) :
                (
                    reportView == false && <Box marginTop={'150px'} width={'100%'}><Typography textAlign={'center'}>{t('ACCEVENT036')}</Typography></Box>

                )
            }
        </>
    );

}

export default Events;