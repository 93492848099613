import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import CommonUtil from '../util/CommonUtils';

export default function OverFlowText(props) {
  return (
    <Tooltip title={props.children}>
      <Typography variant={props.variant} id={"view-label"+props.index?props.index:"1"}>{CommonUtil.handleTextOverflow(props.children,props.length?props.length:25)}</Typography>
    </Tooltip>
  );
}
