import { Box, Button, Grid, Paper } from '@mui/material'
import React, { useState } from 'react'
import CustomInput from '../../components/CustomInput';
export const GroupAccessTimeFilter = (props) => {
    const [filterState, setFilterState] = useState({ name: '' });

    const handleChange = (event) => {
        const name = event.target.name;
        setFilterState({
            ...filterState,
            [name]: event.target.value
        });
    };

    const handleSubmit = () => {
        props.filterDatas(filterState);
    }

    const FieldFirstName = (key) => (
        <CustomInput
           id="textfield-groupaccesstimefilter-name"
            placeholder='Group Access Time'
            size='small'
            fullWidth
            name='name'
            value={filterState.name} 
            handleChange={handleChange}
            inputProps={{
                maxLength: 255,
            }}

        />
    );

    return (
        <Paper elevation={0}
            style={{
                minHeight: '60px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                padding: '5px',
                marginBottom: '15px'
            }}>
            <Grid container alignItems={'center'}>
                <Grid item lg={4} xs={12}   >
                    <Box display='flex' p={1}>
                        {FieldFirstName()}
                    </Box>
                </Grid>

                {filterState.name === ''  ?
                    <>
                       <Grid  Grid item lg={8} xs={8} display='flex' direction='row' justifyContent='flex-end' alignItems='center' paddingRight={1}>
                            <Box p={1}>
                                <Button id="button-groupaccesstimefilter-apply" size='large' disabled variant='contained'
                                    onClick={() => handleSubmit()}
                                    sx={{ width: { xs: '100px', md: '150px', textTransform:'capitalize' } }}>
                                    Apply
                                </Button>
                            </Box>
                            <Box p={1}>
                                <Button id="button-groupaccesstimefilter-clear" size='large' variant='outlined' disabled={!props.Filtered}
                                    onClick={() => props.clearData()}
                                    sx={{ width: { xs: '100px', md: '150px', textTransform:'capitalize' } }}>
                                    Clear
                                </Button>
                            </Box>
                        </Grid></> : <>
                        <Grid  Grid item lg={8} xs={8} display='flex' direction='row' justifyContent='flex-end' alignItems='center' paddingRight={1}>
                            <Box p={1}>
                                <Button  id="button-groupaccesstimefilter-apply" size='large' variant='contained'
                                    onClick={(e) => handleSubmit(e)}
                                    sx={{ width: { xs: '100px', md: '150px',  textTransform:'capitalize'  } }}>
                                    Apply
                                </Button>
                            </Box>
                            <Box p={1}>
                                <Button id="button-groupaccesstimefilter-clear" size='large' variant='outlined'
                                    onClick={() => { props.clearData(); setFilterState({ name: '' }) }}
                                    sx={{ width: { xs: '100px', md: '150px',  textTransform:'capitalize'  } }}>
                                    Clear
                                </Button>
                            </Box>
                        </Grid></>}
            </Grid>
        </Paper>
    )
}