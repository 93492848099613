import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ReaderInfoPage = (props) => {
  const {t} = useTranslation()

  const fieldName={
    textAlign: 'left',
    letterSpacing: '0.8px',
    color: 'rgba(71, 75, 79, 0.5)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight:  "normal",
    fontSize: {xl:"16px",xs:"14px"},
    lineHeight:{xl:"19px",xs:"17px"},
    fontFamily: "Roboto"
  }

  const fieldValue={
    textAlign: 'left',
    letterSpacing: '0.8px',
    color: 'rgba(71, 75, 79, 1)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight:  "normal",
    fontSize: {xl:"16px",xs:"14px"},
    lineHeight:{xl:"19px",xs:"17px"},
    fontFamily: "Roboto",
    overflow: 'hidden',
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
  }
  return (
    <>
    <Grid container direction='row' justifyContent='center' alignItems='center'>
    <Grid item xs={6}>
             <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t("COMMON006")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
            <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}>{props.state.name}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology021')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}>{props.state.doorName}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology008')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}>{props.state.doorNum}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
            <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology022')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}>{props.state.commType}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
            <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology023')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}> {props.state.commAddress}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
            <Box mb={1} mt={1} p={1}>
                <Typography  sx={ fieldName}>
                  {t('AccDMTopology024')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={1} mt={1} p={1}>
              <Typography  sx={fieldValue}> {props.state.readerState === 1 ? 'Out' : 'In'}</Typography>
              </Box>
            </Grid>
          </Grid>
      
    </>
  )
}

export default ReaderInfoPage
