import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Filter } from "../../assets/new-images/Filter icon (1).svg";
import DataTable from '../../components/layout-components/DataTable';
import RightViewLayout from '../../components/RighViewLayout';
import { REACT_URL_ACCESS_GROUP_PERSON } from '../../constants/ReactEndPoints';
import { addPersonsToAccessGroup, getFilterAccessGroup } from '../../services/AccessGroupService';
import { loadOrgPersons } from '../../services/PersonService';
import CommonUtil from '../../util/CommonUtils';
import { AccessGroupPersonFilter } from './AccessGroupPersonFilter';
import { GridFilterAltIcon } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';

import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import PageNotFound from '../../components/PageNotFound';
import PaginationContext from '../../components/layout-components/PaginationContext';

export default function AddAccessGroupPerson() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    const { manageAddAGPersonPageNumber, setManageAddAGPersonPageNumber, manageAddAGPersonPageSize, setManageAddAGPersonPageSize } = useContext(PaginationContext);
    const [page, setPage] = React.useState(1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [data, setData] = React.useState([]);
    const [Filtered, setFiltered] = React.useState(false);
    const [selectedRecords, onSelectionChange] = React.useState([]);
    const [selectedIds, onSelectionIds] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const [viewErrorPage, setViewErrorPage] = React.useState(false)
    const [accessId, setAccessId] = React.useState("");

    const [filter, setFilter] = React.useState(false);
    const [load, setLoad] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [change, setChange] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [clearAndRefresh, setClearAndRefresh] = React.useState(false);
    const [addPersonAccessGroupList, setAddPersonAccessGroupList] = React.useState(true);
    const [defaultFilter, setDefaultFilter] = React.useState({
        firstName: '',
        lastName: '',
        code: '',
        email: '',
        phone: ''
    });

    const [filterData, setFilterData] = React.useState({
        ...defaultFilter
    });
    const DEFAULT_PAGE_SIZE = 10;
    const resetFilter = () => {
        // setFilterData({
        //     ...defaultFilter,
        // });
        if (page === 1) {
            loadData(1, manageAddAGPersonPageSize, filterData);
            setRowsPerPage(manageAddAGPersonPageSize);
        }
        else {
            setPage(filter ? 1 : manageAddAGPersonPageNumber);
            loadData(filter ? 1 : manageAddAGPersonPageNumber, manageAddAGPersonPageSize, filterData);
            setRowsPerPage(manageAddAGPersonPageSize);
        }

    };

    const loadData = (pageNumber, pageSize, filter) => {
        onSelectionChange([]);
        loadOrgPersons(pageNumber, pageSize, filter).then((res) => {
            if (res?.data?.code === 'OMSI0000') {
                setData(res.data.data.employees);
                setTotalRecords(res.data.data.totalCount);
            }
        });
    }

    useEffect(() => {
        if (accessId !== "")
            getPermissionsByRoleId(api.decoded().roleId).then((res) => {
                var l = [];
                res.data.data.rolePermissions.map((item, index) => {
                    l.push(item.code);
                });
                setAddPersonAccessGroupList(l.includes("ADD_PERSONS_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                if (l.includes("ADD_PERSONS_ACCESS_GROUP") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
                    loadData(1, rowsPerPage, filterData);
                }
            });

    }, [accessId]);

    useEffect(() => {
        loadAccessGroup();
    }, []);

    const loadAccessGroup = (page, size) => {
        const filter = {
            id: id,
        }
        getFilterAccessGroup(1, 1, filter).then((res) => {
            if (
                res?.status === 200 &
                res != null &&
                !CommonUtil.isEmpty(res) &&
                res?.data?.code === 'DMSI0000' &&
                !CommonUtil.isEmpty(res?.data?.data) &&
                res.data.data.accessGroups instanceof Array
            ) {
                setAccessId(res.data.data.accessGroups[0].id);
            } else {
                setViewErrorPage(true)
            }
        });
    };
    const [addButton, setAddButton] = React.useState(true);

    const handleAddPersonToAccessGroup = (selectedRecords) => {
        if (selectedRecords.length < 1) {
            enqueueSnackbar(t('AccGroupAccessTime036'), {
                variant: 'error',
            });
            return;
        } else {
            if (addButton) {

                var persons = [];
                selectedRecords.map((person) => {
                    data.map((personData) => {
                        if (person === personData.id) {
                            persons.push({ personId: person, firstName: personData.firstName, lastName: personData.lastName });
                        }
                    })
                });
                setAddButton(false);
                addPersonsToAccessGroup(accessId, persons).then((res) => {
                    if (res?.data?.code === 'DMSI0000' || res?.data?.code === 'DMSE0009') {
                        enqueueSnackbar(t('AccGroupAccessTime037'), {
                            variant: 'success',
                        });
                        navigate(-1);
                    } else {
                        if (res?.data?.data?.error && res?.data?.data?.error instanceof Array && res?.data?.data?.error.length > 0 && res.data.data.error.length === selectedRecords.length) {
                            enqueueSnackbar(res.data.data.error[0].msg, {
                                variant: 'error',
                            });
                        } else {
                            enqueueSnackbar(res.data.msg, {
                                variant: 'warning',
                            });
                            navigate(-1);
                        }
                    }
                    setAddButton(true);
                });
            }
        }
    };


    const columns = [
        {
            field: "firstName",
            headerName: t('COMMON030'),
            flex: 1,
        },
        {
            field: "lastName",
            headerName: t('COMMON031'),
            flex: 1,
        },
        {
            field: "code",
            headerName: t('COMMON032'),
            flex: 1,
        }
    ];

    const actions = () => (
        <>
            <Box display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }}>
                <Tooltip title={t('COMMON002')}>
                    <Box
                        aria-label="refresh"
                        onClick={() => resetFilter()}
                        mr={2}
                        sx={{ display: { xl: "block", xs: "none" } }}
                    >
                        <RefreshIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => resetFilter()}
                        mr={2}
                        sx={{ display: { xl: "none", xs: "block" } }}
                    >
                        <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
                <Tooltip title={t('COMMON003')}>
                    <Box
                        aria-label="filter"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xl: "block", xs: "none" } }}
                    >
                        <FilterIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xl: "none", xs: "block" } }}
                    >
                        <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
            </Box>
        </>
    );

    const FieldFirstName = (key) => (
        <CustomInput
            placeholder={t('COMMON030')}
            value={filterData.firstName}
            size='small'
            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            onKeyDown={handleKeyPress}
            fullWidth
            name='firstName'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 100,
            }}

        />
    );

    const FieldLastName = (key) => (
        <CustomInput
            placeholder={t('COMMON031')}
            value={filterData.lastName}
            size='small'
            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            onKeyDown={handleKeyPress}
            fullWidth
            name='lastName'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 25,
            }}

        />
    );

    const handleSubmitFilter = () => {
        setFilter(true);
        setLoad(false);
        setReload(true);
        loadData(1, rowsPerPage, filterData);
    }

    const clearFilter = () => {
        setFilterData(defaultFilter);
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else setPage(1);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
    }

    useEffect(() => {
        if (filterData.firstName === '' && filterData.lastName === '') {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [filterData]);

    const handleChange = (event) => {
        setChange(true);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitFilter();
        }
    };

    const handleKeyPress1 = React.useCallback((event) => {
        if (event.key === 'Enter' && selectedRecords.length > 0) {
            handleAddPersonToAccessGroup(selectedRecords);
        }
    }, [selectedRecords, handleAddPersonToAccessGroup])

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress1);
        return () => {
            window.removeEventListener('keydown', handleKeyPress1)
        }
    },[]);

    const onSelectChecxBoxChange = (id) => {
        onSelectionChange(id);
    }

    useEffect(() => {
        if (!open && !disabled) {
            setPage(1);
            setChange(false);
            setFilterData(defaultFilter);
            loadData(1, rowsPerPage, defaultFilter);
        }
    }, [open]);
    
    return (
        <>
            {viewErrorPage ? <PageNotFound /> : addPersonAccessGroupList ? <>
                <RightViewLayout title={t('COMMON033')}>
                    {actions()}
                </RightViewLayout>
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open && <FilterComponent
                        firstField={FieldFirstName()}
                        // children={FieldDoorName()}
                        secondField={FieldLastName()}
                        clearFilter={clearFilter}
                        handleSubmit={handleSubmitFilter}
                        disabled={disabled}
                    />}
                    <>
                        <DataTable
                            page={page - 1}
                            totalRecords={totalRecords}
                            rowsPerPage={rowsPerPage}
                            getRowId={(row) => row.id}
                            columns={columns}
                            rows={data}
                            checkboxSelection={true}
                            onSelection={(e) => { onSelectChecxBoxChange(e); }}
                            rowSelectionModel={selectedRecords}
                            count={totalRecords}
                            onPageChange={(e) => {
                                const CurrentPage = CommonUtil.getPageNumber(e);
                                setPage(CurrentPage);
                                setManageAddAGPersonPageNumber(CurrentPage);
                                if (Filtered) {
                                    loadData(CurrentPage, rowsPerPage, filterData);
                                } else {
                                    loadData(CurrentPage, rowsPerPage, filterData);
                                }
                            }}
                            onPageSizeChange={(e) => {
                                // setFilterData({ ...defaultFilter });
                                setRowsPerPage(e);
                                setManageAddAGPersonPageSize(e);
                                setPage(1);
                                loadData(1, e, filterData);
                            }}
                            noRecordText={t("ACCORG010")}
                        /><Grid item md={12} xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: { xs: 'center', md: 'flex-end' },
                                mt: { xl: '30px', xs: '15px' }
                            }}
                            display={'flex'}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}
                            paddingTop={'0px'}>
                            <Button
                                id="button-accessgroup-person-add"
                                variant='contained'
                                onClick={(rowData) => handleAddPersonToAccessGroup(selectedRecords)}
                                // disabled={!validateForm}
                                sx={{
                                    width: { xl: "233px", xs: "180px" },
                                    height: { xl: "64px", xs: "50px" },
                                    marginRight: "30px"
                                }}
                            >
                                {t("COMMON018")}
                            </Button>
                            <Button
                                id="button-accessgroup-person-clear"
                                onClick={() => resetFilter()}
                                variant='outlined'
                                sx={{
                                    width: { xl: "233px", xs: "180px" },
                                    height: { xl: "64px", xs: "50px" },
                                }}
                            >
                                {t("COMMON015")}
                            </Button>
                        </Grid>
                    </>
                </Box>
            </> : (<Box marginTop={'150px'} width={'100%'}>
                <Typography textAlign={'center'}>{t("AccGroupAccessTime043")}</Typography>
            </Box>)}
        </>
    );
}
