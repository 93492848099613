import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Loader from './Loader';

const ImageCard = ({ src, fileName, attachmentId, index, loading, cancel, key, ...props }) => {

    useEffect(() => {
    }, [loading]);

    const handleDelete = () => {
        console.log("Deleting attachmentId:", attachmentId, index);
        cancel(attachmentId, index);
    };

    return (
        <Box
            key={key}
            sx={{
                position: 'relative',
                cursor: 'default',
                borderRadius: '5px',
                width: '150px',
                height: '150px',
                padding: '5px 5px 40px 5px',
                border: '1px solid #BFBFBF',
                backgroundColor: 'white',
                boxShadow: '5px 5px 5px #aaaaaa',
            }}
        >    {loading && (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999,
                }}
            >
                <CircularProgress size={24} />
            </div>
        )}

            <img src={src} draggable={false} alt="" style={{ width: '100%', height: '100%' }} />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                }}
            >
                <IconButton onClick={handleDelete} color="red" size="small">
                    <Clear />
                </IconButton>
            </div>
            <div style={{ width: '100%', height: '100%' }}>
                <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {fileName}
                </Typography>
            </div>
        </Box>
    );
};

export default ImageCard;
