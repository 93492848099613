import D3TestDeviceImg from '../../assets/realDeviceImage/D3.png';
import EFace10 from '../../assets/realDeviceImage/EFace10.png';
import MB20VL from '../../assets/realDeviceImage/MB20-VL.png';
import MB40VL from '../../assets/realDeviceImage/MB40-VL.png';
import MB560VL from '../../assets/realDeviceImage/MB560-VL.png';
import MiniACDeviceImg from '../../assets/realDeviceImage/SmartAC1.png';
import SpeedFaceV3LLite from '../../assets/realDeviceImage/SpeedFace-V3L(Lite).png';
import SpeedFaceV3LRFID from '../../assets/realDeviceImage/SpeedFace-V3L-RFID.png';
import SpeedFaceV3L from '../../assets/realDeviceImage/SpeedFace-V3L.png';
import SpeedFaceV3LQR from '../../assets/realDeviceImage/SpeedFace-V3L[QR].png';
import SpeedFaceV4L from '../../assets/realDeviceImage/SpeedFace-V4L.png';
import SpeedFaceV4LM1 from '../../assets/realDeviceImage/SpeedFace-V4LM1.png';
import SpeedFaceV5LRFID from '../../assets/realDeviceImage/SpeedFace-V5L-RFID.png';
import SpeedFaceV5L from '../../assets/realDeviceImage/SpeedFace-V5L.png';


export const DeviceImage = {

    "D3": <img alt='D3' src={D3TestDeviceImg} draggable={false} style={{ height: "250px" }} />,

    "SmartAC1": <img alt='MiniACDeviceImg' src={MiniACDeviceImg} draggable={false} style={{ height: "350px" }} />,

    'MB20-VL': <img alt='MB20VL' src={MB20VL} draggable={false} style={{ height: "250px" }} />,

    'SpeedFace-V4L': <img alt='SpeedFaceV4L' src={SpeedFaceV4L} draggable={false} style={{ height: "350px" }} />,

    'SpeedFace-V4LM1': <img alt='SpeedFaceV4LM1' src={SpeedFaceV4LM1} draggable={false} style={{ height: "350px" }} />,

    'SpeedFace-V5L-RFID': <img alt='SpeedFaceV5LRFID' src={SpeedFaceV5LRFID} draggable={false} style={{ height: "350px" }} />,

    "EFace10": <img alt='EFace10' src={EFace10} draggable={false} style={{ height: "250px" }} />,

    'MB40-VL': <img alt='MB40VL' src={MB40VL} draggable={false} style={{ height: "250px" }} />,

    'MB560-VL': <img alt='MB560VL' src={MB560VL} draggable={false} style={{ height: "250px" }} />,

    'SpeedFace-V5L': <img alt='SpeedFaceV5L' src={SpeedFaceV5L} draggable={false} style={{ height: "350px" }} />,

    'SpeedFace-V3L-RFID': <img alt='SpeedFaceV3LRFID' src={SpeedFaceV3LRFID} draggable={false} style={{ height: "350px" }} />,

    'SpeedFace-V3L[QR]': <img alt='SpeedFaceV3LQR' src={SpeedFaceV3LQR} draggable={false} style={{ height: "350px" }} />,

    'SpeedFace-V3L(Lite)': <img alt='SpeedFaceV3LLite' src={SpeedFaceV3LLite} draggable={false} style={{ height: "350px" }} />,

    'SpeedFace-V3L': <img alt='SpeedFaceV3L' src={SpeedFaceV3L} draggable={false} style={{ height: "350px" }} />,

}

export const DeviceTableImage = {

    "D3": <img alt='D3' src={D3TestDeviceImg} draggable={false} style={{ height: "40px" }} />,

    "SmartAC1": <img alt='MiniACDeviceImg' src={MiniACDeviceImg} draggable={false} style={{ height: "50px" }} />,

    'MB20-VL': <img alt='MB20VL' src={MB20VL} draggable={false} style={{ height: "30px" }} />,

    'SpeedFace-V4L': <img alt='SpeedFaceV4L' src={SpeedFaceV4L} draggable={false} style={{ height: "50px" }} />,

    'SpeedFace-V4LM1': <img alt='SpeedFaceV4LM1' src={SpeedFaceV4LM1} draggable={false} style={{ height: "50px" }} />,

    'SpeedFace-V5L-RFID': <img alt='SpeedFaceV5LRFID' src={SpeedFaceV5LRFID} draggable={false} style={{ height: "50px" }} />,

    "EFace10": <img alt='EFace10' src={EFace10} draggable={false} style={{ height: "35px" }} />,

    'MB40-VL': <img alt='MB40VL' src={MB40VL} draggable={false} style={{ height: "30px" }} />,

    'MB560-VL': <img alt='MB560VL' src={MB560VL} draggable={false} style={{ height: "30px" }} />,

    'SpeedFace-V5L': <img alt='SpeedFaceV5L' src={SpeedFaceV5L} draggable={false} style={{ height: "50px" }} />,

    'SpeedFace-V3L-RFID': <img alt='SpeedFaceV3LRFID' src={SpeedFaceV3LRFID} draggable={false} style={{ height: "50px" }} />,

    'SpeedFace-V3L[QR]': <img alt='SpeedFaceV3LQR' src={SpeedFaceV3LQR} draggable={false} style={{ height: "50px" }} />,

    'SpeedFace-V3L(Lite)': <img alt='SpeedFaceV3LLite' src={SpeedFaceV3LLite} draggable={false} style={{ height: "50px" }} />,

    'SpeedFace-V3L': <img alt='SpeedFaceV3L' src={SpeedFaceV3L} draggable={false} style={{ height: "50px" }} />,

}

export const PersonInDeviceImage = {

    "D3": <img alt='D3' src={D3TestDeviceImg} draggable={false} style={{ height: "60px" }} />,

    "SmartAC1": <img alt='MiniACDeviceImg' src={MiniACDeviceImg} draggable={false} style={{  height: "70px" }} />,

    'MB20-VL': <img alt='MB20VL' src={MB20VL} draggable={false} style={{  height: "50px" }} />,

    'SpeedFace-V4L': <img alt='SpeedFaceV4L' src={SpeedFaceV4L} draggable={false} style={{  height: "70px" }} />,

    'SpeedFace-V4LM1': <img alt='SpeedFaceV4LM1' src={SpeedFaceV4LM1} draggable={false} style={{  height: "70px" }} />,

    'SpeedFace-V5L-RFID': <img alt='SpeedFaceV5LRFID' src={SpeedFaceV5LRFID} draggable={false} style={{  height: "70px" }} />,

    "EFace10": <img alt='EFace10' src={EFace10} draggable={false} style={{ height: "50px" }} />,

    'MB40-VL': <img alt='MB40VL' src={MB40VL} draggable={false} style={{  height: "50px" }} />,

    'MB560-VL': <img alt='MB560VL' src={MB560VL} draggable={false} style={{ height: "50px" }} />,

    'SpeedFace-V5L': <img alt='SpeedFaceV5L' src={SpeedFaceV5L} draggable={false} style={{  height: "70px" }} />,

    'SpeedFace-V3L-RFID': <img alt='SpeedFaceV3LRFID' src={SpeedFaceV3LRFID} draggable={false} style={{  height: "70px" }} />,

    'SpeedFace-V3L[QR]': <img alt='SpeedFaceV3LQR' src={SpeedFaceV3LQR} draggable={false} style={{  height: "70px" }} />,

    'SpeedFace-V3L(Lite)': <img alt='SpeedFaceV3LLite' src={SpeedFaceV3LLite} draggable={false} style={{  height: "70px" }} />,

    'SpeedFace-V3L': <img alt='SpeedFaceV3L' src={SpeedFaceV3L} draggable={false} style={{ height: "70px" }} />,

}