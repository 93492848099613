import { Grid } from "@mui/material";
import React from "react";
import TitleBar from "./TitleBar";
import { useTranslation } from "react-i18next";

export default function RightViewLayout(props) {
  const {t} = useTranslation()

  return (
    <>
      <TitleBar
        navigateBack={props.navigateBack}
        title={props.title ? props.title : t("AccDevice022")}
        path={props.path}
        extraTilte={props.extraTilte}
        onClick ={()=>props.path?props.onClick ():console.log()}
      >{props.children}</TitleBar>
    </>
  );
}
