import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Clear } from '@mui/icons-material';
import Loader from './Loader';

const VideoCard = ({ src, fileName, attachmentId, index, cancel, loading, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
    }, [loading]);

    const handleDelete = (index) => {
        console.log('Deleting attachmentId:', attachmentId, index);
        cancel(attachmentId, index);
    };

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    cursor: 'default',
                    borderRadius: '5px',
                    width: '150px',
                    height: '150px',
                    padding: ' 5px 5px 40px 5px',
                    border: ' 1px solid #BFBFBF',
                    backgroundColor: ' white',
                    boxShadow: '5px 5px 5px #aaaaaa',
                }}
            >
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 999,
                        }}
                    >
                        <CircularProgress size={24} />
                    </div>
                )}
                <video src={src} draggable={false} controls={false} style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                    <source src={src} />
                </video>
                <PlayCircleOutlineIcon
                    sx={{
                        position: 'absolute',
                        top: '30%',
                        left: '35%',
                        fontSize: 48,
                        color: '#aaaaaa',
                        cursor: 'pointer',
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                    }}
                >
                    <IconButton onClick={() => handleDelete(index)} color="red" size="small">
                        <Clear />
                    </IconButton>
                </div>
                <div style={{ width: '100%', height: '100%' }}>
                    <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{fileName}</Typography>
                </div>

            </Box>
        </>
    );
};

export default VideoCard;
