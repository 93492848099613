import api from '../configurations/http-common';
export const saveScheduleTimeZoneInterval = async (payload) => {
  return api
    .securedAxios()
    .post('/web/schedule', payload);
};

export const updateSchedule = async (id, payload) => {
  return api
    .securedAxios()
    .put('/web/schedules/' + id, payload);
};

export const getTimeZoneIntervalList = async (page, size, name) => {
  let filterURL='';
  if(name){
    let encodedName = encodeURIComponent(name);
   filterURL='&name='+encodedName;
  }
  return api
    .securedAxios()
    .post('/web/schedules/list?page=' + page + '&size=' + size +filterURL);
};

export const deleteScheduleTimeZoneInterval = async (payload) => {
  return api
    .securedAxios()
    .delete(`/web/schedules/${payload.id}?name=${encodeURIComponent(payload.name)}`);
};

export const getScheduleById = async (id) => {
  return api
    .securedAxios()
    .get('/web/schedules/' + id);
};

