import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import OverFlowText from './OverFlowText';


function CustomInput(props) {
    return (
        <Grid item lg={12} xs={12} id={"view-grid"}>
            <Box item paddingRight={"10px"} paddingBottom={"10px"} width='100%' id={"view-box-lable"+props.index}>
                <OverFlowText variant='fieldLabel' index={props.index}>{props.label}</OverFlowText>
            </Box>
            <Box item paddingBottom={"20px"} width='100%' id={"view-box-value"+props.index}>
                {(props.value) ? <Typography variant='fieldValue' id={"view-value"+props.index}>
                    {props.value}
                </Typography> : <Typography variant='fieldValue'>
                    -
                </Typography>}
            </Box>
        </Grid>
    );
}
export default CustomInput;
