import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./configurations/i18n";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "typeface-roboto";
import { Opacity } from "@mui/icons-material";

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#76B745',
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
          color: "rgba(71, 75, 79, 1)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: "none",
        },
        sizeLarge: {
          width: "180px",
          height: "46px",
          borderRadius: "8px",
        },
        sizeMedium: {
          font: "normal normal normal 14px/22px Roboto",
          textTransform: "none",
        },
        contained: {
          background: 'transparent linear-gradient(97deg, #76B745 0%, #5D9435 100%) 0% 0% no-repeat padding-box;',
          borderRadius: '8px !important',
          opacity: 1,
          fontStyle: "normal !important",
          fontVariant: "normal !important",
          fontWeight: "normal !important",
          fontSize: "16px !important",
          lineHeight: "21px !important",
          fontFamily: "Roboto !important",
          '&:disabled': {
            background: 'gray',
            Opacity: 0.6
          },
        },
        outlined: {
          background: '#FFFFFF 0% 0% no-repeat padding-box;',
          border: '1px solid #76B745',
          borderRadius: '8px',
          opacity: 1,
          fontStyle: "normal !important",
          fontVariant: "normal !important",
          fontWeight: "normal !important",
          fontSize: "16px !important",
          lineHeight: "21px !important",
          fontFamily: "Roboto !important",

        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[aria-selected="true"]': {
            color: '#76B745',
          },
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: 'rgba(71, 75, 79, 1)',
    },
    title: {
      color: 'rgba(71, 75, 79, 1)',
      letterSpacing: "0.4px",
      font: "normal normal normal 20px/29px Roboto",
      opacity: "1",
      fontWeight: 500,
    },
    subtitle3: {
      color: 'rgba(71, 75, 79, 1)',
      letterSpacing: "0px",
      font: "normal normal normal 16px/19px Roboto",
      opacity: "1",
      fontWeight: "400",
    },
    link: {
      color: 'rgba(71, 75, 79, 1)',
      letterSpacing: "0.4px",
      font: "normal normal normal 16px/19px Roboto",
      opacity: "1",
      fontWeight: "400",
    },
    subtitle4: {
      color: "rgba(71, 75, 79, 1)",
      letterSpacing: "0.4px",
      font: "normal normal normal 16px/19px Roboto",
      fontWeight: "400",
      opacity: "0.8",
    },
    label: {
      color: 'rgba(71, 75, 79, 1)',
      letterSpacing: "0",
      font: "normal normal normal 14px/26px Roboto",
      fontWeight: "400",
      opacity: "0.5",
    },
    value: {
      color: 'rgba(71, 75, 79, 1)',
      letterSpacing: "0",
      font: "normal normal normal 14px/26px Roboto",
      fontWeight: "400",
      opacity: "1",
    },
    fieldLabel: {
      color: "rgba(71, 75, 79, 0.5)",
      letterSpacing: "0.7px",
      font: "normal normal normal 14px/22px Roboto",

    },
    fieldValue: {
      color: "rgba(71, 75, 79, 1)",
      letterSpacing: "0.8px",
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "Roboto",
      wordBreak: 'break-all'

    },
    fieldValueLap: {
      color: "rgba(71, 75, 79, 1)",
      letterSpacing: "0.8px",
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "Roboto",
    },
    dropdownLabel: {
      color: 'rgba(71, 75, 79, 1)',
      letterSpacing: "0px",
      font: "normal normal normal 12px/20px Roboto",
      opacity: "1",
      fontWeight: "500",
    },
    labelV2: {
      font: 'normal normal normal 16px/26px Roboto',
      letterSpacing: "0px",
      color: "#474B4F",
      opacity: 0.8,
    },
    labelOverflow: {
      font: 'normal normal normal 16px/26px Roboto',
      letterSpacing: "0px",
      color: "#474B4F",
      opacity: 0.8,
      overflow: 'hidden',
      textOverflow: "ellipsis",
      whiteSpace: 'nowrap'
    },
    moduleHeaderDesktop: {
      textAlign: 'left',
      letterSpacing: '0.4px',
      color: 'rgba(71, 75, 79, 1)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "29px",
      fontFamily: "Roboto",
    },
    moduleHeaderLaptop: {
      textAlign: 'left',
      letterSpacing: '0.4px',
      color: 'rgba(71, 75, 79, 1)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "26px",
      fontFamily: "Roboto",
    },
    inputFieldLabel: {
      textAlign: 'left',
      letterSpacing: '0px',
      color: 'rgba(71, 75, 79, 0.8)',
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      fontFamily: "Roboto",
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={MuiTheme}>
    <I18nextProvider i18n={i18n}>
      <App props={i18n} />
    </I18nextProvider>
  </ThemeProvider>
);



