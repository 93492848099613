import {  Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";


export const FilterComponent = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          padding: { xl: '20px', xs: "15px" },
          width: '100%',
          border: '1px solid #EAEBEB',
          marginBottom: { xl: '30px', xs: "15px" },
        }}
      >
        <Grid item md={2.4} xs={12}
          sx={{
            paddingRight: { md: "20px", xs: "0px" },
            height: { xl: "50px", md: "40px" },
          }}
        >
          {props.firstField}
        </Grid>
        <Grid item md={2.4} xs={12}
          sx={{
            paddingRight: { md: "20px", xs: "0px" },
            height: { xl: "50px", md: "40px" },
            mt: props.secondField ? { md: "0px", xs: "20px", xl: "0px" } : "0px"
          }}
        >  {props.secondField}</Grid>
        <Grid item md={2.4} xs={12}
          sx={{
            paddingRight: { md: "20px", xs: "0px" },
            height: { xl: "50px", md: "40px" },
            mt: props.thirdField ? { md: "0px", xs: "20px", xl: "0px" } : "0px"
          }}
        > {props.thirdField}</Grid>
        <Grid item md={2.4} xs={12}
          sx={{
            paddingRight: { md: "20px", xs: "0px" },
            height: { xl: "50px", md: "40px" },
            mt: props.forthField ? { md: "0px", xs: "20px", xl: "0px" } : "0px"
          }}
        > {props.forthField}</Grid>
        <Grid item md={2.4} xs={12}
          sx={{
            paddingLeft: { md: "20px", xs: "0px" },
            display: "flex",
            justifyContent: { md: "flex-end", xs: "center" },
            mt: { md: "0px", xs: "20px" }
          }}
        >
          <Button variant='contained'
            disabled={props.disabled}
            id={"button-page-filter-apply"}
            onClick={() => props.handleSubmit()}
            sx={{
              marginRight: "20px",
              width: { xl: '100px', xs: "100px" },
              height: { xl: '50px', xs: "40px" },

            }}>
            {t('COMMON014')}
          </Button>
          <Button variant='outlined'
            sx={{
              width: { xl: '100px', xs: "100px" },
              height: { xl: '50px', xs: "40px" },
            }}
            disabled={props.disabled}
            id={"button-page-filter-clear"}
            onClick={() => props.clearFilter()}>
            {t('COMMON015')}
          </Button>

        </Grid>
      </Grid>
    </>
  )
}