import { ReactComponent as DeleteIcon } from "../../assets/new-images/Delete Icon.svg";
import { Box, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Add } from "../../assets/new-images/Plus icon.svg";
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import DataTable from '../../components/layout-components/DataTable';
import RightViewLayout from "../../components/RighViewLayout";
import { deleteAcccessGroupDoorById, getAccessGroupDoors, getFilterAccessGroup } from "../../services/AccessGroupService";
import { filterDoors } from "../../services/DoorService";
import CommonUtil from '../../util/CommonUtils';
import { REACT_URL_ACCESS_GROUP_ADD_DOOR, REACT_URL_PAGE_NOT_FOUND } from "../../constants/ReactEndPoints";
import { useSnackbar } from "notistack";
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from "react-i18next";
import PageNotFound from "../../components/PageNotFound";
import CustomDialogBox from "../../components/CustomDialogBox";
import PaginationContext from "../../components/layout-components/PaginationContext";

export default function AccessGroupDoor() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    const { manageAGDoorPageNumber, setManageAGDoorPageNumber, manageAGDoorPageSize, setManageAGDoorPageSize } = useContext(PaginationContext);
    const [page, setPage] = React.useState(1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [data, setData] = React.useState([]);
    const [Filtered, setFiltered] = React.useState(false);
    const [roles, setRoles] = React.useState(['']);
    const { id } = useParams();
    const [viewErrorPage, setViewErrorPage] = useState(false)
    const [accessId, setAccessId] = useState("");
    const [delId, setDelId] = React.useState('');
    const [del, SetDel] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [ids, setIds] = React.useState([]);
    const [permissions, setPermissions] = React.useState([]);

    const [addDoorAccessGroupList, setAddDoorAccessGroupList] = React.useState(false);
    const [manageDoorAccessGroupList, setManageDoorAccessGroupList] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (accessId !== "")
            loadData(page, rowsPerPage);
    }, [accessId]);

    const DEFAULT_PAGE_SIZE = 10;

    const resetFilter = () => {
        if (page == 1) {
            loadData(1, manageAGDoorPageSize);
            setRowsPerPage(manageAGDoorPageSize);
        } else {
            setPage(manageAGDoorPageNumber);
            loadData(manageAGDoorPageNumber, manageAGDoorPageSize);
            setRowsPerPage(manageAGDoorPageSize);
        }

    };
    useEffect(() => {
        if (checkPermission("ADD_DOOR_ACCESS_GROUP")
            || checkPermission("MANAGE_DOOR_ACCESS_GROUP"))
            loadAccessGroup();
    }, [permissions]);

    useEffect(() => {
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if (res.data.code === "CACCZE001") {
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev => [...prev, item.code])
                });
            }
            setAddDoorAccessGroupList(true);
            setManageDoorAccessGroupList(true)
        });
    }, []);

    const loadData = (pageNumber, pageSize) => {
        setIds([])
        getAccessGroupDoors(pageNumber, pageSize, accessId).then((res) => {
            var doorList = [];
            if (res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data) && res?.data?.data?.accessGroupDoors instanceof Array) {
                filterDoors(1, 1000, {}).then((doorRes) => {
                    if (doorRes?.data?.code === 'DMSI0000') {
                        res.data.data.accessGroupDoors.map((agd) => {
                            doorRes.data.data.doors.map((d) => {
                                if (agd?.doorId === d.doorId) {
                                    doorList.push(d);
                                }
                            });
                        });
                        setData(doorList);
                        setTotalRecords(res.data.data.totalCount);
                    }
                });
            } else {
                setTotalRecords(0);
                setData([]);
            }
        });
    }

    useEffect(() => {
        if (del === true && parseInt(data.length) !== 1) {
            loadData(page, rowsPerPage);
            SetDel(false);
        } else {
            if (del === true) {
                if (page !== 1) {
                    setPage(page - 1);
                    loadData(page - 1, rowsPerPage);
                } else {
                    setPage(1);
                    loadData(1, rowsPerPage);
                }
                SetDel(false);
            }
        }
    }, [del]);

    const loadAccessGroup = (page, size) => {
        const filter = {
            id: id,
        }
        getFilterAccessGroup(1, 1, filter).then((res) => {
            if (
                res?.status === 200 &
                res != null &&
                !CommonUtil.isEmpty(res) &&
                res?.data?.code === 'DMSI0000' &&
                !CommonUtil.isEmpty(res?.data?.data) &&
                res.data.data.accessGroups instanceof Array
            ) {
                setAccessId(res.data.data.accessGroups[0].id);
            } else {
                setViewErrorPage(true)
            }
        });
    };

    const deleteRecord = (data) => {
        setOpenDelete(true);
        setDelId(data);
    }

    const gotoAddAccessGroupDoor = () => {
        navigate(REACT_URL_ACCESS_GROUP_ADD_DOOR + `/${id}`);
    };

    const columns = [
        {
            field: "doorParameters.name",
            minWidth: 140,
            headerName: t('AccDMTopology011'),
            flex: 1,
            valueGetter: (params) => params.row.doorParameters.name
        },
        {
            field: "deviceName",
            minWidth: 140,
            headerName: t('AccDevice014'),
            flex: 1,
        },
        {
            field: "deviceSn",
            minWidth: 140,
            headerName: t('AccDevice039'),
            flex: 1,
        },
        {
            field: "doorParameters.doorNo",
            minWidth: 140,
            headerName: t('AccDoor002'),
            flex: 1,
            valueGetter: (params) => params.row.doorParameters.doorNo
        },
        {
            field: "doorParameters.verifyMode",
            headerName: t('AccDoor005'),
            minWidth: 140,
            flex: 1,
            valueGetter: (params) => params.row.doorParameters.verifyModeName
        },
        {
            field: "Actions",
            headerName: t('COMMON019'),
            type: "actions",
            flex: 1,
            minWidth: 140,
            headerClassName: 'no-sort-filter',
            headerAlign: "center",
            getActions: (params) => [
                checkPermission("ADD_DOOR_ACCESS_GROUP") ? <GridActionsCellItem
                    icon={<Tooltip title={t('AccGroupAccessTime028')}><DeleteIcon /></Tooltip>}

                    onClick={() => deleteRecord([params.row])}
                /> : <Box></Box>
            ],
        },
    ];

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleSubmitDelete = () => {
        if (delId.length < 1) {
            enqueueSnackbar(t('AccGroupAccessTime014'), {
                variant: 'error',
            });
        }
        var persons = [];
        delId.map((e) => {
            persons.push(e.id);
        });
        deleteAcccessGroupDoorById(accessId, delId[0].doorId, delId[0].doorParameters.name).then((res) => {
            if (res?.data?.code === 'DMSI0011') {
                enqueueSnackbar(res.data.msg, {
                    variant: 'success',
                });
                loadData(page, rowsPerPage);
            } else {
                enqueueSnackbar(res.data.msg, {
                    variant: 'error',
                });
            }
            setOpenDelete(false);
        });
    };
    const checkPermission = (permissionCode) => {
        if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
            return true;
        else {
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
    }

    const actions = () => (

        <Box display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }}>
            <Tooltip title={t('COMMON002')}>
                <Box
                    aria-label="refresh"
                    onClick={() => resetFilter()}
                    mr={2}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"refresh-Icon"}
                >
                    <Refresh id="icon-accessgroup-door-refresh" style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => resetFilter()}
                    mr={2}
                    sx={{ display: { xl: "none", xs: "block" } }}
                >
                    <Refresh id="icon-accessgroup-door-refresh" width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
            {addDoorAccessGroupList ? checkPermission("ADD_DOOR_ACCESS_GROUP") ?
                (<>
                    <Box

                        aria-label="add-device"
                        sx={{ display: { xl: "block", xs: "none" } }}
                        id={"add-accessgroup-Icon"}
                    >
                        <Tooltip title={t('AccGroupAccessTime029')}>
                            <Add id="icon-accessgroup-door-add" style={{ cursor: 'pointer' }}
                                onClick={() => gotoAddAccessGroupDoor()}
                            />
                        </Tooltip>
                    </Box>
                    <Box
                        aria-label="add-device"
                        sx={{ display: { xl: "none", xs: "block" } }}
                        id={"add-accessgroup-Icon"}
                    >
                        <Tooltip title={t('AccGroupAccessTime029')}>
                            <Add id="icon-accessgroup-door-add" height={"40px"} style={{ cursor: 'pointer' }}
                                onClick={() => gotoAddAccessGroupDoor()}
                            />
                        </Tooltip>
                    </Box>
                </>) : <></> : <></>}
        </Box>
    );

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    }

    return (
        <>
            {viewErrorPage ? <PageNotFound /> : manageDoorAccessGroupList ? checkPermission("MANAGE_DOOR_ACCESS_GROUP") ? <> <RightViewLayout
                title={t('AccGroupAccessTime006')}
                path={true}
                onClick={() => navigate('/group-access-time',
                    { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
            >
                {actions()}
            </RightViewLayout>
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        getRowId={(row) => row.doorId}
                        columns={columns}
                        rows={data}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        count={totalRecords}
                        onPageChange={(e) => {
                            const CurrentPage = CommonUtil.getPageNumber(e);
                            setPage(CurrentPage);
                            setManageAGDoorPageNumber(CurrentPage);
                            loadData(CurrentPage, rowsPerPage);
                        }}
                        onPageSizeChange={(e) => {
                            setRowsPerPage(e);
                            setManageAGDoorPageSize(e);
                            loadData(1, e);
                        }}
                        noRecordText={t("AccGroupAccessTime030")} />

                </Box></> : (<Box marginTop={'150px'} width={'100%'}>
                    <Typography textAlign={'center'}>{t("AccGroupAccessTime040")}</Typography>
                </Box>) : <></>}
            <CustomDialogBox
                id="delete-door-group-access"
                open={openDelete}
                customWidth={'420px'}
                title={t("COMMON013")}
                message={t("AccGroupAccessTime027")}
                saveButtonName={t("COMMON008")}
                cancelButtonName={t("COMMON009")}
                onClose={handleCloseDelete}
                handleSubmit={handleSubmitDelete}
            />
        </>);
}