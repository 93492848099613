import { GridActionsCellItem } from '@mui/x-data-grid';
import React, { useContext, useEffect, useRef, useState, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Add } from "../../assets/new-images/Plus icon.svg";
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import DataTable from '../../components/layout-components/DataTable';
import { deleteScheduleTimeZoneInterval, getTimeZoneIntervalList } from '../../services/SchedulesService';
import CommonUtil from '../../util/CommonUtils';
import { InputAdornment, Tooltip, Typography, Zoom } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as Delete } from "../../assets/new-images/Delete Icon.svg";
import { ReactComponent as Edit } from "../../assets/new-images/Edit Icon.svg";
import RightViewLayout from '../../components/RighViewLayout';
import { REACT_CREATE_TIMESLOT } from '../../constants/ReactEndPoints';
import { ReactComponent as Filter } from "../../assets/new-images/Filter icon (1).svg";
import { FilterComponent } from '../../components/FilterComponent';
import CustomInput from '../../components/CustomInput';
import { useSnackbar } from 'notistack';
import CustomDialogBox from '../../components/CustomDialogBox';
import { ReactComponent as ViewIcon } from "../../assets/images/ViewIcon.svg"
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from 'react-i18next';
import PaginationContext from '../../components/layout-components/PaginationContext';
import { Clear, SearchOutlined } from '@mui/icons-material';
import { ReactComponent as SearchIcon } from '../../assets/SearchIcon.svg'

export default function TimeSlot() {
  const { t } = useTranslation()
  const location = useLocation();
  const { manageTimeSlotPageNumber, setManageTimeSlotPageNumber, manageTimeSlotPageSize, setManageTImeSlotPageSize } = useContext(PaginationContext);
  const [page, setPage] = React.useState(manageTimeSlotPageNumber ? manageTimeSlotPageNumber : 1);
  const [delId, setDelId] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(manageTimeSlotPageSize ? manageTimeSlotPageSize : 10);
  const [pageSize, setPageSize] = useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [schedules, setSchedules] = useState([]);
  const [del, SetDel] = useState(false);

  const [openDelete, setOpenDelete] = React.useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [ids, setIds] = useState([]);
  const [load, setLoad] = useState(true);
  const [change, setChange] = React.useState(false);
  const [reload, setReload] = useState(false);
  const [clearAndRefresh, setClearAndRefresh] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [perViewTimeSlots, setViewTimeSlots] = useState(true);
  const [roles, setRoles] = React.useState(['']);
  const [perAddTimeSlots, setAddTimeSlots] = useState(true);
  const [perDeleteTimeSlots, setDeleteTimeSlots] = useState(true);
  const [perEditTimeSlots, setEditTimeSlots] = useState(true);
  const [perListTimeSlots, setListTimeSlots] = useState(true);
  const searchQueue = useRef([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (load )
      getPermissionsByRoleId(api.decoded().roleId).then((res) => {
        var l = [];
        res.data.data.rolePermissions.map((item, index) => {
          l.push(item.code);
        });
        setRoles(l);
        setViewTimeSlots(l.includes("VIEW_TIMESLOT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setAddTimeSlots(l.includes("ADD_TIMESLOT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setDeleteTimeSlots(l.includes("ADD_TIMESLOT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setEditTimeSlots(l.includes("ADD_TIMESLOT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListTimeSlots(l.includes("TIMESLOT_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("TIMESLOT_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
          loadSchedules(page, rowsPerPage, searchValue);
        }

      });

  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!load && reload) {
      loadSchedules(1, rowsPerPage, searchValue);
      setReload(false);
    }
  }, [ reload]);

  const columns = [
    {
      field: 'operationName',
      hideable: false,
      headerName: t('AccTime001'),
      flex: 5,
      valueGetter: (params) => params.row.operationName
    },
    {
      field: 'actions',
      headerName: t('COMMON019'),
      type: 'actions',
      flex: 0.6,
      renderCell: (e) => (
        <>
          {e.row.operationName === '24-Hours General' ? <>
            {perViewTimeSlots ? (<Tooltip title={t("COMMON004")}>
              <ViewIcon id="icon-timeslot-view" style={{ cursor: 'pointer' }}
                onClick={() => { openEditPage(e) }}
              /></Tooltip>) : <Box></Box>}
          </> :
            <>
              <GridActionsCellItem
                sx={{ marginRight: { xl: "20px", xs: "10px" } }}
                icon={perEditTimeSlots ? (<Tooltip title={t("COMMON005")}><Edit id="icon-timeslot-edit" style={{ cursor: 'pointer' }}
                  onClick={() => { openEditPage(e) }}
                /></Tooltip>) : <Box></Box>}
              />

              <GridActionsCellItem
                icon={perDeleteTimeSlots ? (<Tooltip title={t("COMMON008")}><Delete id="icon-timeslot-delete" width={'17px'} style={{ cursor: 'pointer' }}
                  onClick={(rowData) => { handleDelete(e) }}
                /></Tooltip>) : <Box></Box>}
              />
            </>}
        </>
      )
    },
  ];

  const DEFAULT_PAGE_SIZE = 10;

  const handleRefresh = (name) => {
    if (page === 1) {
      // setName('');
      // setDisabled(true)
      setLoad(true);
      loadSchedules(1, manageTimeSlotPageSize, name);
      setRowsPerPage(manageTimeSlotPageSize);
    }
    else {
      // setName('');
      // setDisabled(true)
      setLoad(true);
      setPage(manageTimeSlotPageNumber);
      setRowsPerPage(manageTimeSlotPageSize);
      loadSchedules(manageTimeSlotPageNumber, manageTimeSlotPageSize, name);
    }
  }

  const actions = () => (
    <>
      {

      }
    </>
  );

  const openEditPage = (rowData) => {
    navigate(`/update-time-slot/${rowData.id}`, { state: { id: rowData.id, name: rowData.row.operationName, pageNumber: page, pageSize: rowsPerPage } });
  };

  const loadSchedules = (page, size, name) => {
    if(name){
      searchQueue.current = [];
    }
    setIds([]);
  
    getTimeZoneIntervalList(page, size, name).then((res) => {
      if (res != null && !CommonUtil.isEmpty(res)
        && res?.data?.code === 'DMSI0000'
        && !CommonUtil.isEmpty(res?.data?.data)
        && res?.data?.data?.timeZone instanceof Array) {
        setTotalRecords(res.data.data.totalCount);
        setSchedules(res.data.data.timeZone);
        setPageSize(res.data.data.pageSize);
      } else {
        setTotalRecords(0);
        setSchedules([]);
      }
      setClearAndRefresh(false);
    });
  };

  // This for Delete function
  const handleDelete = (data) => {
    setOpenDelete(!openDelete);
    setDelId(data.row);
  }

  const handleSubmitDelete = () => {
    const payload = {
      id: delId.id,
      name: delId.operationName
    }
    if (delId.id)
      deleteScheduleTimeZoneInterval(payload).then((res) => {
        if (res?.data?.code === "DMSI0009") {
          enqueueSnackbar((res.data.msg).replace("Schedules", t("AccTime013")), { variant: 'success' })
          SetDel(true);
        } else {
          if (res?.data?.code === "DMSE00056")
            enqueueSnackbar((res.data.msg).replace("Schedules", t("AccTime013")), { variant: 'error' })
          else
            enqueueSnackbar((res.data.msg).replace("Schedule", t("AccTime013")), { variant: 'error' })
        }
        setOpenDelete(false);
      })
  };

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadSchedules(page, rowsPerPage, searchValue);
      SetDel(false);
    } else {
      if (del === true) {
        loadSchedules(page - 1, rowsPerPage, searchValue);
        SetDel(false);
      }
    }
  }, [del]);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // This is for filter Components

  const debounceTimer = useRef(null);
  const globalsearch = (searchedVal) => {
    var value = searchedVal.target.value;    
   
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
   
    if (value === '') {
      handleRefresh('');
      return;
    }

    setSearchValue(value);

    if (value !== '' && value !== '-' && value !== '_' && value !== null) {
      searchQueue.current.push(value);
      debounceTimer.current = setTimeout(() => {
        searchQueue.current = value;
        processQueue();
      }, 300);
    } else if (searchedVal === '-' || searchedVal === '_') {
      setSchedules([]);
    }
  };
  const searchCache = useRef({});
  const processQueue = () => {
    if (searchQueue.current) {
      const searchValue = searchQueue.current;
      searchQueue.current = '';
      setPage(1);
      loadSchedules(page, rowsPerPage, searchValue);
    }
  };

  const FieldTimeSlotName = (key) => (
    <CustomInput
      id="textfield-time-slot-name"
      placeholder={t('AccTime001')}
      value={searchValue}
      size='small'
      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      fullWidth
      name='operationName'
      variant='outlined'
      handleChange={(e) => { setSearchValue(e.target.value); globalsearch(e);}}
      inputProps={{
        maxLength: 100,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Box>
              {searchValue ?(
                <Tooltip title={t("Clear")} TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                  <Clear
                    style={{ cursor: 'pointer', fontWeight: 800, fontSize: '24px', color: 'rgba(71, 75, 79, 0.8)', marginRight: '5px', marginTop: '5px' }}
                    onClick={() => {
                      setPage(1);
                      setSearchValue(""); 
                      loadSchedules(1, rowsPerPage, ''); 
                    }}
                  />
                </Tooltip>):( <Tooltip title={t("Search")} TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
              <SearchIcon style={{ cursor: 'pointer' }} />
            </Tooltip>)}
            </Box>
           
          </InputAdornment>
        )
      }}
    />
  );


  const handleSubmitFilter = () => {
    
    setLoad(false);
    setReload(true)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmitFilter();
    }
  };

  useEffect(() => {
    if (!openFilter && !disabled) {
      handleRefresh(searchValue);
    }
  }, [openFilter])

  const handlePageChange = (e) => {
    if (searchValue) {
      setPage(e);
     setManageTimeSlotPageNumber(e);
      loadSchedules(e, rowsPerPage, searchValue);
      
    }
    else {
      setLoad(true);
      setPage(e);
      setManageTimeSlotPageNumber(e);
    }
  }

  const handlePageSize = (e) => {
    if (searchValue) {
      setRowsPerPage(e);
      setManageTImeSlotPageSize(e);
      setPage(1);
      loadSchedules(1, e, searchValue);
    }
    else {
      setLoad(true);
      setRowsPerPage(e);
      setManageTImeSlotPageSize(e);
      setPage(1);
    }
  }

  const onSelectChecxBoxChange = (id) => {
    setIds(id);
  };
  return (
    <>
      {perListTimeSlots ? (<>
        <Box
          id="title-bar"
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            height: { xl: "50px", xs: "40px" }
          }}
        >

          <Box item flexGrow={1} sx={{ display: { xl: "block", xs: "none" } }} id={"module-title"}>
            <Typography variant={"moduleHeaderDesktop"}>{t("AccTime013")}</Typography>
          </Box>
          <Box item flexGrow={1} sx={{ display: { xl: "none", xs: "block" } }} id={"module-title"}>
            <Typography variant={"moduleHeaderLaptop"}>{t("AccTime013")}</Typography>
          </Box>

          <Box display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }}>
            <Box
              mr={2}
            >
              {FieldTimeSlotName()}
            </Box>

            <Tooltip title={t('ACCORG008')}>
              <Box
                aria-label="refresh"
                onClick={() => handleRefresh(searchValue)}
                mr={2}
                sx={{ display: { xl: "block", xs: "none" } }}
                id={"refresh-Icon"}
              >
                <Refresh style={{ cursor: 'pointer' }} />
              </Box>
              <Box
                aria-label="refresh"
                onClick={() => handleRefresh(searchValue)}
                mr={2}
                sx={{ display: { xl: "none", xs: "block" } }}
              >
                <Refresh width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
              </Box>
            </Tooltip>
            {/* <Tooltip title={t('ACCORG009')}>
            <Box
              aria-label="filter"
              onClick={() => setOpenFilter(!openFilter)}
              mr={2}
              sx={{ display: { xl: "block", xs: "none" } }}
              id={"filter-Icon"}
            >
              <Filter style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              aria-label="refresh"
              onClick={() => setOpenFilter(!openFilter)}
              mr={2}
              sx={{ display: { xl: "none", xs: "block" } }}
              id={"filter-Icon"}
            >
              <Filter width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
            </Box>
          </Tooltip> */}
            {perAddTimeSlots && (<Tooltip arrow title={t("AccTime014")}>
              <Box
                aria-label="add-box"

                onClick={() => navigate(REACT_CREATE_TIMESLOT, { state: { pageNumber: page, pageSize: rowsPerPage } })}
                sx={{ display: { xl: "block", xs: "none" } }}
                id={"add-Icon"}
              >
                <Add style={{ cursor: 'pointer' }} />
              </Box>
              <Box
                aria-label="add-box"
                onClick={() => navigate(REACT_CREATE_TIMESLOT, { state: { pageNumber: page, pageSize: rowsPerPage } })}
                sx={{ display: { xl: "none", xs: "block" } }}
                id={"add-Icon"}
              >
                <Add width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
              </Box>
            </Tooltip>)}
          </Box>
        </Box>

        <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
          {/* {openFilter && <FilterComponent
            firstField={FieldTimeSlotName()}
            clearFilter={clearFilter}
            handleSubmit={handleSubmitFilter}
            disabled={disabled}
          />} */}
          <DataTable
            page={page - 1}
            totalRecords={totalRecords}
            rowsPerPage={rowsPerPage}
            rows={schedules}
            columns={columns}
            getRowId={(row) => row.id}
            checkboxSelection={true}
            onSelection={(e) => onSelectChecxBoxChange(e)}
            rowSelectionModel={ids}
            onPageChange={(e) => handlePageChange(e + 1)}
            onPageSizeChange={(e) => handlePageSize(e)
            }
            noRecordText={t("AccTime012")}
          />

        </Box>
      </>) : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={"center"}>{t("AccTime010")}</Typography></Box>)
      }
      <CustomDialogBox
        id="deleteTimeZone"
        open={openDelete}
        customWidth={'420px'}
        title={t("COMMON013")}
        message={t("AccTime011")}
        saveButtonName={t("COMMON008")}
        cancelButtonName={t("COMMON009")}
        onClose={handleCloseDelete}
        handleSubmit={handleSubmitDelete}
      />
    </>
  );
}
