import * as React from 'react';
import { Divider, Container, Box, } from '@mui/material';
import UserProfileMenu from './UserProfileMenu';
import CompanyProfileMenu from './CompanyProfileMenu';
// import DarkMode from './DarkMode';
// import UserManual from './UserManual';

export default function Header() {

  // const [openInformation, setOpenInformation] = React.useState(false);

  return (
    <Container style={{
      padding: '0px 0px', height: '60px', 
      boxShadow: "0px 1px 4px #0000001A", 
      minWidth: '100%', 
      borderBottom: '1px solid #cad0d9',
    }}>
      <Box display='flex' flexDirection='row-reverse' width='100%' height={'60px'}>
        <Box item className='profile-icon' sx={{ display: 'flex', alignItems: "center" }}>
          <UserProfileMenu />
        </Box>
        <Divider orientation='vertical' flexItem sx={{ width: '1px', height: '50px', marginTop: '5px'}} />
        <Box item className='profile-icon' sx={{ display: 'flex', alignItems: "center" }}>
          <CompanyProfileMenu />
        </Box>

        <Divider orientation='vertical' flexItem sx={{ width: '1px', height: '50px', marginTop: '5px'}} />
        {/* <Box id='header-help icon-download' item className='help-icon' style={{ display: "flex", alignItems: "center" }}>
              <IconButton style={{padding:'0px 8px', marginRight:'6px'}} onClick={() => setOpenInformation(!openInformation)}>
                <UserManual />
              </IconButton>
            </Box> */}

      </Box>
    </Container>
  );
}
