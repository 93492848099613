import { Autocomplete, Avatar, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as ViewIcon } from '../../assets/ViewIcon.svg';
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import DataTable from '../../components/layout-components/DataTable';
import PaginationContext from '../../components/layout-components/PaginationContext';
import RightViewLayout from '../../components/RighViewLayout';
import OverflowTip from '../../components/tooltip';
import api from '../../configurations/http-common';
import { REACT_URL_PERSON_VIEW } from '../../constants/ReactEndPoints';
import { getDepartmentList, getJobtitleList, loadOrgPersons } from "../../services/PersonService";
import { getPermissionsByRoleId } from '../../services/RoleService';
import CommonUtil from '../../util/CommonUtils';
import SearchBar from '../../components/SearchBar';
import lodash from 'lodash';

function Person() {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [person, setPerson] = useState([]);
    const { managePersonPageNumber, setManagePersonPageNumber, managePersonPageSize, setManagePersonPageSize } = useContext(PaginationContext);
    const [page, setPage] = React.useState(managePersonPageNumber ? managePersonPageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(managePersonPageSize ? managePersonPageSize : 10);
    const [open, setOpen] = useState(false);
    const [perListPerson, setListPerson] = useState(false);
    const [roles, setRoles] = React.useState([]);
    const [filter, setFilter] = React.useState(false);
    const [load, setLoad] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [change, setChange] = React.useState(false);
    const [reload, setReload] = useState(false);
    const [clearAndRefresh, setClearAndRefresh] = useState(false);
    const [ids, setIds] = useState([]);
    const [jobTitles, setJobTitles] = React.useState([]);
    const [departments, setDepartments] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const defaultFilter = { departmentId: '', designationId: '' };
    const [filterData, setFilterData] = useState({ ...defaultFilter, });

    useEffect(() => {
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            var l = [];
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });
            setRoles(l);
            setListPerson(true)
        });

        getDepartmentList().then((res) => {
            if (res.data.code === "OMSI0000")
                setDepartments(res.data.data.department)
        });
        getJobtitleList().then((res) => {
            if (res.data.code === "OMSI0000")
                setJobTitles(res.data.data.designation)
        });
    }, []);

    useEffect(() => {
        if (roles.includes("ACC_VIEW_PERSONLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
            if (load && !filter) {
                loadData(page, rowsPerPage, { ...defaultFilter, search: searchValue });
            }
        }
    }, [page, rowsPerPage, roles]);

    useEffect(() => {
        if (filter && !load && reload) {
            setPage(1)
            loadData(1, rowsPerPage, { ...filterData, search: searchValue });
            setReload(false)
        }
    }, [filter, reload]);

    const searchData = useCallback(lodash.throttle((pageNumber, pageSize, filterData) => {
        loadData(pageNumber, pageSize, filterData)
    }, 1200, { leading: false, trailing: true }), []);

    const loadData = (pageNumber, pageSize, filterData) => {
        setIds([]);
        loadOrgPersons(pageNumber, pageSize, filterData).then((res) => {
            if (res?.data?.code === 'OMSI0000') {
                setPerson(res.data.data.employees);
                setTotalRecords(res.data.data.totalCount);
            }
            else {
                setPerson([]);
                setTotalRecords(0);
            }
            setClearAndRefresh(false);
        });
    };
    const getJobtitle = (id) => {
        if (!CommonUtil.isEmpty(id))
            return jobTitles?.find(element => element?.id === id)?.name;
        else return "";
    }
    const getdepartment = (id) => {
        if (!CommonUtil.isEmpty(id))
            return departments?.find(element => element?.id === id)?.name;
        else return "";
    }
    const columns = [
        {
            field: 'firstName',
            hideable: false,
            headerName: t('ACCORG002'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG002')} >
                    <span>
                        {t('ACCORG002')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.firstName + " " + e.row.lastName}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ bgcolor: '#C0C0C0', height: '40px', width: '40px', marginRight: '10px' }} alt={e.row.firstName} src={e.row.profilePhotoURL}></Avatar>
                </OverflowTip>
            ),
        },
        {
            field: 'code',
            headerName: t('ACCORG003'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG003')} >
                    <span>
                        {t('ACCORG003')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.code}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'email',
            headerName: t('ACCORG004'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG004')} >
                    <span>
                        {t('ACCORG004')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.email}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'phone',
            headerName: t('ACCORG005'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG005')} >
                    <span>
                        {t('ACCORG005')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.phone}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'departmentId',
            headerName: t('ACCORG014'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG014')} >
                    <span>
                        {t('ACCORG014')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={getdepartment(e.row.departmentId)}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'designationId',
            headerName: t('ACCORG015'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG015')} >
                    <span>
                        {t('ACCORG015')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={getJobtitle(e.row.designationId)}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            headerName: t('ACCORG006'),
            sortable: false,
            hideable: false,
            headerClassName: 'no-sort-filter',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG006')} >
                    <span>
                        {t('ACCORG006')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <>
                    {(roles.includes("ACC_VIEW_PERSON_PROFILE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER')
                        || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))
                        && <Tooltip title={t('ACCORG007')}>
                            <GridActionsCellItem
                                icon={(<ViewIcon id="icon-employee-icon-view" style={{ cursor: 'pointer' }}
                                    onClick={() => navigate(REACT_URL_PERSON_VIEW + `/${e.row.code}`,
                                        { state: { picURL: e.row.profilePhotoURL, pageNumber: page, pageSize: rowsPerPage } })} />)}
                            />
                        </Tooltip>}
                </>
            ),
        },
    ];

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setManagePersonPageNumber(1);
        searchData(1, managePersonPageSize, { ...filterData, search: value });
        if (CommonUtil.isEmpty(value)) {
            setTimeout(function () {
                setPage(1);
                setManagePersonPageNumber(1);
                loadData(page, rowsPerPage, filterData);
            }, 2000);
        }
    };

    const clearSearch = () => {
        setSearchValue('');
        if (searchValue !== "") {
            setManagePersonPageNumber(1);
            setPage(1)
            setFilter(true); setLoad(false); setReload(true)
        }
    };

    const actions = () => (
        <>
            {perListPerson && <Box item display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }} >
                <Box
                    aria-label="Search-icon"
                    mr={2}
                    id={"Search-icon"}
                >
                    <SearchBar style={{ cursor: 'pointer' }}
                        placeholder={t('COMMON053')}
                        searchValue={searchValue}
                        clearValue={clearSearch}
                        handleSearchChange={handleSearch}
                        width={{ xl: "350px", xs: "320px" }}
                    />
                </Box>
                <Tooltip title={t('ACCORG008')}>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        mr={2}
                        sx={{ display: { xl: "block", xs: "none" } }}
                        id={"refresh-Icon"}
                    >
                        <RefreshIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        mr={2}
                        sx={{ display: { xl: "none", xs: "block" } }}
                        id={"refresh-Icon"}
                    >
                        <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
                <Tooltip title={t('ACCORG009')}>
                    <Box
                        aria-label="filter"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xl: "block", xs: "none" } }}
                    >
                        <FilterIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xl: "none", xs: "block" } }}
                    >
                        <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
                {/* <IconButton arrow aria-label="add" style={{'background':'#76B745','color':'white'}}>
                    <AddIcon fontSize="inherit" />
                </IconButton> */}
            </Box>}

        </>

    );

    const DEFAULT_PAGE_SIZE = 10;

    const refreshTableData = () => {
        setClearAndRefresh(true);
        const loadParams = (pageNumber) => {
            const params = CommonUtil.isEmpty(searchValue)
                ? filterData
                : { ...filterData, search: searchValue };
            loadData(pageNumber, managePersonPageSize, params);
        };

        if (page === 1) {
            loadParams(1);
        } else {
            setPage(managePersonPageNumber);
            loadParams(managePersonPageNumber);
        }
    };

    useEffect(() => {
        if (filterData.designationId === '' && filterData.departmentId === '') {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [filterData]);

    const clearFilter = () => {
        setFilterData(defaultFilter);
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, rowsPerPage, { ...defaultFilter, search: searchValue });
        }
        else setPage(1);
    }

    const handleSubmitFilter = () => {
        setFilter(true);
        setLoad(false);
        setReload(true)
    }

    const handlePageChange = (e) => {
        if (filter) {
            setPage(e);
            setManagePersonPageNumber(e);
            loadData(e, rowsPerPage, { ...filterData, search: searchValue });
        }
        else {
            setFilter(false);
            setLoad(true);
            setPage(e);
            setManagePersonPageNumber(e);
        }
    }

    const handlePageSize = (e) => {
        if (filter) {
            setRowsPerPage(e);
            setManagePersonPageSize(e);
            setPage(1);
            loadData(1, e, { ...filterData, search: searchValue });
        }
        else {
            setFilter(false);
            setLoad(true);
            setRowsPerPage(e);
            setManagePersonPageSize(e);
            setPage(1);
        }
    }
    const findDepartmentById = (id) => {
        var dept = { id: '' };
        departments.map((department) => {
            if (department.id === id) {
                dept = department;
            }
        });
        return dept;
    };

    const FieldDepartment = (key) => (
        <Autocomplete
            id="dropdown-department-filter"
            fullWidth
            size='small'
            getOptionLabel={(option) => (option?.name ? option.name : '')}
            options={CommonUtil.isEmpty(departments) ? [] : departments}
            value={filterData.departmentId ? findDepartmentById(filterData.departmentId) : null}
            renderInput={(params) => <CustomInput
                handleChange={(e) => console.log()}
                resetError={() => console.log()}
                height={{ xl: "34px", xs: "24px" }}
                placeholder={t('ACCORG014')} {...params} cursor='pointer' />}
            onChange={(event, newValue) => {
                if (newValue !== null) {
                    setChange(true);
                    setFilterData({
                        ...filterData,
                        departmentId: newValue.id,
                    });
                }
                else {
                    setChange(true);
                    setFilterData({
                        ...filterData,
                        departmentId: '',
                    });
                }
            }}
        />
    );

    const findJobtitle = (value) => {
        var jobTitle = '';
        jobTitles.map((data) => {
            if (data.id == value) {
                jobTitle = data; 
            }
        });
        return jobTitle;
    };

    const FieldJobtitle = (key) => (
        <Autocomplete
            id="dropdown-jobtitle-filter"
            fullWidth
            size='small'
            getOptionLabel={(option) => (option.name)}
            options={CommonUtil.isEmpty(jobTitles) ? [] : jobTitles}
            renderInput={(params) => (
                <CustomInput placeholder={t('ACCORG015')} handleChange={(e) => console.log()}
                    resetError={() => console.log()}
                    height={{ xl: "34px", xs: "24px" }}
                    {...params} cursor='pointer' />
            )}
            value={filterData.designationId ? findJobtitle(filterData.designationId) : null}
            onChange={(event, newValue) => {
                if (newValue !== null) {
                    setChange(true);
                    setFilterData({
                        ...filterData,
                        designationId: newValue.id,
                    });
                }
                else {
                    setChange(true);
                    setFilterData({
                        ...filterData,
                        designationId: '',
                    });
                }
            }}
        />
    );

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitFilter();
        }
    };

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    useEffect(() => {
        if (!open && !disabled) {
            setChange(false); setPage(1);
            setFilterData(defaultFilter);
            loadData(1, managePersonPageSize, defaultFilter);
        }
    }, [open]);

    const checkPermission = (permissionCode) => {
        if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
            return true;
        else {
            const permissionIndex = roles.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
    }


    return (
        <>
            {perListPerson ? checkPermission("ACC_VIEW_PERSONLIST") ? <><RightViewLayout title={t('ACCORG001')} navigateBack={true} path={false}>
                {actions()}
            </RightViewLayout>

                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open && <FilterComponent
                        firstField={FieldDepartment()}
                        secondField={FieldJobtitle()}
                        clearFilter={clearFilter}
                        handleSubmit={handleSubmitFilter}
                        disabled={disabled}
                    />}
                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        columns={columns}
                        getRowId={(row) => row.id}
                        rows={person}
                        // toolbar={filters}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        onPageChange={(e) => handlePageChange(e + 1)}
                        onPageSizeChange={(e) => handlePageSize(e)}
                        noRecordText={t('ACCORG010')} />


                </Box></> : (
                <Box marginTop={'150px'} width={'100%'}>
                    <Typography textAlign={'center'}>
                        {t("ACCORG024")}
                    </Typography>
                </Box>) : <></>}
        </>
    );
}

export default Person;
