import api from "../configurations/http-common";

export const loadDeviceAuxiliaryOutput = async (page, size, filter) => {
    let query = 'pageNumber=' + page + '&pageSize=' + size + buildQuery(filter);
   return  api.securedAxios().get('/web/auxiliary_outputs?' + query);

   function buildQuery(filter) {
      let query = ''

      if(!filter){
        return query;
        }

      if ( filter.doorName != undefined && filter.doorName !='') {
          query = query + `&doorName= ${filter.doorName}`.replace(" ", "");

      }
      if (filter.deviceAlias != '' && filter.deviceAlias!=undefined) {
          query = query + `&deviceAlias= ${encodeURIComponent(filter.deviceAlias)}`.replace(" ", "");
      }

      if (filter.name != ''  && filter.name!=undefined) {
          query = query + `&name= ${filter.name}`.replace(" ", "");
      }
      return query

  }
}

export const loadDeviceAuxiliaryOutputById = async (id) => {
    return  api.securedAxios().get('/web/auxiliary-out/' + id);
 }

 export const updateAuxOut = async ( payload) => {
    return api
    .securedAxios()
    .put('/web/auxiliary-out' ,  payload);
  };