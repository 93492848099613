import axios from "axios"
import api from "../configurations/http-common";

export const roleList = async (payload) => {
  
  let url = '/web/role/list';
  if (payload.name !== '') {
    let name = encodeURIComponent(payload.name);
    url += '?name=' + name;
  }
  if ((payload.code !== '') & (payload.name !== '')) {
    let code = encodeURIComponent(payload.code);
    url += '&code=' + code;
  }
  if ((payload.code !== '') & (payload.name === '')) {
    let code = encodeURIComponent(payload.code);
    url += '?code=' + code;
  }
  return api.securedAxios().get(url);
}

export const deleteRole = async (roleId) => {
    return api.securedAxios().delete('/web/role/' + roleId);  
};

export const getPermissionsByRoleId = async (roleId) => {
  return api.securedAxios().get(`/web/role/${roleId}/permissions`);
};
// export const resetRolePermission = async (roleId) => {
//   try {
//     dispatch({
//       type: 'ROLE_PERMISSION_LIST',
//       payload: [],
//     });
//   } catch {}
// };
export const getPermissionsList = async () => {
  return api.securedAxios().get(`/web/role/permissionsList`);
};

export const updateRole = async (payload) => {
  return api.securedAxios().put(`/web/role/${payload.id}`, payload);
}
export const createRole = async (payload) => {
  return api.securedAxios().post('/web/role', payload);
};
