import React from 'react'
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
  import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
const CustomPopState = (props) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
    {(popupState) => (
      <React.Fragment>
        <span {...bindTrigger(popupState)}>
        <Tooltip title={props.tooltip}>
        {props.icon}
        </Tooltip>
        </span>
        <Menu {...bindMenu(popupState)}>
        { props.list instanceof Array && props.list.map((list)=>
        <MenuItem onClick={()=>{popupState.close(); props.handleClick()}}>{list.title}</MenuItem>)}
        </Menu>
      </React.Fragment>
    )}
  </PopupState>
  )
}

export default CustomPopState
