import { Box, Grid, Typography } from '@mui/material';
import React from "react";
import CustomInput from '../../components/ViewText';
import { useEffect, useState } from "react";
import { loadDeviceReaderById, } from "../../services/ReaderService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import CommonUtil from '../../util/CommonUtils';
import { REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import ViewPage from '../../components/ViewPage';
import Loader from '../../components/Loader';

const ViewReader = (props) => {
    const { t } = useTranslation()
    const location = useLocation();
    const [reader, setReader] = useState([]);
    const [perViewReader, setViewReader] = useState(false);
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState([]);
    const {readerId} = useParams();
    const[loader,setLoader]=React.useState(true);

    useEffect(() => {

        if (CommonUtil.isEmpty(readerId)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }

        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if (res.data.code === "CACCZE001") {
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev => [...prev, item.code])
                });
            }
            setViewReader(true);
        });
    }, []);

    useEffect(() => {
        if (checkPermission("ACC_READER_VIEW")) {
            setLoader(true);
            loadDeviceReaderById(readerId).then((res) => {
                if (res?.data?.code === 'DMSI0000') {
                    setReader(res.data.data);

                }
                setLoader(false);
            });
        }
    }, [permissions])

    const checkPermission = (permissionCode) => {
        if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
            return true;
        else {
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
    }

    return (
        <>
            {perViewReader ? checkPermission("ACC_READER_VIEW") ? 
            <ViewPage title={t('AccReader007')}  path={true}
                onClick={() => navigate(
                    '/reader',
                    { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}>

                <Grid direction='row' alignItems={'flex-start'} id={"view-grid"}  >
                    {CustomInput({ label: t('AccReader010'), value: reader.name,index:1 })}
                    {CustomInput({ label: t("AccReader011"), value: reader.doorName,index:2 })}
                    {CustomInput({ label: t("AccReader012"), value: reader.deviceName,index:3 })}
                    {CustomInput({ label: t("AccReader013"), value: reader.deviceSn,index:4 })}
                    {CustomInput({ label: t("AccReader014"), value: reader.readerNo,index:5 })}
                    {CustomInput({ label: t("AccReader015"), value: reader.commType,index:6 })}
                    {CustomInput({ label: t("AccReader016"), value: reader.commAddress,index:7 })}
                    {CustomInput({ label: t("AccReader017"), value: reader.status == '0' ? t('COMMON020') : t('COMMON021'),index:8 })}
                </Grid>
                {loader?<Loader open={loader}/>:<></>}
            </ViewPage> : (<Box marginTop={'150px'} width={'100%'}>
                <Typography textAlign={'center'}>{t("AccReader027")}</Typography>
            </Box>) : <></>}

        </>

    )
};

export default ViewReader;