import { Cancel } from '@mui/icons-material';
import { ReactComponent as Search } from '../../../assets/Search Icon.svg';
import { Box, TextField } from '@mui/material';
import React from 'react';
import { useState } from 'react';


export default function SearchBar(props) {

  const [expand, setExpand] = useState(false)

  const ValidationTextField = {

    "& .MuiInputBase-input": {
      height: { xl: "33px", xs: "23px" },
    },
  };


  return (
    <>
      <Box letiant='standard' sx={{ width: !expand ? "46px" : '100%', transition: "0.5s" }}>

        <TextField
          size='small'
          autoComplete='new-password'
          fullWidth id={props.id}
          placeholder={props.placeholder}
          onMouseEnter={() => { if (props.value == "") setExpand(true) }}
          onMouseLeave={() => { if (props.value == "") setExpand(false) }}
          disabled={!expand}
          onBlur={() => { if (props.value == "") setExpand(false) }}
          sx={{ ...ValidationTextField }}
          InputProps={{
            endAdornment: (
              <>
                {props.value ? (
                  <Box id='close-icon' style={{ cursor: "pointer" }}>
                    <Cancel
                      style={{ color: "rgba(71, 75, 79, 0.5)", marginTop: "8px" }}
                      onClick={() => props.clearSearch()}
                    />
                  </Box>
                ) : (
                  <Box id='search-icon' style={{ cursor: "pointer" }}>
                    <Search style={{ cursor: "pointer", marginTop: "6px" }} onClick={() => { if (props.value == "") setExpand(true) }} />
                  </Box>
                )}
              </>
            ),
          }}
          value={props.value} helperText={props.helperText} error={props.helperText} onChange={props.handleChange} />
      </Box>
    </>
  );
}
