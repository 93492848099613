import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import { loadDeviceAuxiliaryInputById, updateAuxIn } from '../../services/AuxiliaryInputService';
import CommonUtil from '../../util/CommonUtils';
import { useTranslation } from 'react-i18next';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import ViewPage from '../../components/ViewPage';
import { REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';


export default function EditAuxiliaryInput(props) {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const [perEditAuxIN, setEditAuxIn] = React.useState(false);
    const navigate = useNavigate();
    const [permissions,setPermissions]= useState([]);
    const params = useParams();
    const deviceId = params.deviceId;
    const [viewErrorPage,setViewErrorPage]=useState(false)
    const[loader,setLoader]=React.useState(false);


    const [payload, setPayload] = React.useState({
        address:"",
        aux_id: "",
        aux_no: "",
        dev_id: "",
        deviceName:"",
        deviceSn: "",
        disable:"",
        enable: "",
        ext_dev_id:"",
        ipaddress: "",
        name: "",
        printer_number: "",
        remark: "",
        status: "",
        timeseg_id: location.state?.aux.timeseg_id
    });
    const [error, setError] = React.useState({
        name: false,
    });
    

    React.useEffect(() => {

        if (CommonUtil.isEmpty(deviceId)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }
      
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if(res.data.code==="CACCZE001"){
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev=>[...prev,item.code])
                });
            }
            setEditAuxIn(true);
        });
    }, []);

    useEffect(()=>{
     if(checkPermission("ACC_AUXILIARY_EDIT")){
        setLoader(true);
        loadDeviceAuxiliaryInputById(deviceId).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setPayload(res.data.data);
            }else{
                setViewErrorPage(true)
            }
            setLoader(false);
        });
     }
    },[permissions])

    const checkPermission = (permissionCode) => {  
        if(api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
          return true;
        else{
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
      }
      
    const resetFilter = () => {
        loadDeviceAuxiliaryInputById(deviceId).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setPayload(res.data.data);
                setError({
                    ...error,
                    name: false,
                });
            }
        });
    };

    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(payload.name)) {
            setError({
                ...error,
                name: t('AccAuxIn011'),
            });
            return;
        }

        const state = {
            address: payload.address,
            aux_id: payload.aux_id,
            aux_no: payload.aux_no,
            dev_id: payload.dev_id,
            deviceName: payload.deviceName,
            deviceSn: payload.deviceSn,
            disable: payload.disable,
            enable: payload.enable,
            ext_dev_id: payload.ext_dev_id,
            ipaddress: payload.ipaddress,
            name: payload.name,
            printer_number: payload.printer_number,
            remark: payload.remark,
            status: payload.status,
            timeseg_id: payload.timeseg_id
        };

        if (CommonUtil.isEmptyString(payload.aux_id)) {
        } else {
            setLoader(true);
            updateAuxIn(state).then((res) => {
                if (res.data.code === 'DMSI00456') {
                    enqueueSnackbar(t("AccAuxIn012"), { variant: 'success' });
                    handleClose();
                } else {
                    enqueueSnackbar((res.data.msg), { variant: 'error' })
                }
                setLoader(false);
            });
        }
    };

    const handleClose = () => {
        navigate(
            '/auxiliaryinput',
            { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })
    };


    const handleChange = (event) => {
        var val = event.target.value;
        if (val.trim() === '') {
            val = val.trim();
        }
        if (event.target.value.length > 0 && val.length == 0) {
            return;
        }
        if (val.length > 2) {
            if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
                return;
            }
            if (val[0] === ' ') {
                return;
            }
        }
        const name = event.target.name;
        setPayload({
            ...payload,
            [name]: event.target.value,
        });
        setError({
            ...error,
            [name]: false,
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const FieldName = () => (
        <CustomInput
            id="textfield-updateauxiliary-inputname"
            error={error.name}
            name='name'
            label={t('AccAuxIn007')}
            required
            size='large'
            onKeyDown={handleKeyPress}
            height={{xl:"64px",xs:"50px"}}
            padding={{xl:"0px 20px",xs:"0px 10px"}}
            helperText={error.name}
            placeholder={t('AccAuxIn013')}
            value={payload.name}
            handleChange={(data) => handleChange(data)}
            inputProps={{
                maxLength: 50,
            }}
        />
    );

    const FieldDevicename = () => (
        <CustomInput
            id="textfield-updateauxiliaryinput-devicename"
            disabled
            error={error.deviceName}
            name='deviceName'
            label={t('AccDevice014')}
            height={{xl:"64px",xs:"50px"}}
            padding={{xl:"0px 20px",xs:"0px 10px"}}
            size='large'
            onKeyDown={handleKeyPress}
            placeholder={t('AccReader021')}
            value={payload.deviceName}
            inputProps={{
                maxLength: 30,
            }}
        />
    );

    const FieldSerialNumber = () => (
        <CustomInput
            id="textfield-updateauxiliary-inputsn"
            disabled
            error={error.deviceSn}
            name='sn'
            label={'SN'}
            height={{xl:"64px",xs:"50px"}}
            padding={{xl:"0px 20px",xs:"0px 10px"}}
            size='large'
            onKeyDown={handleKeyPress}
            placeholder={t('AccReader022')}
            value={payload.deviceSn}
        />
    );

    const FieldAuxiliaryinputnum = () => (
        <CustomInput
            id="textfield-updateauxiliaryinputnum"
            disabled
            error={error.aux_no}
            name='number'
            label={t('AccAuxIn010')}
            height={{xl:"64px",xs:"50px"}}
            padding={{xl:"0px 20px",xs:"0px 10px"}}
            size='large'
            onKeyDown={handleKeyPress}
            placeholder={t('COMMON022')}
            value={payload.aux_no}
        />
    );

    const Fieldprinternum = () => (
        <CustomInput
            id="textfield-updateauxiliaryinput-printernum"
            disabled
            error={error.printer_number}
            name='printerName'
            label={t('AccAuxIn014')}
            height={{xl:"64px",xs:"50px"}}
            padding={{xl:"0px 20px",xs:"0px 10px"}}
            size='large'
            onKeyDown={handleKeyPress}
            placeholder={t('AccAuxIn015')}
            value={payload.printer_number}
        />
    );

    const FieldRemark = () => (
        <CustomInput
            // disabled
            id="textfield-updateauxiliaryinput-remark"
            error={error.remark}
            name='remark'
            label={t('COMMON007')}
            size='large'
            onKeyDown={handleKeyPress}
            height={{xl:"64px",xs:"50px"}}
            padding={{xl:"0px 20px",xs:"0px 10px"}}
            inputProps={{ maxLength: 50 }}
            placeholder={t('COMMON025')}
            value={payload.remark}
            handleChange={(data) => handleChange(data)}
        />
    );

    return (
        <>
            {viewErrorPage?<PageNotFound></PageNotFound>:perEditAuxIN ?checkPermission("ACC_AUXILIARY_EDIT") ?<ViewPage
                title={t("AccAuxIn006")}
                type='table'
                path={true}
                onClick={() => navigate(
                    '/auxiliaryinput',
                    { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
            >
                {loader?<Loader open={loader}/>:<></>}
                <Grid container ml={-0.5}>
                <Grid item xs={12} md={6} sx={{pr:{xl:"30px",md:"20px"}}}>
                       { FieldName() }
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <FieldDevicename />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pr:{xl:"30px",md:"20px"}}}>
                        <FieldSerialNumber />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <FieldAuxiliaryinputnum />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pr:{xl:"30px",md:"20px"}}}>
                        <Fieldprinternum />
                    </Grid>
                    <Grid item xs={12} md={6} >
                    { FieldRemark() }
                    </Grid>
                    <Grid item xs={12}
                        sx={{
                            mt: { xl: "10px", xs: "20px" },
                            display: "flex",
                            justifyContent: { lg: "flex-end", xs: "center" }
                        }}
                    >

                        <Button
                            id="button-updateauxiliaryinput-save"
                            onClick={handleSubmit}
                            variant='contained'
                            sx={{
                                width: { xl: "233px", xs: "180px" },
                                height: { xl: "64px", xs: "50px" },
                                marginRight: "30px"
                            }}
                        >
                            {t('COMMON023')}
                        </Button>

                        <Button
                            id="button-updateauxiliaryinput-clear"
                            onClick={() => resetFilter()}
                            variant='outlined'
                            sx={{
                                width: { xl: "233px", xs: "180px" },
                                height: { xl: "64px", xs: "50px" },
                            }}
                        >
                            {t('COMMON015')}
                        </Button>

                    </Grid>
                </Grid>
            </ViewPage>
                : (<Box marginTop={'150px'} width={'100%'}>
                    <Typography textAlign={'center'}>{t("AccAuxIn017")}</Typography>
                </Box>):<></>}
        </>
    );
}
