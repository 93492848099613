import DataTable from '../../components/layout-components/DataTable';
import { Grid, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem } from '@mui/x-data-grid';
import OverflowTip from '../../components/tooltip';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import RightViewLayout from '../../components/RighViewLayout';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useContext, useEffect, useState } from "react";
import { loadDeviceReader } from '../../services/ReaderService';
import { withStyles } from '@mui/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { REACT_URL_READER_EDIT, REACT_URL_READER_VIEW } from '../../constants/ReactEndPoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { FilterComponent } from '../../components/FilterComponent';
import CustomInput from '../../components/CustomInput';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import { ReactComponent as ViewIcon } from '../../assets/ViewIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';
import PaginationContext from '../../components/layout-components/PaginationContext';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ffffff !important',
        color: '#474B4F !important',
        maxWidth: 250,
        fontSize: '14px',
        border: '1px solid #76B745 !important',
    },
}))(Tooltip);

function Reader() {

    const { t } = useTranslation()
    const [readers, setReaders] = useState([]);
    const location = useLocation();
    const { manageReaderPageNumber, setManageReaderPageNumber, manageReaderPageSize, setManageReaderPageSize } = useContext(PaginationContext);
    const [page, setPage] = React.useState(manageReaderPageNumber ? manageReaderPageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(manageReaderPageSize ? manageReaderPageSize : 10);
    const [perViewReader, setViewReader] = useState(true);
    const [perListReader, setListReader] = useState(true);
    const [perEditReader, setEditReader] = useState(true);
    const [ids, setIds] = useState([]);

    const [open, setOpen] = useState(false);

    const [filter, setFilter] = React.useState(false);
    const [load, setLoad] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [change, setChange] = React.useState(false);
    const [reload, setReload] = useState(false);
    const [clearAndRefresh, setClearAndRefresh] = useState(false);

    const defaultFilter = { doorName: '', deviceAlias: '', name: '' };
    const [filterData, setFilterData] = useState({ ...defaultFilter, });

    let navigate = useNavigate();

    useEffect(() => {
        if (roles.includes("ACC_READER_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
            if (load && !filter) {
                loadDeviceReader(page, rowsPerPage, filterData).then((res) => {
                    if (res?.data?.code === 'DMSI0000') {
                        setReaders(res.data.data.Readers);
                        setTotalRecords(res.data.data.totalCount);
                    } else {
                        setReaders([]);
                        setTotalRecords(0);
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        if (filter && !load && reload) {
            loadData(1, rowsPerPage, filterData);
            setReload(false)
        }
    }, [filter, reload]);

    React.useEffect(() => {
        if (load && !filter) {
            getPermissionsByRoleId(api.decoded().roleId).then((res) => {
                var l = [];
                res.data.data.rolePermissions.map((item, index) => {
                    l.push(item.code);
                });
                setRoles(l);
                setEditReader(l.includes("ACC_READER_EDIT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                setViewReader(l.includes("ACC_READER_VIEW") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                setListReader(l.includes("ACC_READER_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

                if (l.includes("ACC_READER_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
                    loadData(page, rowsPerPage, defaultFilter);
                }
            });
        }
    }, []);

    const [roles, setRoles] = React.useState(['']);

    const columns = [
        {
            field: 'name',
            hideable: false,
            headerName: t('AccReader005'),
            flex: 1.4,
            renderHeader: (e) => (
                <>
                    <Tooltip title={t('AccReader005')} >
                        <span>
                            {t("AccReader005")}
                        </span>
                    </Tooltip>
                    <HtmlTooltip arrow title={t('AccReader004')} >
                        <QuestionMarkIcon style={{ height: 16, width: 16 }} />
                    </HtmlTooltip>
                </>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.name}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'door',
            headerName: t('AccDMTopology011'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDMTopology011')} >
                    <span>
                        {t('AccDMTopology011')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.doorName}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'deviceName',
            headerName: t('AccDevice014'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDevice014')} >
                    <span>
                        {t('AccDevice014')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.deviceName}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'sn',
            headerName: t('AccDevice013'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDevice013')} >
                    <span>
                        {t('AccDevice013')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.deviceSn}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'number',
            headerName: t('AccDMTopology008'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccReader009')} >
                    <span>
                        {t('AccReader009')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.readerNo}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'commType',
            headerName: t('AccDMTopology022'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDMTopology022')} >
                    <span>
                        {t("AccDMTopology022")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.commType}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'commAddress',
            headerName: t('AccDMTopology023'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDMTopology023')} >
                    <span>
                        {t('AccDMTopology023')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.commAddress}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'status',
            headerName: t('AccReader006'),
            flex: 0.7,
            renderHeader: (e) => (
                <Tooltip title={t('AccReader006')} >
                    <span>
                        {t("AccReader006")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.readerState == '0' ? 'In' : 'Out'}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            headerName: 'Actions',
            sortable: false,
            hideable: false,
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('COMMON019')} >
                    <span>
                        {t("COMMON019")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <>
                    <Box sx={{ flex: "0.78", display: 'flex',marginLeft: '20px', alignItems: 'end',}}>
                        {perViewReader && (<GridActionsCellItem
                            sx={{ marginRight: { xl: "10px", xs: "10px" } }}
                            icon={<Tooltip title={t('AccReader007')}>
                                <ViewIcon id="icon-readervisibility"
                                    onClick={() => navigate(REACT_URL_READER_VIEW + `/${e.row.readerId}`, { state: { pageNumber: page, pageSize: rowsPerPage } })} />
                            </Tooltip>} />)}
                        {perEditReader && (<GridActionsCellItem
                            sx={{ marginRight: { xl: "10px", xs: "10px" } }}
                            icon={<Tooltip title={t('AccReader008')}>
                                <EditIcon id="icon-reader-edit"
                                    onClick={() => navigate(REACT_URL_READER_EDIT + `/${e.row.readerId}`, { state: { reader: e.row, pageNumber: page, pageSize: rowsPerPage } })} />
                            </Tooltip>} />)}
                    </Box>
                </>
            ),
        },
    ];

    const loadData = (pageNumber, pageSize, filter) => {
        setIds([]);
        setTotalRecords(0)
        loadDeviceReader(pageNumber, pageSize, filter).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setReaders(res.data.data.Readers);
                setTotalRecords(res.data.data.totalCount);
            }
            else {
                setTotalRecords(0);
                setReaders([]);
            }
            setClearAndRefresh(false);
        });
    };

    const defaultPageSize = 10;

    const refreshTableData = () => {
        setClearAndRefresh(true);
        if (page === 1) {
            // setFilterData(defaultFilter);
            // setDisabled(true)
            // setFilter(false);
            setLoad(true);
            loadData(1, manageReaderPageSize, filterData);
            setRowsPerPage(manageReaderPageSize);
        }
        else {
            // setFilterData(defaultFilter);
            // setDisabled(true)
            // setFilter(false);
            setLoad(true);
            setPage(filter ? 1 : manageReaderPageNumber);
            setRowsPerPage(manageReaderPageSize);
            loadData(filter ? 1 : manageReaderPageNumber, manageReaderPageSize, filterData);
        }
    };
    //Filter  Related
    const handleChange = (event) => {
        setChange(true);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
    };

    useEffect(() => {
        if (filterData.name === '' && filterData.deviceAlias === '') {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [filterData]);

    const clearFilter = () => {
        setFilterData(defaultFilter);
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, defaultPageSize, defaultFilter);
            setRowsPerPage(defaultPageSize);
        }
        else setPage(1);
        setRowsPerPage(defaultPageSize);
    }

    const handleSubmitFilter = () => {
        setFilter(true);
        setLoad(false);
        setReload(true)
    }


    const FieldReaderName = (key) => (
        <CustomInput
            id="textfield-reader-name"
            placeholder={t('AccReader005')}

            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            value={filterData.name}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='name'
            //label='Person ID'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 50,
            }}
        />
    );

    const FieldDoorName = (key) => (
        <CustomInput
            id="textfield-reader-doorname"
            placeholder={t('AccDMTopology011')}
            style={{ margin: '2px' }}
            value={filterData.doorName}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='doorName'
            //label='Person ID'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 50,
            }}

        />
    );

    const FieldDeviceName = (key) => (
        <CustomInput
            id="textfield-reader-devicename"
            placeholder={t('AccDevice014')}

            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            value={filterData.deviceAlias}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='deviceAlias'
            //label='Person ID'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 30,
            }}

        />
    );

    const actions = () => (
        <Box item display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }} >
            <Tooltip title={t('ACCORG008')}>
                <Box
                    aria-label="refresh"
                    onClick={() => refreshTableData()}
                    mr={2}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"refresh-Icon"}
                >
                    <RefreshIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => refreshTableData()}
                    mr={2}
                    sx={{ display: { xl: "none", xs: "block" } }}
                >
                    <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
            <Tooltip title={t('ACCORG009')}>
                <Box
                    aria-label="filter"
                    onClick={() => setOpen(!open)}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => setOpen(!open)}
                    sx={{ display: { xl: "none", xs: "block" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
        </Box>
    );

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitFilter();
        }
    };

    useEffect(() => {
        if (!open && !disabled) {
            setPage(1);
            setChange(false);
            setFilterData(defaultFilter);
            loadData(1, manageReaderPageSize, defaultFilter);
        }
    }, [open]);

    const handlePageChange = (e) => {
        if (filter) {
            setPage(e);
            setManageReaderPageNumber(e);
            loadData(e, rowsPerPage, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setPage(e);
            setManageReaderPageNumber(e);
        }
    }

    const handlePageSize = (e) => {
        if (filter) {
            setRowsPerPage(e);
            setManageReaderPageSize(e);
            setPage(1);
            loadData(1, e, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setRowsPerPage(e);
            setManageReaderPageSize(e);
            setPage(1);
        }
    }

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    return (
        <>
            {perListReader ? (<> <RightViewLayout title={t("AccReader001")} navigateBack={true}>
                {actions()}
            </RightViewLayout>
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open && <FilterComponent
                        firstField={FieldReaderName()}
                        secondField={FieldDeviceName()}
                        clearFilter={clearFilter}
                        handleSubmit={handleSubmitFilter}
                        disabled={disabled}
                    />}
                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        columns={columns}
                        getRowId={(row) => row.readerId}
                        rows={readers}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        onPageChange={(e) => handlePageChange(e + 1)}
                        onPageSizeChange={(e) => handlePageSize(e)}
                        noRecordText={t("AccReader002")} />
                </Box></>) : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={'center'}>{t("AccReader003")}</Typography></Box>)}
        </>
    );
}

export default Reader;